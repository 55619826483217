import * as yup from "yup";

export const signupFormValidationSchema = yup.object().shape({
  mobile_number: yup
    .string()
    .nullable()
    .required("Phone number is required")
    .test("valid", "Phone number is not valid", (value, { parent }) => {
      return parent.isValid;
    }),
  isValid: yup.boolean().nullable().required(""),
  username: yup
    .string()
    .nullable()
    .min(2, "Please enter you username")
    .required("Username is required"),
  agency_name: yup
    .string()
    .nullable()
    .min(2, "Please enter a valid agency name")
    .required("Agency name is required"),
  email: yup
    .string()
    .required("Please enter email.")
    .email("Please enter valid email"),
});
