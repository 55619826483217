<template>
  <AGContainer class="login_wrapper">
    <AGPanel>
      <AGLogo />
      <div class="panel-container">
        <h4>Congratulations!</h4>
        <h5>Your account has been created successfully</h5>
        <p>
          We've just sent you a confirmation email. Take a moment to check your
          inbox and click on the confirmation link enclosed. This will enable
          you to create your account credentials seamlessly.
        </p>
        <p class="bottom-note">Thanks for being a part of Aeroglobe</p>
        <br />
        <AgDiv class="button_container">
          <AGButton @click="handleProceedToPortalBtn">
            Proceed to Aeroglobe Portal
          </AGButton>
        </AgDiv>
      </div>
    </AGPanel>
  </AGContainer>
</template>

<script lang="ts">
import { AGButton } from "@aeroglobe/ag-core-ui";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SignupSuccess",
  methods: {
    handleProceedToPortalBtn() {
      this.$router.push("/");
    },
  },
});
</script>

<style scoped>
.panel-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
h4 {
  color: #333;
  margin-top: 0;
  text-align: center;
  font-weight: bold;
  margin-bottom: 40px;
  margin-top: 30px;
}
h5 {
  color: #555;
  margin-top: 5px;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
}
p {
  color: #666;
  margin: 10px 0;
}
.bottom-note {
  text-align: center;
  font-weight: bold;
  margin-top: 30px;
}
.button_container {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  h5 {
    text-wrap: balance;
  }
}
</style>
