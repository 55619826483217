import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShimmerCard = _resolveComponent("ShimmerCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ShimmerCard, { "card-height": "200px" }),
    _createVNode(_component_ShimmerCard, { "card-height": "100px" }),
    _createVNode(_component_ShimmerCard, { "card-height": "100px" }),
    _createVNode(_component_ShimmerCard, { "card-height": "75px" })
  ], 64 /* STABLE_FRAGMENT */))
}