<template>
  <AgCard>
    <AgDiv class="insights_overall_booking_header">
      <h3>Over All Bookings</h3>
      <div class="insights_header_action_btn">
        <ag-heading title="Last" variant="label" class="input_label" />
        <AGTextField v-model="value" :value="value" type="text" />
        <ag-select
          :items="items"
          v-model="aggregated_by"
          :value="aggregated_by"
          variant="outlined"
        />
        <AGButton
          variant="primary"
          :is-loading="isLoading"
          class="fire_btn_wrap"
          id="fetch_btn"
          icon="refreshIcon"
          @click="fetchData"
          >Fetch</AGButton
        >
      </div>
    </AgDiv>

    <AgCard class="p-0">
      <AgRow>
        <AgOverallBooking
          v-for="(item, index) in $store.getters.bookingAggregate"
          :key="index"
          :category="renderCategory(item.name)"
          :booking="item.booking_count"
          :earnings="formattedEarnings(item.total_earnings)"
          :categoryIcon="renderIcon(item.name)"
        />
      </AgRow>
    </AgCard>

    <highcharts class="hc" :options="chartOptions" ref="chart" />
  </AgCard>
</template>

<script>
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import { getCurrencyFormatter } from "@/ag-portal-common/utils/helpers";
import { capitalize } from "lodash";

import { IOrganization } from "@/ag-portal-common/interfaces/organization.interface";

exportingInit(Highcharts);

export default {
  data() {
    return {
      value: 5,
      aggregated_by: "day",
      items: [
        { label: "Days", value: "day" },
        { label: "Weeks", value: "week" },
        { label: "Months", value: "month" },
      ],
    };
  },
  computed: {
    getCurrency() {
      return localStorage.getItem("currency") || "PKR";
    },
    isLoading() {
      return this.$store.getters.isDashboardInsightsLoading;
    },
    chartOptions() {
      const insights = this.$store.state.dashboardModule?.insights;
      const options = {
        chart: {
          type: "column",
        },
        title: {
          text: "Revenue Overview",
        },
        xAxis: {
          categories: insights?.x_legends?.map((item) => item.name),
          crosshair: true,
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        series: this.generateSeries(),
      };
      return options;
    },
  },
  methods: {
    generateSeries() {
      const insights = this.$store.state.dashboardModule?.insights;
      const seriesData = insights?.y_legends?.map((item) => ({
        ...item,
        data: [],
      }));
      insights?.matrix?.forEach((dataSet) => {
        dataSet.forEach((dataPoint, i) => {
          seriesData[i].data.push(dataPoint);
        });
      });
      return seriesData;
    },
    fetchData() {
      this.$store.dispatch("getInsights", {
        value: this.value,
        aggregated_by: this.aggregated_by,
      });
    },
    formattedEarnings(earning) {
      const currency = this.getCurrency;
      const formattedEarning = getCurrencyFormatter(currency).format(earning);
      return formattedEarning;
    },
    renderCategory(category) {
      return capitalize(category);
    },
    renderIcon(name) {
      const iconMap = {
        flight: "plane_icon",
        hotel: "bedIcon",
        tour: "flag_icon",
      };

      return iconMap[name] || "flag_icon";
    },
  },
  beforeMount() {
    this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
.insights_overall_booking_header {
  display: flex;
  justify-content: space-between;

  button {
    margin-bottom: 30px;
  }

  @media (max-width: 767px) {
    display: block;
  }

  .insights_header_action_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .input_label {
      font-size: 19px;
      font-weight: bold;
      margin-bottom: 24px;
    }

    * {
      margin-right: 5px;
    }

    @media (max-width: 767px) {
      justify-content: flex-start;
      margin-top: 15px;
      margin-bottom: 0px;
    }
  }
}
</style>

<!-- 



  
const responseData = {
    "issuances": {
        "00:00": {
            "providers": [
                {
                    "provider": "airsial",
                    "count": 20
                },
                {
                    "provider": "airblue",
                    "count": 10
                },
                {
                    "provider": "flyjinnah",
                    "count": 30
                }
            ],
            "total_issuances": 60
        },
        "01:00": {
            "providers": [
                {
                    "provider": "airsial",
                    "count": 20
                },
                {
                    "provider": "airblue",
                    "count": 10
                },
                {
                    "provider": "flyjinnah",
                    "count": 30
                }
            ],
            "total_issuances": 60
        }
    },
    "confirmed": {
        "00:00": {
            "providers": [
                {
                    "provider": "airsial",
                    "count": 20
                },
                {
                    "provider": "airblue",
                    "count": 10
                },
                {
                    "provider": "flyjinnah",
                    "count": 30
                }
            ],
            "total_confirmed": 60
        },
        "01:00": {
            "providers": [
                {
                    "provider": "airsial",
                    "count": 20
                },
                {
                    "provider": "airblue",
                    "count": 10
                },
                {
                    "provider": "flyjinnah",
                    "count": 30
                }
            ],
            "total_confirmed": 60
        }
    }
}

// Extracting hours and data for the chart
  const hours = Object.keys(responseData.issuances);
  const providerNames = Array.from(new Set(hours.flatMap(hour => responseData.issuances[hour].providers.map(provider => provider.provider))));
  
  const seriesData = providerNames.map(provider => {
    return {
      name: provider,
      data: hours.map(hour => {
        const providerData = responseData.issuances[hour].providers.find(p => p.provider === provider);
        return providerData ? providerData.count : 0;
      }),
    };
  });



Highcharts.chart('container', {

    chart: {
      type: 'column',
    },
    title: {
      text: '24-Hour Provider Breakdown Chart',
    },
    xAxis: {
      categories: hours,
    },
    yAxis: {
      title: {
        text: 'Total Issuances',
      },
                crosshair: true,

    },
    series: seriesData,
    plotOptions: {
      bar: {
        grouping: true,
      },
    },
  });


 -->
