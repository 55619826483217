import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_AgCard = _resolveComponent("AgCard")!
  const _component_ShimmerCard = _resolveComponent("ShimmerCard")!
  const _component_OrganizationForm = _resolveComponent("OrganizationForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AgCard, null, {
      default: _withCtx(() => [
        _createVNode(_component_ag_heading, {
          variant: "h2",
          title: "Update Organization"
        })
      ]),
      _: 1 /* STABLE */
    }),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_ShimmerCard, {
          key: 0,
          cardHeight: "400px"
        }))
      : (_openBlock(), _createBlock(_component_AgCard, { key: 1 }, {
          default: _withCtx(() => [
            (_ctx.$store.getters.organization)
              ? (_openBlock(), _createBlock(_component_OrganizationForm, {
                  key: 0,
                  organizationData: _ctx.$store.getters.organization,
                  onOnSubmitOrganizationForm: _ctx.updateOrganization,
                  onLoading: _ctx.$store.getters.isUpdatingOrganization
                }, null, 8 /* PROPS */, ["organizationData", "onOnSubmitOrganizationForm", "onLoading"]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }))
  ], 64 /* STABLE_FRAGMENT */))
}