export const PKCities = [
  {
    label: "Abbottabad",
    value: "Abbottabad",
  },
  {
    label: "Adilpur",
    value: "Adilpur",
  },
  {
    label: "Ahmadpur East",
    value: "Ahmadpur East",
  },
  {
    label: "Ahmadpur Sial",
    value: "Ahmadpur Sial",
  },
  {
    label: "Akora",
    value: "Akora",
  },
  {
    label: "Aliabad",
    value: "Aliabad",
  },
  {
    label: "Alik Ghund",
    value: "Alik Ghund",
  },
  {
    label: "Alipur",
    value: "Alipur",
  },
  {
    label: "Alizai",
    value: "Alizai",
  },
  {
    label: "Alpurai",
    value: "Alpurai",
  },
  {
    label: "Aman Garh",
    value: "Aman Garh",
  },
  {
    label: "Amirabad",
    value: "Amirabad",
  },
  {
    label: "Arifwala",
    value: "Arifwala",
  },
  {
    label: "Ashanagro Koto",
    value: "Ashanagro Koto",
  },
  {
    label: "Athmuqam",
    value: "Athmuqam",
  },
  {
    label: "Attock City",
    value: "Attock City",
  },
  {
    label: "Awaran",
    value: "Awaran",
  },
  {
    label: "Baddomalhi",
    value: "Baddomalhi",
  },
  {
    label: "Badin",
    value: "Badin",
  },
  {
    label: "Baffa",
    value: "Baffa",
  },
  {
    label: "Bagarji",
    value: "Bagarji",
  },
  {
    label: "Bagh",
    value: "Bagh",
  },
  {
    label: "Bahawalnagar",
    value: "Bahawalnagar",
  },
  {
    label: "Bahawalnagar",
    value: "Bahawalnagar",
  },
  {
    label: "Bahawalpur",
    value: "Bahawalpur",
  },
  {
    label: "Bakhri Ahmad Khan",
    value: "Bakhri Ahmad Khan",
  },
  {
    label: "Bandhi",
    value: "Bandhi",
  },
  {
    label: "Bannu",
    value: "Bannu",
  },
  {
    label: "Barishal",
    value: "Barishal",
  },
  {
    label: "Barkhan",
    value: "Barkhan",
  },
  {
    label: "Basirpur",
    value: "Basirpur",
  },
  {
    label: "Basti Dosa",
    value: "Basti Dosa",
  },
  {
    label: "Bat Khela",
    value: "Bat Khela",
  },
  {
    label: "Battagram",
    value: "Battagram",
  },
  {
    label: "Begowala",
    value: "Begowala",
  },
  {
    label: "Bela",
    value: "Bela",
  },
  {
    label: "Berani",
    value: "Berani",
  },
  {
    label: "Bhag",
    value: "Bhag",
  },
  {
    label: "Bhakkar",
    value: "Bhakkar",
  },
  {
    label: "Bhalwal",
    value: "Bhalwal",
  },
  {
    label: "Bhan",
    value: "Bhan",
  },
  {
    label: "Bhawana",
    value: "Bhawana",
  },
  {
    label: "Bhera",
    value: "Bhera",
  },
  {
    label: "Bhimbar",
    value: "Bhimbar",
  },
  {
    label: "Bhiria",
    value: "Bhiria",
  },
  {
    label: "Bhit Shah",
    value: "Bhit Shah",
  },
  {
    label: "Bhopalwala",
    value: "Bhopalwala",
  },
  {
    label: "Bozdar Wada",
    value: "Bozdar Wada",
  },
  {
    label: "Bulri",
    value: "Bulri",
  },
  {
    label: "Būrewāla",
    value: "Būrewāla",
  },
  {
    label: "Chak",
    value: "Chak",
  },
  {
    label: "Chak Azam Sahu",
    value: "Chak Azam Sahu",
  },
  {
    label: "Chak Five Hundred Seventy-five",
    value: "Chak Five Hundred Seventy-five",
  },
  {
    label: "Chak Jhumra",
    value: "Chak Jhumra",
  },
  {
    label: "Chak One Hundred Twenty Nine Left",
    value: "Chak One Hundred Twenty Nine Left",
  },
  {
    label: "Chak Thirty-one -Eleven Left",
    value: "Chak Thirty-one -Eleven Left",
  },
  {
    label: "Chak Two Hundred Forty-nine Thal Development Authority",
    value: "Chak Two Hundred Forty-nine Thal Development Authority",
  },
  {
    label: "Chakwal",
    value: "Chakwal",
  },
  {
    label: "Chaman",
    value: "Chaman",
  },
  {
    label: "Chamber",
    value: "Chamber",
  },
  {
    label: "Charsadda",
    value: "Charsadda",
  },
  {
    label: "Chawinda",
    value: "Chawinda",
  },
  {
    label: "Chenab Nagar",
    value: "Chenab Nagar",
  },
  {
    label: "Cherat Cantonement",
    value: "Cherat Cantonement",
  },
  {
    label: "Chhor",
    value: "Chhor",
  },
  {
    label: "Chichawatni",
    value: "Chichawatni",
  },
  {
    label: "Chilas",
    value: "Chilas",
  },
  {
    label: "Chiniot",
    value: "Chiniot",
  },
  {
    label: "Chishtian",
    value: "Chishtian",
  },
  {
    label: "Chitral",
    value: "Chitral",
  },
  {
    label: "Choa Saidan Shah",
    value: "Choa Saidan Shah",
  },
  {
    label: "Chowki Jamali",
    value: "Chowki Jamali",
  },
  {
    label: "Chuchar-kana Mandi",
    value: "Chuchar-kana Mandi",
  },
  {
    label: "Chuhar Jamali",
    value: "Chuhar Jamali",
  },
  {
    label: "Chunian",
    value: "Chunian",
  },
  {
    label: "Dadhar",
    value: "Dadhar",
  },
  {
    label: "Dadu",
    value: "Dadu",
  },
  {
    label: "Daggar",
    value: "Daggar",
  },
  {
    label: "Daira Din Panah",
    value: "Daira Din Panah",
  },
  {
    label: "Dajal",
    value: "Dajal",
  },
  {
    label: "Dalbandin",
    value: "Dalbandin",
  },
  {
    label: "Dandot RS",
    value: "Dandot RS",
  },
  {
    label: "Daromehar",
    value: "Daromehar",
  },
  {
    label: "Darya Khan",
    value: "Darya Khan",
  },
  {
    label: "Darya Khan Marri",
    value: "Darya Khan Marri",
  },
  {
    label: "Daska Kalan",
    value: "Daska Kalan",
  },
  {
    label: "Dasu",
    value: "Dasu",
  },
  {
    label: "Daud Khel",
    value: "Daud Khel",
  },
  {
    label: "Daulatpur",
    value: "Daulatpur",
  },
  {
    label: "Daultala",
    value: "Daultala",
  },
  {
    label: "Daur",
    value: "Daur",
  },
  {
    label: "Dera Allahyar",
    value: "Dera Allahyar",
  },
  {
    label: "Dera Bugti",
    value: "Dera Bugti",
  },
  {
    label: "Dera Ghazi Khan",
    value: "Dera Ghazi Khan",
  },
  {
    label: "Dera Ismail Khan",
    value: "Dera Ismail Khan",
  },
  {
    label: "Dera Murad Jamali",
    value: "Dera Murad Jamali",
  },
  {
    label: "Dhanot",
    value: "Dhanot",
  },
  {
    label: "Dhaunkal",
    value: "Dhaunkal",
  },
  {
    label: "Dhoro Naro",
    value: "Dhoro Naro",
  },
  {
    label: "Digri",
    value: "Digri",
  },
  {
    label: "Dijkot",
    value: "Dijkot",
  },
  {
    label: "Dinan Bashnoian Wala",
    value: "Dinan Bashnoian Wala",
  },
  {
    label: "Dinga",
    value: "Dinga",
  },
  {
    label: "Dipalpur",
    value: "Dipalpur",
  },
  {
    label: "Diplo",
    value: "Diplo",
  },
  {
    label: "Doaba",
    value: "Doaba",
  },
  {
    label: "Dokri",
    value: "Dokri",
  },
  {
    label: "Duki",
    value: "Duki",
  },
  {
    label: "Dullewala",
    value: "Dullewala",
  },
  {
    label: "Dunga Bunga",
    value: "Dunga Bunga",
  },
  {
    label: "Dunyapur",
    value: "Dunyapur",
  },
  {
    label: "Eidgah",
    value: "Eidgah",
  },
  {
    label: "Eminabad",
    value: "Eminabad",
  },
  {
    label: "Faisalabad",
    value: "Faisalabad",
  },
  {
    label: "Faqirwali",
    value: "Faqirwali",
  },
  {
    label: "Faruka",
    value: "Faruka",
  },
  {
    label: "Fazilpur",
    value: "Fazilpur",
  },
  {
    label: "Fort Abbas",
    value: "Fort Abbas",
  },
  {
    label: "Gadani",
    value: "Gadani",
  },
  {
    label: "Gakuch",
    value: "Gakuch",
  },
  {
    label: "Gambat",
    value: "Gambat",
  },
  {
    label: "Gandava",
    value: "Gandava",
  },
  {
    label: "Garh Maharaja",
    value: "Garh Maharaja",
  },
  {
    label: "Garhi Khairo",
    value: "Garhi Khairo",
  },
  {
    label: "Garhiyasin",
    value: "Garhiyasin",
  },
  {
    label: "Gharo",
    value: "Gharo",
  },
  {
    label: "Ghauspur",
    value: "Ghauspur",
  },
  {
    label: "Ghotki",
    value: "Ghotki",
  },
  {
    label: "Gilgit",
    value: "Gilgit",
  },
  {
    label: "Gojra",
    value: "Gojra",
  },
  {
    label: "Goth Garelo",
    value: "Goth Garelo",
  },
  {
    label: "Goth Phulji",
    value: "Goth Phulji",
  },
  {
    label: "Goth Radhan",
    value: "Goth Radhan",
  },
  {
    label: "Gujar Khan",
    value: "Gujar Khan",
  },
  {
    label: "Gujranwala",
    value: "Gujranwala",
  },
  {
    label: "Gujrat",
    value: "Gujrat",
  },
  {
    label: "Gulishah Kach",
    value: "Gulishah Kach",
  },
  {
    label: "Gwadar",
    value: "Gwadar",
  },
  {
    label: "Hadali",
    value: "Hadali",
  },
  {
    label: "Hafizabad",
    value: "Hafizabad",
  },
  {
    label: "Hala",
    value: "Hala",
  },
  {
    label: "Hangu",
    value: "Hangu",
  },
  {
    label: "Haripur",
    value: "Haripur",
  },
  {
    label: "Harnai",
    value: "Harnai",
  },
  {
    label: "Harnoli",
    value: "Harnoli",
  },
  {
    label: "Harunabad",
    value: "Harunabad",
  },
  {
    label: "Hasilpur",
    value: "Hasilpur",
  },
  {
    label: "Hattian Bala",
    value: "Hattian Bala",
  },
  {
    label: "Haveli Lakha",
    value: "Haveli Lakha",
  },
  {
    label: "Havelian",
    value: "Havelian",
  },
  {
    label: "Hazro City",
    value: "Hazro City",
  },
  {
    label: "Hingorja",
    value: "Hingorja",
  },
  {
    label: "Hujra Shah Muqim",
    value: "Hujra Shah Muqim",
  },
  {
    label: "Hyderabad",
    value: "Hyderabad",
  },
  {
    label: "Islamabad",
    value: "Islamabad",
  },
  {
    label: "Islamkot",
    value: "Islamkot",
  },
  {
    label: "Jacobabad",
    value: "Jacobabad",
  },
  {
    label: "Jahanian Shah",
    value: "Jahanian Shah",
  },
  {
    label: "Jalalpur Jattan",
    value: "Jalalpur Jattan",
  },
  {
    label: "Jalalpur Pirwala",
    value: "Jalalpur Pirwala",
  },
  {
    label: "Jampur",
    value: "Jampur",
  },
  {
    label: "Jamshoro",
    value: "Jamshoro",
  },
  {
    label: "Jand",
    value: "Jand",
  },
  {
    label: "Jandiala Sher Khan",
    value: "Jandiala Sher Khan",
  },
  {
    label: "Jaranwala",
    value: "Jaranwala",
  },
  {
    label: "Jati",
    value: "Jati",
  },
  {
    label: "Jatoi Shimali",
    value: "Jatoi Shimali",
  },
  {
    label: "Jauharabad",
    value: "Jauharabad",
  },
  {
    label: "Jhang City",
    value: "Jhang City",
  },
  {
    label: "Jhang Sadr",
    value: "Jhang Sadr",
  },
  {
    label: "Jhawarian",
    value: "Jhawarian",
  },
  {
    label: "Jhelum",
    value: "Jhelum",
  },
  {
    label: "Jhol",
    value: "Jhol",
  },
  {
    label: "Jiwani",
    value: "Jiwani",
  },
  {
    label: "Johi",
    value: "Johi",
  },
  {
    label: "Jām Sāhib",
    value: "Jām Sāhib",
  },
  {
    label: "Kabirwala",
    value: "Kabirwala",
  },
  {
    label: "Kadhan",
    value: "Kadhan",
  },
  {
    label: "Kahna Nau",
    value: "Kahna Nau",
  },
  {
    label: "Kahror Pakka",
    value: "Kahror Pakka",
  },
  {
    label: "Kahuta",
    value: "Kahuta",
  },
  {
    label: "Kakad Wari Dir Upper",
    value: "Kakad Wari Dir Upper",
  },
  {
    label: "Kalabagh",
    value: "Kalabagh",
  },
  {
    label: "Kalaswala",
    value: "Kalaswala",
  },
  {
    label: "Kalat",
    value: "Kalat",
  },
  {
    label: "Kaleke Mandi",
    value: "Kaleke Mandi",
  },
  {
    label: "Kallar Kahar",
    value: "Kallar Kahar",
  },
  {
    label: "Kalur Kot",
    value: "Kalur Kot",
  },
  {
    label: "Kamalia",
    value: "Kamalia",
  },
  {
    label: "Kamar Mushani",
    value: "Kamar Mushani",
  },
  {
    label: "Kambar",
    value: "Kambar",
  },
  {
    label: "Kamoke",
    value: "Kamoke",
  },
  {
    label: "Kamra",
    value: "Kamra",
  },
  {
    label: "Kandhkot",
    value: "Kandhkot",
  },
  {
    label: "Kandiari",
    value: "Kandiari",
  },
  {
    label: "Kandiaro",
    value: "Kandiaro",
  },
  {
    label: "Kanganpur",
    value: "Kanganpur",
  },
  {
    label: "Karachi",
    value: "Karachi",
  },
  {
    label: "Karak",
    value: "Karak",
  },
  {
    label: "Karaundi",
    value: "Karaundi",
  },
  {
    label: "Kario Ghanwar",
    value: "Kario Ghanwar",
  },
  {
    label: "Karor",
    value: "Karor",
  },
  {
    label: "Kashmor",
    value: "Kashmor",
  },
  {
    label: "Kasur",
    value: "Kasur",
  },
  {
    label: "Keshupur",
    value: "Keshupur",
  },
  {
    label: "Keti Bandar",
    value: "Keti Bandar",
  },
  {
    label: "Khadan Khak",
    value: "Khadan Khak",
  },
  {
    label: "Khadro",
    value: "Khadro",
  },
  {
    label: "Khairpur",
    value: "Khairpur",
  },
  {
    label: "Khairpur Mir’s",
    value: "Khairpur Mir’s",
  },
  {
    label: "Khairpur Nathan Shah",
    value: "Khairpur Nathan Shah",
  },
  {
    label: "Khairpur Tamewah",
    value: "Khairpur Tamewah",
  },
  {
    label: "Khalabat",
    value: "Khalabat",
  },
  {
    label: "Khandowa",
    value: "Khandowa",
  },
  {
    label: "Khanewal",
    value: "Khanewal",
  },
  {
    label: "Khangah Dogran",
    value: "Khangah Dogran",
  },
  {
    label: "Khangarh",
    value: "Khangarh",
  },
  {
    label: "Khanpur",
    value: "Khanpur",
  },
  {
    label: "Khanpur Mahar",
    value: "Khanpur Mahar",
  },
  {
    label: "Kharan",
    value: "Kharan",
  },
  {
    label: "Kharian",
    value: "Kharian",
  },
  {
    label: "Khewra",
    value: "Khewra",
  },
  {
    label: "Khurrianwala",
    value: "Khurrianwala",
  },
  {
    label: "Khushāb",
    value: "Khushāb",
  },
  {
    label: "Khuzdar",
    value: "Khuzdar",
  },
  {
    label: "Kohat",
    value: "Kohat",
  },
  {
    label: "Kohlu",
    value: "Kohlu",
  },
  {
    label: "Kot Addu",
    value: "Kot Addu",
  },
  {
    label: "Kot Diji",
    value: "Kot Diji",
  },
  {
    label: "Kot Ghulam Muhammad",
    value: "Kot Ghulam Muhammad",
  },
  {
    label: "Kot Malik Barkhurdar",
    value: "Kot Malik Barkhurdar",
  },
  {
    label: "Kot Mumin",
    value: "Kot Mumin",
  },
  {
    label: "Kot Radha Kishan",
    value: "Kot Radha Kishan",
  },
  {
    label: "Kot Rajkour",
    value: "Kot Rajkour",
  },
  {
    label: "Kot Samaba",
    value: "Kot Samaba",
  },
  {
    label: "Kot Sultan",
    value: "Kot Sultan",
  },
  {
    label: "Kotli",
    value: "Kotli",
  },
  {
    label: "Kotli Loharan",
    value: "Kotli Loharan",
  },
  {
    label: "Kotri",
    value: "Kotri",
  },
  {
    label: "Kulachi",
    value: "Kulachi",
  },
  {
    label: "Kundian",
    value: "Kundian",
  },
  {
    label: "Kunjah",
    value: "Kunjah",
  },
  {
    label: "Kunri",
    value: "Kunri",
  },
  {
    label: "Lachi",
    value: "Lachi",
  },
  {
    label: "Ladhewala Waraich",
    value: "Ladhewala Waraich",
  },
  {
    label: "Lahore",
    value: "Lahore",
  },
  {
    label: "Lakhi",
    value: "Lakhi",
  },
  {
    label: "Lakki",
    value: "Lakki",
  },
  {
    label: "Lala Musa",
    value: "Lala Musa",
  },
  {
    label: "Lalian",
    value: "Lalian",
  },
  {
    label: "Landi Kotal",
    value: "Landi Kotal",
  },
  {
    label: "Larkana",
    value: "Larkana",
  },
  {
    label: "Layyah",
    value: "Layyah",
  },
  {
    label: "Liliani",
    value: "Liliani",
  },
  {
    label: "Lodhran",
    value: "Lodhran",
  },
  {
    label: "Loralai",
    value: "Loralai",
  },
  {
    label: "Mach",
    value: "Mach",
  },
  {
    label: "Madeji",
    value: "Madeji",
  },
  {
    label: "Mailsi",
    value: "Mailsi",
  },
  {
    label: "Malakand",
    value: "Malakand",
  },
  {
    label: "Malakwal",
    value: "Malakwal",
  },
  {
    label: "Malakwal City",
    value: "Malakwal City",
  },
  {
    label: "Malir Cantonment",
    value: "Malir Cantonment",
  },
  {
    label: "Mamu Kanjan",
    value: "Mamu Kanjan",
  },
  {
    label: "Mananwala",
    value: "Mananwala",
  },
  {
    label: "Mandi Bahauddin",
    value: "Mandi Bahauddin",
  },
  {
    label: "Mangla",
    value: "Mangla",
  },
  {
    label: "Mankera",
    value: "Mankera",
  },
  {
    label: "Mansehra",
    value: "Mansehra",
  },
  {
    label: "Mardan",
    value: "Mardan",
  },
  {
    label: "Mastung",
    value: "Mastung",
  },
  {
    label: "Matiari",
    value: "Matiari",
  },
  {
    label: "Matli",
    value: "Matli",
  },
  {
    label: "Mehar",
    value: "Mehar",
  },
  {
    label: "Mehmand Chak",
    value: "Mehmand Chak",
  },
  {
    label: "Mehrabpur",
    value: "Mehrabpur",
  },
  {
    label: "Mian Channun",
    value: "Mian Channun",
  },
  {
    label: "Mianke Mor",
    value: "Mianke Mor",
  },
  {
    label: "Mianwali",
    value: "Mianwali",
  },
  {
    label: "Minchianabad",
    value: "Minchianabad",
  },
  {
    label: "Mingora",
    value: "Mingora",
  },
  {
    label: "Miran Shah",
    value: "Miran Shah",
  },
  {
    label: "Miro Khan",
    value: "Miro Khan",
  },
  {
    label: "Mirpur Bhtoro",
    value: "Mirpur Bhtoro",
  },
  {
    label: "Mirpur Khas",
    value: "Mirpur Khas",
  },
  {
    label: "Mirpur Mathelo",
    value: "Mirpur Mathelo",
  },
  {
    label: "Mirpur Sakro",
    value: "Mirpur Sakro",
  },
  {
    label: "Mirwah Gorchani",
    value: "Mirwah Gorchani",
  },
  {
    label: "Mitha Tiwana",
    value: "Mitha Tiwana",
  },
  {
    label: "Mithi",
    value: "Mithi",
  },
  {
    label: "Moro",
    value: "Moro",
  },
  {
    label: "Moza Shahwala",
    value: "Moza Shahwala",
  },
  {
    label: "Multan",
    value: "Multan",
  },
  {
    label: "Muridke",
    value: "Muridke",
  },
  {
    label: "Murree",
    value: "Murree",
  },
  {
    label: "Musa Khel Bazar",
    value: "Musa Khel Bazar",
  },
  {
    label: "Mustafābād",
    value: "Mustafābād",
  },
  {
    label: "Muzaffargarh",
    value: "Muzaffargarh",
  },
  {
    label: "Muzaffarābād",
    value: "Muzaffarābād",
  },
  {
    label: "Nabisar",
    value: "Nabisar",
  },
  {
    label: "Nankana Sahib",
    value: "Nankana Sahib",
  },
  {
    label: "Narang Mandi",
    value: "Narang Mandi",
  },
  {
    label: "Narowal",
    value: "Narowal",
  },
  {
    label: "Nasirabad",
    value: "Nasirabad",
  },
  {
    label: "Naudero",
    value: "Naudero",
  },
  {
    label: "Naukot",
    value: "Naukot",
  },
  {
    label: "Naushahra Virkan",
    value: "Naushahra Virkan",
  },
  {
    label: "Naushahro Firoz",
    value: "Naushahro Firoz",
  },
  {
    label: "Nawabshah",
    value: "Nawabshah",
  },
  {
    label: "Nazir Town",
    value: "Nazir Town",
  },
  {
    label: "New Bādāh",
    value: "New Bādāh",
  },
  {
    label: "New Mirpur",
    value: "New Mirpur",
  },
  {
    label: "Noorabad",
    value: "Noorabad",
  },
  {
    label: "Nowshera",
    value: "Nowshera",
  },
  {
    label: "Nowshera Cantonment",
    value: "Nowshera Cantonment",
  },
  {
    label: "Nushki",
    value: "Nushki",
  },
  {
    label: "Okara",
    value: "Okara",
  },
  {
    label: "Ormara",
    value: "Ormara",
  },
  {
    label: "Pabbi",
    value: "Pabbi",
  },
  {
    label: "Pad Idan",
    value: "Pad Idan",
  },
  {
    label: "Paharpur",
    value: "Paharpur",
  },
  {
    label: "Pakpattan",
    value: "Pakpattan",
  },
  {
    label: "Panjgur",
    value: "Panjgur",
  },
  {
    label: "Pano Aqil",
    value: "Pano Aqil",
  },
  {
    label: "Parachinar",
    value: "Parachinar",
  },
  {
    label: "Pasni",
    value: "Pasni",
  },
  {
    label: "Pasrur",
    value: "Pasrur",
  },
  {
    label: "Pattoki",
    value: "Pattoki",
  },
  {
    label: "Peshawar",
    value: "Peshawar",
  },
  {
    label: "Phalia",
    value: "Phalia",
  },
  {
    label: "Pind Dadan Khan",
    value: "Pind Dadan Khan",
  },
  {
    label: "Pindi Bhattian",
    value: "Pindi Bhattian",
  },
  {
    label: "Pindi Gheb",
    value: "Pindi Gheb",
  },
  {
    label: "Pir Jo Goth",
    value: "Pir Jo Goth",
  },
  {
    label: "Pir Mahal",
    value: "Pir Mahal",
  },
  {
    label: "Pishin",
    value: "Pishin",
  },
  {
    label: "Pithoro",
    value: "Pithoro",
  },
  {
    label: "Qadirpur Ran",
    value: "Qadirpur Ran",
  },
  {
    label: "Qila Abdullah",
    value: "Qila Abdullah",
  },
  {
    label: "Qila Saifullah",
    value: "Qila Saifullah",
  },
  {
    label: "Quetta",
    value: "Quetta",
  },
  {
    label: "Rahim Yar Khan",
    value: "Rahim Yar Khan",
  },
  {
    label: "Raiwind",
    value: "Raiwind",
  },
  {
    label: "Raja Jang",
    value: "Raja Jang",
  },
  {
    label: "Rajanpur",
    value: "Rajanpur",
  },
  {
    label: "Rajo Khanani",
    value: "Rajo Khanani",
  },
  {
    label: "Ranipur",
    value: "Ranipur",
  },
  {
    label: "Rasulnagar",
    value: "Rasulnagar",
  },
  {
    label: "Ratodero",
    value: "Ratodero",
  },
  {
    label: "Rawala Kot",
    value: "Rawala Kot",
  },
  {
    label: "Rawalpindi",
    value: "Rawalpindi",
  },
  {
    label: "Renala Khurd",
    value: "Renala Khurd",
  },
  {
    label: "Risalpur Cantonment",
    value: "Risalpur Cantonment",
  },
  {
    label: "Rohri",
    value: "Rohri",
  },
  {
    label: "Rojhan",
    value: "Rojhan",
  },
  {
    label: "Rustam",
    value: "Rustam",
  },
  {
    label: "Saddiqabad",
    value: "Saddiqabad",
  },
  {
    label: "Sahiwal",
    value: "Sahiwal",
  },
  {
    label: "Sahiwal",
    value: "Sahiwal",
  },
  {
    label: "Saidu Sharif",
    value: "Saidu Sharif",
  },
  {
    label: "Sakrand",
    value: "Sakrand",
  },
  {
    label: "Samaro",
    value: "Samaro",
  },
  {
    label: "Sambrial",
    value: "Sambrial",
  },
  {
    label: "Sanghar",
    value: "Sanghar",
  },
  {
    label: "Sangla Hill",
    value: "Sangla Hill",
  },
  {
    label: "Sanjwal",
    value: "Sanjwal",
  },
  {
    label: "Sann",
    value: "Sann",
  },
  {
    label: "Sarai Alamgir",
    value: "Sarai Alamgir",
  },
  {
    label: "Sarai Naurang",
    value: "Sarai Naurang",
  },
  {
    label: "Sarai Sidhu",
    value: "Sarai Sidhu",
  },
  {
    label: "Sargodha",
    value: "Sargodha",
  },
  {
    label: "Sehwan",
    value: "Sehwan",
  },
  {
    label: "Setharja Old",
    value: "Setharja Old",
  },
  {
    label: "Shabqadar",
    value: "Shabqadar",
  },
  {
    label: "Shahdad Kot",
    value: "Shahdad Kot",
  },
  {
    label: "Shahdadpur",
    value: "Shahdadpur",
  },
  {
    label: "Shahkot",
    value: "Shahkot",
  },
  {
    label: "Shahpur",
    value: "Shahpur",
  },
  {
    label: "Shahpur Chakar",
    value: "Shahpur Chakar",
  },
  {
    label: "Shahr Sultan",
    value: "Shahr Sultan",
  },
  {
    label: "Shakargarh",
    value: "Shakargarh",
  },
  {
    label: "Sharqpur Sharif",
    value: "Sharqpur Sharif",
  },
  {
    label: "Shekhupura",
    value: "Shekhupura",
  },
  {
    label: "Shikarpur",
    value: "Shikarpur",
  },
  {
    label: "Shingli Bala",
    value: "Shingli Bala",
  },
  {
    label: "Shinpokh",
    value: "Shinpokh",
  },
  {
    label: "Shorkot",
    value: "Shorkot",
  },
  {
    label: "Shujaabad",
    value: "Shujaabad",
  },
  {
    label: "Sialkot",
    value: "Sialkot",
  },
  {
    label: "Sibi",
    value: "Sibi",
  },
  {
    label: "Sillanwali",
    value: "Sillanwali",
  },
  {
    label: "Sinjhoro",
    value: "Sinjhoro",
  },
  {
    label: "Skardu",
    value: "Skardu",
  },
  {
    label: "Sobhodero",
    value: "Sobhodero",
  },
  {
    label: "Sodhri",
    value: "Sodhri",
  },
  {
    label: "Sohbatpur",
    value: "Sohbatpur",
  },
  {
    label: "Sukheke Mandi",
    value: "Sukheke Mandi",
  },
  {
    label: "Sukkur",
    value: "Sukkur",
  },
  {
    label: "Surab",
    value: "Surab",
  },
  {
    label: "Surkhpur",
    value: "Surkhpur",
  },
  {
    label: "Swabi",
    value: "Swabi",
  },
  {
    label: "Sīta Road",
    value: "Sīta Road",
  },
  {
    label: "Talagang",
    value: "Talagang",
  },
  {
    label: "Talamba",
    value: "Talamba",
  },
  {
    label: "Talhar",
    value: "Talhar",
  },
  {
    label: "Tandlianwala",
    value: "Tandlianwala",
  },
  {
    label: "Tando Adam",
    value: "Tando Adam",
  },
  {
    label: "Tando Allahyar",
    value: "Tando Allahyar",
  },
  {
    label: "Tando Bago",
    value: "Tando Bago",
  },
  {
    label: "Tando Jam",
    value: "Tando Jam",
  },
  {
    label: "Tando Mitha Khan",
    value: "Tando Mitha Khan",
  },
  {
    label: "Tando Muhammad Khan",
    value: "Tando Muhammad Khan",
  },
  {
    label: "Tangi",
    value: "Tangi",
  },
  {
    label: "Tangwani",
    value: "Tangwani",
  },
  {
    label: "Tank",
    value: "Tank",
  },
  {
    label: "Taunsa",
    value: "Taunsa",
  },
  {
    label: "Thal",
    value: "Thal",
  },
  {
    label: "Tharu Shah",
    value: "Tharu Shah",
  },
  {
    label: "Thatta",
    value: "Thatta",
  },
  {
    label: "Thul",
    value: "Thul",
  },
  {
    label: "Timargara",
    value: "Timargara",
  },
  {
    label: "Toba Tek Singh",
    value: "Toba Tek Singh",
  },
  {
    label: "Topi",
    value: "Topi",
  },
  {
    label: "Turbat",
    value: "Turbat",
  },
  {
    label: "Ubauro",
    value: "Ubauro",
  },
  {
    label: "Umarkot",
    value: "Umarkot",
  },
  {
    label: "Upper Dir",
    value: "Upper Dir",
  },
  {
    label: "Usta Muhammad",
    value: "Usta Muhammad",
  },
  {
    label: "Uthal",
    value: "Uthal",
  },
  {
    label: "Utmanzai",
    value: "Utmanzai",
  },
  {
    label: "Vihari",
    value: "Vihari",
  },
  {
    label: "Wana",
    value: "Wana",
  },
  {
    label: "Warah",
    value: "Warah",
  },
  {
    label: "Wazirabad",
    value: "Wazirabad",
  },
  {
    label: "Yazman",
    value: "Yazman",
  },
  {
    label: "Zafarwal",
    value: "Zafarwal",
  },
  {
    label: "Zahir Pir",
    value: "Zahir Pir",
  },
  {
    label: "Zaida",
    value: "Zaida",
  },
  {
    label: "Zhob",
    value: "Zhob",
  },
  {
    label: "Ziarat",
    value: "Ziarat",
  },
];
