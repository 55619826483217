import { ActionContext, ActionTree } from "vuex";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { ICreditLimitManagementState } from "@/interfaces/creditLimitManagementState.interface";
import CreditLimitManagementService from "@/modules/CreditLimitManagement/services/creditLimitManagement.service";
import { IPaginatedCreditLimitRequests } from "@/interfaces/creditLimitRequest.interface";
import { FetchCreditLimitRequestsParamsRequest } from "@/modules/CreditLimitManagement/models/fetchCreditLimitRequestsParams.request";
import { CreateCreditLimitRequestsBody } from "@/modules/CreditLimitManagement/models/createCreditLimitRequestsBody.request";
import { ICreditLimitRequestDetail } from "@/interfaces/creditLimitRequestDetail.interface";
import { ProcessCreditLimitRequestBody } from "@/modules/CreditLimitManagement/models/processCreditLimitRequestBody.request";

const actions: ActionTree<
  ICreditLimitManagementState,
  ICreditLimitManagementState
> = {
  async createCreditLimitRequest(
    context: ActionContext<
      ICreditLimitManagementState,
      ICreditLimitManagementState
    >,
    {
      body,
      callback,
    }: { body: CreateCreditLimitRequestsBody; callback?: () => void }
  ) {
    const methodName = "actions.createCreditLimitRequests";
    context.commit("enableIsCreatingCreditLimitRequests");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    const creditLimitManagementService = new CreditLimitManagementService();
    await creditLimitManagementService.createCreditLimitRequest(body, callback);
    context.commit("disableIsCreatingCreditLimitRequests");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },

  async processCreditLimitRequest(
    context: ActionContext<
      ICreditLimitManagementState,
      ICreditLimitManagementState
    >,
    {
      body,
      callback,
    }: { body: ProcessCreditLimitRequestBody; callback?: () => void }
  ) {
    const methodName = "actions.processCreditLimitRequest";
    context.commit("enableIsProcessingCreditLimitRequest");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    const creditLimitManagementService = new CreditLimitManagementService();
    await creditLimitManagementService.processCreditLimitRequest(body);
    context.commit("disableIsProcessingCreditLimitRequest");
    if (callback) {
      callback();
    }
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },
  async fetchCreditLimitRequestDetail(
    context: ActionContext<
      ICreditLimitManagementState,
      ICreditLimitManagementState
    >,
    id: string
  ) {
    const methodName = "actions.fetchCreditLimitRequestDetail";
    context.commit("enableIsFetchingCreditLimitRequestDetail");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    const creditLimitManagementService = new CreditLimitManagementService();
    const response: ICreditLimitRequestDetail | null =
      await creditLimitManagementService.fetchCreditLimitRequestDetail(id);
    context.commit("saveCreditLimitRequestDetail", response);
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },
  async fetchCreditLimitRequests(
    context: ActionContext<
      ICreditLimitManagementState,
      ICreditLimitManagementState
    >,
    {
      params,
      callback,
    }: { params: FetchCreditLimitRequestsParamsRequest; callback?: () => void }
  ) {
    const methodName = "actions.fetchCreditLimitRequests";
    context.commit("enableIsFetchCreditLimitRequests");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    const creditLimitManagementService = new CreditLimitManagementService();
    const response: IPaginatedCreditLimitRequests =
      await creditLimitManagementService.fetchCreditLimitRequests(params);
    context.commit("saveCreditLimitRequests", response);
    if (callback) {
      callback();
    }
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },
  async fetchCreditLimitSlabs(
    context: ActionContext<
      ICreditLimitManagementState,
      ICreditLimitManagementState
    >
  ) {
    const methodName = "actions.fetchCreditLimitSlabs";
    context.commit("setCreditLimitSlabLoading", true);
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    const creditLimitManagementService = new CreditLimitManagementService();
    const response = await creditLimitManagementService.fetchCreditLimitSlabs();
    context.commit("setCreditLimitSlabLoading", false);
    context.commit("saveCreditLimitSlabs", response);
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },
};

export default actions;
