import * as yup from "yup";

export const createPaymentValidationschema = (minValue: number) =>
  yup.object().shape({
    amount: yup
      .number()
      .required("Amount is required.")
      .min(minValue, "Amount must be atleast " + minValue),
    method: yup.string().required("Payment method is required"),
    financial_profile_public_id: yup
      .string()
      .required("Financial profile is required"),
  });
