import { ICreditLimitManagementState } from "@/interfaces/creditLimitManagementState.interface";

const state: ICreditLimitManagementState = {
  creditLimitRequests: {
    data: [],
    previous: "",
    next: "",
    count: 0,
    totalPages: 0,
  },
  creditLimitRequestDetail: null,
  isCreatingCreditLimitRequest: false,
  isFetchingCreditLimitRequests: false,
  isFetchingCreditLimitRequestDetail: false,
  isProcessingCreditLimitRequest: false,
  creditLimitSlabs: [],
  isCreditLimitSlabLoading: false,
};

export default state;
