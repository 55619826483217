<template>
  <AGAppContainer class="default-page-color" fullWidth>
    <div class="ag-forgotpass-wrapper">
      <div class="ag-forgotpass-card">
        <AGPanel>
          <AGLogo :url="urlLogo" is-small />
          <h5>Forgot Your <span>Password?</span></h5>
          <p>
            Enter your registered email address below and we will send you a
            link to reset your password.
          </p>
          <form @submit.prevent="onSubmit">
            <AGTextField
              v-model="forgotPasswordPayload.email"
              :value="forgotPasswordPayload.email"
              :testId="genTestId(ELEMENT_TYPES.TEXT_FIELD, 'login-email')"
              type="email"
              name="email"
              label="Email"
              :error="errors['email']"
            />

            <div class="forgot_card">
              <router-link
                :to="path.ROOT"
                :testId="genTestId(ELEMENT_TYPES.LINK, 'login')"
              >
                <AGButton
                  :testId="genTestId(ELEMENT_TYPES.BUTTON, 'login')"
                  variant="link"
                  >Back to Login
                </AGButton>
              </router-link>
            </div>

            <div class="error-container">
              <div>
                {{ error }}
              </div>
            </div>

            <div class="d-grid">
              <AGButton :is-loading="isLoading" variant="primary" type="submit"
                >Submit
              </AGButton>
            </div>
          </form>
        </AGPanel>
      </div>
    </div>
  </AGAppContainer>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { PATH } from "@/ag-portal-common/constants/path";
import {
  genTestId,
  parseLocalStorageData,
} from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { IOrganizationPreference } from "@/ag-portal-common/interfaces/organization.interface";
import { STORAGE_KEYS } from "@/ag-portal-common/constants/storageKeys";

import { forgotPasswordValidation } from "../validations/forgotPassword.validation";
import { yupValidationErrorAsSchema } from "@/ag-portal-common/utils/helpers";
import { ValidationError } from "yup";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";

interface ForgotPasswordPayload {
  email: string;
  password: string;
}

export default defineComponent({
  name: "ForgotPasswordView",
  data() {
    return {
      path: {} as typeof PATH,
      isLoading: false,
      ELEMENT_TYPES,
      error: "",
      forgotPasswordPayload: { email: "" } as ForgotPasswordPayload,

      errors: {} as ForgotPasswordPayload,
    };
  },
  created() {
    this.path = PATH;
    this.onPageTitleUpdateHandler();
  },
  computed: {
    urlLogo() {
      const organizationPreference: IOrganizationPreference | null =
        parseLocalStorageData(STORAGE_KEYS.PREFERENCES);
      return organizationPreference?.logo;
    },
  },
  methods: {
    genTestId,
    async onSubmit() {
      this.isLoading = true;
      this.errors = {} as ForgotPasswordPayload;

      try {
        const validatedPaylaod = await forgotPasswordValidation.validate(
          {
            email: this.forgotPasswordPayload.email,
          },
          {
            abortEarly: false,
          }
        );

        await this.$store.dispatch("forgotPassword", {
          body: validatedPaylaod,
          callback: () => {
            this.isLoading = false;
          },
        });
      } catch (ex) {
        if (ex instanceof ValidationError) {
          const _errors = yupValidationErrorAsSchema(ex);
          this.errors = _errors;
        }
        this.isLoading = false;
      }
    },

    onPageTitleUpdateHandler() {
      const organisationName = this.getOrganisationNameHandler();

      document.title = `Forgot Password - ${organisationName}`;
    },
    getOrganisationNameHandler(): string {
      const DEFAULT_NAME = "Aeroglobe";
      const organization = this.organizationPreference();

      if (!organization) {
        return DEFAULT_NAME;
      }

      return (
        organization.display_name ||
        organization.org_name_to_display ||
        this.user()?.full_name ||
        DEFAULT_NAME
      );
    },
  },
  setup() {
    const organizationPreference = inject(
      AUTH_CONTEXT_KEYS.organizationPreference
    ) as () => IOrganizationPreference;

    let user = inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2 | null;

    return {
      organizationPreference,
      user,
    };
  },
});
</script>

<style scoped lang="css">
.ag-forgotpass-wrapper {
  min-height: 100vh;

  display: grid;
  place-content: center;

  padding: 12px;
}

.ag-forgotpass-wrapper .ag-forgotpass-card .login_box {
  min-height: 0;
  width: 400px;
}

@media screen and (max-width: 480px) {
  .ag-forgotpass-wrapper .ag-forgotpass-card .login_box {
    max-width: none;
    width: 100%;
  }
}
</style>
