<template>
  <div class="icon-m-check" v-if="isCheck">
    <MIcon name="m-check" class="icon" />
  </div>

  <div class="icon-m-cross" v-else>
    <MIcon name="m-cross" class="icon" />
  </div>
</template>

<script lang="ts">
import { IconName } from "@aeroglobe/ag-core-ui/dist/src/components/material/icons/icons.type";
import { defineComponent } from "vue";
export default defineComponent({
  name: "CheckCrossIconComponent",
  props: {
    isCheck: {
      type: Boolean,
    },
  },
  computed: {
    renderIcon(): IconName {
      return this.isCheck ? "m-check" : "m-cross";
    },
  },
});
</script>

<style lang="scss" scoped>
.icon-m-cross {
  background: rgba(230, 90, 90, 0.1);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  .icon {
    color: #e65a5a;
    display: flex;
    width: 20px;
    height: 20px;
    svg {
      width: 18px;
    }
  }
}

.icon-m-check {
  background: rgba(16, 176, 18, 0.1);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  .icon {
    color: #10b949;
    display: flex;
    width: 20px;
    height: 20px;
    svg {
      width: 18px;
    }
  }
}
</style>
