<template>
  <MAccordion :initial-open="!!financialProfile.is_default">
    <template v-slot:title>
      <div class="accordion-title-container">
        <span class="accordion-title"
          >{{ financialProfile.financial_profile_name }} -
          {{ financialProfile.platform_id }}</span
        >
        <div class="accordion-chip-container">
          <MChip class="status-chip" :variant="isActive ? 'success' : 'error'">
            {{ financialProfile.status }}
          </MChip>
          <MChip
            class="status-chip"
            v-if="financialProfile.is_default"
            variant="success"
          >
            DEFAULT
          </MChip>
        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="content-container">
        <div class="container">
          <span class="plan_name">
            <MIcon
              :type="getFPType(financialProfile.plan_name)"
              class="details-type-icon icon"
              :name="getFPIconName(financialProfile.plan_name)"
              width="24"
              height="24"
            />
            {{ financialProfile.plan_name.toUpperCase() }}
          </span>
          <MButton v-if="enableActions" @click="onUnLinknBtnClick"
            >Unlink</MButton
          >
        </div>
        <!-- Tiles container -->
        <div class="tile-container">
          <MAccordionTile
            title="Net Available Limit"
            :value="
              formatCurrency(
                Number(financialProfile.financial_data?.total_balance_current),
                financialProfile.financial_data?.currency_code
              )
            "
          >
            <template #icon>
              <MIcon
                name="m-request-quote"
                class="icon white-icon"
                width="24"
                height="24"
              />
            </template>
          </MAccordionTile>

          <MAccordionTile
            title="Available Limit"
            :value="
              formatCurrency(
                Number(financialProfile.financial_data?.available_credit),
                financialProfile.financial_data?.currency_code
              )
            "
          >
            <template #icon>
              <MIcon
                class="icon white-icon"
                name="m-wallet"
                width="24"
                height="24"
              />
            </template>
          </MAccordionTile>

          <MAccordionTile
            title="Total Not Invoiced Sales"
            :value="
              formatCurrency(
                Number(
                  financialProfile.financial_data?.total_of_not_invoiced_tickets
                ),
                financialProfile.financial_data?.currency_code
              )
            "
          >
            <template #icon>
              <MIcon
                class="icon white-icon"
                name="m-receipt-long"
                width="24"
                height="24"
              />
            </template>
            <template #button>
              <MFabButton
                @click="handleOpenNotInvoicedDialog"
                type="outlined"
                icon="m-receipt-long"
              />
            </template>
          </MAccordionTile>

          <MAccordionTile
            title="Total Payable Amount"
            :value="
              formatCurrency(
                Number(financialProfile.financial_data?.current_balance),
                financialProfile.financial_data?.currency_code
              )
            "
          >
            <template #icon>
              <MIcon
                class="icon white-icon"
                name="m-credit-score"
                width="24"
                height="24"
              />
            </template>
          </MAccordionTile>

          <MAccordionTile
            title="Temporary Credit Limit"
            :value="
              formatCurrency(
                Number(financialProfile.financial_data?.temporary_credit_limit),
                financialProfile.financial_data?.currency_code
              )
            "
          >
            <template #icon>
              <MIcon
                class="icon white-icon"
                name="m-credit-limit-management"
                width="24"
                height="24"
              />
            </template>
          </MAccordionTile>

          <MAccordionTile
            title="Permanent Credit Limit"
            :value="
              formatCurrency(
                Number(financialProfile.financial_data?.credit_limit),
                financialProfile.financial_data?.currency_code
              )
            "
          >
            <template #icon>
              <MIcon
                name="m-request-quote"
                class="icon white-icon"
                width="24"
                height="24"
              />
            </template>
          </MAccordionTile>
        </div>
      </div>
    </template>
  </MAccordion>
  <UnlinkFinancialProfileConfirmationDialog
    @handleClose="handleReject"
    @handleAccept="handleApprove"
    :financial_profile_name="financialProfile?.financial_profile_name"
    :is-open="isUnlinkConfirmationDialogOpened"
  />
  <ViewNotInvoicedReceiptsDialog
    :financial-profile="financialProfile"
    :is-open="isViewNotInvoicedReceiptsDialogOpened"
    @handleClose="handleCloseNotInvoicedDialog"
  />
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import {
  MIcon,
  MAccordion,
  MChip,
  MButton,
  MFabButton,
} from "@aeroglobe/ag-core-ui";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { getCurrencyFormatter } from "@/ag-portal-common/utils/helpers";
import { IconName } from "@aeroglobe/ag-core-ui/dist/src/components/material/icons/icons.type";
import MAccordionTile from "../MAccordionTile.vue";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import UnlinkFinancialProfileConfirmationDialog from "./UnlinkFinancialProfileConfirmationDialog.vue";
import ViewNotInvoicedReceiptsDialog from "./ViewNotInvoicedReceiptsDialog.vue";

export default defineComponent({
  name: "OrganizationFinancialProfile",
  components: {
    MAccordionTile,
    MIcon,
    MAccordion,
    MButton,
    MChip,
    UnlinkFinancialProfileConfirmationDialog,
    ViewNotInvoicedReceiptsDialog,
    MFabButton,
  },
  data() {
    return {
      isUnlinkConfirmationDialogOpened: false,
      isViewNotInvoicedReceiptsDialogOpened: false,
    };
  },
  props: {
    financialProfile: {
      type: Object as PropType<IFinancialProfile>,
      required: true,
    },
    enableActions: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatCurrency(amount: number, currency: string | undefined) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      const formatedAmount = formatter.format(amount);
      return `${currency?.toUpperCase() ?? ""} ${formatedAmount ?? ""}`;
    },
    getFPIconName(plan_name: string): IconName {
      const plan = plan_name.split(" ")[1]?.toLowerCase();
      switch (plan) {
        case "elite":
          return "m-ag-elite";
        case "pro":
          return "m-ag-pro";
        case "lite":
          return "m-ag-elite";
        case "smart":
          return "m-ag-smart";
        default:
          return "m-ag-elite";
      }
    },
    getFPType(plan_name: string): string {
      const plan = plan_name.split(" ")[1]?.toLowerCase();
      return plan;
    },
    onUnLinknBtnClick() {
      this.isUnlinkConfirmationDialogOpened = true;
    },
    onSuccessHandler() {
      this.isUnlinkConfirmationDialogOpened = false;
    },
    handleApprove() {
      this.$store.dispatch("unlinkFinancialProfile", {
        id: this.financialProfile?.public_id,
        successCallback: this.onSuccessHandler,
      });
    },
    handleReject() {
      this.isUnlinkConfirmationDialogOpened = false;
    },
    handleOpenNotInvoicedDialog() {
      this.isViewNotInvoicedReceiptsDialogOpened = true;
    },
    handleCloseNotInvoicedDialog() {
      this.isViewNotInvoicedReceiptsDialogOpened = false;
    },
  },
  computed: {
    isActive(): boolean {
      return this.financialProfile.status === ORGANIZATION_STATUSES.ACTIVE;
    },
  },
});
</script>

<style scoped lang="scss">
.accordion-title-container {
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 10px;
  }
}
.accordion-chip-container {
  display: flex;
  gap: 10px;
}
.accordion-title {
  font-size: 20px;
  margin-right: 20px;
  font-weight: 600;
  text-align: start;
  @media (max-width: 767px) {
    text-align: center;
  }
}
.content-container {
  padding: 26px 0 0 0;
  .container {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
  }
}

.plan_name {
  display: flex;
  font-weight: 500;
  font-size: 16px;
  margin: 10px;
}
.plan_name > .icon {
  margin-top: 1px;
  margin-right: 5px;
}
.tile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  flex-wrap: wrap;
  width: 100%;
}
.white-icon {
  color: #fff;
}

.details-type-icon[type="pro"] {
  color: var(--m-info-color);
}
.details-type-icon[type="elite"] {
  color: var(--m-success-color);
}
.details-type-icon[type="smart"] {
  color: var(--m-warning-color);
}
.details-type-icon[type="lite"] {
  color: var(--m-success-color);
}
.status-chip {
  font-weight: 600;
}
</style>
