<template>
  <ag-drawer width="560" :is-open="isOpen" direction="right">
    <AgDiv class="margin_bottom_10 margin_top_10 main_payment_detail_box">
      <ag-div class="payment_pop_head">
        <v-card-title>Payment Details</v-card-title>

        <a-g-button
          :testId="
            genTestId(ELEMENT_TYPES.BUTTON, 'payment-detail-modal-close')
          "
          type="button"
          class="modal_close_icon"
          variant="link"
          @click="closeModal"
          >Close
        </a-g-button>
      </ag-div>

      <ag-loader v-if="isFetchingPayment" />
      <ag-div v-else-if="!isFetchingPayment && payment">
        <ag-div
          class="d-flex justify-content-end align-items-center margin_right_20"
        >
          <a-g-button
            :testId="
              genTestId(
                ELEMENT_TYPES.BUTTON,
                'payment-detail-modal-one-bill-guide'
              )
            "
            @click="onPaymentGuide"
            v-if="payment?.oneBillDetail"
            >How To Pay
          </a-g-button>
          <a-g-button
            :testId="
              genTestId(
                ELEMENT_TYPES.BUTTON,
                'payment-detail-modal-proceed-to-pay'
              )
            "
            @click="onProceedPayment"
            v-if="payment?.creditCardlDetail"
            >Proceed To Pay
          </a-g-button>
        </ag-div>

        <ag-div
          class="d-flex margin_top_20 justify-content-between align-items-center pymnt_financial_profile"
        >
          <ag-div>
            <ag-div class="p_label"> Agent Profile</ag-div>
            <ag-div class="p_min_value">
              {{ payment?.agentEmail }}
            </ag-div>
          </ag-div>
          <ag-div>
            <ag-div class="p_min_value">
              {{ payment?.agentName }}
            </ag-div>
          </ag-div>
        </ag-div>

        <ag-div
          class="d-flex margin_top_20 justify-content-between align-items-center pymnt_financial_profile"
        >
          <ag-div>
            <ag-div class="p_label"> Financial Profile</ag-div>
            <ag-div class="p_min_value">
              {{ "#" + payment?.financialProfile }}
            </ag-div>
          </ag-div>

          <ag-div>
            <ag-div class="p_min_amount">
              {{ formattedCurrency(payment?.amount) }}
            </ag-div>
          </ag-div>
        </ag-div>

        <ag-div class="d-flex justify-content-between payment_col_2">
          <ag-div class="d-flex flex-direction-column justify-content-between">
            <ag-div class="margin_top_10 margin_bottom_20">
              <ag-div class="p_label"> Transaction ID</ag-div>
              <ag-div class="p_min_value">
                {{ payment?.transactionId }}
              </ag-div>
            </ag-div>

            <ag-div
              class="margin_top_20 margin_bottom_20"
              v-if="payment?.oneBillDetail"
            >
              <ag-div class="p_label"> One Bill ID</ag-div>

              <ag-div class="p_min_value">
                {{ payment?.oneBillDetail.billCode }}
              </ag-div>
            </ag-div>
            <ag-div
              class="margin_top_20 margin_bottom_20"
              v-if="payment?.ibftDetail"
            >
              <ag-div class="p_label"> Payment Code</ag-div>

              <ag-div class="p_min_value">
                {{ payment?.ibftDetail.paymentCode }}
              </ag-div>
            </ag-div>

            <ag-div class="margin_top_20 margin_bottom_10">
              <ag-div class="p_label"> Transaction Type</ag-div>
              <ag-div class="p_min_value">
                {{ TRANSACTION_TYPES[payment?.transactionType] }}
              </ag-div>
            </ag-div>
          </ag-div>

          <ag-div class="d-flex flex-direction-column justify-content-between">
            <ag-div class="margin_bottom_20">
              <ag-div class="p_label">Payment Status</ag-div>
              <ag-div
                :class="'margin_top_10 pay_chip_wrap ' + paymentStatusClass"
              >
                {{ PAYMENT_STATUSES[payment?.paymentStatus] }}
              </ag-div>
            </ag-div>
            <ag-div class="margin_top_20 margin_bottom_10">
              <ag-div class="p_label">Receipt Status</ag-div>
              <ag-div
                :class="'margin_top_10 pay_chip_wrap ' + receiptStatusClass"
              >
                {{ RECEIPT_STATUSES[payment?.receiptStatus] }}
              </ag-div>
            </ag-div>
          </ag-div>
        </ag-div>

        <ag-div
          class="d-flex justify-content-between align-items-center pymnt_financial_profile"
        >
          <ag-div class="p_min_head"> Payment Created At</ag-div>
          <ag-div>
            <ag-div class="p_date">
              {{ formattedDate }}
              <ag-div class="p_time">
                {{ formattedTime }}
              </ag-div>
            </ag-div>
          </ag-div>
        </ag-div>

        <ag-div v-if="payment.description" class="pymnt_financial_profile">
          <ag-heading title="Description" variant="h2" />
          <ag-heading :title="payment?.description" variant="p" />
        </ag-div>
      </ag-div>

      <ag-not-found v-else />
    </AgDiv>
  </ag-drawer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  formatNumber,
  genTestId,
  getFormattedDateTime,
  getCurrencyFormatter,
} from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { IPayment } from "@/interfaces/payments.interface";
import {
  payGuideUrl,
  PAYMENT_STATUS_MAPPING,
  RECEIPT_STATUS_MAPPING,
} from "@/modules/Payments/constants";
import { TRANSACTION_TYPES } from "@/ag-portal-common/constants/transactionTypes";
import { PAYMENT_STATUSES } from "../../../ag-portal-common/constants/paymentStatuses";
import { RECEIPT_STATUSES } from "../../../ag-portal-common/constants/receiptStatuses";
import {
  FORMAT_DD_MMM_YYYY,
  FORMAT_HH_SS_A,
} from "@/ag-portal-common/constants/dateTimeFormats";
import { PAYMENT_STATUS } from "@/ag-portal-common/enums/PAYMENT_STATUS";
import { RECEIPT_STATUS } from "@/ag-portal-common/enums/RECEIPT_STATUS";
import {
  AGENT_FINANCES_EVENTS,
  agentFinanceBus,
} from "@/ag-portal-common/eventBusses/agentFinances";
import analyticsService from "@/analytic.service";
import { PAYMENTS_ANALYTICS_EVENTS } from "@/modules/Payments/constants/anaylticsEvents";

export default defineComponent({
  name: "PaymentDetailModal",
  computed: {
    RECEIPT_STATUSES() {
      return RECEIPT_STATUSES;
    },
    PAYMENT_STATUSES() {
      return PAYMENT_STATUSES;
    },
    TRANSACTION_TYPES() {
      return TRANSACTION_TYPES;
    },
    ELEMENT_TYPES() {
      return ELEMENT_TYPES;
    },
    payment(): IPayment | null {
      return this.$store.getters.payment;
    },
    isFetchingPayment(): boolean {
      return this.$store.getters.isFetchingPayment;
    },
    formattedDate() {
      return getFormattedDateTime(
        this.payment?.dateTime || new Date(),
        FORMAT_DD_MMM_YYYY
      );
    },
    formattedTime() {
      return getFormattedDateTime(
        this.payment?.dateTime || new Date(),
        FORMAT_HH_SS_A
      );
    },
    receiptStatusClass() {
      return `color_chip ${
        RECEIPT_STATUS_MAPPING[
          this.payment?.receiptStatus || RECEIPT_STATUS.PENDING
        ]
      }`;
    },
    paymentStatusClass() {
      return `color_chip ${
        PAYMENT_STATUS_MAPPING[
          this.payment?.paymentStatus || PAYMENT_STATUS.INITIATED
        ]
      }`;
    },
  },
  methods: {
    formatNumber,
    genTestId,
    onProceedPayment() {
      const paymentUrl = this.payment?.creditCardlDetail?.paymentUrl;

      analyticsService.logActionEvent(
        PAYMENTS_ANALYTICS_EVENTS.PAYMENT_PROCEED,
        { "payment-url": paymentUrl }
      );

      window.open(paymentUrl);
    },
    closeModal() {
      this.$emit("onCloseModal");
    },
    onPaymentGuide() {
      analyticsService.logActionEvent(PAYMENTS_ANALYTICS_EVENTS.PAYMENT_GUIDE, {
        "pay-guide-url": payGuideUrl,
      });

      window.open(payGuideUrl);
    },
    formattedCurrency(amount: number) {
      const currency = localStorage.getItem("currency") || "";
      const formattedAmount = getCurrencyFormatter(currency).format(amount);
      return formattedAmount;
    },
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: this.showModal,
    };
  },

  beforeMount() {
    this.$store.dispatch("fetchPayment", this.$route.params.id);
  },
  watch: {
    showModal(value) {
      this.isOpen = value;
    },
    isFetchingPayment() {
      if (
        !this.isFetchingPayment &&
        this.payment?.paymentStatus === PAYMENT_STATUS.SUCCESS
      ) {
        agentFinanceBus.emit(AGENT_FINANCES_EVENTS.REFRESH, true);
      }
    },
  },
});
</script>
