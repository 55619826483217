<template>
  <ag-agent-list-customer-balance v-if="financialProfile?.financial_data">
    <template #heading>
      <AgDiv class="header-container">
        <AgDiv class="financial-profile-name-container">
          <p class="financial-profile-name">
            {{ financialProfile.financial_profile_name }}
          </p>
          <AgDiv>
            <span class="financial-profile-name-sub-items"
              >{{ financialProfile.plan_name }} |
              {{ financialProfile.is_default }}</span
            >
            <span class="financial-profile-status-container"
              ><span :class="renderStatusClass(financialProfile.status)">{{
                financialProfile.status
              }}</span>
              ({{ financialProfile?.status_remarks }})</span
            >
          </AgDiv>
        </AgDiv>

        <AGButton
          v-show="isAgSuperUser"
          @click="openModal(financialProfile.public_id)"
          >View not invoiced sales</AGButton
        >
      </AgDiv>
    </template>
    <template #items>
      <ag-agent-info-box
        title="Total Available Current Limit"
        icon="userType"
        :value="
          formatAmount(financialProfile?.financial_data.total_balance_current)
        "
      >
        <template #value>
          <ag-heading
            :title="
              formatAmount(
                financialProfile?.financial_data.total_balance_current
              )
            "
            variant="p"
            class="info-details color-red"
          ></ag-heading>
        </template>
      </ag-agent-info-box>
      <ag-agent-info-box
        title="Customer Status"
        icon="customerStatus"
        :value="
          financialProfile?.financial_data.customer_status +
            ' (' +
            financialProfile?.status_remarks.toLocaleLowerCase() +
            ')' || ''
        "
      ></ag-agent-info-box>
      <ag-agent-info-box
        title="Current Balance"
        icon="currentBalance"
        :value="formatAmount(financialProfile?.financial_data.current_balance)"
      ></ag-agent-info-box>

      <ag-agent-info-box
        title="Total Not Invoiced Sales"
        icon="notInvoice"
        :value="
          formatAmount(
            financialProfile?.financial_data.total_of_not_invoiced_tickets
          )
        "
        class="no_border"
      ></ag-agent-info-box>
      <ag-agent-info-box
        title="Plan Name"
        icon="provider"
        :value="financialProfile?.plan_name"
      ></ag-agent-info-box>
      <ag-agent-info-box
        title="Temporary Credit Limit"
        icon="tempCreditLimit"
        :value="
          formatAmount(financialProfile?.financial_data.temporary_credit_limit)
        "
      ></ag-agent-info-box>
      <ag-agent-info-box
        title="Permanent Credit Limit"
        icon="permanentCredit"
        :value="formatAmount(financialProfile?.financial_data.credit_limit)"
      ></ag-agent-info-box>
      <ag-agent-info-box
        title="Financial Profile"
        icon="profileCardLight"
        class="no_border"
        :value="financialProfile?.financial_data.customer_code"
      ></ag-agent-info-box>
      <a-g-button
        :is-loading="
          selectedId === financialProfile.public_id &&
          isUnlinkingFinancialProfile
        "
        v-if="enableActions"
        @click="onUnlinkFinancialProfile(financialProfile.public_id)"
        >Unlink
      </a-g-button>
    </template>
  </ag-agent-list-customer-balance>
  <ag-modal v-model="isModalOpen" modal-width="60%">
    <template #header>
      <agheading
        >Pending Receipt and Invoice of
        <b>{{ financialProfile.financial_profile_name }}</b></agheading
      >
    </template>
    <template #body>
      <ag-card>
        <ag-heading
          variant="h2"
          title="Invoices"
          v-if="pendingPostingInvoices.length > 0"
        ></ag-heading>

        <AgTable
          v-if="pendingPostingInvoices && pendingPostingInvoices.length > 0"
          :headers="tableHeaders_Invoices"
          :items="pendingPostingInvoices"
          :has-pagination="true"
          :items-per-page="5"
          :has-search="false"
        >
          <template #col-ticket_no="{ item }">
            <ag-div>{{ item.raw.ticket_no }}</ag-div>
          </template>
          <template #col-pnr="{ item }">
            <ag-div class="color_chip green">{{ item.raw.pnr }}</ag-div>
          </template>
          <template #col-posting_status="{ item }">
            <ag-div class="color_chip green"
              >{{ item.raw.posting_status }}
            </ag-div>
          </template>
        </AgTable>

        <ag-heading
          variant="h2"
          title="Receipts"
          v-if="pendingPostingReceipts.length > 0"
          style="margin-bottom: 0.5cm; margin-top: 0.5cm"
        ></ag-heading>
        <AgTable
          v-if="pendingPostingReceipts && pendingPostingReceipts.length > 0"
          :headers="tableHeaders_Receipts"
          :items="pendingPostingReceipts"
          :has-pagination="true"
          :items-per-page="5"
          :has-search="false"
        >
          <template #col-payment_id="{ item }">
            <ag-div>{{ item.raw.payment_id }}</ag-div>
          </template>
          <template #col-amount="{ item }">
            <ag-div class="color_chip green">{{ item.raw.amount }}</ag-div>
          </template>
          <template #col-posting_status="{ item }">
            <ag-div class="color_chip green"
              >{{ item.raw.posting_status }}
            </ag-div>
          </template>
        </AgTable>
        <AgNotFound
          v-if="
            pendingPostingInvoices.length === 0 &&
            pendingPostingReceipts.length === 0
          "
          test-id=""
          heading="No Pending Receipts & Invoices Found"
          description=""
        />
      </ag-card>
    </template>
    <template #footer>
      <AGButton @click="closeModal" variant="primary">Close</AGButton>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent, PropType, inject } from "vue";
import { formatNumber } from "@/ag-portal-common/utils/helpers";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import { COLOR_CODES } from "@/ag-portal-common/constants/colorCodes";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";

const defaultFinancialProfileTag = "DEFAULT";
export default defineComponent({
  name: "FinancialProfileBox",
  props: {
    financialProfile: {
      type: Object as PropType<IFinancialProfile>,
      required: true,
    },
    enableActions: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    COLOR_CODES: { [key: string]: string };
    defaultFinancialProfileTag: string;
    selectedId: string;
    isModalOpen: boolean;
    pendingPostingReceipts: [];
    pendingPostingInvoices: [];
    tableHeaders_Receipts: {
      title: string;
      value: string;
      key: string;
      sortable: boolean;
    }[];
    tableHeaders_Invoices: {
      title: string;
      value: string;
      key: string;
      sortable: boolean;
    }[];
  } {
    return {
      selectedId: "",
      COLOR_CODES,
      defaultFinancialProfileTag,
      isModalOpen: false,
      pendingPostingReceipts: [],
      pendingPostingInvoices: [],
      tableHeaders_Receipts: [
        {
          title: "Payment Id",
          value: "payment_id",
          key: "payment_id",
          sortable: false,
        },
        {
          title: "Amount",
          value: "amount",
          key: "amount",
          sortable: false,
        },
        {
          title: "Posting Status",
          value: "posting_status",
          key: "posting_status",
          sortable: false,
        },
      ],
      tableHeaders_Invoices: [
        {
          title: "Ticket Number",
          value: "ticket_no",
          key: "ticket_no",
          sortable: false,
        },
        {
          title: "PNR",
          value: "pnr",
          key: "pnr",
          sortable: false,
        },
        {
          title: "Posting Status",
          value: "posting_status",
          key: "posting_status",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    formatNumber,
    onUnlinkFinancialProfile(id: string) {
      this.selectedId = id;
      this.$emit("unlinkFinancialProfile", id);
    },
    async openModal(fpId: string) {
      this.isModalOpen = true;
      const responseData = await this.$store.dispatch(
        "fetchPendingReceiptAndInvoice",
        {
          id: fpId,
        }
      );
      this.pendingPostingInvoices = responseData.invoices;
      this.pendingPostingReceipts = responseData.receipts;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    renderStatusClass(status: string) {
      return status.toLocaleLowerCase() === "active"
        ? "financial-profile-status-active"
        : "financial-profile-status-inactive";
    },
    formatAmount(amount: string) {
      const convertedAmount = Number(amount || 0).toFixed(2);
      return this.formatNumber(convertedAmount);
    },
  },
  setup() {
    let user = inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2 | null;
    return {
      user,
    };
  },
  computed: {
    planStatusColorCode(): string {
      return this.financialProfile.plan_name === "Aeroglobe Pro"
        ? COLOR_CODES.ERROR_RED
        : COLOR_CODES.SUCCESS_GREEN;
    },
    statusColorCode(): string {
      return this.financialProfile.status === ORGANIZATION_STATUSES.ACTIVE
        ? COLOR_CODES.SUCCESS_PURPLE
        : COLOR_CODES.ERROR_RED;
    },
    isAgSuperUser(): boolean {
      let user = this.user() as IUserV2 | null;
      if (user?.role_unique_id === USER_ROLES.AG_SUPER_USER) {
        return true;
      } else {
        return false;
      }
    },
    isUnlinkingFinancialProfile(): boolean {
      return this.$store.getters.isUnlinkingFinancialProfile;
    },
  },
});
</script>

<style scoped>
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0px;
}

.financial-profile-name {
  font-weight: bold;
  font-size: 18px;
  color: black;
  margin: 0;
}

.financial-profile-name-sub-items {
  color: black;
  margin-right: 10px;
  font-weight: 600;
}

.financial-profile-status-container {
  color: black;
  margin-right: 10px;
  font-weight: 600;
}

.financial-profile-status-active {
  background: green;
  padding: 5px;
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
}

.financial-profile-status-inactive {
  background: red;
  padding: 5px;
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
}
</style>
