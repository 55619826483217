import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b62047d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "financial-profile-name" }
const _hoisted_2 = { class: "financial-profile-name-sub-items" }
const _hoisted_3 = { class: "financial-profile-status-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_ag_agent_info_box = _resolveComponent("ag-agent-info-box")!
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_ag_agent_list_customer_balance = _resolveComponent("ag-agent-list-customer-balance")!
  const _component_agheading = _resolveComponent("agheading")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_AgTable = _resolveComponent("AgTable")!
  const _component_AgNotFound = _resolveComponent("AgNotFound")!
  const _component_ag_card = _resolveComponent("ag-card")!
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.financialProfile?.financial_data)
      ? (_openBlock(), _createBlock(_component_ag_agent_list_customer_balance, { key: 0 }, {
          heading: _withCtx(() => [
            _createVNode(_component_AgDiv, { class: "header-container" }, {
              default: _withCtx(() => [
                _createVNode(_component_AgDiv, { class: "financial-profile-name-container" }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.financialProfile.financial_profile_name), 1 /* TEXT */),
                    _createVNode(_component_AgDiv, null, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.financialProfile.plan_name) + " | " + _toDisplayString(_ctx.financialProfile.is_default), 1 /* TEXT */),
                        _createElementVNode("span", _hoisted_3, [
                          _createElementVNode("span", {
                            class: _normalizeClass(_ctx.renderStatusClass(_ctx.financialProfile.status))
                          }, _toDisplayString(_ctx.financialProfile.status), 3 /* TEXT, CLASS */),
                          _createTextVNode(" (" + _toDisplayString(_ctx.financialProfile?.status_remarks) + ")", 1 /* TEXT */)
                        ])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }),
                _withDirectives(_createVNode(_component_AGButton, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModal(_ctx.financialProfile.public_id)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("View not invoiced sales")
                  ]),
                  _: 1 /* STABLE */
                }, 512 /* NEED_PATCH */), [
                  [_vShow, _ctx.isAgSuperUser]
                ])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          items: _withCtx(() => [
            _createVNode(_component_ag_agent_info_box, {
              title: "Total Available Current Limit",
              icon: "userType",
              value: 
          _ctx.formatAmount(_ctx.financialProfile?.financial_data.total_balance_current)
        
            }, {
              value: _withCtx(() => [
                _createVNode(_component_ag_heading, {
                  title: 
              _ctx.formatAmount(
                _ctx.financialProfile?.financial_data.total_balance_current
              )
            ,
                  variant: "p",
                  class: "info-details color-red"
                }, null, 8 /* PROPS */, ["title"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["value"]),
            _createVNode(_component_ag_agent_info_box, {
              title: "Customer Status",
              icon: "customerStatus",
              value: 
          _ctx.financialProfile?.financial_data.customer_status +
            ' (' +
            _ctx.financialProfile?.status_remarks.toLocaleLowerCase() +
            ')' || ''
        
            }, null, 8 /* PROPS */, ["value"]),
            _createVNode(_component_ag_agent_info_box, {
              title: "Current Balance",
              icon: "currentBalance",
              value: _ctx.formatAmount(_ctx.financialProfile?.financial_data.current_balance)
            }, null, 8 /* PROPS */, ["value"]),
            _createVNode(_component_ag_agent_info_box, {
              title: "Total Not Invoiced Sales",
              icon: "notInvoice",
              value: 
          _ctx.formatAmount(
            _ctx.financialProfile?.financial_data.total_of_not_invoiced_tickets
          )
        ,
              class: "no_border"
            }, null, 8 /* PROPS */, ["value"]),
            _createVNode(_component_ag_agent_info_box, {
              title: "Plan Name",
              icon: "provider",
              value: _ctx.financialProfile?.plan_name
            }, null, 8 /* PROPS */, ["value"]),
            _createVNode(_component_ag_agent_info_box, {
              title: "Temporary Credit Limit",
              icon: "tempCreditLimit",
              value: 
          _ctx.formatAmount(_ctx.financialProfile?.financial_data.temporary_credit_limit)
        
            }, null, 8 /* PROPS */, ["value"]),
            _createVNode(_component_ag_agent_info_box, {
              title: "Permanent Credit Limit",
              icon: "permanentCredit",
              value: _ctx.formatAmount(_ctx.financialProfile?.financial_data.credit_limit)
            }, null, 8 /* PROPS */, ["value"]),
            _createVNode(_component_ag_agent_info_box, {
              title: "Financial Profile",
              icon: "profileCardLight",
              class: "no_border",
              value: _ctx.financialProfile?.financial_data.customer_code
            }, null, 8 /* PROPS */, ["value"]),
            (_ctx.enableActions)
              ? (_openBlock(), _createBlock(_component_a_g_button, {
                  key: 0,
                  "is-loading": 
          _ctx.selectedId === _ctx.financialProfile.public_id &&
          _ctx.isUnlinkingFinancialProfile
        ,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onUnlinkFinancialProfile(_ctx.financialProfile.public_id)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Unlink ")
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["is-loading"]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_ag_modal, {
      modelValue: _ctx.isModalOpen,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isModalOpen) = $event)),
      "modal-width": "60%"
    }, {
      header: _withCtx(() => [
        _createVNode(_component_agheading, null, {
          default: _withCtx(() => [
            _createTextVNode("Pending Receipt and Invoice of "),
            _createElementVNode("b", null, _toDisplayString(_ctx.financialProfile.financial_profile_name), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      body: _withCtx(() => [
        _createVNode(_component_ag_card, null, {
          default: _withCtx(() => [
            (_ctx.pendingPostingInvoices.length > 0)
              ? (_openBlock(), _createBlock(_component_ag_heading, {
                  key: 0,
                  variant: "h2",
                  title: "Invoices"
                }))
              : _createCommentVNode("v-if", true),
            (_ctx.pendingPostingInvoices && _ctx.pendingPostingInvoices.length > 0)
              ? (_openBlock(), _createBlock(_component_AgTable, {
                  key: 1,
                  headers: _ctx.tableHeaders_Invoices,
                  items: _ctx.pendingPostingInvoices,
                  "has-pagination": true,
                  "items-per-page": 5,
                  "has-search": false
                }, {
                  "col-ticket_no": _withCtx(({ item }) => [
                    _createVNode(_component_ag_div, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.ticket_no), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  "col-pnr": _withCtx(({ item }) => [
                    _createVNode(_component_ag_div, { class: "color_chip green" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.pnr), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  "col-posting_status": _withCtx(({ item }) => [
                    _createVNode(_component_ag_div, { class: "color_chip green" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.posting_status), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["headers", "items"]))
              : _createCommentVNode("v-if", true),
            (_ctx.pendingPostingReceipts.length > 0)
              ? (_openBlock(), _createBlock(_component_ag_heading, {
                  key: 2,
                  variant: "h2",
                  title: "Receipts",
                  style: {"margin-bottom":"0.5cm","margin-top":"0.5cm"}
                }))
              : _createCommentVNode("v-if", true),
            (_ctx.pendingPostingReceipts && _ctx.pendingPostingReceipts.length > 0)
              ? (_openBlock(), _createBlock(_component_AgTable, {
                  key: 3,
                  headers: _ctx.tableHeaders_Receipts,
                  items: _ctx.pendingPostingReceipts,
                  "has-pagination": true,
                  "items-per-page": 5,
                  "has-search": false
                }, {
                  "col-payment_id": _withCtx(({ item }) => [
                    _createVNode(_component_ag_div, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.payment_id), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  "col-amount": _withCtx(({ item }) => [
                    _createVNode(_component_ag_div, { class: "color_chip green" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.amount), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  "col-posting_status": _withCtx(({ item }) => [
                    _createVNode(_component_ag_div, { class: "color_chip green" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.posting_status), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["headers", "items"]))
              : _createCommentVNode("v-if", true),
            (
            _ctx.pendingPostingInvoices.length === 0 &&
            _ctx.pendingPostingReceipts.length === 0
          )
              ? (_openBlock(), _createBlock(_component_AgNotFound, {
                  key: 4,
                  "test-id": "",
                  heading: "No Pending Receipts & Invoices Found",
                  description: ""
                }))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_AGButton, {
          onClick: _ctx.closeModal,
          variant: "primary"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Close")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ], 64 /* STABLE_FRAGMENT */))
}