<template>
  <MCombobox
    v-model:inputValue="searchValue"
    label="Select Organization"
    itemValue="value"
    itemLabel="label"
    :options="filteredOrgs"
    @update:inputValue="onUpdateModelValue"
    :disabled="isFetchingOrganizations"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { IOrganization } from "@/ag-portal-common/interfaces/organization.interface";
import { MCombobox } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "OrganizationAutoComplete",
  components: {
    MCombobox,
  },
  props: {
    defaultValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      searchValue: "",
      ELEMENT_TYPES,
    };
  },
  methods: {
    genTestId,
    onUpdateModelValue(value: string) {
      this.$emit("onChange", value);
    },
  },
  computed: {
    filteredOrgs() {
      let filterOrganizations = this.$store.getters.organizations || [];
      filterOrganizations = filterOrganizations.map(
        (item: IOrganization, i: number) => ({
          label: item.name,
          value: item.public_id,
        })
      );
      return filterOrganizations;
    },
    isFetchingOrganizations() {
      return this.$store.getters.isFetchingOrganizations;
    },
  },
  watch: {
    defaultValue: {
      handler: function (value) {
        this.searchValue = value;
      },
      immediate: true,
    },
  },
});
</script>
