<template>
  <DashboardGreetingsCard user="Operation User" />
  <FlightIssuanceChart />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DashboardGreetingsCard from "../DashboardGreetingsCard.vue";
import FlightIssuanceChart from "../FlightIssuanceChart.vue";

export default defineComponent({
  name: "OperationUserDashboardView",
  components: { DashboardGreetingsCard, FlightIssuanceChart },
});
</script>
