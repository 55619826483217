<template>
  <AgentDashboardView v-if="isUserAgent()" />
  <SuperUserDashboardView v-if="isSuperUser()" />
  <OperationUserDashboardView v-if="isOperationUser()" />
  <FinanceUserDashboardView v-if="isFinanceUser()" />
  <SalesUserDashboardView v-if="isSalesUser()" />
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";

import AgentDashboardView from "../components/Dashboards/AgentDashboard.vue";
import SuperUserDashboardView from "../components/Dashboards/SuperUserDashboard.vue";
import OperationUserDashboardView from "../components/Dashboards/OperationUserDashboard.vue";
import FinanceUserDashboardView from "../components/Dashboards/FinanceUserDashboard.vue";
import SalesUserDashboardView from "../components/Dashboards/SalesUserDashboard.vue";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";

import "swiper/css";
import "swiper/css/navigation";

export default defineComponent({
  name: "DashboardView",
  components: {
    AgentDashboardView,
    SuperUserDashboardView,
    OperationUserDashboardView,
    FinanceUserDashboardView,
    SalesUserDashboardView,
  },

  methods: {
    isUserAgent() {
      let user: IUserV2 | null = this.user();
      return user?.role_unique_id === USER_ROLES.AGENT;
    },
    isSuperUser() {
      let user: IUserV2 | null = this.user();
      return user?.role_unique_id === USER_ROLES.AG_SUPER_USER;
    },
    isFinanceUser() {
      let user: IUserV2 | null = this.user();
      return user?.role_unique_id === USER_ROLES.FINANCE;
    },
    isOperationUser() {
      let user: IUserV2 | null = this.user();
      return user?.role_unique_id === USER_ROLES.OPERATIONS;
    },
    isSalesUser() {
      let user: IUserV2 | null = this.user();
      return user?.role_unique_id === USER_ROLES.SALES;
    },
  },

  setup() {
    let user = inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2 | null;
    return {
      user,
    };
  },
});
</script>
