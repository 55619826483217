<template>
  <ag-modal :is-open="isOpen" @close="handleCloseDialog" modal-width="80%">
    <template #header>
      <v-card-title>Terms and Conditions</v-card-title>
    </template>
    <template #body>
      <AgDiv>
        <p>
          <b>AEROGLOBE (PRIVATE) LIMITED</b>, a travel management company,
          having its Registered Office at Shop # 1 & 2, Ground Floor Shams
          Plaza, Block B North Nazimabad, Karachi, Pakistan, through its
          Executive Director (hereinafter referred to as the “Company”) which
          expression shall include its successors-in-interest and assigns of the
          Second Part; (Company and Agent shall hereinafter collectively be
          referred to as ‘Parties’ and individually as ‘Party’)
        </p>
        <p><b>WHEREAS:</b></p>

        <p>
          The Company is an International Air Transport Association certified
          travel agency engaged in the business of providing local and
          international travel services. The Agent is a Non-IATA travel agency
          seeking to engage the travel related services provided by the Company
          and has approached the Company with a request to avail its services to
          help it provide travel related services to its clientele.
        </p>
        <p>
          The Company has acceded to the request of the Agent on the basis of
          the terms and conditions contained in this Agreement.
        </p>
        <p>
          NOW THEREFORE, in consideration of the mutual covenants, promises,
          agreements, representations and warranties contained in this
          Agreement, the Parties hereto do hereby covenant, promise, agree,
          represent and warrant as follows:
        </p>
        <p>
          That the Agent hereby engages the services (hereinafter referred to as
          “Services” and as more particularly described in Schedule ‘A’ attached
          hereto) to be provided by the Company and the Company hereby accedes
          to render the Services to the Agent. The Company may also provide
          services of processing of umrah visa, booking of accommodation,
          transport arrangements & value-added services to the clients of the
          Agent (“Umrah Package”) on request of Agent made on letter head, duly
          signed by authorized signatories and/ or via official email. This
          Agreement shall come into force on the date hereof and shall continue
          in force for a period of Five [5] years, unless extended further by
          mutual written consent of the Parties. Notwithstanding the expiry or
          termination of this Agreement, if any obligation remains to be
          performed by the Agent, whether under the instant Agreement or any
          previous agreement between the Parties, the terms of this Agreement
          corresponding to such unperformed obligation shall remain in force
          until such time the obligation stands performed by the Agent. That the
          Company shall provide IDs, passwords, and access codes of its portal
          and relevant airlines registration systems / global distribution
          system / local airlines to the Agent which shall be valid for the
          purpose of being able to sell tickets to its clients, subject to the
          following:
        </p>

        <ol>
          <li>
            The IDs, passwords, and access codes that the Company shall make
            available, are specified in Schedule B attached herewith. The access
            of the Agent shall be limited to the same.
          </li>
          <li>
            The Company may provide the IDs, passwords, and access codes to the
            authorized representative of the Agent upon the Agent requesting in
            writing in this regard. The Agent shall be solely responsible in
            keeping the IDs and passwords secured and prevent any unauthorized
            use thereof;
          </li>
          <li>
            The use of the IDs, passwords, and access codes allotted to the
            Agent shall be valid only till the continuance of this Agreement and
            upon the expiry/termination of this Agreement, the Agent shall be
            obligated to return the IDs, passwords, and access codes to the
            Company;
          </li>
          <li>
            The Agent shall ensure that its authorized representatives /
            employees shall not in any manner whatsoever misuse the IDs,
            passwords, and access codes provided by the Company or engage in any
            fraudulent activity with respect to the same. In any such event, the
            Agent shall be solely liable to bear any penalties, financial losses
            or damages that may be sustained.
          </li>
          <li>
            The responsibility for use of the IDs, passwords, and access codes
            during the term of this Agreement shall be the exclusive and sole
            responsibility of the Agent, including Agency Debit Memo Advice
            (ADMs), Airline Debit Memo in relation to tickets issued or services
            availed by the Agent, whether due to the misuse of GDS segments or
            any other reason, including cases involving Inadmissible Passengers
            (INAD) or similar circumstances, under collection taxes, no show
            charges, flight booking misuse etc.
          </li>
          <li>
            The Agent shall be exclusively responsible for the issuance of
            tickets by its employees and its clientele and ensure that they do a
            thorough background check of its clientele before allotting tickets.
            Any legal violation, negligence, or carelessness on part of the
            Agent and its employees shall be the sole responsibility of the
            Agent who shall indemnify the Company for any losses, liabilities,
            inconveniences, litigation proceedings incurred in consequence of
            the negligence on part of the Agent.
          </li>
          <li>
            The responsibility of the Agent entailed above shall continue after
            the termination of this Agreement in relation to all such tickets
            issued during the term of the Agreement under the IDs, passwords,
            and access codes provided by the Company.
          </li>
        </ol>
        <p>
          That the Agent shall render payments to the Company in the following
          manner in accordance with the rules prescribed by the International
          Air Transport Association (IATA) and shall ensure that the payment is
          cleared one day prior to the IATA prescribed date. Failure shall
          trigger an event of default under this Agreement. In order to secure
          its payment obligations, the Agent shall provide alternate payment
          options through open dated cheque to the Company and in this regard it
          shall fulfill the following conditions to the satisfaction of the
          Company:
        </p>

        <ol>
          <li>
            The Agent shall ensure that the bank account from which the cheque
            shall be issued is a running/ active account of the Agent;
          </li>
          <li>
            It shall procure a latest bank statement for the preceding six
            months before the issuance of open dated cheque;
          </li>
          <li>
            The Agent shall also procure an account maintenance certificate
            (addressed to Company) from the concerned bank in which the bank
            account of the Agent is being maintained and the cheque(s) are being
            issued from;
          </li>
          <li>
            As further security for its payment obligations towards the Company,
            the Agent shall obtain personal guarantees/undertakings from its
            directors and/or sponsor shareholders in favor of the Company to pay
            any outstanding payments due by the Agent to the Company.
          </li>
        </ol>
        <p>
          That the Company shall have no concern with or be aware of the
          identity of the clients and shall not in any way be liable for any
          illegal actions of the clients of the Agent, including but not limited
          to stay beyond VISA validity. In the event a client undertakes any
          illegal activity abroad, the Agent shall fully participate in the
          inquiry / investigation that may be conducted by any law enforcement
          agency and the Company shall have no concern therewith. In the event
          any penalty is imposed due to the illegal activity of any client of
          the Agent, the Agent shall be liable to settle such penalty. Failure
          to settle such penalty shall operate as default of the Agent. The
          Agent shall exercise due care in ensuring the validity, safe custody
          and verification of all travel documents which the Agent has received
          from its clients. In exercising this care, the Agent shall comply with
          the security standards prescribed for international airlines travel
          agents. The Agent shall be solely and exclusively responsible for any
          loss, use or misuse of these documents and the Company shall have no
          liability whatsoever in any such event. The Agent shall submit sales
          reports and follow the accounting instructions provided to it by the
          Company. Within five days after each fortnight end, the Agent shall
          submit to the Company a sales report covering such fortnight. Such
          report shall show the total sale with quantities at the fortnight end.
          The Agent shall provide data pertaining to sales volumes and the
          number of tickets sold fortnightly on Domestic Airlines and
          International Airlines as and when demanded by the Company. The Agent
          shall diligently ensure payment of commission to the Company on the
          rates. The Agent shall maintain adequate records and accounts,
          together with supporting documents, recording the details of all
          transactions effected pursuant to this Agreement, including accounts
          and supporting documents evidencing the sale of tickets. Such records,
          accounts and documents shall be preserved by The Agent and be
          furnished to the Company whenever it requires the same. The Agent
          shall at all times during the subsistence of this Agreement use its
          best efforts to perform the Services in a manner that is in line with
          best business practices and shall at all times demonstrate such degree
          of care as is expected of a skilled and qualified travel agency. From
          time to time during the subsistence of this Agreement, the Company may
          provide to The Agent thresholds, covering, by way of illustration and
          without limitation, the specific services, performance expectations,
          performance assessment, areas of improvement and any other pertinent
          information and The Agent shall be bound to meet such service
          thresholds. The Agent hereby represents warrants and undertakes as
          follows:
        </p>
        <ol>
          <li>
            That it has all rights, powers and authorities to enter into and
            perform all duties and responsibilities under this Agreement;
          </li>
          <li>
            It holds a valid license to carry on business as a travel agency;
          </li>
          <li>
            It has carefully read and understood the contents of this Agreement
            and has voluntarily accepted them
          </li>
          <li>
            That it will not misuse the IDs provided by the Company for the
            purpose of this Agreement and it will be fully responsible for any
            contravention, penalties, financial losses caused on the given
            reservation / ticketing ID due to their or any their employees,
            staff, agents negligence and fraud.
          </li>
          <li>
            That it understands that the Company has reserved the right to
            cancel this Agreement and all reservations made in case of any
            violation found.
          </li>
          <li>
            That in case of any financial discrepancy, the Company has the right
            to cancel all reservation and refunds tickets whether fully or
            partially utilized and refund charges will be debited to its
            account.
          </li>
          <li>
            It will be fully responsible of the authenticity of all travel
            documents such as passport, visa, tickets, CNICs, and other travel
            documents.
          </li>
          <li>
            It has agreed to this Agreement, being fully aware of the
            regulations/policies related to traveling under the Laws of
            Pakistan. More particularly, it is fully aware of the policy and
            procedure regarding issuance of airlines tickets.
          </li>
          <li>
            It will be fully responsible for any business-related transaction
            pertaining to this Agreement.
          </li>
          <li>
            It shall be fully responsible in case any suspicious passenger has
            traveled on issued tickets.
          </li>
          <li>
            The Umrah Package shall not be refundable once booked with the
            responsibility of the Company only extending to the provision of
            services as per the issued Hotel voucher.
          </li>
          <li>
            The Agent shall execute an affidavit on stamp paper thereby
            affirming his responsibilities hereunder and absolve the Company
            therefrom in relation to the Umrah Package.
          </li>
          <li>
            It has all the requisite licenses and approvals from the relevant
            authorities required to sell airline tickets to the general public
            or generally to provide any services ancillary or having nexus with
            travel / tour and the Company shall not be liable in the event any
            loss occurs or damage is incurred due to want of any requisite
            license or approval.
          </li>
        </ol>
        <p>
          The Company may exercise its option to immediately terminate the
          Agreement and the rights granted herein to the Agent:
        </p>
        <ol>
          <li>
            If the Agent (whether by reason of its own acts or any other reason)
            defaults at any time in meeting the service thresholds (if any)
            and/or in making any payment required by this Agreement and/or
            committing any breach of any covenant herein contained, and fails to
            remedy such fault or breach within 2 Business Days after receipt of
            written notice thereof by the Company;
          </li>
          <li>If the Agent is declared insolvent or becomes bankrupt.</li>
        </ol>
      </AgDiv>

      <hr />
      <div class="action-btn-container">
        <AGButton variant="danger" @click="onCloseModal">Not Agree</AGButton>
        <span>&nbsp;&nbsp;</span>
        <AGButton variant="primary" @click="onTermsAgree">Agree</AGButton>
      </div>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import { AGButton } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "termsAndConditionsDialog",
  events: ["onTermsNotAgree", "onTermsAgree"],
  props: {
    isOpen: { type: Boolean, required: true },
  },
  data(): {
    ELEMENT_TYPES: IObject;
  } {
    return {
      ELEMENT_TYPES,
    };
  },
  methods: {
    genTestId,
    onCloseModal() {
      this.$emit("onTermsNotAgree");
    },
    onTermsAgree() {
      this.$emit("onTermsAgree");
    },
    handleCloseDialog() {
      this.$emit("handleClose");
    },
  },
  components: { AGButton },
});
</script>

<style scoped>
.action-btn-container {
  float: right;
}

ol {
  padding-left: 40px;
  margin-bottom: 20px;
}

ol li::marker {
  font-weight: bold;
  font-size: 14px;
}
</style>
