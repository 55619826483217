import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_agent_info_box = _resolveComponent("ag-agent-info-box")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_AgNotFound = _resolveComponent("AgNotFound")!
  const _component_SnapshotDetailAccordion = _resolveComponent("SnapshotDetailAccordion")!

  return (_openBlock(), _createBlock(_component_SnapshotDetailAccordion, { title: "Sabre Segment" }, {
    overview: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sabreSegmentAggregates, (item, index) => {
        return (_openBlock(), _createBlock(_component_ag_column, {
          xs: "12",
          sm: "12",
          md: "6",
          lg: "3",
          key: index
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ag_agent_info_box, {
              title: _ctx.renderItemTitle(item),
              icon: "notInvoice",
              value: _ctx.renderItemValue(item)
            }, null, 8 /* PROPS */, ["title", "value"])
          ]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    "accordion-content": _withCtx(() => [
      (_ctx.showDetails)
        ? (_openBlock(), _createBlock(_component_MDataTable, {
            key: 0,
            headers: _ctx.sabreSegmentHeader,
            data: _ctx.sabreSegmentOrganizations,
            "items-per-page": 10,
            hasSearch: true
          }, {
            organization_name: _withCtx(({ item }) => [
              _createVNode(_component_AgDiv, {
                onClick: ($event: any) => (_ctx.handleOrganizationRowClick($event, item)),
                class: "name-column organization-link"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.organization_name), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["headers", "data"]))
        : (_openBlock(), _createBlock(_component_AgNotFound, {
            key: 1,
            "test-id": "",
            heading: "Organizations not found"
          }))
    ]),
    _: 1 /* STABLE */
  }))
}