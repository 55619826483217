import { IDashboardState } from "@/ag-portal-common/interfaces/dashboard.interface";

const state: IDashboardState = {
  isInsightsLoading: false,
  insights: null,
  bookingAggregate: null,
  dailyChartData: null,
};

export default state;
