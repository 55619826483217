import { ActionContext, ActionTree } from "vuex";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { ISalesState } from "@/ag-portal-common/interfaces/salesState.interface";
import { StatusCodes } from "http-status-codes";
import OrganizationService from "@/modules/Organization/services/organization.service";
import SalesService from "../services/sales.service";
import analyticServices from "@/analytic.service";
import { SALES_ANALYTICS_EVENTS } from "../constants/analyticsEvents";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { SalesProductivityParamsRequestModel } from "../models/FetchSalesProductivityParams.request";

const actions: ActionTree<ISalesState, ISalesState> = {
  // *********** Get Sales Sectors ***********
  async fetchSalesSectors(context: ActionContext<ISalesState, ISalesState>) {
    const methodName = "actions.fetchSalesSectors";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setFetchSectorsLoading", true);
      context.commit("setSalesSectors", null);
      const organizationService = new OrganizationService();
      const response: IAGResponse<any> =
        await organizationService.getFormDetails();
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("setSalesSectors", response.data?.sector);
        return response.data;
      } else {
        throw response;
      }
    } catch (err) {
      loggerService.logError(`${methodName}:`, err);
    } finally {
      context.commit("setFetchSectorsLoading", false);
    }
  },

  // *********** Get Sales Stats ***********
  async getSalesStats(
    context: ActionContext<ISalesState, ISalesState>,
    salesmanId
  ) {
    const methodName = "actions.getSalesStats";
    let response: IAGResponse<any> = {
      success: false,
    };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setSalesLoading", true);
      context.commit("setSalesSnapshot", null);
      context.commit("setSalesProductivity", null);
      const salesService = new SalesService();
      response = await salesService.getSalesSnapshot(salesmanId);
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("setSalesSnapshot", response.data?.data);
        analyticServices.logActionEvent(
          SALES_ANALYTICS_EVENTS.SALES_STATS_FETCHED,
          {
            salesmanId,
          }
        );
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          "Sales statistics fetched successfully";
      } else {
        loggerService.logInfo(
          `${methodName}: ${LOG_LABELS.ERROR}`,
          response.error
        );
        throw response;
      }
    } catch (err) {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, err);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        response.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      context.commit("setSalesLoading", false);
    }
    notificationService.triggerNotification();
  },

  // *********** Get Sales Productivity ***********
  async getSalesProductivity(
    context: ActionContext<ISalesState, ISalesState>,
    {
      payload,
      salesmanId,
    }: {
      payload: SalesProductivityParamsRequestModel;
      salesmanId: string;
    }
  ) {
    const methodName = "actions.getSalesProductivity";
    let response: IAGResponse<any> = {
      success: false,
    };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setSalesLoading", true);
      context.commit("setSalesProductivity", null);
      const salesService = new SalesService();
      response = await salesService.getSalesProductivity(salesmanId, payload);
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("setSalesProductivity", response.data?.data);
        analyticServices.logActionEvent(
          SALES_ANALYTICS_EVENTS.SALES_PRODUCTIVIY_FETCHED,
          {
            salesmanId,
            ...payload,
          }
        );
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          "Sales Productivity fetched successfully";
      } else {
        loggerService.logInfo(
          `${methodName}: ${LOG_LABELS.ERROR}`,
          response.error
        );
        throw response;
      }
    } catch (err) {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, err);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        response.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      context.commit("setSalesLoading", false);
    }
    notificationService.triggerNotification();
  },

  // *********** Get Sales My Team ***********
  async getSalesMyTeam(context: ActionContext<ISalesState, ISalesState>) {
    const methodName = "actions.getSalesMyTeam";
    let response: IAGResponse<any> = {
      success: false,
    };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setSalesMyTeamLoading", true);
      context.commit("setSalesMyTeam", []);
      const salesService = new SalesService();
      response = await salesService.getSalesMyTeam();
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("setSalesMyTeam", response.data?.data?.team);
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = "Team Members Successfully Fetched";
        analyticServices.logActionEvent(
          SALES_ANALYTICS_EVENTS.SALES_MY_TEAM_FETCHED
        );
      } else {
        loggerService.logInfo(
          `${methodName}: ${LOG_LABELS.ERROR}`,
          response.error
        );
        throw response;
      }
    } catch (err) {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, err);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        response.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      context.commit("setSalesMyTeamLoading", false);
    }
    notificationService.triggerNotification();
  },
};

export default actions;
