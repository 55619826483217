<template>
  <SnapshotDetailAccordion title="Payments">
    <template #overview>
      <ag-column
        xs="12"
        sm="12"
        md="6"
        lg="3"
        v-for="(item, index) in paymentsDataAggregates"
        :key="index"
      >
        <ag-agent-info-box
          :title="renderItemTitle(item)"
          icon="notInvoice"
          :value="renderItemValue(item)"
        />
      </ag-column>
    </template>
    <template #accordion-content>
      <MDataTable
        v-if="showDetails"
        :headers="paymentHeader"
        :data="paymentsOrganizations"
        :items-per-page="10"
        :hasSearch="true"
      >
        <template #organization_name="{ item }">
          <AgDiv
            @click="handleOrganizationRowClick($event, item)"
            class="name-column organization-link"
            >{{ item.organization_name }}</AgDiv
          >
        </template>
      </MDataTable>
      <AgNotFound v-else test-id="" heading="Organizations not found" />
    </template>
  </SnapshotDetailAccordion>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import _ from "lodash";

import {
  ISectorProductivity,
  PaymentAggregates,
} from "@/ag-portal-common/interfaces/sectorProductivity.interface";
import { MDataTableHeader } from "@/ag-portal-common/types";
import { MDataTable } from "@aeroglobe/ag-core-ui";
import SnapshotDetailAccordion from "@/components/SnapshotDetailAccordion.vue";

export default defineComponent({
  name: "PaymentsData",
  components: {
    MDataTable,
    SnapshotDetailAccordion,
  },
  props: {
    handleOrganizationRowClick: {
      type: Function,
      default: () => ({}),
    },
  },
  data(): {
    startDate: Date;
    endDate: Date;
    selectedSector: string;
    paymentHeader: MDataTableHeader[];
  } {
    return {
      startDate: new Date(),
      endDate: new Date(),
      selectedSector: "",
      paymentHeader: [
        {
          title: "Organization Name",
          value: "organization_name",
          key: "organization_name",
          sortable: true,
          align: "left",
        },
        {
          title: "Total Payment",
          value: "total_payments",
          key: "total_payments",
          sortable: true,
          align: "left",
        },
        {
          title: "ONE BILL",
          value: "ONE_BILL",
          key: "ONE_BILL",
          sortable: true,
          align: "left",
        },
        {
          title: "CREDIT CARD",
          value: "CREDIT_CARD",
          key: "CREDIT_CARD",
          sortable: true,
          align: "left",
        },
        {
          title: "IBFT",
          value: "IBFT",
          key: "IBFT",
          sortable: true,
          align: "left",
        },
      ],
    };
  },
  methods: {
    renderItemTitle(value: string) {
      return _.capitalize(value?.replaceAll("_", " "));
    },
    renderItemValue(value: keyof PaymentAggregates) {
      return this.sectorProductivity.payment_data?.aggregates[value];
    },
  },
  computed: {
    sectorProductivity(): ISectorProductivity {
      return this.$store.getters.sectorProductivity;
    },
    showDetails(): boolean {
      const isLoading = this.$store.getters.isSectorProductivityLoading;
      const organizations =
        this.$store.getters.sectorProductivity?.payment_data?.organizations ||
        [];
      return !isLoading && organizations.length > 0;
    },
    paymentsOrganizations() {
      const sectorProductivity = this.$store.getters
        .sectorProductivity as ISectorProductivity;
      const organizations =
        sectorProductivity?.payment_data?.organizations ?? [];

      const formattedOrganizations = organizations.map((item) => {
        const paymentMethods = Object.fromEntries(
          item.payments_by_method.map(({ method_name, payment_count }) => [
            method_name,
            payment_count,
          ])
        );

        return {
          public_id: item.public_id,
          organization_name: item.organization_name,
          total_payments: item.total_payments,
          ...paymentMethods,
        };
      });

      return formattedOrganizations;
    },
    paymentsDataAggregates(): Array<keyof PaymentAggregates> {
      const sectorProductivity = this.$store.getters.sectorProductivity as
        | ISectorProductivity
        | undefined;
      const aggregates = sectorProductivity?.payment_data?.aggregates;

      if (aggregates) {
        return Object.keys(aggregates) as Array<keyof PaymentAggregates>;
      }

      return [];
    },
  },
});
</script>

<style lang="scss" scoped>
.card-wrapper {
  margin-block: 30px;
  border: solid 1px var(--green-color);
  border-top: solid 8px var(--green-color);
}

.heading {
  font-size: 30px;
  font-weight: bold;
}

.overview-heading {
  font-size: 19px;
  margin: 10px;
  font-weight: bold;
}

.items-container {
  border-left: solid 4px var(--green-color);
}

.empty-items-message {
  text-align: center;
  margin-block: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #828282;
}
.organization-link {
  color: var(--green-color);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
