<template>
  <div class="password-change">
    <ag-card class="pass-container">
      <div class="profile-header">
        <ag-heading variant="h3" title="Password Management" />
        <ag-heading variant="p" title="Change your password" />
        <hr />
      </div>

      <form class="user-profile" autocomplete="off" @submit.prevent>
        <div class="pass-input">
          <div class="label">Current Password</div>

          <AGTextField
            v-model.trim="password.current"
            :value="password.current"
            label=""
            class="pass-text-field"
            :disabled="isChangePasswordLoading"
            :appendIcon="passwordShow.current ? 'mdi-eye' : 'mdi-eye-off'"
            :type="passwordShow.current ? 'text' : 'password'"
            @click:append="passwordShow.current = !passwordShow.current"
            :error="changePasswordErrors['current']"
          />
        </div>

        <div class="pass-input">
          <div class="label">New Password</div>

          <AGTextField
            v-model.trim="password.newPass"
            :value="password.newPass"
            label=""
            class="pass-text-field"
            :disabled="isChangePasswordLoading"
            :appendIcon="passwordShow.newPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="passwordShow.newPass ? 'text' : 'password'"
            @click:append="passwordShow.newPass = !passwordShow.newPass"
            :error="changePasswordErrors['newPass']"
          />
        </div>

        <div class="pass-input">
          <div class="label">Confirm New Password</div>

          <AGTextField
            v-model.trim="password.confirm"
            :value="password.confirm"
            label=""
            class="pass-text-field"
            :disabled="isChangePasswordLoading"
            :appendIcon="passwordShow.confirm ? 'mdi-eye' : 'mdi-eye-off'"
            :type="passwordShow.confirm ? 'text' : 'password'"
            @click:append="passwordShow.confirm = !passwordShow.confirm"
            :error="changePasswordErrors['confirm']"
          />
        </div>

        <MButton
          @click="onChangePasswordHandler"
          :disabled="isChangePasswordLoading"
        >
          Change Password
        </MButton>
      </form>
    </ag-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { passwordChangeValidation } from "@/modules/Profile/validations/passwordChangeValidation";

import { yupValidationErrorAsSchema } from "@/ag-portal-common/utils/helpers";
import { ValidationError } from "yup";

export default defineComponent({
  name: "userProfile",
  data() {
    return {
      password: {
        current: "",
        newPass: "",
        confirm: "",
      },

      passwordShow: {
        current: false,
        newPass: false,
        confirm: false,
      },

      isChangePasswordLoading: false,
      changePasswordErrors: {} as { [index: string]: string },
    };
  },
  methods: {
    async onChangePasswordHandler() {
      this.isChangePasswordLoading = true;
      this.changePasswordErrors = {};

      try {
        const { current, newPass, confirm } = this.password;

        const payload = await passwordChangeValidation.validate(
          {
            current,
            newPass,
            confirm,
          },
          { abortEarly: false }
        );

        const body = {
          new_password: payload.newPass,
          old_password: payload.current,
        };

        await this.$store.dispatch("changePassword", body);

        this.onResetPasswordFormHandler();
      } catch (exception) {
        if (exception instanceof ValidationError) {
          this.changePasswordErrors = yupValidationErrorAsSchema(exception);
        }
      } finally {
        this.isChangePasswordLoading = false;
      }
    },

    onResetPasswordFormHandler() {
      this.password = {
        current: "",
        confirm: "",
        newPass: "",
      };
    },
  },
  unmounted() {
    this.onResetPasswordFormHandler();
  },
});
</script>

<style lang="scss">
.pass-text-field {
  position: relative;

  .v-input {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .v-input__control {
      flex: 1;
    }

    .v-input__append {
      right: 2px;
      background-color: white;
      padding: 10px;
      margin: 0;
    }

    .error-text {
      width: calc(100% - 10px);
      position: absolute;
      inset: 58px 10px;
    }
  }
}
</style>

<style lang="scss" scoped>
[disabled="true"] {
  opacity: 0.5 !important;
  pointer-events: none;
  user-select: none;
  cursor: not-allowed;
}

.pass-container {
  max-width: 1024px;
  margin: 0 auto 16px;
}

.password-change {
  width: 100%;
  padding: 4px 0 0;

  .pass-text-field {
    max-width: 360px;
    .v-input {
      margin: 0;
    }
  }
  .pass-input {
    .label {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 12px;
  }
}

@media screen and (max-width: 470px) {
  .pass-action {
    width: 100% !important;
  }
}
</style>
