import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_AgFile = _resolveComponent("AgFile")!
  const _component_AGTextField = _resolveComponent("AGTextField")!
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createBlock(_component_ag_modal, {
    "test-id": "",
    "is-open": _ctx.isOpen,
    onClose: _ctx.closeModal,
    "modal-width": "40%"
  }, {
    header: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.isEdit ? "Edit" : "Add") + " Service", 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_AGButton, {
        "test-id": "",
        type: "button",
        class: "modal_close_icon",
        variant: "link",
        onClick: _ctx.closeModal
      }, {
        default: _withCtx(() => [
          _createTextVNode("Close ")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ]),
    body: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
      }, [
        _createVNode(_component_AgFile, {
          "test-id": "",
          class: "ag-file-theme",
          label: "Service Image",
          multiple: false,
          "onOn:change": _ctx.onFileChangeHandler
        }, null, 8 /* PROPS */, ["onOn:change"]),
        _createVNode(_component_AGTextField, {
          "test-id": _ctx.genTestId(_ctx.ELEMENT_TYPES.TEXT_FIELD, 'service-price'),
          modelValue: _ctx.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
          value: _ctx.name,
          type: "text",
          label: "Service Name",
          error: _ctx.errors?.name
        }, null, 8 /* PROPS */, ["test-id", "modelValue", "value", "error"]),
        _createVNode(_component_AGTextField, {
          "test-id": _ctx.genTestId(_ctx.ELEMENT_TYPES.TEXT_FIELD, 'service-price'),
          modelValue: _ctx.description,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.description) = $event)),
          value: _ctx.description,
          type: "text",
          label: "Description",
          error: _ctx.errors?.description
        }, null, 8 /* PROPS */, ["test-id", "modelValue", "value", "error"]),
        _createVNode(_component_AGButton, {
          "test-id": "",
          type: "submit",
          class: "submit-btn",
          disabled: _ctx.isLoading
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.isEdit ? "Save" : "Add"), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled"])
      ], 32 /* NEED_HYDRATION */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-open", "onClose"]))
}