import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { ISector } from "@/ag-portal-common/interfaces/sector.interface";
import { SAAS_ELITE_ORGANIZATION_STATUS } from "@/ag-portal-common/enums/SAAS_ELITE_ORGANIZATION_STATUS";

export interface IOrganization {
  pia_provider: string;
  contact_person_email: string;
  contact_person_phone: string;
  created_at: string;
  is_active: boolean;
  is_delete: boolean;
  sector_name: string;
  sector: string;
  name: string;
  public_id: string;
  default_financial_profile: IFinancialProfile;
  default_currency: string;
  status: string;
  meta: {
    identity_dump: string;
    phone_dump: string;
  };
}

export interface IOrganizationPreference {
  display_name: string;
  logo: string | null;
  default_color: string;
  organization_id: string;
  org_name_to_display: string;
}

export interface IOrganizationFromLoginResponse {
  organization_id: string;
  sector: ISector;
  financial_profiles: IFinancialProfile[];
}

export interface ISaasEliteOrganization {
  id: number;
  organization_id: string;
  name: string;
  public_id: string;
  country: string;
  city: string;
  status: SAAS_ELITE_ORGANIZATION_STATUS;
  documents: object;
  created_at: Date;
  contact_person_email: string;
  contact_person_phone: string;
}
