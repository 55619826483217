<template>
  <OrganizationDetailPage />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import OrganizationDetailPage from "../components/OrganizationDetails/OrganizationDetailPage.vue";

export default defineComponent({
  name: "AgentOrgDetails",
  components: {
    OrganizationDetailPage,
  },
  created() {
    this.$store.dispatch("fetchOrganization", this.$route.params.id);
  },
});
</script>
