import { GetterTree } from "vuex";
import { IAuthState } from "@/ag-portal-common/interfaces/authState.interface";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";
import { ISettings } from "@/ag-portal-common/interfaces/settings.interface";
import { IOrganizationPreference } from "@/ag-portal-common/interfaces/organization.interface";

const getters: GetterTree<IAuthState, IAuthState> = {
  user: (state): IUserV2 | null => state.user,
  isUserSignedIn: (state): boolean => state.isUserSignedIn,
  isAuthenticating: (state): boolean => state.isAuthenticating,
  permissions: (state): Array<string> | null => state.permissions,
  settings: (state): ISettings | null => state.settings,
  organizationPreferences: (state): IOrganizationPreference | null =>
    state.organization_preference || null,
  organizationId: (state): string | null =>
    state.organization_preference?.organization_id || null,
};

export default getters;
