<template>
  <div class="shimmer-card">
    <div
      class="shimmer-container"
      :style="{ width: cardWidth, height: cardHeight }"
    >
      <div class="shimmer"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ShimmerCard",
  props: {
    cardWidth: {
      type: String,
      default: "100%", // Default width
    },
    cardHeight: {
      type: String,
      default: "100px", // Default height
    },
  },
});
</script>

<style lang="scss" scoped>
.shimmer-card {
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 15px;
}

.shimmer-container {
  position: relative;
  width: 100%;
  height: 200px; /* Default height */
  overflow: hidden;
}

.shimmer {
  position: absolute;
  top: 0;
  left: -50%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(215, 215, 215, 0.8) 50%,
    transparent 100%
  );
  animation: shimmer-animation 2s infinite linear;
}

/* Animation keyframes */
@keyframes shimmer-animation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>
