import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-98c03b7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "heading" }
const _hoisted_2 = { class: "empty-items-message" }
const _hoisted_3 = { class: "empty-items-message" }
const _hoisted_4 = { class: "empty-items-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_calendar = _resolveComponent("ag-calendar")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_ShimmerCard = _resolveComponent("ShimmerCard")!
  const _component_ag_agent_info_box = _resolveComponent("ag-agent-info-box")!
  const _component_SnapshotDetailAccordion = _resolveComponent("SnapshotDetailAccordion")!
  const _component_ResultNotFound = _resolveComponent("ResultNotFound")!

  return (_openBlock(), _createBlock(_component_MCard, { class: "organization-snapshot-header" }, {
    default: _withCtx(() => [
      _createVNode(_component_MCard, null, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_1, "Statistics " + _toDisplayString(_ctx.renderOrgName()), 1 /* TEXT */),
          _createVNode(_component_ag_row, { class: "mt-10" }, {
            default: _withCtx(() => [
              _createVNode(_component_ag_column, {
                xs: "12",
                sm: "12",
                md: "12",
                lg: "5"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ag_calendar, {
                    label: "Start Date",
                    "onUpdate:startDate": _cache[0] || (_cache[0] = (value) => _ctx.startDate = value),
                    "calendar-name": "startDate",
                    "max-date": new Date(),
                    date: _ctx.startDate ? _ctx.startDate : new Date()
                  }, null, 8 /* PROPS */, ["max-date", "date"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_ag_column, {
                xs: "12",
                sm: "12",
                md: "12",
                lg: "5"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ag_calendar, {
                    label: "End Date",
                    "onUpdate:endDate": _cache[1] || (_cache[1] = (value) => _ctx.endDate = value),
                    "calendar-name": "endDate",
                    "max-date": new Date(),
                    "min-date": _ctx.startDate,
                    date: _ctx.endDate ? _ctx.endDate : new Date()
                  }, null, 8 /* PROPS */, ["max-date", "min-date", "date"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_ag_column, {
                xs: "12",
                sm: "12",
                md: "12",
                lg: "2",
                class: "d-flex align-items-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_MButton, {
                    onClick: _ctx.handleFetchData,
                    disabled: _ctx.isLoading,
                    "test-id": "",
                    variant: "primary",
                    class: "fire_btn_wrap"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Fetch Statistics")
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick", "disabled"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      (_ctx.isOrganizationProductivityLoading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_ShimmerCard, { "card-height": "200px" }),
            _createVNode(_component_ShimmerCard, { "card-height": "200px" }),
            _createVNode(_component_ShimmerCard, { "card-height": "150px" }),
            _createVNode(_component_ShimmerCard, { "card-height": "100px" })
          ], 64 /* STABLE_FRAGMENT */))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.showDetails)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createCommentVNode(" Overall Counts "),
                  _createVNode(_component_SnapshotDetailAccordion, { title: "Overall Counts" }, {
                    overview: _withCtx(() => [
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_agent_info_box, {
                            title: "Total Flight Bookings",
                            icon: "notInvoice",
                            value: 
                  _ctx.organizationProductivity?.hotel_and_flight_details?.aggregates
                    ?.total_flight_bookings
                
                          }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_agent_info_box, {
                            title: "Total Issued Tickets",
                            icon: "notInvoice",
                            value: 
                  _ctx.organizationProductivity?.total_tickets_issued
                    ?.total_issued_tickets
                
                          }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_agent_info_box, {
                            title: "Total Hotel Bookings",
                            icon: "notInvoice",
                            value: 
                  _ctx.organizationProductivity?.hotel_and_flight_details?.aggregates
                    ?.total_hotel_bookings
                
                          }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_agent_info_box, {
                            title: "Total Issued Hotels",
                            icon: "notInvoice",
                            value: 
                  _ctx.organizationProductivity?.hotel_and_flight_details?.aggregates
                    ?.total_hotel_issued
                
                          }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_agent_info_box, {
                            title: "Total Payment Created",
                            icon: "currentBalance",
                            value: 
                  _ctx.organizationProductivity?.payment_details?.aggregates
                    .total_payments_created
                
                          }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_agent_info_box, {
                            title: "Total Credit Limit Requests",
                            icon: "notInvoice",
                            value: _ctx.organizationProductivity?.total_credit_limit_requests
                          }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_agent_info_box, {
                            title: "Total Sabre Segments",
                            icon: "notInvoice",
                            value: _ctx.organizationProductivity?.total_sabre_segments
                          }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createCommentVNode(" Booking Card "),
                  _createVNode(_component_SnapshotDetailAccordion, { title: "Booked" }, {
                    overview: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizationProductivity
                ?.hotel_and_flight_details?.flight_bookings, (item, index) => {
                        return (_openBlock(), _createBlock(_component_ag_column, {
                          key: index,
                          xs: "12",
                          sm: "12",
                          md: "6",
                          lg: "2"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ag_agent_info_box, {
                              title: item.provider_name,
                              icon: "notInvoice",
                              value: item.count
                            }, null, 8 /* PROPS */, ["title", "value"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */))
                      }), 128 /* KEYED_FRAGMENT */)),
                      _withDirectives(_createElementVNode("p", _hoisted_2, " Nothing Booked Yet ", 512 /* NEED_PATCH */), [
                        [_vShow, _ctx.showBookedEmptyMsg]
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createCommentVNode(" Issued Card "),
                  _createVNode(_component_SnapshotDetailAccordion, { title: "Issued" }, {
                    overview: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizationProductivity
                ?.total_tickets_issued?.issued_tickes, (item, index) => {
                        return (_openBlock(), _createBlock(_component_ag_column, {
                          key: index,
                          xs: "12",
                          sm: "12",
                          md: "6",
                          lg: "2"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ag_agent_info_box, {
                              title: item.provider_name,
                              icon: "notInvoice",
                              value: item.count
                            }, null, 8 /* PROPS */, ["title", "value"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */))
                      }), 128 /* KEYED_FRAGMENT */)),
                      _withDirectives(_createElementVNode("p", _hoisted_3, " Nothing Issued Yet ", 512 /* NEED_PATCH */), [
                        [_vShow, _ctx.showIssuedEmptyMsg]
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createCommentVNode(" Payment Card "),
                  _createVNode(_component_SnapshotDetailAccordion, { title: "Payments" }, {
                    overview: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizationProductivity?.payment_details
                ?.payments, (item, index) => {
                        return (_openBlock(), _createBlock(_component_ag_column, {
                          key: index,
                          xs: "12",
                          sm: "12",
                          md: "6",
                          lg: "4"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ag_agent_info_box, {
                              title: item.payment_method,
                              icon: "currentBalance",
                              value: item.count
                            }, null, 8 /* PROPS */, ["title", "value"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */))
                      }), 128 /* KEYED_FRAGMENT */)),
                      _withDirectives(_createElementVNode("p", _hoisted_4, " No Payment Found ", 512 /* NEED_PATCH */), [
                        [_vShow, _ctx.showPaymentsEmptyMsg]
                      ])
                    ]),
                    _: 1 /* STABLE */
                  })
                ], 64 /* STABLE_FRAGMENT */))
              : _createCommentVNode("v-if", true),
            (_ctx.showResultNotFound)
              ? (_openBlock(), _createBlock(_component_ResultNotFound, { key: 1 }))
              : _createCommentVNode("v-if", true)
          ], 64 /* STABLE_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }))
}