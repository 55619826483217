export const flags = {
  SAR: "https://firebasestorage.googleapis.com/v0/b/aeroglobe-network.appspot.com/o/sa.png?alt=media&token=cdac4fb6-44d0-46fe-8517-624d4f7716f8",
  AED: "https://firebasestorage.googleapis.com/v0/b/aeroglobe-network.appspot.com/o/ae.png?alt=media&token=c7494a7b-84b1-4186-b43a-e2feb52ca86c",
  PKR: "https://firebasestorage.googleapis.com/v0/b/aeroglobe-network.appspot.com/o/pk.png?alt=media&token=906c45a3-7118-4b7a-8acf-02cc8724da75",
};

export const getFlagByCurrency = (currency: keyof typeof flags) => {
  const flag = flags[currency];
  return flag;
};
