import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { SALES_API_PATH } from "@/modules/Sales/constants";
import { SalesProductivityParamsRequestModel } from "../models/FetchSalesProductivityParams.request";
import { formatString } from "@/ag-portal-common/utils/helpers";

class SalesService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public getSalesSnapshot(salesmanId: string): Promise<IAGResponse<any>> {
    return this.restClient.get(
      formatString(SALES_API_PATH.GET_SALES_USAGE_SNAPSHOT, salesmanId)
    );
  }

  public getSalesProductivity(
    salesmanId: string,
    payload: SalesProductivityParamsRequestModel
  ): Promise<IAGResponse<any>> {
    return this.restClient.get(
      formatString(SALES_API_PATH.GET_SALES_PRODUCTIVITY, salesmanId),
      payload
    );
  }

  public getSalesMyTeam(): Promise<IAGResponse<any>> {
    return this.restClient.get(SALES_API_PATH.SALES_MY_TEAM);
  }
}

export default SalesService;
