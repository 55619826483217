import { MutationTree } from "vuex";
import { IAgentState } from "@/interfaces/agentState.interface";
import { IAgentDetail } from "@/ag-portal-common/interfaces/agent.interface";

const mutations: MutationTree<IAgentState> = {
  fetchingOrganizationAgents(state, fetching: boolean) {
    state.isFetchingOrganizationAgents = fetching;
  },
  saveOrganizationAgents(state, agents: Array<IAgentDetail>) {
    state.isFetchingAgents = false;
    state.organization_agents = agents;
  },
  fetchingAgent(state, fetching: boolean) {
    state.isFetchingAgent = fetching;
  },
  fetchAgentSuccess(state, agent: IAgentDetail) {
    state.isFetchingAgent = false;
    state.agent = agent;
  },
  updatingAgentPermissions(state, updating: boolean) {
    state.isUpdatingAgentPermissions = updating;
  },
  updateAgentPermissionsSuccess(state, agent: IAgentDetail) {
    state.isUpdatingAgentPermissions = false;
    state.agent = agent;
  },
};

export default mutations;
