import { GetterTree } from "vuex";
import { IDashboardState } from "@/ag-portal-common/interfaces/dashboard.interface";

const getters: GetterTree<IDashboardState, IDashboardState> = {
  isDashboardInsightsLoading: (state) => state.isInsightsLoading,
  bookingAggregate: (state) => state.bookingAggregate,
  dailyIssuanceData: (state) => state.dailyChartData,
};

export default getters;
