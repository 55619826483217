<template>
  <ListViewForSalesUser v-if="showListForSalesUser" />
  <ListViewForAgentsUser v-if="isAgentUser" />
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import ListViewForSalesUser from "@/modules/CreditLimitManagement/components/ListViewForSalesUser.vue";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import ListViewForAgentsUser from "@/modules/CreditLimitManagement/components/ListViewForAgentsUser.vue";

export default defineComponent({
  name: "CreditLimitList",
  components: { ListViewForAgentsUser, ListViewForSalesUser },
  data() {
    return {
      USER_ROLES,
    };
  },
  computed: {
    showListForSalesUser(): boolean {
      const userRole = this.userRole();
      return [
        USER_ROLES.SUPER_ADMIN,
        USER_ROLES.AG_SUPER_USER,
        USER_ROLES.SALES,
      ].includes(userRole);
    },
    isAgentUser(): boolean {
      return this.userRole() === USER_ROLES.AGENT;
    },
  },

  methods: {
    userRole(): number {
      let user = this.user() as IUserV2 | null;
      return user?.role_unique_id as number;
    },
  },
  setup() {
    let user = inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2 | null;
    return {
      user,
    };
  },
});
</script>
