import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_ag_card = _resolveComponent("ag-card")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_ag_table = _resolveComponent("ag-table")!
  const _component_ag_not_found = _resolveComponent("ag-not-found")!
  const _component_AgTabs = _resolveComponent("AgTabs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ag_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_ag_div, { class: "d-flex justify-content-between align-items-center" }, {
          default: _withCtx(() => [
            _createVNode(_component_ag_heading, {
              class: "margin_bottom_0",
              title: "Credit Limit Management",
              variant: "h2"
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_ag_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_AgTabs, {
          "test-id": "test",
          "panel-value": [1],
          class: "no_tab_icon"
        }, {
          TabHeading: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.CREDIT_LIMIT_REQUEST_STATUSES), (status, index) => {
              return (_openBlock(), _createBlock(_component_v_tab, {
                key: index,
                value: index,
                onClick: ($event: any) => (_ctx.onClickTab($event, _ctx.CREDIT_LIMIT_REQUEST_STATUSES[status]))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.formatWordIntoCapitalize(status.replaceAll("_", " "))), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "onClick"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          TabDetail: _withCtx(() => [
            _createVNode(_component_ag_div, null, {
              default: _withCtx(() => [
                _createVNode(_component_ag_card, null, {
                  default: _withCtx(() => [
                    (_ctx.isFetchingCreditLimitRequests)
                      ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
                      : (
                !_ctx.isFetchingCreditLimitRequests &&
                _ctx.creditLimitRequests.data.length
              )
                        ? (_openBlock(), _createBlock(_component_ag_table, {
                            key: 1,
                            headers: _ctx.headers,
                            items: _ctx.creditLimitRequests.data,
                            "total-page-count": _ctx.creditLimitRequests.totalPages,
                            page: _ctx.page,
                            onOnPageChange: _ctx.onPageChange,
                            onOnClickRow: _ctx.onItemClick,
                            "items-per-page": _ctx.itemsPerPage,
                            "has-pagination": true,
                            "has-search": false
                          }, {
                            "col-requestInitiatedAt": _withCtx(({ item }) => [
                              _createVNode(_component_AgDiv, { style: {"min-width":"160px"} }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ag_heading, {
                                    title: 
                      _ctx.getFormattedDateTime(
                        item.raw.requestInitiatedAt,
                        _ctx.FORMAT_YYY_MM_DD
                      )
                    ,
                                    variant: "p",
                                    class: "margin_bottom_0"
                                  }, null, 8 /* PROPS */, ["title"]),
                                  _createVNode(_component_ag_heading, {
                                    class: "margin_bottom_0 text-color-green",
                                    title: 
                      _ctx.getFormattedDateTime(
                        item.raw.requestInitiatedAt,
                        _ctx.FORMAT_HH_SS_A
                      )
                    ,
                                    variant: "p"
                                  }, null, 8 /* PROPS */, ["title"])
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */)
                            ]),
                            "col-organizationName": _withCtx(({ item }) => [
                              _createVNode(_component_AgDiv, { style: {"min-width":"200px"} }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.raw.organizationName), 1 /* TEXT */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */)
                            ]),
                            "col-agentSectorName": _withCtx(({ item }) => [
                              _createVNode(_component_AgDiv, { style: {"min-width":"200px"} }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatNumber(item.raw.agentSectorName)), 1 /* TEXT */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */)
                            ]),
                            "col-requestNotes": _withCtx(({ item }) => [
                              _createVNode(_component_AgDiv, { style: {"min-width":"200px"} }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.raw.requestNotes), 1 /* TEXT */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */)
                            ]),
                            "col-status": _withCtx(({ item }) => [
                              _createVNode(_component_ag_div, {
                                class: _normalizeClass(_ctx.creditLimitStatusClass(item.raw.status))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.raw.status), 1 /* TEXT */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class"])
                            ]),
                            "col-priority": _withCtx(({ item }) => [
                              _createVNode(_component_ag_div, {
                                class: _normalizeClass(_ctx.creditLimitPriorityclass(item.raw.priority))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.raw.priority), 1 /* TEXT */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class"])
                            ]),
                            "col-requestedAmount": _withCtx(({ item }) => [
                              _createTextVNode(_toDisplayString(_ctx.formatNumber(item.raw.requestedAmount)), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["headers", "items", "total-page-count", "page", "onOnPageChange", "onOnClickRow", "items-per-page"]))
                        : (_openBlock(), _createBlock(_component_ag_not_found, {
                            key: 2,
                            heading: _ctx.NOTIFICATION_MESSAGES.NO_RESULTS_FOUND,
                            description: _ctx.NOTIFICATION_MESSAGES.NO_RESULTS_FOUND_DESCRIPTION
                          }, null, 8 /* PROPS */, ["heading", "description"]))
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ], 64 /* STABLE_FRAGMENT */))
}