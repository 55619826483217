import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_AgCard = _resolveComponent("AgCard")!
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_AgTable = _resolveComponent("AgTable")!
  const _component_ag_accordion_panel = _resolveComponent("ag-accordion-panel")!
  const _component_ag_accordion = _resolveComponent("ag-accordion")!

  return (_ctx.$store.getters.isSalesMyTeamLoading)
    ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createVNode(_component_AgCard, null, {
          default: _withCtx(() => [
            _createVNode(_component_ag_heading, {
              variant: "h2",
              title: "My Team"
            })
          ]),
          _: 1 /* STABLE */
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.myTeam, (item, index) => {
          return (_openBlock(), _createBlock(_component_AgCard, { key: index }, {
            default: _withCtx(() => [
              _createVNode(_component_AgIconInfoBar, {
                title: _ctx.renderSectoName(item.sector_name)
              }, null, 8 /* PROPS */, ["title"]),
              _createVNode(_component_ag_accordion, {
                class: "flight_accordion_wrap margin_bottom_0",
                "panel-value": [0]
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ag_accordion_panel, null, {
                    tabSection: _withCtx(() => [
                      _createVNode(_component_AgTable, {
                        headers: _ctx.headers,
                        items: item.my_team_members,
                        "items-per-page": 10,
                        "has-search": false,
                        simpleDataTable: true
                      }, {
                        "col-role": _withCtx(({ item }) => [
                          _createTextVNode(_toDisplayString(item.raw?.role?.replace("_", " ")), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["headers", "items"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)
            ]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */))
        }), 128 /* KEYED_FRAGMENT */))
      ], 64 /* STABLE_FRAGMENT */))
}