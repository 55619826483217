import { ActionContext, ActionTree } from "vuex";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { IPaymentState } from "@/interfaces/paymentState.interface";
import PaymentsService from "@/modules/Payments/services/payments.service";
import { IPayment, IPayments } from "@/interfaces/payments.interface";
import { FetchPaymentsParamsRequestModel } from "@/modules/Payments/models/fetchPaymentsParams.request";
import { CreatePaymentBodyRequest } from "@/modules/Payments/models/createPaymentBody.request";
import { StartDateEndDateDTO } from "@/modules/Organization/dtos/organization.dto";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { StatusCodes } from "http-status-codes";

import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import analyticServices from "@/analytic.service";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";

const actions: ActionTree<IPaymentState, IPaymentState> = {
  async fetchPayments(
    context: ActionContext<IPaymentState, IPaymentState>,
    params: FetchPaymentsParamsRequestModel
  ) {
    const methodName = "actions.fetchPayments";
    context.commit("enableIsFetchingPayments");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    const paymentsService = new PaymentsService();
    const response: IPayments = await paymentsService.fetchPayments(params);
    context.commit("savePayments", response);
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },

  async fetchPayment(
    context: ActionContext<IPaymentState, IPaymentState>,
    id: string
  ) {
    const methodName = "actions.fetchPayment";
    context.commit("enableIsFetchingPayment");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    const paymentsService = new PaymentsService();
    const response: IPayment | null = await paymentsService.fetchPayment(id);
    context.commit("savePayment", response);
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },

  async getPaymentList(
    context: ActionContext<IPaymentState, IPaymentState>,
    organization_id: string
  ) {
    const methodName = "actions.getPaymentMethods";
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    const paymentsService = new PaymentsService();
    const response: IAGResponse<any> =
      await paymentsService.getAllPaymentMethods(organization_id);
    try {
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("setPaymentMethods", response.data?.data);
      } else {
        throw response;
      }
    } catch (err) {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, err);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        response.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      context.commit("setSectorStatsLoading", false);
    }
    notificationService.triggerNotification();
  },
  async createPayment(
    context: ActionContext<IPaymentState, IPaymentState>,
    {
      payload,
      callback,
    }: { payload: CreatePaymentBodyRequest; callback: (id: string) => void }
  ) {
    const methodName = "actions.createPayment";
    context.commit("enableIsCreatingPayment");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    const paymentsService = new PaymentsService();
    await paymentsService.createPayment(payload, callback);
    context.commit("disableIsCreatingPayment");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },
  async fetchPaymentsBySector(
    context: ActionContext<IPaymentState, IPaymentState>,
    payload: StartDateEndDateDTO
  ) {
    const methodName = "actions.fetchPaymentsBySector";
    context.commit("setPaymentsByMethods", {});
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("enableIsFetchingPayments");
      const paymentsService = new PaymentsService();
      const response: IAGResponse<any> =
        await paymentsService.getAllPaymentsByDate(payload);
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("setPaymentsByMethods", response.data?.data);
      } else {
        throw response;
      }
    } catch (err) {
      context.commit("setPaymentsByMethods", null);
      loggerService.logError(`${methodName}:`, err);
    } finally {
      context.commit("disbaleIsFetchingPayments");
    }
  },
};

export default actions;
