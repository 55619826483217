<template>
  <ag-card>
    <ag-heading
      variant="h2"
      class="margin_bottom_10"
      title="Search for Sabre Details"
    />
    <ag-row>
      <ag-column sm="12" lg="3">
        <AGTextField name="pnr" label="PNR" v-model="inputPNR" />
      </ag-column>
      <ag-column sm="12" lg="3">
        <AGTextField
          :value="inputAirLineCode"
          v-model="inputAirLineCode"
          type="text"
          icon="calendar"
          :items="sabreAirlinesList"
          label="Airline Code"
        >
          <template #dropdownItem="{ itemProps, itemData }">
            <AgTextFieldDropDownItem v-bind="itemProps">
              <p>{{ itemData }}</p>
            </AgTextFieldDropDownItem>
          </template>
        </AGTextField>
      </ag-column>
      <ag-column sm="12" lg="3">
        <MButton type="filled" @click="buttonClick" :disabled="isLoading"
          >Get Fare</MButton
        >
      </ag-column>
    </ag-row>
  </ag-card>
  <ag-card v-show="Response">
    <AgIconInfoBar
      test-id=""
      icon="contactTravelerIcon"
      title="Passenger Information"
    />
    <ag-accordion
      class="flight_accordion_wrap margin_bottom_0"
      :panel-value="[0]"
    >
      <ag-accordion-panel>
        <template #tabSection>
          <ag-table
            :headers="headers"
            :items="Response.passengers"
            :has-pagination="false"
            :items-per-page="50"
            :has-search="false"
          >
            <template #col-title="{ item }">
              <ag-div>{{ item.raw.title }} </ag-div>
            </template>
            <template #col-first_name="{ item }">
              <ag-div>{{ item.raw.first_name }} </ag-div>
            </template>
            <template #col-last_name="{ item }">
              <ag-div>{{ item.raw.last_name }} </ag-div>
            </template>
            <template #col-passenger_type="{ item }">
              <ag-div>{{ item.raw.passenger_type }} </ag-div>
            </template>
            <template #col-name_type="{ item }">
              <ag-div>{{ item.raw.name_type }} </ag-div>
            </template>
          </ag-table>
        </template>
      </ag-accordion-panel>
    </ag-accordion>
  </ag-card>
  <ag-card v-show="Response">
    <AgIconInfoBar test-id="" icon="userType" title="Old Price Quotes" />
    <ag-accordion
      class="flight_accordion_wrap margin_bottom_0"
      :panel-value="[1]"
    >
      <ag-accordion-panel>
        <template #tabSection>
          <ag-div
            v-for="(priceQuote, index) in Response.old_price_quotes"
            :key="index"
            ><ag-card>
              <ag-accordion
                class="flight_accordion_wrap margin_bottom_0"
                :panel-value="[0]"
              >
                <ag-accordion-panel>
                  <template #tabSection>
                    <ag-row>
                      <ag-agent-info-box
                        style="margin-right: 0.3cm; padding-right: 0.3cm"
                        class="no_border"
                        test-id=""
                        icon="contactTravelerIcon"
                        title="Passenger Type"
                        :value="priceQuote.passenger_type"
                      />
                      <ag-agent-info-box
                        class="no_border"
                        style="margin-right: 0.3cm; padding-right: 0.3cm"
                        test-id=""
                        icon="currentBalance"
                        title="Base Fare"
                        :value="
                          formatNumber(priceQuote.price.base_fare) +
                          ' ' +
                          priceQuote.price_per_passenger.currency_code
                        "
                      />
                      <ag-agent-info-box
                        class="no_border"
                        style="margin-right: 0.3cm; padding-right: 0.3cm"
                        test-id=""
                        icon="notInvoice"
                        title="Total Tax"
                        :value="
                          formatNumber(priceQuote.price.total_tax) +
                          ' ' +
                          priceQuote.price_per_passenger.currency_code
                        "
                      />
                      <ag-agent-info-box
                        class="no_border"
                        style="margin-right: 0.3cm; padding-right: 0.3cm"
                        test-id=""
                        icon="tempCreditLimit"
                        title="Fees"
                        :value="
                          formatNumber(priceQuote.price.fees) +
                          ' ' +
                          priceQuote.price_per_passenger.currency_code
                        "
                      />
                      <ag-agent-info-box
                        class="no_border"
                        style="margin-right: 0.3cm; padding-right: 0.3cm"
                        test-id=""
                        icon="permanentCredit"
                        title="Surcharges"
                        :value="
                          formatNumber(priceQuote.price.surcharges) +
                          ' ' +
                          priceQuote.price_per_passenger.currency_code
                        "
                      />
                      <ag-agent-info-box
                        class="no_border"
                        style="margin-right: 0.3cm; padding-right: 0.3cm"
                        test-id=""
                        icon="userType"
                        title="Gross Fare"
                        :value="
                          formatNumber(priceQuote.price.gross_fare) +
                          ' ' +
                          priceQuote.price_per_passenger.currency_code
                        "
                      />
                    </ag-row>
                    <ag-table
                      :headers="oldPriceQuotesTable"
                      :items="priceQuote.price.tax_list"
                      :has-pagination="false"
                      :items-per-page="priceQuote.price.tax_list.length"
                      :has-search="false"
                    >
                      <template #col-code="{ item }">
                        <ag-div>{{ item.raw.code }}</ag-div>
                      </template>
                      <template #col-amount="{ item }">
                        <ag-div
                          >{{ formatNumber(item.raw.amount) }}
                          {{ item.raw.currency_code }}</ag-div
                        >
                      </template>
                      <template #col-taxName="{ item }">
                        <ag-div>
                          {{
                            item.raw.tax_name !== null ? item.raw.tax_name : "-"
                          }}
                        </ag-div>
                      </template>
                      <template #col-ticketing_tax_code="{ item }">
                        <ag-div>
                          {{
                            item.raw.ticketing_tax_code !== null
                              ? item.raw.ticketing_tax_code
                              : "-"
                          }}
                        </ag-div>
                      </template>
                    </ag-table>
                    <ag-div>
                      <ag-row>
                        <AgIconInfoBar
                          test-id=""
                          icon="contactTravelerIcon"
                          :title="
                            'Price Per Passenger (passenger count: ' +
                            priceQuote.passenger_count +
                            ')'
                          "
                          style="margin-top: 0.5cm"
                        />
                      </ag-row>
                      <ag-row>
                        <ag-agent-info-box
                          class="no_border"
                          style="margin-right: 0.3cm; padding-right: 0.3cm"
                          test-id=""
                          icon="currentBalance"
                          title="Base Fare"
                          :value="
                            formatNumber(
                              priceQuote.price_per_passenger.base_fare
                            ) +
                            ' ' +
                            priceQuote.price_per_passenger.currency_code
                          "
                        />
                        <ag-agent-info-box
                          class="no_border"
                          test-id=""
                          style="margin-right: 0.3cm; padding-right: 0.3cm"
                          icon="notInvoice"
                          title="Total Tax"
                          :value="
                            formatNumber(
                              priceQuote.price_per_passenger.total_tax
                            ) +
                            ' ' +
                            priceQuote.price_per_passenger.currency_code
                          "
                        />
                        <ag-agent-info-box
                          class="no_border"
                          test-id=""
                          icon="tempCreditLimit"
                          style="margin-right: 0.3cm; padding-right: 0.3cm"
                          title="Fees"
                          :value="
                            formatNumber(priceQuote.price_per_passenger.fees) +
                            ' ' +
                            priceQuote.price_per_passenger.currency_code
                          "
                        />
                        <ag-agent-info-box
                          class="no_border"
                          test-id=""
                          icon="permanentCredit"
                          title="Surcharges"
                          style="margin-right: 0.3cm; padding-right: 0.3cm"
                          :value="
                            formatNumber(
                              priceQuote.price_per_passenger.surcharges
                            ) +
                            ' ' +
                            priceQuote.price_per_passenger.currency_code
                          "
                        />
                        <ag-agent-info-box
                          class="no_border"
                          test-id=""
                          icon="userType"
                          title="Gross Fare"
                          style="margin-right: 0.3cm; padding-right: 0.3cm"
                          :value="
                            formatNumber(
                              priceQuote.price_per_passenger.gross_fare
                            ) +
                            ' ' +
                            priceQuote.price_per_passenger.currency_code
                          "
                        />
                      </ag-row>
                      <ag-table
                        :headers="oldPriceQuotesTable"
                        :items="priceQuote.price_per_passenger.tax_list"
                        :has-pagination="false"
                        :items-per-page="
                          priceQuote.price_per_passenger.tax_list.length
                        "
                        :has-search="false"
                      >
                        <template #col-code="{ item }">
                          <ag-div>{{ item.raw.code }}</ag-div>
                        </template>
                        <template #col-amount="{ item }">
                          <ag-div
                            >{{ formatNumber(item.raw.amount) }}
                            {{ item.raw.currency_code }}</ag-div
                          >
                        </template>
                        <template #col-taxName="{ item }">
                          <ag-div>
                            {{
                              item.raw.tax_name !== null
                                ? item.raw.tax_name
                                : "-"
                            }}
                          </ag-div>
                        </template>
                        <template #col-ticketing_tax_code="{ item }">
                          <ag-div>
                            {{
                              item.raw.ticketing_tax_code !== null
                                ? item.raw.ticketing_tax_code
                                : "-"
                            }}
                          </ag-div>
                        </template>
                      </ag-table>

                      <AgIconInfoBar
                        test-id=""
                        icon="contactTravelerIcon"
                        title="Segments"
                        style="margin-top: 0.5cm"
                      />

                      <ag-table
                        style="margin-bottom: 0.5cm"
                        :headers="segments"
                        :items="priceQuote.segments"
                        :has-pagination="false"
                        :items-per-page="priceQuote.segments.length"
                        :has-search="false"
                      >
                        <template #col-Sector="{ item }">
                          <ag-div
                            >{{ item.raw.arrival_city }} -
                            {{ item.raw.departure_city }}</ag-div
                          >
                        </template>
                        <template #col-arrival_date_time="{ item }">
                          <ag-div>{{
                            getFormattedDateTime(
                              item.raw.arrival_date_time,
                              FORMAT_YYY_MM_DD_HH_SS_A
                            )
                          }}</ag-div>
                        </template>
                        <template #col-departure_date_time="{ item }">
                          <ag-div>{{
                            getFormattedDateTime(
                              item.raw.departure_date_time,
                              FORMAT_YYY_MM_DD_HH_SS_A
                            )
                          }}</ag-div>
                        </template>
                        <template #col-flight_number="{ item }">
                          <ag-div>{{ item.raw.flight_number }}</ag-div>
                        </template>
                        <template #col-rbd="{ item }">
                          <ag-div>{{ item.raw.rbd }}</ag-div>
                        </template>
                        <template #col-segment_status="{ item }">
                          <ag-div>{{ item.raw.segment_status }}</ag-div>
                        </template>
                        <template #col-Baggage="{ item }">
                          <ag-div>{{
                            item.raw.baggage.allowance + item.raw.baggage.type
                          }}</ag-div>
                        </template>
                      </ag-table>
                    </ag-div>
                  </template>
                </ag-accordion-panel>
              </ag-accordion>
            </ag-card>
          </ag-div>
        </template>
      </ag-accordion-panel>
    </ag-accordion>
  </ag-card>
  <ag-card v-show="Response">
    <AgIconInfoBar
      test-id=""
      icon="userType"
      :title="
        'New Price Quotes (' + formatNumber(totalFare) + ' ' + currency + ')'
      "
    />
    <ag-accordion
      class="flight_accordion_wrap margin_bottom_0"
      :panel-value="[0]"
    >
      <ag-accordion-panel>
        <template #tabSection>
          <ag-div
            v-for="(priceQuote, index) in Response.new_price_quotes"
            :key="index"
            ><ag-card>
              <ag-accordion
                class="flight_accordion_wrap margin_bottom_0"
                :panel-value="[0]"
              >
                <ag-accordion-panel>
                  <template #tabSection>
                    <ag-row>
                      <ag-agent-info-box
                        style="margin-right: 0.3cm; padding-right: 0.3cm"
                        class="no_border"
                        test-id=""
                        icon="contactTravelerIcon"
                        title="Passenger Type"
                        :value="formatNumber(priceQuote.passenger_type)"
                      />
                      <ag-agent-info-box
                        class="no_border"
                        style="margin-right: 0.3cm; padding-right: 0.3cm"
                        test-id=""
                        icon="currentBalance"
                        title="Base Fare"
                        :value="
                          formatNumber(priceQuote.price.base_fare) +
                          ' ' +
                          priceQuote.price.currency_code
                        "
                      />
                      <ag-agent-info-box
                        style="margin-right: 0.3cm; padding-right: 0.3cm"
                        class="no_border"
                        test-id=""
                        icon="notInvoice"
                        title="Total Tax"
                        :value="
                          formatNumber(priceQuote.price.total_tax) +
                          ' ' +
                          priceQuote.price.currency_code
                        "
                      />
                      <ag-agent-info-box
                        class="no_border"
                        test-id=""
                        icon="tempCreditLimit"
                        style="margin-right: 0.3cm; padding-right: 0.3cm"
                        title="Fees"
                        :value="
                          formatNumber(priceQuote.price.fees) +
                          ' ' +
                          priceQuote.price.currency_code
                        "
                      />
                      <ag-agent-info-box
                        class="no_border"
                        test-id=""
                        icon="permanentCredit"
                        style="margin-right: 0.3cm; padding-right: 0.3cm"
                        title="Surcharges"
                        :value="
                          formatNumber(priceQuote.price.surcharges) +
                          ' ' +
                          priceQuote.price.currency_code
                        "
                      />
                      <ag-agent-info-box
                        class="no_border"
                        test-id=""
                        icon="userType"
                        title="Gross Fare"
                        style="margin-right: 0.3cm; padding-right: 0.3cm"
                        :value="
                          formatNumber(priceQuote.price.gross_fare) +
                          ' ' +
                          priceQuote.price.currency_code
                        "
                      />
                    </ag-row>
                    <ag-table
                      :headers="oldPriceQuotesTable"
                      :items="priceQuote.price.tax_list"
                      :has-pagination="false"
                      :items-per-page="priceQuote.price.tax_list.length"
                      :has-search="false"
                    >
                      <template #col-code="{ item }">
                        <ag-div>{{ item.raw.code }}</ag-div>
                      </template>
                      <template #col-amount="{ item }">
                        <ag-div
                          >{{ formatNumber(item.raw.amount) }}
                          {{ item.raw.currency_code }}</ag-div
                        >
                      </template>
                      <template #col-taxName="{ item }">
                        <ag-div>
                          {{
                            item.raw.tax_name !== null ? item.raw.tax_name : "-"
                          }}
                        </ag-div>
                      </template>
                      <template #col-ticketing_tax_code="{ item }">
                        <ag-div>
                          {{
                            item.raw.ticketing_tax_code !== null
                              ? item.raw.ticketing_tax_code
                              : "-"
                          }}
                        </ag-div>
                      </template>
                    </ag-table>
                    <ag-card>
                      <ag-row>
                        <AgIconInfoBar
                          test-id=""
                          icon="contactTravelerIcon"
                          :title="
                            'Price Per Passenger (passenger count: ' +
                            priceQuote.passenger_count +
                            ')'
                          "
                          style="margin-top: 0.5cm"
                        />
                      </ag-row>
                      <ag-row>
                        <ag-agent-info-box
                          class="no_border"
                          style="margin-right: 0.3cm; padding-right: 0.3cm"
                          test-id=""
                          icon="currentBalance"
                          title="Base Fare"
                          :value="
                            formatNumber(
                              priceQuote.price_per_passenger.base_fare
                            ) +
                            ' ' +
                            priceQuote.price.currency_code
                          " />
                        <ag-agent-info-box
                          class="no_border"
                          test-id=""
                          icon="notInvoice"
                          title="Total Tax"
                          style="margin-right: 0.3cm; padding-right: 0.3cm"
                          :value="
                            formatNumber(
                              priceQuote.price_per_passenger.total_tax
                            ) +
                            ' ' +
                            priceQuote.price.currency_code
                          " />
                        <ag-agent-info-box
                          class="no_border"
                          test-id=""
                          icon="tempCreditLimit"
                          title="Fees"
                          style="margin-right: 0.3cm; padding-right: 0.3cm"
                          :value="
                            formatNumber(priceQuote.price_per_passenger.fees) +
                            ' ' +
                            priceQuote.price.currency_code
                          " />
                        <ag-agent-info-box
                          class="no_border"
                          test-id=""
                          icon="permanentCredit"
                          style="margin-right: 0.3cm; padding-right: 0.3cm"
                          title="Surcharges"
                          :value="
                            formatNumber(
                              priceQuote.price_per_passenger.surcharges
                            ) +
                            ' ' +
                            priceQuote.price.currency_code
                          " />
                        <ag-agent-info-box
                          class="no_border"
                          test-id=""
                          icon="userType"
                          style="margin-right: 0.3cm; padding-right: 0.3cm"
                          title="Gross Fare"
                          :value="
                            formatNumber(
                              priceQuote.price_per_passenger.gross_fare
                            ) +
                            ' ' +
                            priceQuote.price.currency_code
                          "
                      /></ag-row>

                      <ag-table
                        :headers="oldPriceQuotesTable"
                        :items="priceQuote.price_per_passenger.tax_list"
                        :has-pagination="false"
                        :items-per-page="
                          priceQuote.price_per_passenger.tax_list.length
                        "
                        :has-search="false"
                      >
                        <template #col-code="{ item }">
                          <ag-div>{{ item.raw.code }}</ag-div>
                        </template>
                        <template #col-amount="{ item }">
                          <ag-div
                            >{{ formatNumber(item.raw.amount) }}
                            {{ item.raw.currency_code }}</ag-div
                          >
                        </template>
                        <template #col-taxName="{ item }">
                          <ag-div>
                            {{
                              item.raw.tax_name !== null
                                ? item.raw.tax_name
                                : "-"
                            }}
                          </ag-div>
                        </template>
                        <template #col-ticketing_tax_code="{ item }">
                          <ag-div>
                            {{
                              item.raw.ticketing_tax_code !== null
                                ? item.raw.ticketing_tax_code
                                : "-"
                            }}
                          </ag-div>
                        </template>
                      </ag-table>
                      <AgIconInfoBar
                        test-id=""
                        icon="contactTravelerIcon"
                        title="Price Quote Policies"
                        style="margin-top: 0.5cm"
                      />
                      <ag-table
                        style="margin-bottom: 0.5cm"
                        :headers="priceQuotePolicies"
                        :items="priceQuote.price_quote_policies"
                        :has-pagination="false"
                        :items-per-page="priceQuote.price_quote_policies.length"
                        :has-search="false"
                      >
                        <template #col-type="{ item }">
                          <ag-div>{{ item.raw.type }}</ag-div>
                        </template>
                        <template #col-amount="{ item }">
                          <ag-div
                            >{{ formatNumber(item.raw.amount) }}
                            {{ item.raw.currency }}</ag-div
                          >
                        </template>
                      </ag-table>
                      <AgIconInfoBar
                        test-id=""
                        icon="contactTravelerIcon"
                        title="Baggage info"
                        style="margin-top: 0.5cm"
                      />
                      <p v-for="(Info, i) in priceQuote.baggage_info" :key="i">
                        {{ Info }}
                      </p>
                    </ag-card>
                  </template>
                </ag-accordion-panel>
              </ag-accordion>
            </ag-card>
          </ag-div>
        </template>
      </ag-accordion-panel>
    </ag-accordion>
  </ag-card>
  <ag-div class="d-flex justify-content-end">
    <AGButton
      v-show="Response"
      variant="primary"
      @click="ApplyButtonClick"
      :isLoading="isSaveLoading"
    >
      Apply Fare
    </AGButton>
  </ag-div>

  <AgNotFound
    v-show="!Response"
    test-id=""
    heading="Modify PNR & Airline Code"
    description=""
  />
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";

import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import FareManagementServices from "@/modules/FareManagement/services/faremanagement.service";
import {
  formatNumber,
  getFormattedDateTime,
} from "@/ag-portal-common/utils/helpers";
import {
  FORMAT_HH_SS_A,
  FORMAT_YYY_MM_DD,
  FORMAT_YYY_MM_DD_HH_SS_A,
} from "@/ag-portal-common/constants/dateTimeFormats";

import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import notificationService from "@/ag-portal-common/services/notification.service";
import { StatusCodes } from "http-status-codes";
import {
  ISabreAirlineConfig,
  ISettings,
} from "@/ag-portal-common/interfaces/settings.interface";

export default defineComponent({
  name: "FinancialProfileBox",
  data() {
    return {
      isLoading: false,
      isSaveLoading: false,
      inputPNR: "",
      inputAirLineCode: "",
      sabreAirlineValue: "",
      Response: "",
      saveResponse: "",
      reference: "",
      totalFare: "",
      currency: "",
      headers: [
        {
          title: "Title",
          value: "title",
          key: "title",
          sortable: false,
        },
        {
          title: "First Name",
          value: "first_name",
          key: "first_name",
          sortable: false,
        },
        {
          title: "Last Name",
          value: "last_name",
          key: "last_name",
          sortable: false,
        },
        {
          title: "Passenger Type",
          value: "passenger_type",
          key: "passenger_type",
          sortable: false,
        },
        {
          title: "Name Type",
          value: "name_type",
          key: "name_type",
          sortable: false,
        },
      ],
      oldPriceQuotesTable: [
        {
          title: "Code",
          value: "code",
          key: "code",
          sortable: false,
        },
        {
          title: "Amount",
          value: "amount",
          key: "amount",
          sortable: false,
        },
        {
          title: "Tax Name",
          value: "taxName",
          key: "taxName",
          sortable: false,
        },
        {
          title: "Ticketing Tax Code",
          value: "ticketing_tax_code",
          key: "ticketing_tax_code",
          sortable: false,
        },
      ],
      segments: [
        {
          title: "Sector",
          value: "Sector",
          key: "Sector",
          sortable: false,
        },
        {
          title: "departure date",
          value: "departure_date_time",
          key: "departure_date_time",
          sortable: false,
        },
        {
          title: "arrival date",
          value: "arrival_date_time",
          key: "arrival_date_time",
          sortable: false,
        },
        {
          title: "Baggage",
          value: "Baggage",
          key: "Baggage",
          sortable: false,
        },
        {
          title: "flight number",
          value: "flight_number",
          key: "flight_number",
          sortable: false,
        },
        {
          title: "rbd",
          value: "rbd",
          key: "rbd",
          sortable: false,
        },
        {
          title: "segment status",
          value: "segment_status",
          key: "segment_status",
          sortable: false,
        },
      ],
      priceQuotePolicies: [
        {
          title: "type",
          value: "type",
          key: "type",
          sortable: false,
        },
        {
          title: "amount",
          value: "amount",
          key: "amount",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    formatNumber,
    getFormattedDateTime,
    async buttonClick() {
      const varibale = new FareManagementServices();
      if (this.inputAirLineCode && this.inputPNR && this.inputPNR.length >= 5) {
        this.isLoading = true;
        this.Response = "";
        try {
          const response = await varibale.getFares(
            this.inputPNR.trim(),
            this.inputAirLineCode.trim()
          );
          if (response.success && response.status === StatusCodes.OK) {
            this.Response = response.data.data;
            this.reference = response.data.data.reference;
            this.totalFare = response.data.data.total_new_fare;
            this.currency =
              response.data.data.new_price_quotes[0].price.currency_code;
          } else {
            throw response;
          }
        } catch (err) {
          notificationService.type = NOTIFICATION_TYPES.ERROR;
          notificationService.description = "Error while fetching fare";
          notificationService.triggerNotification();
        }
        this.isLoading = false;
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          "Both PNR & Airline Code are required";
        notificationService.triggerNotification();
      }
    },
    async ApplyButtonClick() {
      const varibale = new FareManagementServices();
      this.isSaveLoading = true;
      this.saveResponse = "";
      try {
        const response = await varibale.saveFares(this.reference);
        if (response.success && response.status === StatusCodes.OK) {
          this.saveResponse = response.data.data;
          notificationService.type = NOTIFICATION_TYPES.SUCCESS;
          notificationService.description = "Fare Save Successfully";
          notificationService.triggerNotification();
          this.isLoading = false;
          this.isSaveLoading = false;
          this.inputPNR = "";
          this.inputAirLineCode = "";
          this.sabreAirlineValue = "";
          this.Response = "";
          this.saveResponse = "";
          this.reference = "";
          this.totalFare = "";
          this.currency = "";
        } else {
          throw response;
        }
      } catch (err) {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = "Error while saving fare";
        notificationService.triggerNotification();
      }
      this.isSaveLoading = false;
    },
  },
  setup() {
    let settings = inject(AUTH_CONTEXT_KEYS.settings) as () => ISettings | null;
    return {
      settings,
    };
  },
  computed: {
    FORMAT_YYY_MM_DD() {
      return FORMAT_YYY_MM_DD;
    },
    FORMAT_YYY_MM_DD_HH_SS_A() {
      return FORMAT_YYY_MM_DD_HH_SS_A;
    },
    FORMAT_HH_SS_A() {
      return FORMAT_HH_SS_A;
    },
    sabreAirlinesList() {
      let settings = this.settings() as ISettings | null;

      return settings?.sabre_airlines?.map((item) => item.airline_code);
    },
  },
});
</script>
