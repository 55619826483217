<template>
  <my-bookings-filters-view
    v-show="showFilters"
    @fetchBookings="onUpdateFilter"
  />

  <MDataTable
    :is-api-paginated="true"
    :total-item-count="totalPageCount"
    :headers="headers"
    :item-per-page="30"
    :data="$store.getters.hotelBookings.bookings"
    :is-loading="$store.getters.isFetchingHotelBookings"
    @onPageChange="onPageChange"
  >
    <template #bookingId="{ item }">
      <router-link
        :data-test-id="
          genTestId(ELEMENT_TYPES.LINK, `hotel-booking-${item.id}`)
        "
        :to="
          formatStringToRoutePath(PATH.HOTEL_BOOKING_DETAILS, {
            id: item.id,
          })
        "
        ><span class="booking-id">{{ item.bookingId }}</span>
      </router-link>
    </template>

    <template #guests="{ item }">
      <div class="text-center" v-if="isInitiated(item?.status)">-</div>

      <div class="guest-dropdown-container" v-else>
        <AGButtonDropDown
          text="Test"
          class="table_dropdown_wrap"
          :items="item.guests"
        >
          <template #label>
            <i>{{ item.guests[0] }}</i>
          </template>
          <AGDropDownButtonItem
            v-for="(item, index) in item.guests"
            :key="index"
            :name="item"
          />
        </AGButtonDropDown>
      </div>
    </template>
    <template #bookingTime="{ item }">
      <div class="text-align-center width-150">
        <span>{{ formattedDate(item.bookingTime) }}</span>
        <span class="text-color-green booking-time">
          {{ formattedTime(item.bookingTime) }}
        </span>
      </div>
    </template>

    <template #status="{ item }">
      <div class="status-container">
        <MChip :variant="bookingStatus(item.status)">{{ item.status }}</MChip>
      </div>
    </template>
    <template #status_remarks="{ item }">
      <div class="status-container">
        <div v-if="isBookingExpiryNotAvailable(item)" class="status_msg">
          Booking Expiry will be available soon
        </div>
        <div v-else-if="isBookingExpired(item)" class="status_msg expired">
          Expired
        </div>
        <div v-else-if="!checkStatus(item)" class="status_msg expires_in_msg">
          Expires in <br />
          <AgDuration
            :to="
                  getConfirmOrInitiateDate(item.status, item.initiatedBookingExpiresAt, item.confirmedBookingExpiresAt) as Date
                "
          />
        </div>
        <div v-else class="text-align-center">-</div>
      </div>
    </template>
    <template #checkIn="{ item }">
      <div class="date-container">{{ formattedDate(item.checkIn) }}</div>
    </template>
    <template #checkOut="{ item }">
      <div class="date-container">{{ formattedDate(item.checkOut) }}</div>
    </template>
  </MDataTable>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  genTestId,
  getFormattedDateTime,
  formatStringToRoutePath,
  isDateExpired,
} from "@/ag-portal-common/utils/helpers";
import {
  FORMAT_DD_MMM_YYYY_WITH_DASH,
  FORMAT_HH_SS_24_HOURS,
} from "@/ag-portal-common/constants/dateTimeFormats";
import { PATH } from "@/ag-portal-common/constants/path";
import { BOOKING_STATUS } from "@/ag-portal-common/enums/BOOKING_STATUS";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { FetchBookingsParamsRequestModel } from "@/modules/MyBookings/models/fetchBookingsParams.request";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import AgDuration from "@/ag-portal-common/components/AgDuration.vue";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { BOOKING_TYPES } from "@/ag-portal-common/enums/BOOKING_TYPES";
import MyBookingsFiltersView from "@/modules/MyBookings/components/MyHotelBookingsFilters.vue";

import analyticsService from "@/analytic.service";
import { MY_BOOKING_ANALYTICS_EVENTS } from "@/modules/MyBookings/constants/anaylticsEvents";
import {
  MButton,
  MTextfield,
  MDataTable,
  MTypography,
  MChip,
  MFabButton,
} from "@aeroglobe/ag-core-ui";
import { MDataTableHeader } from "@/ag-portal-common/types";
import { ChipVariant } from "@aeroglobe/ag-core-ui/dist/src/components/material/atoms/atoms.type";
import { BOOKING_STATUSES_MAPPING } from "../constants";
import { IFlightBookings } from "@/interfaces/flightBookings.interface";
import { IHotelBookings } from "@/interfaces/hotelBookings.interface";

export default defineComponent({
  name: "HotelBookings",
  components: { AgDuration, MyBookingsFiltersView, MDataTable, MChip },
  beforeMount() {
    this.fetchBookings(this.setItemsPerPage);
  },
  computed: {
    bookings() {
      const bookings = this.$store.getters.hotelBookings
        .bookings as IHotelBookings[];
      return bookings.map((booking: IHotelBookings) => {
        return {
          ...booking,
          status_remarks: booking.status,
        };
      });
    },
    showFilters() {
      return this.isDashboardView === false;
    },
    PATH() {
      return PATH;
    },
    totalPageCount() {
      return this.$store.getters.hotelBookings.count;
    },
    isIssued() {
      return (
        BOOKING_STATUS.ISSUED.toUpperCase() ||
        BOOKING_STATUS.ISSUED_BUT_NOT_PAID.toUpperCase()
      );
    },
    isCanceled() {
      return BOOKING_STATUS.CANCELED.toUpperCase();
    },
  },
  props: {
    isDashboardView: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    genTestId,
    formatStringToRoutePath,
    isDateExpired,
    onUpdateFilter(filters: FetchBookingsParamsRequestModel) {
      this.fetchBookingParams = {
        ...filters,
        booking_type: this.fetchBookingParams.booking_type,
        page: 1,
      };
      this.fetchBookings();
    },
    onPageChange(num: number) {
      let nextPage = this.$store.getters.hotelBookings.next;
      let prevPage = this.$store.getters.hotelBookings.previous;
      let currentPage: number = this.fetchBookingParams.page || 1;
      if ((num > currentPage && nextPage) || (currentPage > num && prevPage)) {
        this.fetchBookingParams.page = num;
        this.fetchBookings();
      }
    },
    setItemsPerPage() {
      let bookingsLength = this.$store.getters.hotelBookings.bookings.length;
      this.itemsPerPage = bookingsLength;
    },
    getConfirmOrInitiateDate(
      status: BOOKING_STATUS,
      initiatedBookingExpiresAt: Date,
      confirmedBookingExpiresAt: null | Date
    ) {
      if (status === BOOKING_STATUS.CONFIRMED.toUpperCase())
        return confirmedBookingExpiresAt || NOTIFICATION_MESSAGES.INVALID_DATE;
      return initiatedBookingExpiresAt;
    },
    fetchBookings(callback?: () => void) {
      const payload = this.fetchBookingParams;

      let eventName = MY_BOOKING_ANALYTICS_EVENTS.HOTELS_SEARCH;

      analyticsService.logActionEvent(eventName, payload);

      this.$store.dispatch("fetchHotelBookings", {
        payload,
        callback,
      });
    },
    formattedDate(date: Date) {
      return getFormattedDateTime(date, FORMAT_DD_MMM_YYYY_WITH_DASH);
    },
    formattedTime(date: Date) {
      return getFormattedDateTime(date, FORMAT_HH_SS_24_HOURS);
    },
    bookingStatus(status: BOOKING_STATUS): ChipVariant {
      return BOOKING_STATUSES_MAPPING[status] as ChipVariant;
    },
    isBookingExpiryNotAvailable(item: IFlightBookings) {
      const isNotIssued = item.status !== this.isIssued;
      const isNotInitiated = !this.isInitiated(item.status as BOOKING_STATUS);
      const isNotCanceled = item.status !== this.isCanceled;
      return (
        item.confirmedBookingExpiresAt === null &&
        isNotIssued &&
        isNotInitiated &&
        isNotCanceled
      );
    },
    isBookingExpired(item: IFlightBookings) {
      if (BOOKING_STATUS.INITIATED.toUpperCase() === item.status) {
        return false;
      } else if (BOOKING_STATUS.CANCELED.toUpperCase() === item.status) {
        return true;
      }
      return (
        this.isDateExpired(
          this.getConfirmOrInitiateDate(
            item.status as BOOKING_STATUS,
            item.initiatedBookingExpiresAt,
            item.confirmedBookingExpiresAt
          ) as Date
        ) && item.status !== this.isIssued
      );
    },
    isInitiated(status: BOOKING_STATUS) {
      return status === BOOKING_STATUS.INITIATED.toUpperCase();
    },
    checkStatus(item: IFlightBookings): boolean {
      return [
        BOOKING_STATUS.CANCELED.toUpperCase(),
        BOOKING_STATUS.ISSUED.toUpperCase(),
        BOOKING_STATUS.INITIATED.toUpperCase(),
      ].includes(item.status);
    },
  },

  data(): {
    ELEMENT_TYPES: IObject;
    headers: MDataTableHeader[];
    NOTIFICATION_MESSAGES: IObject;
    fetchBookingParams: FetchBookingsParamsRequestModel;
    itemsPerPage: number;
  } {
    return {
      itemsPerPage: 0,
      fetchBookingParams: {
        search: "",
        start: null,
        end: null,
        page: 1,
        booking_type: BOOKING_TYPES.HOTEL,
      },
      NOTIFICATION_MESSAGES,
      ELEMENT_TYPES,
      headers: [
        {
          title: "Booking ID",
          value: "bookingId",
          key: "bookingId",
          sortable: false,
          align: "left",
        },
        {
          title: "Booking Time",
          value: "bookingTime",
          key: "bookingTime",
          sortable: false,
        },
        {
          title: "Status",
          value: "status",
          key: "status",
          sortable: false,
        },
        {
          title: "Status Remarks",
          value: "status_remarks",
          key: "status_remarks",
          sortable: false,
        },
        {
          title: "Guests",
          value: "guests",
          key: "guests",
          sortable: false,
        },
        {
          title: "Check In",
          value: "checkIn",
          key: "checkIn",
          sortable: false,
        },
        {
          title: "Check Out",
          value: "checkOut",
          key: "checkOut",
          sortable: false,
        },
      ],
    };
  },
});
</script>

<style scoped>
.booking-id {
  color: var(--green-color);
}
.status-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.status_msg {
  font-size: 12px;
  line-height: 1.4;
  display: inline;
  width: 110px;
  text-align: center;
  font-weight: 500;
}
.expired {
  font-weight: 500;
  color: red;
}
.expires_in_msg {
  color: #ff8600;
  width: 150px;
}
.date-container {
  text-align: center;
}
.guest-dropdown-container {
  display: flex;
  justify-content: center;
}
.booking-time-container {
  text-align: center;
}
.width-150 {
  width: 150px;
}
.text-align-center {
  text-align: center;
}
.booking-time {
  margin-left: 5px;
}
</style>
