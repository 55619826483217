import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_ag_select = _resolveComponent("ag-select")!
  const _component_a_g_text_field = _resolveComponent("a-g-text-field")!
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createBlock(_component_ag_modal, {
    "is-open": _ctx.isOpen,
    onClose: _ctx.closeModal,
    "modal-width": "40%"
  }, {
    header: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createTextVNode("Add Agent")
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_a_g_button, {
        testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'add-agent-modal-close'),
        type: "button",
        class: "modal_close_icon",
        variant: "link",
        onClick: _ctx.closeModal
      }, {
        default: _withCtx(() => [
          _createTextVNode("Close ")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["testId", "onClick"])
    ]),
    body: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
      }, [
        _createVNode(_component_ag_select, {
          items: _ctx.roles,
          modelValue: _ctx.role,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.role) = $event)),
          value: _ctx.role,
          required: "",
          testId: 
            _ctx.genTestId(_ctx.ELEMENT_TYPES.SELECT_FIELD, 'add-agent-modal-role')
          ,
          variant: "outlined",
          label: "Role",
          error: _ctx.errors['role']
        }, null, 8 /* PROPS */, ["items", "modelValue", "value", "testId", "error"]),
        _createVNode(_component_a_g_text_field, {
          label: "Name",
          modelValue: _ctx.full_name,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.full_name) = $event)),
          testId: 
            _ctx.genTestId(_ctx.ELEMENT_TYPES.TEXT_FIELD, 'add-agent-modal-full_name')
          ,
          error: _ctx.errors['full_name']
        }, null, 8 /* PROPS */, ["modelValue", "testId", "error"]),
        _createVNode(_component_a_g_text_field, {
          label: "Email",
          type: "email",
          testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.TEXT_FIELD, 'add-agent-modal-email'),
          modelValue: _ctx.email,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event)),
          error: _ctx.errors['email']
        }, null, 8 /* PROPS */, ["testId", "modelValue", "error"]),
        _createVNode(_component_a_g_button, {
          type: "submit",
          disabled: _ctx.$store.getters.isAddingAgent,
          testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'add-agent-modal-submit'),
          isLoading: _ctx.$store.getters.isAddingAgent,
          class: "submit-btn"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Submit ")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled", "testId", "isLoading"])
      ], 32 /* NEED_HYDRATION */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-open", "onClose"]))
}