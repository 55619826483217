import { IPaymentState } from "@/interfaces/paymentState.interface";

const state: IPaymentState = {
  payments: {
    hasNext: false,
    totalRecords: 0,
    pageNumber: 0,
    pageSize: 0,
    totalPages: 0,
    payments: [],
  },
  payment: null,
  isFetchingPayments: false,
  isFetchingPayment: false,
  isCreatingPayment: false,
  paymentsByMethods: null,
};

export default state;
