import { GetterTree } from "vuex";
import { ISectorProductivity } from "@/ag-portal-common/interfaces/sectorProductivity.interface";
import { ISalesState } from "@/ag-portal-common/interfaces/salesState.interface";
import {
  ISalesSector,
  ISalesSnapshot,
} from "@/ag-portal-common/interfaces/salesProductivity.interface";
import { IMyTeam } from "@/ag-portal-common/interfaces/salesMyTeam.interface";

const getters: GetterTree<ISalesState, ISalesState> = {
  salesSectors: (state): Array<ISalesSector> | null => state.sectors,
  isSalesSectorsFetching: (state): boolean => state.isSalesSectorsFetching,
  isSalesLoading: (state): boolean => state.isLoading,
  salesSnapshot: (state): ISalesSnapshot | null => state.salesSnapshot,
  salesProductivity: (state): ISectorProductivity | null =>
    state.salesProductivity,
  isSalesMyTeamLoading: (state): boolean => state.isSalesMyTeamLoading,
  salesMyTeam: (state): IMyTeam[] => state.salesMyTeam,
};

export default getters;
