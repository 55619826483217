<!-- eslint-disable vue/no-side-effects-in-computed-properties -->
<template>
  <ag-loader v-if="isPaymentMethodLoaded" />
  <ag-card v-else>
    <AgNotFound
      v-if="!isAnyPaymentMethod"
      test-id=""
      heading="No Payment Methods Found"
      description=""
    />
    <Agdiv v-else>
      <ag-heading title="Create New Payment" variant="h3" />
      <ag-row>
        <ag-column xs="12" sm="12" md="6">
          <ag-heading title="Select Payment Method" variant="label" />
          <ag-radio
            v-for="type in PaymentMethods"
            :key="type.payment_type"
            v-model="method"
            :testId="
              genTestId(ELEMENT_TYPES.RADIO_BUTTON, 'create-payment-method')
            "
          >
            <ag-card
              v-if="type.payment_type === 'IBFT' && type.is_active === true"
            >
              <ag-radio-item
                :testId="
                  genTestId(ELEMENT_TYPES.RADIO_BUTTON, 'create-payment-IBFT')
                "
                :value="PAYMENT_METHODS_TYPES.IBFT"
                name="method"
                label="IBFT - Bank Transfer"
              ></ag-radio-item>
              <AgTable
                v-if="bankDetails?.bank_account_no !== null && isBank"
                :headers="bankTable"
                :items="[bankDetails]"
                :has-pagination="false"
                :items-per-page="1"
                :has-search="false"
              >
                <template #col-bank_name="{ item }">
                  <ag-div>{{ item.raw.bank_name }}</ag-div>
                </template>
                <template #col-bank_iban="{ item }">
                  <ag-div>{{ item.raw.bank_iban }}</ag-div>
                </template>
                <template #col-bank_title="{ item }">
                  <ag-div>{{ item.raw.bank_title }}</ag-div>
                </template>
                <template #col-bank_account_no="{ item }">
                  <ag-div>{{ item.raw.bank_account_no }}</ag-div>
                </template>
              </AgTable>
              <ag-div class="margin_top_20" v-if="isBank">
                <ag-heading
                  v-if="bankDetails?.bank_account_no !== null"
                  :title="`Note: ${noteForAmount}`"
                  variant="b"
                />
              </ag-div>
              <ag-heading
                v-if="method === PAYMENT_METHODS_TYPES.IBFT"
                :title="`${noteForPayment(
                  type.payment_fee_value || 0,
                  type.payment_type,
                  type.payment_fee_type,
                  type.currency
                )}`"
                variant="b"
              />
              <AgNotFound
                v-if="bankDetails?.bank_account_no === null && isBank"
                test-id=""
                heading="Please contact billing team to have your IBFT details updated"
                description=""
              />
            </ag-card>

            <ag-card
              v-if="type.payment_type === 'ONE_BILL' && type.is_active === true"
            >
              <ag-radio-item
                :testId="
                  genTestId(
                    ELEMENT_TYPES.RADIO_BUTTON,
                    'create-payment-ONE_BILL'
                  )
                "
                :value="PAYMENT_METHODS_TYPES.ONE_BILL"
                name="method"
                label="One Bill"
              ></ag-radio-item>
              <ag-heading
                v-if="method === PAYMENT_METHODS_TYPES.ONE_BILL"
                :title="`${noteForPayment(
                  type.payment_fee_value || 0,
                  type.payment_type,
                  type.payment_fee_type,
                  type.currency
                )}`"
                variant="b"
              />
            </ag-card>
            <ag-card
              v-if="
                type.payment_type === 'CREDIT_CARD' && type.is_active === true
              "
            >
              <ag-radio-item
                :testId="
                  genTestId(
                    ELEMENT_TYPES.RADIO_BUTTON,
                    'create-payment-CREDIT_CARD'
                  )
                "
                :value="PAYMENT_METHODS_TYPES.CREDIT_CARD"
                name="method"
                label="Debit/Credit Card"
              ></ag-radio-item>
              <ag-heading
                v-if="method === PAYMENT_METHODS_TYPES.CREDIT_CARD"
                :title="`${noteForPayment(
                  type.payment_fee_value || 0,
                  type.payment_type,
                  type.payment_fee_type,
                  type.currency
                )}`"
                variant="b"
              />
            </ag-card>
          </ag-radio>
          <ag-heading
            class="color-red"
            v-if="errors?.method"
            :title="errors?.method"
            variant="p"
          />

          <form @submit.prevent="onSubmit" v-if="!isBank">
            <ag-heading v-if="!isBank" title="Amount" variant="label" />
            <a-g-text-field
              :testId="
                genTestId(ELEMENT_TYPES.TEXT_FIELD, 'create-payment-amount')
              "
              :error="errors?.amount"
              v-model="amount"
              type="number"
              :value="amount"
            />
            <ag-heading
              v-if="amount && !isBank"
              :title="`Note: ${paymentAmount()}`"
              variant="b"
            />
            <financial-profile-dropdown
              class="margin_top_20"
              label="Financial Profile"
              :error="errors['financial_profile_public_id']"
              @onUpdateFinancialProfile="updateFinancialProfilePublicId"
            />
            <ag-heading title="Description" variant="label" />
            <a-g-text-field
              :testId="
                genTestId(
                  ELEMENT_TYPES.TEXT_FIELD,
                  'create-payment-description'
                )
              "
              v-model="description"
              :value="description"
            />
            <ag-div class="d-flex justify-content-end">
              <MButton
                variant="filled"
                class="margin_right_20"
                @click="routeToPaymentList"
                :disabled="$store.getters.isCreatingPayment"
                >Cancel
              </MButton>
              <MButton
                type="filled"
                :testId="
                  genTestId(ELEMENT_TYPES.BUTTON, 'create-payment-submit')
                "
                :disabled="$store.getters.isCreatingPayment"
                :is-loading="$store.getters.isCreatingPayment"
                behaviour="submit"
                >Create Payment
              </MButton>
            </ag-div>
          </form>
        </ag-column>
      </ag-row>
    </Agdiv>
  </ag-card>
</template>

<script lang="ts">
import PaymentsService from "@/modules/Payments/services/payments.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { StatusCodes } from "http-status-codes";
import { IOrganizationFromLoginResponse } from "@/ag-portal-common/interfaces/organization.interface";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { defineComponent, inject } from "vue";
import { PAYMENT_METHODS_TYPES } from "@/ag-portal-common/enums/PAYMENT_METHODS_TYPES";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { ISettings } from "@/ag-portal-common/interfaces/settings.interface";
import {
  formatString,
  formatStringToRoutePath,
  genTestId,
  yupValidationErrorAsSchema,
} from "@/ag-portal-common/utils/helpers";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { ValidationError } from "yup";
import { createPaymentValidationschema } from "@/modules/Payments/validations/createPaymentValidationSchema";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";
import { PATH } from "@/ag-portal-common/constants/path";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import { IBankDetails } from "@/ag-portal-common/interfaces/bankDetails.interface";
import FinancialProfileDropdown from "@/components/FinancialProfileDropdown.vue";
import analyticsService from "@/analytic.service";
import { PAYMENTS_ANALYTICS_EVENTS } from "@/modules/Payments/constants/anaylticsEvents";

interface IState {
  method: PAYMENT_METHODS_TYPES;
  financialProfilePublicId: string;
  bankDetails: IBankDetails | null;
  description: string;
  amount: number;
  errors: IObject;
  isBank: boolean;
  bankTable: {
    title: string;
    value: string;
    key: string;
    sortable: boolean;
  }[];
  isPaymentMethodLoaded: boolean;
  isAnyPaymentMethod: boolean;
  PaymentMethods:
    | {
        payment_type: string;
        is_active: boolean;
        payment_fee_type: "PERCENTAGE" | "FIXED_FEE" | "FREE";
        payment_fee_value: number | null;
        minimum_payment: number | null;
        currency: string;
      }[]
    | null;
}

export default defineComponent({
  name: "CreatePayment",
  components: { FinancialProfileDropdown },
  computed: {
    isSectorDubai() {
      return localStorage.getItem("sector") === "Aeroglobe - Dubai";
    },
    ELEMENT_TYPES() {
      return ELEMENT_TYPES;
    },
    PAYMENT_METHODS_TYPES() {
      return PAYMENT_METHODS_TYPES;
    },
    noteForAmount() {
      const settings: ISettings = this.settings();
      let ccMDR = settings ? settings.cc_payment_mdr_charge : 2;
      if (localStorage.getItem("currency") !== "PKR") {
        ccMDR = 3;
      }
      const onebillFlatCharge = settings
        ? settings.one_bill_payment_flat_charge
        : 100;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      let { amount } = this;
      const netAmountCreditCard = amount - amount * (ccMDR / 100);
      const netAmountOnebill = amount - onebillFlatCharge;

      const noteForNetAmountCreditCard = `${formatString(
        NOTIFICATION_MESSAGES.CC_CHARGES_DEDUCTION,
        ccMDR.toString()
      )} ${
        netAmountCreditCard > 0
          ? formatString(
              NOTIFICATION_MESSAGES.TOTAL_AMOUNT_DEPOSIT,
              netAmountCreditCard.toString(),
              localStorage.getItem("currency") || ""
            )
          : ""
      }`;
      const noteForIBFT = NOTIFICATION_MESSAGES.IBFT_DEDUCTION;
      const noteForNetAmountOneBill = `${formatString(
        NOTIFICATION_MESSAGES.ONE_BILL_CHARGES_DEDUCTION,
        onebillFlatCharge.toString()
      )} ${
        netAmountOnebill > 0
          ? formatString(
              NOTIFICATION_MESSAGES.TOTAL_AMOUNT_DEPOSIT,
              netAmountOnebill.toString(),
              localStorage.getItem("currency") || ""
            )
          : ""
      }`;

      if (this.method === PAYMENT_METHODS_TYPES.CREDIT_CARD)
        return noteForNetAmountCreditCard;
      if (this.method === PAYMENT_METHODS_TYPES.ONE_BILL)
        return noteForNetAmountOneBill;
      if (this.method === PAYMENT_METHODS_TYPES.IBFT) return noteForIBFT;
      return "";
    },
  },
  data(): IState {
    return {
      method: PAYMENT_METHODS_TYPES.IBFT,
      bankDetails: null,
      isAnyPaymentMethod: false,
      description: "",
      financialProfilePublicId: "",
      amount: Number(0),
      errors: {},
      isBank: true,
      isPaymentMethodLoaded: true,
      bankTable: [
        {
          title: "Bank Name",
          value: "bankDetails.bank_name",
          key: "bank_name",
          sortable: false,
        },
        {
          title: "Bank IBAN",
          value: "bankDetails.bank_iban",
          key: "bank_iban",
          sortable: false,
        },
        {
          title: "Bank Account No",
          value: "bankDetails.bank_account_no",
          key: "bank_account_no",
          sortable: false,
        },
        {
          title: "Bank Title",
          value: "bankDetails.bank_title",
          key: "bank_title",
          sortable: false,
        },
      ],
      PaymentMethods: [],
    };
  },
  methods: {
    genTestId,
    async onSubmit() {
      this.errors = {};
      const payload = {
        amount: Number(this.amount),
        method: this.method,
        description: this.description,
        financial_profile_public_id: this.financialProfilePublicId,
      };
      let minValue = 0;
      for (const paymentMethod of this.PaymentMethods || []) {
        if (
          this.method === paymentMethod.payment_type &&
          paymentMethod.payment_fee_type === "FIXED_FEE"
        ) {
          minValue = paymentMethod.minimum_payment || 0;
        } else if (
          this.method === paymentMethod.payment_type &&
          paymentMethod.payment_fee_type === "PERCENTAGE"
        ) {
          minValue = paymentMethod.minimum_payment || 0;
        }
      }
      try {
        this.$store.dispatch("createPayment", {
          payload,
          callback: this.routeToPaymentDetail,
        });
        await createPaymentValidationschema(minValue).validate(payload, {
          abortEarly: false,
        });
        this.$store.dispatch("createPayment", {
          payload,
          callback: this.routeToPaymentDetail,
        });

        analyticsService.logActionEvent(
          PAYMENTS_ANALYTICS_EVENTS.PAYMENT_NEW,
          payload
        );
      } catch (ex) {
        if (ex instanceof ValidationError) {
          this.errors = yupValidationErrorAsSchema(ex);
        }
      }
    },
    paymentAmount() {
      let note = "";
      for (const paymentMethod of this.PaymentMethods || []) {
        if (
          this.method === paymentMethod.payment_type &&
          paymentMethod.payment_fee_type === "FIXED_FEE"
        ) {
          const amount = this.amount - (paymentMethod.payment_fee_value || 0);
          note += " " + amount.toFixed(2) + " " + paymentMethod.currency;
        } else if (
          // eslint-disable-next-line no-dupe-else-if
          this.method === paymentMethod.payment_type &&
          paymentMethod.payment_fee_type === "PERCENTAGE"
        ) {
          const percentageAmount =
            ((paymentMethod.payment_fee_value || 0) / 100) * this.amount;
          const amount = this.amount - percentageAmount;
          note += " " + amount.toFixed(2) + " " + paymentMethod.currency;
        }
      }
      note += " will be deposited in your account";
      return note;
    },
    noteForPayment(
      payment_fee_value: number,
      payment_type: string,
      charges: string,
      currency: string
    ) {
      let note = "Note: " + payment_fee_value;
      if (charges === "PERCENTAGE") {
        note += "%";
      } else if (charges === "FIXED_FEE") {
        note += " " + currency;
      } else {
        note += " " + currency;
      }

      note += " will be deducted in case of ";
      if (payment_type === "CREDIT_CARD") {
        note += "debit/credit card";
      } else if (payment_type === "ONE_BILL") {
        note += "one bill";
      } else {
        note += "IBFT";
      }
      return note;
    },

    updateFinancialProfilePublicId(value: string) {
      this.financialProfilePublicId = value;
    },
    routeToPaymentDetail(id: string) {
      this.$router.push(formatStringToRoutePath(PATH.PAYMENT_DETAIL, { id }));
    },
    routeToPaymentList() {
      this.$router.push(PATH.PAYMENTS);
    },
  },
  created() {
    let user = this.user() as IUserV2;
    this.bankDetails = user?.bank_details || null;
  },
  async beforeMount() {
    const paymentsService = new PaymentsService();
    const response: IAGResponse<any> =
      await paymentsService.getAllPaymentMethods(
        this.organization()?.organization_id
      );
    try {
      if (response.success && response.status === StatusCodes.OK) {
        this.PaymentMethods = response.data.data.payment_methods;
        if (this.PaymentMethods?.length) {
          this.isAnyPaymentMethod = true;
        }
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          response.error || "fetched payment methods successfully";
      } else {
        throw response;
      }
    } catch (err) {
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        response.error || "error while fetching payment methods";
    } finally {
      this.isPaymentMethodLoaded = false;
    }
    notificationService.triggerNotification();
  },
  setup() {
    const settings = inject(AUTH_CONTEXT_KEYS.settings) as () => ISettings;
    const user = inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2;
    const organization = inject(
      AUTH_CONTEXT_KEYS.organization
    ) as () => IOrganizationFromLoginResponse;
    return { settings, user, organization };
  },
  watch: {
    method(newMethod: string) {
      this.isBank = newMethod === PAYMENT_METHODS_TYPES.IBFT;
    },
  },
});
</script>
