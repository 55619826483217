import { IAuthState } from "@/ag-portal-common/interfaces/authState.interface";

const authState: IAuthState = {
  user: null,
  settings: null,
  permissions: null,
  organization_preference: null,
  isUserSignedIn: false,
  organization: null,
  isAuthenticating: false,
};

export default authState;
