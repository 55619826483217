<template>
  <ag-card>
    <ag-div class="d-flex justify-content-between align-items-center">
      <ag-heading
        variant="h2"
        class="margin_bottom_0"
        title="Agent Credit Limit Request"
      ></ag-heading>
      <ag-div class="credit_limit_btn_space">
        <MButton
          :test-id="genTestId(ELEMENT_TYPES.BUTTON, 'create-credit-limit')"
          @click="routeToCreateCreditLimitRequest"
          >Request Limit
        </MButton>
      </ag-div>
    </ag-div>
  </ag-card>
  <ag-card>
    <ag-div>
      <ag-loader class="padding_top_20" v-if="isFetchingCreditLimitRequests" />
      <ag-table
        v-else-if="
          !isFetchingCreditLimitRequests && creditLimitRequests.data.length
        "
        :headers="headers"
        :items="creditLimitRequests.data"
        :total-page-count="creditLimitRequests.totalPages"
        :page="page"
        @onPageChange="onPageChange"
        :items-per-page="itemsPerPage"
        :has-pagination="true"
        :has-search="false"
      >
        <template #col-requestInitiatedAt="{ item }">
          <ag-heading
            v-if="item.raw.requestInitiatedAt"
            :title="
              getFormattedDateTime(
                item.raw.requestInitiatedAt,
                FORMAT_YYY_MM_DD
              )
            "
            class="margin_bottom_0 margin_top_20"
            variant="p"
          />
          <ag-heading
            v-if="item.raw.requestInitiatedAt"
            class="text-color-green"
            :title="
              getFormattedDateTime(item.raw.requestInitiatedAt, FORMAT_HH_SS_A)
            "
            variant="p"
          />
          <ag-heading
            v-if="!item.raw.requestInitiatedAt"
            title="N/A"
            variant="p"
          />
        </template>
        <template #col-requestedAmount="{ item }">
          {{ formatNumber(item.raw.requestedAmount) }}
        </template>
        <template #col-limitExpiresAt="{ item }">
          <ag-heading
            v-if="item.raw.requestInitiatedAt"
            :title="
              getFormattedDateTime(item.raw.limitExpiresAt, FORMAT_YYY_MM_DD)
            "
            class="margin_bottom_0 margin_top_20"
            variant="p"
          />
          <ag-heading
            class="text-color-green"
            :title="
              getFormattedDateTime(item.raw.limitExpiresAt, FORMAT_HH_SS_A)
            "
            variant="p"
          />
        </template>
        <template #col-status="{ item }">
          <ag-div :class="creditLimitStatusClass(item.raw.status)">
            {{ item.raw.status }}
          </ag-div>
        </template>
        <template #col-priority="{ item }">
          <ag-div :class="creditLimitPriorityclass(item.raw.priority)">
            {{ item.raw.priority }}
          </ag-div>
        </template>
      </ag-table>

      <ag-not-found
        v-else
        :heading="NOTIFICATION_MESSAGES.NO_RESULTS_FOUND"
        :description="NOTIFICATION_MESSAGES.NO_RESULTS_FOUND_DESCRIPTION"
      />
    </ag-div>
  </ag-card>
</template>

<script lang="ts">
import { CREDIT_LIMIT_REQUEST_STATUSES } from "@/ag-portal-common/enums/CREDIT_LIMIT_REQUEST_STATUSES";
import {
  formatNumber,
  genTestId,
  getFormattedDateTime,
} from "@/ag-portal-common/utils/helpers";
import { IPaginatedCreditLimitRequests } from "@/interfaces/creditLimitRequest.interface";
import {
  FORMAT_HH_SS_A,
  FORMAT_YYY_MM_DD,
  FORMAT_YYY_MM_DD_HH_SS_A,
} from "@/ag-portal-common/constants/dateTimeFormats";
import { defineComponent } from "vue";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { PATH } from "@/ag-portal-common/constants/path";
import {
  CREDIT_PRIORITIES,
  CREDIT_PRIORITIES_MAPPING,
  CREDIT_STATUSES,
  CREDIT_STATUSES_MAPPING,
} from "../constants";

export default defineComponent({
  name: "ListViewForAgentsUser",
  data() {
    return {
      itemsPerPage: 0,
      page: 1,
      activeTab: CREDIT_LIMIT_REQUEST_STATUSES.PENDING,
      headers: [
        {
          key: "requestInitiatedAt",
          title: "Request Initiated At",
          align: "start",
          value: "requestInitiatedAt",
        },
        {
          key: "priority",
          title: "Priority",
          value: "priority",
        },
        {
          key: "status",
          title: "Status",
          value: "status",
        },
        {
          key: "limitExpiresAt",
          title: "Credit Limit Expires At",
          value: "limitExpiresAt",
        },
        {
          key: "requestedAmount",
          title: "Requested Amount",
          value: "requestedAmount",
        },
        {
          key: "requestedDays",
          title: "Requested Days",
          value: "requestedDays",
        },
        {
          key: "requestNotes",
          title: "Request Notes",
          value: "requestNotes",
        },
      ],
    };
  },
  methods: {
    genTestId,
    formatNumber,
    getFormattedDateTime,
    routeToCreateCreditLimitRequest() {
      this.$router.push(PATH.CREATE_CREDIT_LIMIT_REQUEST);
    },
    setItemsPerPage() {
      let requestsLength = this.$store.getters.creditLimitRequests.data.length;
      this.itemsPerPage = requestsLength;
    },
    fetchCreditLimitRequests(callback?: () => void) {
      let params = {
        status: this.activeTab,
        page: this.page,
      };
      let payload = {
        params,
        callback,
      };

      this.$store.dispatch("fetchCreditLimitRequests", payload);
    },
    onPageChange(num: number) {
      let nextPage = this.$store.getters.creditLimitRequests.next;
      let prevPage = this.$store.getters.creditLimitRequests.previous;
      let currentPage: number = this.page || 1;
      if ((num > currentPage && nextPage) || (currentPage > num && prevPage)) {
        this.page = num;
        this.fetchCreditLimitRequests();
      }
    },
    onClickTab(e: PointerEvent, tab: CREDIT_LIMIT_REQUEST_STATUSES) {
      this.activeTab = tab;
    },
    creditLimitStatusClass(status: CREDIT_STATUSES) {
      return `color_chip ${CREDIT_STATUSES_MAPPING[status]}`;
    },
    creditLimitPriorityclass(status: CREDIT_PRIORITIES) {
      return `color_chip ${CREDIT_PRIORITIES_MAPPING[status]}`;
    },
  },
  computed: {
    FORMAT_HH_SS_A() {
      return FORMAT_HH_SS_A;
    },
    ELEMENT_TYPES() {
      return ELEMENT_TYPES;
    },
    NOTIFICATION_MESSAGES() {
      return NOTIFICATION_MESSAGES;
    },
    CREDIT_LIMIT_REQUEST_STATUSES() {
      return CREDIT_LIMIT_REQUEST_STATUSES;
    },
    creditLimitRequests(): IPaginatedCreditLimitRequests {
      return this.$store.getters.creditLimitRequests;
    },
    isFetchingCreditLimitRequests(): boolean {
      return this.$store.getters.isFetchingCreditLimitRequests;
    },
    FORMAT_YYY_MM_DD() {
      return FORMAT_YYY_MM_DD;
    },
    FORMAT_YYY_MM_DD_HH_SS_A() {
      return FORMAT_YYY_MM_DD_HH_SS_A;
    },
  },
  watch: {
    activeTab() {
      this.page = 1;
      this.fetchCreditLimitRequests(this.setItemsPerPage);
    },
  },
  beforeMount() {
    this.fetchCreditLimitRequests(this.setItemsPerPage);
  },
});
</script>
