import { RouteRecordRaw } from "vue-router";
import SalesSnapshot from "@/modules/Sales/views/SalesSnapshot.vue";
import IssuanceChartPage from "@/modules/Sales/views/DailyIssancePage.vue";
import SalesMyTeam from "@/modules/Sales/views/SalesMyTeamPage.vue";

import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";
import { PATH } from "@/ag-portal-common/constants/path";

const routes: Array<RouteRecordRaw> = [
  {
    path: PATH.SALES_SNAPSHOT,
    name: "salesSnapshot",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.SALES_SNAPSHOT.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.SALES_SNAPSHOT.PERMISSIONS,
    },
    component: SalesSnapshot,
  },
  {
    path: PATH.DAILY_ISSUANCE,
    name: "dailyIssuance",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.DAILY_ISSUANCE.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.DAILY_ISSUANCE.PERMISSIONS,
    },
    component: IssuanceChartPage,
  },
  {
    path: PATH.MY_TEAM,
    name: "salesMyTeam",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.SALES_MY_TEAM.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.SALES_MY_TEAM.PERMISSIONS,
    },
    component: SalesMyTeam,
  },
];

export default routes;
