<template>
  <DigitalPaymentsChart />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DigitalPaymentsChart from "@/modules/Payments/components/DigitalPaymentsChart.vue";

export default defineComponent({
  name: "DigitalPaymentsBySectors",
  components: {
    DigitalPaymentsChart,
  },
});
</script>
