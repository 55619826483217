import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19941cd6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "width-150 text-align-center" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MCombobox = _resolveComponent("MCombobox")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_ShimmerCard = _resolveComponent("ShimmerCard")!
  const _component_ag_agent_info_box = _resolveComponent("ag-agent-info-box")!
  const _component_SnapshotDetailAccordion = _resolveComponent("SnapshotDetailAccordion")!
  const _component_ag_color_chip = _resolveComponent("ag-color-chip")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_ResultNotFound = _resolveComponent("ResultNotFound")!
  const _component_MCard = _resolveComponent("MCard")!

  return (_openBlock(), _createBlock(_component_MCard, { class: "sector-snapshot-header" }, {
    default: _withCtx(() => [
      _createVNode(_component_ag_row, { class: "fields-container" }, {
        default: _withCtx(() => [
          _createVNode(_component_ag_column, {
            sm: "12",
            lg: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MCombobox, {
                inputValue: _ctx.selectedSector,
                "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedSector) = $event)),
                label: "Select Sector",
                itemValue: "value",
                itemLabel: "label",
                options: _ctx.sectors,
                disabled: _ctx.$store.getters.isSalesSectorsFetching
              }, null, 8 /* PROPS */, ["inputValue", "options", "disabled"])
            ]),
            _: 1 /* STABLE */
          }),
          _withDirectives(_createVNode(_component_ag_column, {
            sm: "12",
            lg: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MCombobox, {
                inputValue: _ctx.selectedSalesUser,
                "onUpdate:inputValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedSalesUser) = $event)),
                label: "Sales People",
                itemValue: "value",
                itemLabel: "label",
                options: _ctx.salesUsers
              }, null, 8 /* PROPS */, ["inputValue", "options"])
            ]),
            _: 1 /* STABLE */
          }, 512 /* NEED_PATCH */), [
            [_vShow, _ctx.selectedSector]
          ])
        ]),
        _: 1 /* STABLE */
      }),
      (_ctx.$store.getters.isSalesLoading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_ShimmerCard, { "card-height": "400px" }),
            _createVNode(_component_ShimmerCard, { "card-height": "300px" }),
            _createVNode(_component_ShimmerCard, { "card-height": "200px" })
          ], 64 /* STABLE_FRAGMENT */))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.showDetails)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createCommentVNode(" General Stats Card "),
                  _createVNode(_component_SnapshotDetailAccordion, { title: "General Stats" }, {
                    overview: _withCtx(() => [
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_agent_info_box, {
                            title: "Enabled Websites",
                            icon: "provider",
                            value: _ctx.salesSnapshot?.aggregates?.enabled_website_count
                          }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_agent_info_box, {
                            title: "Disabled Websites",
                            icon: "provider",
                            value: _ctx.salesSnapshot?.aggregates?.disabled_website_count
                          }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_agent_info_box, {
                            title: "Logo Added",
                            icon: "provider",
                            value: _ctx.salesSnapshot?.aggregates?.logo_added_count
                          }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_agent_info_box, {
                            title: "Color Added",
                            icon: "provider",
                            value: _ctx.salesSnapshot?.aggregates?.color_added_count
                          }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_agent_info_box, {
                            title: "Default Color",
                            icon: "provider",
                            value: _ctx.salesSnapshot?.aggregates?.default_color_count
                          }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_agent_info_box, {
                            title: "Default Logo",
                            icon: "provider",
                            value: _ctx.salesSnapshot?.aggregates?.default_logo_count
                          }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createCommentVNode(" Users Card "),
                  _createVNode(_component_SnapshotDetailAccordion, { title: "Users" }, {
                    overview: _withCtx(() => [
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_agent_info_box, {
                            title: "Owners",
                            icon: "customerStatus",
                            value: _ctx.salesSnapshot?.users?.owners
                          }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_agent_info_box, {
                            title: "Employees",
                            icon: "customerStatus",
                            value: _ctx.salesSnapshot?.users?.employees
                          }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_agent_info_box, {
                            title: "Sub Agents",
                            icon: "customerStatus",
                            value: _ctx.salesSnapshot?.users?.sub_agents
                          }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createCommentVNode(" Organization Card "),
                  _createVNode(_component_SnapshotDetailAccordion, { title: "Organizations" }, {
                    "accordion-content": _withCtx(() => [
                      (_ctx.showOrganizationTable)
                        ? (_openBlock(), _createBlock(_component_MDataTable, {
                            key: 0,
                            headers: _ctx.headers,
                            data: _ctx.salesSnapshotOrganizations,
                            "item-per-page": 10,
                            "has-search": true
                          }, {
                            owner_count: _withCtx(({ item }) => [
                              _createElementVNode("div", null, _toDisplayString(item.owner_count), 1 /* TEXT */)
                            ]),
                            sub_agent_count: _withCtx(({ item }) => [
                              _createElementVNode("div", null, _toDisplayString(item.sub_agent_count), 1 /* TEXT */)
                            ]),
                            employee_count: _withCtx(({ item }) => [
                              _createElementVNode("div", null, _toDisplayString(item.employee_count), 1 /* TEXT */)
                            ]),
                            logo: _withCtx(({ item }) => [
                              _createElementVNode("div", _hoisted_1, [
                                _createElementVNode("img", {
                                  width: "60",
                                  src: item.logo
                                }, null, 8 /* PROPS */, _hoisted_2)
                              ])
                            ]),
                            org_color: _withCtx(({ item }) => [
                              _createElementVNode("div", null, [
                                _createVNode(_component_ag_color_chip, {
                                  "color-name": item.org_color,
                                  "color-code": item.org_color
                                }, null, 8 /* PROPS */, ["color-name", "color-code"])
                              ])
                            ]),
                            name: _withCtx(({ item }) => [
                              _createElementVNode("div", {
                                onClick: ($event: any) => (_ctx.handleRowClick($event, item)),
                                class: "name-column organization-link"
                              }, _toDisplayString(item.name), 9 /* TEXT, PROPS */, _hoisted_3)
                            ]),
                            domain_name: _withCtx(({ item }) => [
                              _createElementVNode("div", null, _toDisplayString(item.domain_name ? item.domain_name : "N/A"), 1 /* TEXT */)
                            ]),
                            display_name: _withCtx(({ item }) => [
                              _createElementVNode("div", null, _toDisplayString(item.display_name ? item.display_name : "N/A"), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["headers", "data"]))
                        : (_openBlock(), _createBlock(_component_ResultNotFound, {
                            key: 1,
                            title: "No Organization Found",
                            description: ""
                          }))
                    ]),
                    _: 1 /* STABLE */
                  })
                ], 64 /* STABLE_FRAGMENT */))
              : _createCommentVNode("v-if", true),
            (_ctx.showResultNotFound)
              ? (_openBlock(), _createBlock(_component_ResultNotFound, {
                  key: 1,
                  title: "No Details Found"
                }))
              : _createCommentVNode("v-if", true)
          ], 64 /* STABLE_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }))
}