import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11c81ec4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "accordion-title-container" }
const _hoisted_2 = { class: "accordion-title" }
const _hoisted_3 = { class: "accordion-chip-container" }
const _hoisted_4 = { class: "content-container" }
const _hoisted_5 = { class: "container" }
const _hoisted_6 = { class: "plan_name" }
const _hoisted_7 = { class: "tile-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MChip = _resolveComponent("MChip")!
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MAccordionTile = _resolveComponent("MAccordionTile")!
  const _component_MFabButton = _resolveComponent("MFabButton")!
  const _component_MAccordion = _resolveComponent("MAccordion")!
  const _component_UnlinkFinancialProfileConfirmationDialog = _resolveComponent("UnlinkFinancialProfileConfirmationDialog")!
  const _component_ViewNotInvoicedReceiptsDialog = _resolveComponent("ViewNotInvoicedReceiptsDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MAccordion, {
      "initial-open": !!_ctx.financialProfile.is_default
    }, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.financialProfile.financial_profile_name) + " - " + _toDisplayString(_ctx.financialProfile.platform_id), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_MChip, {
              class: "status-chip",
              variant: _ctx.isActive ? 'success' : 'error'
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.financialProfile.status), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["variant"]),
            (_ctx.financialProfile.is_default)
              ? (_openBlock(), _createBlock(_component_MChip, {
                  key: 0,
                  class: "status-chip",
                  variant: "success"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" DEFAULT ")
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ]),
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_MIcon, {
                type: _ctx.getFPType(_ctx.financialProfile.plan_name),
                class: "details-type-icon icon",
                name: _ctx.getFPIconName(_ctx.financialProfile.plan_name),
                width: "24",
                height: "24"
              }, null, 8 /* PROPS */, ["type", "name"]),
              _createTextVNode(" " + _toDisplayString(_ctx.financialProfile.plan_name.toUpperCase()), 1 /* TEXT */)
            ]),
            (_ctx.enableActions)
              ? (_openBlock(), _createBlock(_component_MButton, {
                  key: 0,
                  onClick: _ctx.onUnLinknBtnClick
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Unlink")
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"]))
              : _createCommentVNode("v-if", true)
          ]),
          _createCommentVNode(" Tiles container "),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_MAccordionTile, {
              title: "Net Available Limit",
              value: 
              _ctx.formatCurrency(
                Number(_ctx.financialProfile.financial_data?.total_balance_current),
                _ctx.financialProfile.financial_data?.currency_code
              )
            
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_MIcon, {
                  name: "m-request-quote",
                  class: "icon white-icon",
                  width: "24",
                  height: "24"
                })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["value"]),
            _createVNode(_component_MAccordionTile, {
              title: "Available Limit",
              value: 
              _ctx.formatCurrency(
                Number(_ctx.financialProfile.financial_data?.available_credit),
                _ctx.financialProfile.financial_data?.currency_code
              )
            
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_MIcon, {
                  class: "icon white-icon",
                  name: "m-wallet",
                  width: "24",
                  height: "24"
                })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["value"]),
            _createVNode(_component_MAccordionTile, {
              title: "Total Not Invoiced Sales",
              value: 
              _ctx.formatCurrency(
                Number(
                  _ctx.financialProfile.financial_data?.total_of_not_invoiced_tickets
                ),
                _ctx.financialProfile.financial_data?.currency_code
              )
            
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_MIcon, {
                  class: "icon white-icon",
                  name: "m-receipt-long",
                  width: "24",
                  height: "24"
                })
              ]),
              button: _withCtx(() => [
                _createVNode(_component_MFabButton, {
                  onClick: _ctx.handleOpenNotInvoicedDialog,
                  type: "outlined",
                  icon: "m-receipt-long"
                }, null, 8 /* PROPS */, ["onClick"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["value"]),
            _createVNode(_component_MAccordionTile, {
              title: "Total Payable Amount",
              value: 
              _ctx.formatCurrency(
                Number(_ctx.financialProfile.financial_data?.current_balance),
                _ctx.financialProfile.financial_data?.currency_code
              )
            
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_MIcon, {
                  class: "icon white-icon",
                  name: "m-credit-score",
                  width: "24",
                  height: "24"
                })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["value"]),
            _createVNode(_component_MAccordionTile, {
              title: "Temporary Credit Limit",
              value: 
              _ctx.formatCurrency(
                Number(_ctx.financialProfile.financial_data?.temporary_credit_limit),
                _ctx.financialProfile.financial_data?.currency_code
              )
            
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_MIcon, {
                  class: "icon white-icon",
                  name: "m-credit-limit-management",
                  width: "24",
                  height: "24"
                })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["value"]),
            _createVNode(_component_MAccordionTile, {
              title: "Permanent Credit Limit",
              value: 
              _ctx.formatCurrency(
                Number(_ctx.financialProfile.financial_data?.credit_limit),
                _ctx.financialProfile.financial_data?.currency_code
              )
            
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_MIcon, {
                  name: "m-request-quote",
                  class: "icon white-icon",
                  width: "24",
                  height: "24"
                })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["value"])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["initial-open"]),
    _createVNode(_component_UnlinkFinancialProfileConfirmationDialog, {
      onHandleClose: _ctx.handleReject,
      onHandleAccept: _ctx.handleApprove,
      financial_profile_name: _ctx.financialProfile?.financial_profile_name,
      "is-open": _ctx.isUnlinkConfirmationDialogOpened
    }, null, 8 /* PROPS */, ["onHandleClose", "onHandleAccept", "financial_profile_name", "is-open"]),
    _createVNode(_component_ViewNotInvoicedReceiptsDialog, {
      "financial-profile": _ctx.financialProfile,
      "is-open": _ctx.isViewNotInvoicedReceiptsDialogOpened,
      onHandleClose: _ctx.handleCloseNotInvoicedDialog
    }, null, 8 /* PROPS */, ["financial-profile", "is-open", "onHandleClose"])
  ], 64 /* STABLE_FRAGMENT */))
}