import { MutationTree } from "vuex";
import { IAuthState } from "@/ag-portal-common/interfaces/authState.interface";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";
import { ISettings } from "@/ag-portal-common/interfaces/settings.interface";
import { IOrganizationPreference } from "@/ag-portal-common/interfaces/organization.interface";
import { PERMISSIONS } from "@/ag-portal-common/enums/PERMISSIONS";

const mutations: MutationTree<IAuthState> = {
  enableIsAuthenticating(state) {
    state.isAuthenticating = true;
  },
  setUser(state, user: IUserV2) {
    state.user = user;
  },
  setPermissions(state, permissions: Array<PERMISSIONS>) {
    state.permissions = permissions;
  },
  setSettings(state, settings: ISettings) {
    state.settings = settings;
  },
  disableIsAuthenticating(state: any) {
    state.isAuthenticating = false;
  },
  saveAuthInfo(state, payload: IAuthState) {
    state.user = payload.user;
    state.settings = payload.settings;
    state.permissions = payload.permissions;
    state.organization_preference = payload.organization_preference;
    state.isUserSignedIn = true;
    state.isAuthenticating = false;
  },
  clearAuthInfo(state) {
    state.user = null;
    state.settings = null;
    state.permissions = null;
    state.isUserSignedIn = false;
  },
  updateOrganizationPreference(state, payload: IOrganizationPreference) {
    state.organization_preference = payload;
  },
};

export default mutations;
