export enum ISSUANCE_LOG_TYPE {
  UNDEFINED = "UNDEFINED",
  FETCHED_SUCCESS = "FETCHED_SUCCESS",
  FETCHED_FAILURE = "FETCHED_FAILURE",
  ISSUANCE_SUCCESS = "ISSUANCE_SUCCESS",
  ISSUANCE_FAILURE = "ISSUANCE_FAILURE",
  PRE_ISSUANCE_FAILURE = "PRE_ISSUANCE_FAILURE",
  POST_ISSUANCE_FAILURE = "POST_ISSUANCE_FAILURE",
}

export const ISSUANCE_LOG_TYPE_SERVERITY = {
  [ISSUANCE_LOG_TYPE.UNDEFINED]: "error",
  [ISSUANCE_LOG_TYPE.FETCHED_SUCCESS]: "info",
  [ISSUANCE_LOG_TYPE.FETCHED_FAILURE]: "error",
  [ISSUANCE_LOG_TYPE.ISSUANCE_SUCCESS]: "success",
  [ISSUANCE_LOG_TYPE.ISSUANCE_FAILURE]: "error",
  [ISSUANCE_LOG_TYPE.PRE_ISSUANCE_FAILURE]: "error",
  [ISSUANCE_LOG_TYPE.POST_ISSUANCE_FAILURE]: "error",
};
