<template>
  <SalesMyTeam />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SalesMyTeam from "./../components/SalesMyTeam.vue";

export default defineComponent({
  name: "SalesMyTeamPage",
  components: {
    SalesMyTeam,
  },
});
</script>
