<template>
  <!-- Loading Shimmer -->
  <OrganizationDetailsShimmer v-if="fetchingPriceDetails" />

  <template v-else>
    <ResultNotFound v-if="errorOrgnatizationNotFound" />

    <div v-if="!!organization">
      <!-- Search Result Accordion -->
      <MAccordion :initial-open="true" v-if="searchedEmail">
        <template v-slot:title>
          <div class="accordion-title-container">
            <span class="accordion-title"
              >Search Results - {{ searchedEmail }}</span
            >
          </div>
        </template>
        <template v-slot:content>
          <div class="content-container">
            <AgentsDataTable
              :organization-id="organization?.public_id"
              :searched-email="searchedEmail"
              :has-pagination="false"
              :has-search="false"
            />
          </div>
        </template>
      </MAccordion>

      <!-- Link Financial Profile -->
      <div class="link-financial-profile-btn-container">
        <MButton
          variant="filled"
          @click="toggleLinkFinancialProfileModal"
          v-if="shouldEnableFinancialActions"
        >
          {{ renderLinkFinancialButtonTitle }}</MButton
        >
      </div>

      <!-- Financial Profile Accordion -->
      <FinancialProfile
        v-for="(financialProfile, index) in $store.getters.organization
          ?.financial_profiles"
        :key="index"
        :financial-profile="financialProfile"
        :enable-actions="shouldEnableFinancialActions"
      />

      <!-- Organization Accordion -->
      <OrganizationDetails
        :organization="$store.getters.organization"
        @refetchOrganizationDetails="handleRefetchOrganizationDetails"
      />

      <!-- Agents Accordion -->
      <MAccordion :initial-open="true">
        <template v-slot:title>
          <div class="accordion-title-container">
            <span class="accordion-title">Agents</span>
          </div>
        </template>
        <template v-slot:content>
          <div class="content-container">
            <AgentsDataTable
              :organization-id="organization?.public_id"
              :has-add-new-agent="true"
            />
          </div>
        </template>
      </MAccordion>
    </div>
  </template>
  <LinkFinancialProfileModal
    :organization-id="organization?.public_id"
    v-if="isLinkFinancialProfileModalOpen"
    :is-open="isLinkFinancialProfileModalOpen"
    @toggle="toggleLinkFinancialProfileModal"
  />
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { MAccordion } from "@aeroglobe/ag-core-ui";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import FinancialProfile from "./FinancialProfile.vue";
import OrganizationDetails from "./OrganizationDetail.vue";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import OrganizationDetailsShimmer from "../OrganizationDetailsShimmer.vue";
import ResultNotFound from "./ResultNotFound.vue";
import AgentsDataTable from "./AgentsTable.vue";
import LinkFinancialProfileModal from "../LinkFinancialProfileModal.vue";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";
import { PATH } from "@/ag-portal-common/constants/path";
import { IOrganizationDetail } from "@/ag-portal-common/interfaces/organizationDetail.interface";

export default defineComponent({
  name: "newOrgDetails",
  components: {
    MAccordion,
    FinancialProfile,
    OrganizationDetails,
    OrganizationDetailsShimmer,
    LinkFinancialProfileModal,
    ResultNotFound,
    AgentsDataTable,
  },
  props: {
    searchedEmail: {
      type: String,
      default: "",
    },
    handleResultNotFoundState: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    financialProfileInput: string | null;
    emailInput: string | null;
    isLinkFinancialProfileModalOpen: boolean;
  } {
    return {
      financialProfileInput: "",
      isLinkFinancialProfileModalOpen: false,
      emailInput: "",
    };
  },
  methods: {
    handleRefetchOrganizationDetails() {
      const isOrganizationDetailsRoute = this.$route.path.startsWith(
        PATH.ORGANIZATION
      );
      if (isOrganizationDetailsRoute) {
        this.$store.dispatch("fetchOrganization", this.$route.params.id);
      } else {
        this.$emit("fetchOrganizationDetails");
      }
    },
    toggleLinkFinancialProfileModal() {
      this.isLinkFinancialProfileModalOpen =
        !this.isLinkFinancialProfileModalOpen;
    },
  },
  computed: {
    financialProfileOptions() {
      const profiles = this.$store.getters
        .financialProfiles as IFinancialProfile[];
      return profiles?.map((fp: IFinancialProfile) => {
        const planType = fp?.plan_name?.split(" ")[1]?.toLowerCase();
        const sector = fp?.sector?.replace(/^Aeroglobe\s*-\s*/, "");
        return {
          id: fp?.platform_id,
          label: fp?.financial_profile_name,
          value: fp?.organization_public_id,
          isActive: fp?.status === ORGANIZATION_STATUSES.ACTIVE,
          status: fp?.status,
          sector: sector,
          type: planType,
        };
      });
    },
    organization(): IOrganizationDetail {
      return this.$store.getters.organization;
    },

    fetchingPriceDetails() {
      return this.$store.getters.isFetchingOrganization;
    },
    isAgent() {
      return this.user().role_unique_id === USER_ROLES.AGENT;
    },
    shouldEnableFinancialActions() {
      return this.user().role_unique_id === USER_ROLES.AG_SUPER_USER;
    },
    renderLinkFinancialButtonTitle() {
      return this.$store.getters.organization?.financial_profiles.length
        ? "Link Another Financial Profile"
        : "Link Financial Profile";
    },
    errorOrgnatizationNotFound(): boolean {
      return (
        !this.$store.getters.isFetchingOrganization &&
        this.$store.getters.errorOrgnatizationNotFound
      );
    },
  },
  setup() {
    const user = inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2;
    return { user };
  },
});
</script>

<style scoped lang="scss">
.accordion-title-container {
  display: flex;
  align-items: center;
}
.accordion-chip-container {
  display: flex;
  gap: 10px;
}
.accordion-title {
  font-size: 20px;
  margin-right: 20px;
  font-weight: 600;
  text-align: start;
}
.content-container {
  padding: 26px 0 0 0;
}

.link-financial-profile-btn-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 767px) {
    justify-content: center;
  }
  button {
    margin: 20px 20px;
  }
}
</style>
