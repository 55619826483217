<template>
  <ShimmerCard card-height="200px" />
  <ShimmerCard card-height="100px" />
  <ShimmerCard card-height="100px" />
  <ShimmerCard card-height="75px" />
</template>
<script lang="ts">
import { defineComponent } from "vue";
import ShimmerCard from "@/components/ShimmerCard.vue";

export default defineComponent({
  name: "OrganizationDetailsSimmer",
  components: { ShimmerCard },
});
</script>
