<template>
  <AGAppContainer class="default-page-color" fullWidth>
    <div class="ag-login-wrapper">
      <div class="ag-login-card">
        <AGPanel>
          <AGLogo :url="urlLogo" is-small />

          <h5>
            Welcome to <span>{{ agencyName }}</span>
          </h5>

          <p>Sign in to your account to continue...</p>

          <form @submit.prevent="onSubmit">
            <AGTextField
              ref="emailRef"
              v-model="loginPayload.email"
              :value="loginPayload.email"
              :testId="genTestId(ELEMENT_TYPES.TEXT_FIELD, 'login-email')"
              type="email"
              name="email"
              label="Email"
              autocomplete="email"
              :error="errors['email']"
            />

            <AGTextField
              ref="passwordRef"
              v-model="loginPayload.password"
              :value="loginPayload.password"
              :testId="genTestId(ELEMENT_TYPES.TEXT_FIELD, 'login-password')"
              :type="showText ? 'text' : 'password'"
              name="password"
              label="Password"
              autocomplete="password"
              :appendIcon="showText ? 'mdi-eye' : 'mdi-eye-off'"
              :error="errors['password']"
              @click:append="showText = !showText"
            />

            <div class="forgot_card">
              <router-link
                :to="path.FORGOT_PASSWORD"
                :testId="genTestId(ELEMENT_TYPES.LINK, 'forgot-password')"
              >
                <AGButton
                  variant="link"
                  :testId="genTestId(ELEMENT_TYPES.BUTTON, 'forgot-password')"
                  >Forgot password
                </AGButton>
              </router-link>
            </div>

            <div class="d-grid">
              <AGButton
                type="submit"
                :testId="genTestId(ELEMENT_TYPES.BUTTON, 'login-submit')"
                :is-loading="isAuthenticating()"
                variant="primary"
                >Log In
              </AGButton>
            </div>
          </form>
        </AGPanel>
      </div>
    </div>
  </AGAppContainer>
</template>

<script lang="ts">
import { CreateComponentPublicInstance, defineComponent, inject } from "vue";
import { PATH } from "@/ag-portal-common/constants/path";
import {
  genTestId,
  parseLocalStorageData,
} from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { IOrganizationPreference } from "@/ag-portal-common/interfaces/organization.interface";
import { STORAGE_KEYS } from "@/ag-portal-common/constants/storageKeys";

import { loginFormValidation } from "../validations/loginForm.validation";
import { yupValidationErrorAsSchema } from "@/ag-portal-common/utils/helpers";
import { ValidationError } from "yup";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";

interface LoginPayload {
  email: string;
  password: string;
}

export default defineComponent({
  name: "LoginView",
  created() {
    this.path = PATH;
    this.onPageTitleUpdateHandler();
  },
  data() {
    return {
      showText: false,
      password: "Password",
      ELEMENT_TYPES,
      path: {} as typeof PATH,
      loginPayload: { email: "", password: "" } as LoginPayload,

      errors: {} as LoginPayload,
    };
  },
  methods: {
    genTestId,
    async onSubmit() {
      this.enableIsAuthenticating();
      this.errors = {} as LoginPayload;

      try {
        const validatedPaylaod = await loginFormValidation.validate(
          {
            email: this.loginPayload.email,
            password: this.loginPayload.password,
          },
          {
            abortEarly: false,
          }
        );

        await this.$store.dispatch("loginUser", {
          payload: validatedPaylaod,
          callback: this.disableIsAuthenticating,
        });
      } catch (ex) {
        if (ex instanceof ValidationError) {
          const _errors = yupValidationErrorAsSchema(ex);
          this.errors = _errors;
        }
        this.disableIsAuthenticating();
      }
    },

    onPageTitleUpdateHandler() {
      const organisationName = this.getOrganisationNameHandler();

      document.title = `Login - ${organisationName}`;
    },
    getOrganisationNameHandler(): string {
      const DEFAULT_NAME = "Aeroglobe";
      const organization = this.organizationPreference();

      if (!organization) {
        return DEFAULT_NAME;
      }

      return (
        organization.display_name ||
        organization.org_name_to_display ||
        this.user()?.full_name ||
        DEFAULT_NAME
      );
    },
  },
  computed: {
    urlLogo() {
      const organizationPreference: IOrganizationPreference | null =
        parseLocalStorageData(STORAGE_KEYS.PREFERENCES);
      return organizationPreference?.logo;
    },
    agencyName() {
      const organizationPreference: IOrganizationPreference | null =
        parseLocalStorageData(STORAGE_KEYS.PREFERENCES);
      return organizationPreference?.display_name
        ? organizationPreference?.display_name
        : "Aeroglobe";
    },
  },
  setup() {
    const isAuthenticating = inject(
      AUTH_CONTEXT_KEYS.isAuthenticating
    ) as () => boolean;

    const enableIsAuthenticating = inject(
      AUTH_CONTEXT_KEYS.enableIsAuthenticating
    ) as () => void;

    const disableIsAuthenticating = inject(
      AUTH_CONTEXT_KEYS.disableIsAuthenticating
    ) as () => void;

    const organizationPreference = inject(
      AUTH_CONTEXT_KEYS.organizationPreference
    ) as () => IOrganizationPreference;

    let user = inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2 | null;

    return {
      isAuthenticating,
      enableIsAuthenticating,
      disableIsAuthenticating,
      organizationPreference,
      user,
    };
  },
  mounted() {
    const textfields: HTMLInputElement[] = [];

    const emailRef = this.$refs
      .emailRef as CreateComponentPublicInstance | null;
    const passwordRef = this.$refs
      .passwordRef as CreateComponentPublicInstance | null;

    if (emailRef) {
      if (emailRef.$el) {
        textfields.push(emailRef.$el);
      }
    }

    if (passwordRef) {
      if (passwordRef.$el) {
        textfields.push(passwordRef.$el);
      }
    }

    if (textfields.length) {
      textfields.forEach((field) => {
        const inputElement = field.querySelector("input");

        if (inputElement) {
          inputElement.setAttribute("autocomplete", inputElement.type);
        }
      });
    }
  },
});
</script>

<style scoped lang="css">
.ag-login-wrapper {
  min-height: 100vh;

  display: grid;
  place-content: center;

  padding: 12px;
}

.ag-login-wrapper .ag-login-card .login_box {
  min-height: 0;
  width: 400px;
}

@media screen and (max-width: 480px) {
  .ag-login-wrapper .ag-login-card .login_box {
    max-width: none;
    width: 100%;
  }
}
</style>
