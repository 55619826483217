export const MY_BOOKING_ANALYTICS_EVENTS = {
  FLIGHTS_TAB_CLICKED: "my-bookings-flights-clicked",
  HOTELS_TAB_CLICKED: "my-bookings-hotels-clicked",
  TOURS_TAB_CLICKED: "my-bookings-tours-clicked",

  FLIGHTS_SEARCH: "my-bookings-flights-search-clicked",
  FLIGHTS_CLEARALL: "my-bookings-flights-clearall-clicked",

  HOTELS_SEARCH: "my-bookings-hotels-search-clicked",
  HOTELS_CLEARALL: "my-bookings-hotels-clearall-clicked",

  TOURS_SEARCH: "my-bookings-tours-search-clicked",
  TOURS_CLEARALL: "my-bookings-tours-clearall-clicked",
};
