<template>
  <AgNotFound
    test-id=""
    heading="No News Found"
    description=""
    v-if="normalNewsResponse.id === null"
  />
  <ag-div v-if="normalNewsResponse.id !== null">
    <ag-card v-for="(normalNews, index) in normalNewsResponse" :key="index">
      <ag-row>
        <ag-column sm="12" md="2" cols="12">
          <ag-image
            class="renderImage"
            :src="normalNews?.featured_image"
            alt="news"
            width="100%"
          ></ag-image>
        </ag-column>
        <ag-column>
          <ag-div class="news-content-box" shaped outlined>
            <ag-row style="justify-content: space-between">
              <ag-heading
                variant="h1"
                :title="normalNews?.news_title"
                class="news-title"
              />
              <ag-div v-if="normalNews?.show_on_banner === true">
                <MButton
                  class="pulse"
                  variant="filled"
                  :disabled="true"
                  style="margin-bottom: 0.5cm"
                  >Featured
                </MButton>
              </ag-div>
            </ag-row>
            <ag-heading
              variant="p"
              :title="normalNews?.news_description"
              class="news-description"
            />
            <a-g-button
              class="news-action-btn"
              @click="onNormalActionButtonClick(normalNews?.action_url)"
            >
              {{ normalNews?.call_to_action_label }}
            </a-g-button>
            <ag-div class="margin_top_10"></ag-div>
          </ag-div>
        </ag-column>
      </ag-row>
    </ag-card>
  </ag-div>
</template>
<script lang="ts">
import { defineComponent, inject } from "vue";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
export default defineComponent({
  name: "NewsView",
  data() {
    return {
      normalNewsResponse: {
        id: null,
        news_title: "",
        news_description: "",
        action_url: "",
        call_to_action_label: "",
        featured_image: "",
        news_type: null,
        show_on_banner: false,
      },
    };
  },
  computed: {
    getNormalNewsResponse() {
      return this.$store.getters.newsData;
    },
  },
  methods: {
    async fecthNormaldNews() {
      try {
        const resp = await this.$store.dispatch("getAllNews");
        this.normalNewsResponse = this.getNormalNewsResponse;
      } catch (error) {
        //
      }
    },
    onNormalActionButtonClick(url: string) {
      if (url?.includes("https://") || url?.includes("http://")) {
        window.open(url);
      } else {
        this.$router.push(url);
      }
    },
    isUserAgent() {
      let user: IUserV2 | null = this.user();
      return user?.role_unique_id === USER_ROLES.AGENT;
    },
  },
  mounted() {
    if (this.isUserAgent()) {
      this.fecthNormaldNews();
    }
  },
  setup() {
    let user = inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2 | null;
    return {
      user,
    };
  },
});
</script>

<style scoped>
@keyframes pulse {
  0% {
    transform: scale(0.7);
    opacity: 1;
  }
  100% {
    transform: scale(0.85);
    opacity: 0.7;
  }
}

.pulse {
  animation: pulse 1.5s infinite;
}
.renderImage {
  max-width: 600px;
  max-height: 600px;
  width: 100%;
  height: auto;
  border-radius: 2%;
}
</style>
