import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0955736d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_calendar = _resolveComponent("ag-calendar")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_ShimmerCard = _resolveComponent("ShimmerCard")!
  const _component_FlightIssuance = _resolveComponent("FlightIssuance")!
  const _component_FlightBookings = _resolveComponent("FlightBookings")!
  const _component_HotelBookings = _resolveComponent("HotelBookings")!
  const _component_SabreSegments = _resolveComponent("SabreSegments")!
  const _component_Payments = _resolveComponent("Payments")!
  const _component_CreditLimit = _resolveComponent("CreditLimit")!
  const _component_ResultNotFound = _resolveComponent("ResultNotFound")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MCard, { class: "sector-snapshot-header" }, {
      default: _withCtx(() => [
        _createElementVNode("p", _hoisted_1, "Statistics " + _toDisplayString(_ctx.selectedSalesman), 1 /* TEXT */),
        _createVNode(_component_ag_row, { class: "mt-10" }, {
          default: _withCtx(() => [
            _createVNode(_component_ag_column, {
              xs: "12",
              sm: "12",
              md: "12",
              lg: "5"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ag_calendar, {
                  label: "Start Date",
                  "onUpdate:startDate": _cache[0] || (_cache[0] = (value) => _ctx.startDate = value),
                  "calendar-name": "startDate",
                  "max-date": new Date(),
                  date: _ctx.startDate ? _ctx.startDate : new Date()
                }, null, 8 /* PROPS */, ["max-date", "date"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_ag_column, {
              xs: "12",
              sm: "12",
              md: "12",
              lg: "5"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ag_calendar, {
                  label: "End Date",
                  "onUpdate:endDate": _cache[1] || (_cache[1] = (value) => _ctx.endDate = value),
                  "calendar-name": "endDate",
                  "max-date": new Date(),
                  "min-date": _ctx.startDate,
                  date: _ctx.endDate ? _ctx.endDate : new Date()
                }, null, 8 /* PROPS */, ["max-date", "min-date", "date"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_ag_column, {
              xs: "12",
              sm: "12",
              md: "12",
              lg: "2",
              class: "d-flex align-items-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MButton, {
                  onClick: _ctx.handleFetchData,
                  disabled: _ctx.isSalesLoading,
                  "test-id": "",
                  variant: "primary",
                  class: "fire_btn_wrap"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Fetch Statistics")
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick", "disabled"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }),
    (_ctx.isSalesLoading)
      ? (_openBlock(), _createBlock(_component_MCard, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_ShimmerCard, { "card-height": "200px" }),
            _createVNode(_component_ShimmerCard, { "card-height": "200px" }),
            _createVNode(_component_ShimmerCard, { "card-height": "150px" }),
            _createVNode(_component_ShimmerCard, { "card-height": "100px" })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    (_ctx.showDetails)
      ? (_openBlock(), _createBlock(_component_MCard, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_FlightIssuance, { handleOrganizationRowClick: _ctx.handleRowClick }, null, 8 /* PROPS */, ["handleOrganizationRowClick"]),
            _createVNode(_component_FlightBookings, { handleOrganizationRowClick: _ctx.handleRowClick }, null, 8 /* PROPS */, ["handleOrganizationRowClick"]),
            _createVNode(_component_HotelBookings, { handleOrganizationRowClick: _ctx.handleRowClick }, null, 8 /* PROPS */, ["handleOrganizationRowClick"]),
            _createVNode(_component_SabreSegments, { handleOrganizationRowClick: _ctx.handleRowClick }, null, 8 /* PROPS */, ["handleOrganizationRowClick"]),
            _createVNode(_component_Payments, { handleOrganizationRowClick: _ctx.handleRowClick }, null, 8 /* PROPS */, ["handleOrganizationRowClick"]),
            _createVNode(_component_CreditLimit, { handleOrganizationRowClick: _ctx.handleRowClick }, null, 8 /* PROPS */, ["handleOrganizationRowClick"])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    (_ctx.showResultNotFound)
      ? (_openBlock(), _createBlock(_component_ResultNotFound, { key: 2 }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}