<template>
  <form @submit="onSubmit">
    <ag-card>
      <ag-heading variant="h3" title="Update Preferences"></ag-heading>
      <ag-heading variant="p" title="Update your preference here"></ag-heading>
      <hr />
      <ag-row>
        <ag-column xs="12" md="6" cols="12">
          <ag-column class="preferences_right_border">
            <ag-upload
              :testId="
                genTestId(
                  ELEMENT_TYPES.FILE_UPLOAD_FIELD,
                  'organization-preference-logo'
                )
              "
              accept=".jpg, .jpeg, .png"
              @onFileChange="onLogoChange"
              @onFileRemove="onLogoRemove"
            />
            <ag-heading
              variant="h3"
              title="Travel Agency Name"
              class="margin_bottom_30"
            ></ag-heading>
            <AGTextField
              v-model="displayName"
              :value="displayName"
              :testId="
                genTestId(
                  ELEMENT_TYPES.TEXT_FIELD,
                  'organization-preference-displayName'
                )
              "
              type="text"
              label="Display Name"
              :onkeyup="onDisplayNameChange"
            />
            <ag-heading variant="h3" title="Choose Theme"></ag-heading>
            <ag-card class="chip_wrapper">
              <ag-color-chip
                v-for="(color, index) in colorList"
                v-bind:key="index"
                :color-name="color.name"
                :testId="
                  genTestId(
                    ELEMENT_TYPES.BUTTON,
                    `organization-preference-color-${color.code}`
                  )
                "
                :color-code="color.code"
                :is-active="color.code === primaryColor.code"
                @onClick="onColorChange(color)"
              />
            </ag-card>
          </ag-column>
        </ag-column>
        <ag-column xs="12" md="6" cols="12">
          <ag-column class="preview_box">
            <ag-heading variant="h3" title="Live Preview"></ag-heading>
            <booking-template
              :logo="logo.url"
              :organization-name="orgName"
              :default-color="primaryColor.code"
            />
          </ag-column>
        </ag-column>
      </ag-row>
      <ag-column class="bottom_inline_btn">
        <MButton
          type="text"
          :testId="
            genTestId(ELEMENT_TYPES.BUTTON, 'organization-preference-cancel')
          "
          @click="onCancelClick"
          :disabled="isUpdateButtonDisabled"
          >Cancel
        </MButton>
        <MButton
          type="filled"
          behaviour="submit"
          :testId="
            genTestId(
              ELEMENT_TYPES.TEXT_FIELD,
              'organization-preference-submit'
            )
          "
          :disabled="isUpdateButtonDisabled"
          :is-loading="$store.getters.isUpdatingOrganization"
          >Update
        </MButton>
      </ag-column>
    </ag-card>
  </form>
</template>

<script>
import { ORGANIZATION_COLORS } from "@/modules/Organization/constants";
import BookingTemplate from "@/modules/Organization/components/BookingTemplate.vue";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { inject } from "vue";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import analyticsService from "@/analytic.service";
import { ORGANIZATION_ANALYTICS_EVENTS } from "@/modules/Organization/constants/analyticsEvents";

export default {
  name: "OrganizationPreference",
  components: {
    BookingTemplate,
  },
  methods: {
    genTestId,
    updateDataAsStateData() {
      this.logo = {
        url: this.initialOrgLogo,
        file: null,
      };
      this.displayName = this.initialOrgName;
      this.primaryColor = this.initialOrgPrimaryColor;
    },
    onLogoChange(files) {
      const image = new Image();
      image.src = URL.createObjectURL(files[0]);
      image.onload = () => {
        if (!(image.width === 600 && image.height === 300)) {
          this.onLogoRemove();
          notificationService.type = NOTIFICATION_TYPES.ERROR;
          notificationService.description =
            "Image Dimensions must be 600 x 300, Please resize your image accordingly.";
          notificationService.triggerNotification();
          return;
        }
        this.logo = {
          url: URL.createObjectURL(files[0]),
          file: files[0],
        };
      };
    },
    onLogoRemove() {
      this.logo = {
        url: this.initialOrgLogo,
        file: null,
      };
    },
    onDisplayNameChange(event) {
      this.displayName = event.target.value;
    },
    onColorChange(selectedColor) {
      this.primaryColor = selectedColor;
    },
    onCancelClick() {
      this.updateDataAsStateData();

      const analyticsPayload = {
        "cancel-update": true,
      };

      analyticsService.logActionEvent(
        ORGANIZATION_ANALYTICS_EVENTS.PREFERENCES_CANCEL,
        analyticsPayload
      );
    },
    onSubmit(event) {
      event.preventDefault();
      const request = {};
      if (this.isDisplayNameChanged)
        request.displayName = this.displayName.trim();
      if (this.isPrimaryColorChanged)
        request.defaultColor = this.primaryColor.code;
      if (this.logo.file) request.logoFile = this.logo.file;
      if (Object.keys(request).length === 0) return;

      const analyticsPayload = {
        "organisation-name": request.displayName,
        "organisation-color": request.defaultColor,
        "organisation-filename": request.logoFile
          ? "new logo added"
          : "no logo added",
      };

      analyticsService.logActionEvent(
        ORGANIZATION_ANALYTICS_EVENTS.PREFERENCES_UPDATE,
        analyticsPayload
      );

      this.$store.dispatch("updateOrganizationPreference", {
        payload: request,
        organizationId: this.organizationPreference()?.organization_id,
      });
    },
  },
  computed: {
    initialOrgPreference() {
      let organizationPreference = this.organizationPreference();
      return organizationPreference;
    },
    initialOrgLogo() {
      return this.initialOrgPreference?.logo || null;
    },
    initialOrgName() {
      return this.initialOrgPreference?.display_name || "";
    },
    initialOrgPrimaryColor() {
      if (this.initialOrgPreference?.default_color) {
        const foundColor = ORGANIZATION_COLORS.find(
          ({ code }) => code === this.initialOrgPreference?.default_color
        );
        return foundColor || ORGANIZATION_COLORS[0];
      }
      return ORGANIZATION_COLORS[0];
    },
    orgName() {
      if (this.displayName) return this.displayName;
      if (this.initialOrgPreference?.display_name)
        return this.initialOrgPreference.display_name;
      if (this.initialOrgPreference?.org_name_to_display)
        return this.initialOrgPreference.org_name_to_display;
      return "-";
    },
    colorList() {
      return ORGANIZATION_COLORS;
    },
    isDisplayNameChanged() {
      const newDisplayName = this.displayName.trim();
      if (newDisplayName === "") return false;
      return newDisplayName !== this.initialOrgName;
    },
    isPrimaryColorChanged() {
      return this.primaryColor.code !== this.initialOrgPrimaryColor.code;
    },
    isUpdateButtonDisabled() {
      const logoChanged = this.logo.file !== null;
      const preferencesChanged = !(
        logoChanged ||
        this.isDisplayNameChanged ||
        this.isPrimaryColorChanged
      );
      return preferencesChanged || this.$store.getters.isUpdatingOrganization;
    },
  },
  data() {
    return {
      logo: {
        url: null,
        file: null,
      },
      ELEMENT_TYPES,
      displayName: "",
      primaryColor: { code: "", name: "" },
    };
  },
  created() {
    this.updateDataAsStateData();
  },
  watch: {
    "$store.state.authModule.organization_preference": function () {
      this.logo = {
        url: this.initialOrgLogo,
        file: null,
      };
    },
  },
  setup() {
    const organizationPreference = inject(
      AUTH_CONTEXT_KEYS.organizationPreference
    );
    return { organizationPreference };
  },
};
</script>
