import * as yup from "yup";

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export const passwordChangeValidation = yup.object().shape({
  current: yup.string().required("Current Password is required"),
  newPass: yup
    .string()
    .matches(
      passwordRegex,
      "Must contain 8+ characters, 1 uppercase, 1 lowercase, 1 special, 1 number"
    )
    .notOneOf([yup.ref("current")], "New Password can't be same as old")
    .required("New Password is required"),
  confirm: yup
    .string()
    .oneOf([yup.ref("newPass"), null], "Passwords must match")
    .required("Confirm Password is required"),
});
