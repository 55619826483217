import { StatusCodes } from "http-status-codes";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import AuthService from "@/modules/Auth/services/auth.service";
import { AUTH_EVENTS, authBus } from "@/ag-portal-common/eventBusses/auth";
import {
  ChangePasswordDto,
  ForgotPasswordDto,
  LoginDto,
  SignupDto,
  ResetPasswordDto,
} from "@/modules/Auth/dtos/auth.dto";
import { ChangeNumberDto } from "@/modules/Profile/dtos/auth.dto";
import { ActionContext, ActionTree } from "vuex";
import { IAuthState } from "@/ag-portal-common/interfaces/authState.interface";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import {
  NOTIFICATION_EVENTS,
  notificationBus,
} from "@/ag-portal-common/eventBusses/notification";
import { INotification } from "@/ag-portal-common/interfaces/notification.interface";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import analyticsService from "@/analytic.service";
import { ANALYTICS_EVENTS } from "@/modules/Auth/constants/analyticsEvents";
import notificationService from "@/ag-portal-common/services/notification.service";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";

const actions: ActionTree<IAuthState, IAuthState> = {
  async loginUser(
    context: ActionContext<IAuthState, IAuthState>,
    { payload, callback }: { payload: LoginDto; callback: () => void }
  ) {
    let response: IAGResponse<any> = {
      success: false,
    };
    const notificationData: INotification = {
      type: NOTIFICATION_TYPES.INFO,
      description: "",
    };
    const methodName = "actions.loginUser";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const authService = new AuthService();
      response = await authService.login(payload);
      if (response.success && response.status === StatusCodes.OK) {
        localStorage.setItem(
          "currency",
          response.data.organization?.preferences?.ag_preferences
            ?.default_currency
        );
        localStorage.setItem(
          "sector",
          response?.data?.organization?.sector?.sector_name
        );
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);

        authBus.emit(AUTH_EVENTS.LOGIN, response?.data);

        analyticsService.setUser(response?.data.user);
        analyticsService.logActionEvent(ANALYTICS_EVENTS.LOGIN, {
          email: response?.data?.user?.email,
          role: response?.data?.user?.role_name,
          sector: response?.data?.organization?.sector?.sector_name,
        });

        const user = response?.data.user as IUserV2;
        const isAgentUser = user?.role_unique_id === USER_ROLES.AGENT;

        if (isAgentUser) {
          const agentId = (user as any).agent.agent_id as string;

          context.dispatch("fetchPreferences", {
            body: payload,
            agentId,
          });
        }
      } else {
        throw response;
      }
    } catch (e) {
      notificationData.type = NOTIFICATION_TYPES.ERROR;
      notificationData.description =
        response?.error || response.message || NOTIFICATION_MESSAGES.DEFAULT;
      notificationBus.emit(NOTIFICATION_EVENTS.TRIGGER, notificationData);
      loggerService.logError(`${methodName}:`, e);
    }
    callback();
  },

  async signup(
    context: ActionContext<IAuthState, IAuthState>,
    { payload, callback }: { payload: SignupDto; callback: () => void }
  ) {
    let response: IAGResponse<any> = {
      success: false,
    };
    context.commit("enableIsAuthenticating");
    const notificationData: INotification = {
      type: NOTIFICATION_TYPES.INFO,
      description: "",
    };
    const methodName = "actions.signup";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const authService = new AuthService();
      response = await authService.signup(payload);
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        analyticsService.logActionEvent(ANALYTICS_EVENTS.SIGNUP, payload);
        callback();
      } else {
        throw response;
      }
    } catch (e) {
      notificationData.type = NOTIFICATION_TYPES.ERROR;
      notificationData.description =
        response?.error || response.message || NOTIFICATION_MESSAGES.DEFAULT;
      notificationBus.emit(NOTIFICATION_EVENTS.TRIGGER, notificationData);
      loggerService.logError(`${methodName}:`, e);
    } finally {
      context.commit("disableIsAuthenticating");
    }
  },

  async forgotPassword(
    context: ActionContext<IAuthState, IAuthState>,
    payload: { body: ForgotPasswordDto; callback: () => void }
  ) {
    let response: IAGResponse<any> = {
      success: false,
    };
    const { body, callback } = payload;
    try {
      if (body.email) {
        const authService = new AuthService();
        response = await authService.forgotPassword(body);

        if (response.success) {
          notificationService.type = NOTIFICATION_TYPES.SUCCESS;
          notificationService.description =
            response.message || NOTIFICATION_MESSAGES.FORGOT_PASSWORD_SUCCESS;
        } else {
          notificationService.type = NOTIFICATION_TYPES.ERROR;
          notificationService.description =
            response?.error ||
            response?.message ||
            NOTIFICATION_MESSAGES.DEFAULT;
        }
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = NOTIFICATION_MESSAGES.INVALID_EMAIL;
      }
    } catch (e) {
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        response?.error || response?.message || NOTIFICATION_MESSAGES.DEFAULT;
    }
    callback();
    notificationService.triggerNotification();
  },

  async resetPassword(
    context: ActionContext<IAuthState, IAuthState>,
    payload: {
      body: ResetPasswordDto;
      sucessCallback: () => void;
      failureCallback: () => void;
    }
  ) {
    let response: IAGResponse<any> = {
      success: false,
    };
    const { body, sucessCallback } = payload;
    try {
      const authService = new AuthService();
      response = await authService.resetPassword(body);

      if (response.success) {
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          NOTIFICATION_MESSAGES.RESET_PASSWORD_SUCCESS;
        sucessCallback();
        notificationService.triggerNotification();
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response?.error || response?.message || NOTIFICATION_MESSAGES.DEFAULT;
        payload.failureCallback();
        notificationService.triggerNotification();
      }
    } catch (e) {
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        response?.error || response?.message || NOTIFICATION_MESSAGES.DEFAULT;
      payload.failureCallback();
      notificationService.triggerNotification();
    }
  },

  async changePassword(
    context: ActionContext<IAuthState, IAuthState>,
    payload: ChangePasswordDto
  ) {
    let response: IAGResponse<any> = {
      success: false,
    };
    try {
      const authService = new AuthService();
      response = await authService.changePassword(payload);

      if (response.success) {
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          NOTIFICATION_MESSAGES.RESET_PASSWORD_SUCCESS;
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response?.error || response.message || NOTIFICATION_MESSAGES.DEFAULT;
      }
    } catch (e) {
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        response?.error || response.message || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      notificationService.triggerNotification();
    }
  },
  async changeNumber(
    context: ActionContext<IAuthState, IAuthState>,
    payload: {
      body: ChangeNumberDto;
      successCallback: () => void;
      failureCallback: () => void;
    }
  ) {
    let response: IAGResponse<any> = {
      success: false,
    };
    const { body, successCallback, failureCallback } = payload;
    try {
      const authService = new AuthService();
      response = await authService.changeNumber(body);

      if (response.success) {
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          NOTIFICATION_MESSAGES.CHANGE_NUMBER_SUCCESS;
        successCallback();
        notificationService.triggerNotification();
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.message || NOTIFICATION_MESSAGES.DEFAULT;
        failureCallback();
        notificationService.triggerNotification();
      }
    } catch (e) {
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        response.error || NOTIFICATION_MESSAGES.DEFAULT;
      failureCallback();
      notificationService.triggerNotification();
    }
  },
};

export default actions;
