import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50e7f216"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "date-select-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_AgFile = _resolveComponent("AgFile")!
  const _component_AGTextField = _resolveComponent("AGTextField")!
  const _component_ag_calendar = _resolveComponent("ag-calendar")!
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createBlock(_component_ag_modal, {
    "test-id": "",
    "is-open": _ctx.isOpen,
    onClose: _ctx.closeModal,
    "modal-width": "40%"
  }, {
    header: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.isEdit ? "Edit" : "Add") + " Package", 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_AGButton, {
        "test-id": "",
        type: "button",
        class: "modal_close_icon",
        variant: "link",
        onClick: _ctx.closeModal
      }, {
        default: _withCtx(() => [
          _createTextVNode("Close ")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ]),
    body: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
      }, [
        _createVNode(_component_v_switch, {
          "test-id": "",
          "hide-details": "",
          inset: "",
          color: "primary",
          modelValue: _ctx.active,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.active) = $event)),
          value: true,
          label: _ctx.renderPackageActiveLabel
        }, null, 8 /* PROPS */, ["modelValue", "label"]),
        _createVNode(_component_AgFile, {
          "test-id": "",
          class: "ag-file-theme",
          label: "Package Image",
          multiple: true,
          "onOn:change": _ctx.onFileChangeHandler
        }, null, 8 /* PROPS */, ["onOn:change"]),
        _createVNode(_component_AGTextField, {
          "test-id": _ctx.genTestId(_ctx.ELEMENT_TYPES.TEXT_FIELD, 'package-name'),
          modelValue: _ctx.name,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.name) = $event)),
          value: _ctx.name,
          type: "text",
          label: "Package Name",
          error: _ctx.errors?.name
        }, null, 8 /* PROPS */, ["test-id", "modelValue", "value", "error"]),
        _createVNode(_component_AGTextField, {
          "test-id": _ctx.genTestId(_ctx.ELEMENT_TYPES.TEXT_FIELD, 'package-description'),
          modelValue: _ctx.description,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.description) = $event)),
          value: _ctx.description,
          type: "text",
          label: "Description",
          error: _ctx.errors?.description
        }, null, 8 /* PROPS */, ["test-id", "modelValue", "value", "error"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_ag_calendar, {
            class: "date-select",
            "test-id": 
              _ctx.genTestId(_ctx.ELEMENT_TYPES.CALENDAR_FIELD, 'package-start-date')
            ,
            label: "Start Date",
            "label-variant": "float",
            variant: "outlined",
            modelValue: _ctx.start_date,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.start_date) = $event)),
            date: _ctx.start_date,
            "min-date": _ctx.min_date,
            error: _ctx.errors?.start_date,
            "onUpdate:startDate": _ctx.changeStartDate,
            "calendar-name": "startDate"
          }, null, 8 /* PROPS */, ["test-id", "modelValue", "date", "min-date", "error", "onUpdate:startDate"]),
          _createVNode(_component_ag_calendar, {
            class: "date-select",
            "test-id": 
              _ctx.genTestId(_ctx.ELEMENT_TYPES.CALENDAR_FIELD, 'package-end-date')
            ,
            label: "End Date",
            "label-variant": "float",
            variant: "outlined",
            modelValue: _ctx.end_date,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.end_date) = $event)),
            date: _ctx.end_date,
            "min-date": _ctx.min_date,
            error: _ctx.errors?.end_date,
            "onUpdate:endDate": _ctx.changeEndDate,
            "calendar-name": "endDate"
          }, null, 8 /* PROPS */, ["test-id", "modelValue", "date", "min-date", "error", "onUpdate:endDate"])
        ]),
        _createVNode(_component_AGTextField, {
          "test-id": _ctx.genTestId(_ctx.ELEMENT_TYPES.TEXT_FIELD, 'package-price'),
          modelValue: _ctx.price,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.price) = $event)),
          value: _ctx.price,
          type: "text",
          label: "Price",
          error: _ctx.errors?.price
        }, null, 8 /* PROPS */, ["test-id", "modelValue", "value", "error"]),
        _createVNode(_component_AGButton, {
          "test-id": "",
          type: "submit",
          class: "submit-btn",
          disabled: _ctx.isLoading
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.isEdit ? "Save" : "Add"), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled"])
      ], 32 /* NEED_HYDRATION */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-open", "onClose"]))
}