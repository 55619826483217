import { IAgentState } from "@/interfaces/agentState.interface";

const agentState: IAgentState = {
  agents: [],
  isFetchingAgents: false,
  organization_agents: [],
  isFetchingOrganizationAgents: false,
  isFetchingAgent: false,
  agent: null,
  isUpdatingAgentPermissions: false,
};

export default agentState;
