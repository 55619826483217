<template>
  <div class="notfound-container">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="76"
      viewBox="0 0 76 76"
      fill="none"
    >
      <rect width="76" height="76" rx="38" fill="#CAC4D0" fill-opacity="0.46" />
      <path
        d="M44.9 44.1L47.1 42L42.9 37.8C43.2667 37.2333 43.5417 36.6333 43.725 36C43.9083 35.3667 44 34.7 44 34C44 32.0333 43.3083 30.375 41.925 29.025C40.5417 27.675 38.9 27 37 27C35.1 27 33.4583 27.675 32.075 29.025C30.6917 30.375 30 32.0333 30 34C30 35.9667 30.6917 37.625 32.075 38.975C33.4583 40.325 35.1 41 37 41C37.7 41 38.3583 40.9083 38.975 40.725C39.5917 40.5417 40.2 40.2667 40.8 39.9L44.9 44.1ZM37 38C35.9 38 34.9583 37.6083 34.175 36.825C33.3917 36.0417 33 35.1 33 34C33 32.9 33.3917 31.9583 34.175 31.175C34.9583 30.3917 35.9 30 37 30C38.0667 30 39 30.3917 39.8 31.175C40.6 31.9583 41 32.9 41 34C41 35.1 40.6083 36.0417 39.825 36.825C39.0417 37.6083 38.1 38 37 38ZM22 50C20.9 50 19.9583 49.6083 19.175 48.825C18.3917 48.0417 18 47.1 18 46V24C18 22.9 18.3917 21.9583 19.175 21.175C19.9583 20.3917 20.9 20 22 20H54C55.1 20 56.0417 20.3917 56.825 21.175C57.6083 21.9583 58 22.9 58 24V46C58 47.1 57.6083 48.0417 56.825 48.825C56.0417 49.6083 55.1 50 54 50H22ZM16 56V52H60V56H16Z"
        fill="#49454F"
      />
    </svg>
    <span class="title">No results found!</span>
    <span class="sub-title">Please try a different search</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ResultNotFound",
});
</script>

<style lang="scss" scoped>
.notfound-container {
  width: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto auto;
  .title {
    margin-top: 10px;
    font-weight: 600;
  }
  .sub-title {
    font-size: 14px;
  }
}
</style>
