import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_AGTextField = _resolveComponent("AGTextField")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_AgTextFieldDropDownItem = _resolveComponent("AgTextFieldDropDownItem")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_ag_card = _resolveComponent("ag-card")!
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_ag_table = _resolveComponent("ag-table")!
  const _component_ag_accordion_panel = _resolveComponent("ag-accordion-panel")!
  const _component_ag_accordion = _resolveComponent("ag-accordion")!
  const _component_ag_agent_info_box = _resolveComponent("ag-agent-info-box")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_AgNotFound = _resolveComponent("AgNotFound")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ag_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_ag_heading, {
          variant: "h2",
          class: "margin_bottom_10",
          title: "Search for Sabre Details"
        }),
        _createVNode(_component_ag_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_ag_column, {
              sm: "12",
              lg: "3"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AGTextField, {
                  name: "pnr",
                  label: "PNR",
                  modelValue: _ctx.inputPNR,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputPNR) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_ag_column, {
              sm: "12",
              lg: "3"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AGTextField, {
                  value: _ctx.inputAirLineCode,
                  modelValue: _ctx.inputAirLineCode,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputAirLineCode) = $event)),
                  type: "text",
                  icon: "calendar",
                  items: _ctx.sabreAirlinesList,
                  label: "Airline Code"
                }, {
                  dropdownItem: _withCtx(({ itemProps, itemData }) => [
                    _createVNode(_component_AgTextFieldDropDownItem, _normalizeProps(_guardReactiveProps(itemProps)), {
                      default: _withCtx(() => [
                        _createElementVNode("p", null, _toDisplayString(itemData), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["value", "modelValue", "items"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_ag_column, {
              sm: "12",
              lg: "3"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MButton, {
                  type: "filled",
                  onClick: _ctx.buttonClick,
                  disabled: _ctx.isLoading
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Get Fare")
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick", "disabled"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }),
    _withDirectives(_createVNode(_component_ag_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_AgIconInfoBar, {
          "test-id": "",
          icon: "contactTravelerIcon",
          title: "Passenger Information"
        }),
        _createVNode(_component_ag_accordion, {
          class: "flight_accordion_wrap margin_bottom_0",
          "panel-value": [0]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ag_accordion_panel, null, {
              tabSection: _withCtx(() => [
                _createVNode(_component_ag_table, {
                  headers: _ctx.headers,
                  items: _ctx.Response.passengers,
                  "has-pagination": false,
                  "items-per-page": 50,
                  "has-search": false
                }, {
                  "col-title": _withCtx(({ item }) => [
                    _createVNode(_component_ag_div, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.title), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  "col-first_name": _withCtx(({ item }) => [
                    _createVNode(_component_ag_div, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.first_name), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  "col-last_name": _withCtx(({ item }) => [
                    _createVNode(_component_ag_div, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.last_name), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  "col-passenger_type": _withCtx(({ item }) => [
                    _createVNode(_component_ag_div, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.passenger_type), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  "col-name_type": _withCtx(({ item }) => [
                    _createVNode(_component_ag_div, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.name_type), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["headers", "items"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 512 /* NEED_PATCH */), [
      [_vShow, _ctx.Response]
    ]),
    _withDirectives(_createVNode(_component_ag_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_AgIconInfoBar, {
          "test-id": "",
          icon: "userType",
          title: "Old Price Quotes"
        }),
        _createVNode(_component_ag_accordion, {
          class: "flight_accordion_wrap margin_bottom_0",
          "panel-value": [1]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ag_accordion_panel, null, {
              tabSection: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Response.old_price_quotes, (priceQuote, index) => {
                  return (_openBlock(), _createBlock(_component_ag_div, { key: index }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ag_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_accordion, {
                            class: "flight_accordion_wrap margin_bottom_0",
                            "panel-value": [0]
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ag_accordion_panel, null, {
                                tabSection: _withCtx(() => [
                                  _createVNode(_component_ag_row, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ag_agent_info_box, {
                                        style: {"margin-right":"0.3cm","padding-right":"0.3cm"},
                                        class: "no_border",
                                        "test-id": "",
                                        icon: "contactTravelerIcon",
                                        title: "Passenger Type",
                                        value: priceQuote.passenger_type
                                      }, null, 8 /* PROPS */, ["value"]),
                                      _createVNode(_component_ag_agent_info_box, {
                                        class: "no_border",
                                        style: {"margin-right":"0.3cm","padding-right":"0.3cm"},
                                        "test-id": "",
                                        icon: "currentBalance",
                                        title: "Base Fare",
                                        value: 
                          _ctx.formatNumber(priceQuote.price.base_fare) +
                          ' ' +
                          priceQuote.price_per_passenger.currency_code
                        
                                      }, null, 8 /* PROPS */, ["value"]),
                                      _createVNode(_component_ag_agent_info_box, {
                                        class: "no_border",
                                        style: {"margin-right":"0.3cm","padding-right":"0.3cm"},
                                        "test-id": "",
                                        icon: "notInvoice",
                                        title: "Total Tax",
                                        value: 
                          _ctx.formatNumber(priceQuote.price.total_tax) +
                          ' ' +
                          priceQuote.price_per_passenger.currency_code
                        
                                      }, null, 8 /* PROPS */, ["value"]),
                                      _createVNode(_component_ag_agent_info_box, {
                                        class: "no_border",
                                        style: {"margin-right":"0.3cm","padding-right":"0.3cm"},
                                        "test-id": "",
                                        icon: "tempCreditLimit",
                                        title: "Fees",
                                        value: 
                          _ctx.formatNumber(priceQuote.price.fees) +
                          ' ' +
                          priceQuote.price_per_passenger.currency_code
                        
                                      }, null, 8 /* PROPS */, ["value"]),
                                      _createVNode(_component_ag_agent_info_box, {
                                        class: "no_border",
                                        style: {"margin-right":"0.3cm","padding-right":"0.3cm"},
                                        "test-id": "",
                                        icon: "permanentCredit",
                                        title: "Surcharges",
                                        value: 
                          _ctx.formatNumber(priceQuote.price.surcharges) +
                          ' ' +
                          priceQuote.price_per_passenger.currency_code
                        
                                      }, null, 8 /* PROPS */, ["value"]),
                                      _createVNode(_component_ag_agent_info_box, {
                                        class: "no_border",
                                        style: {"margin-right":"0.3cm","padding-right":"0.3cm"},
                                        "test-id": "",
                                        icon: "userType",
                                        title: "Gross Fare",
                                        value: 
                          _ctx.formatNumber(priceQuote.price.gross_fare) +
                          ' ' +
                          priceQuote.price_per_passenger.currency_code
                        
                                      }, null, 8 /* PROPS */, ["value"])
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1024 /* DYNAMIC_SLOTS */),
                                  _createVNode(_component_ag_table, {
                                    headers: _ctx.oldPriceQuotesTable,
                                    items: priceQuote.price.tax_list,
                                    "has-pagination": false,
                                    "items-per-page": priceQuote.price.tax_list.length,
                                    "has-search": false
                                  }, {
                                    "col-code": _withCtx(({ item }) => [
                                      _createVNode(_component_ag_div, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(item.raw.code), 1 /* TEXT */)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1024 /* DYNAMIC_SLOTS */)
                                    ]),
                                    "col-amount": _withCtx(({ item }) => [
                                      _createVNode(_component_ag_div, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.formatNumber(item.raw.amount)) + " " + _toDisplayString(item.raw.currency_code), 1 /* TEXT */)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1024 /* DYNAMIC_SLOTS */)
                                    ]),
                                    "col-taxName": _withCtx(({ item }) => [
                                      _createVNode(_component_ag_div, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(item.raw.tax_name !== null ? item.raw.tax_name : "-"), 1 /* TEXT */)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1024 /* DYNAMIC_SLOTS */)
                                    ]),
                                    "col-ticketing_tax_code": _withCtx(({ item }) => [
                                      _createVNode(_component_ag_div, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(item.raw.ticketing_tax_code !== null
                              ? item.raw.ticketing_tax_code
                              : "-"), 1 /* TEXT */)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1024 /* DYNAMIC_SLOTS */)
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["headers", "items", "items-per-page"]),
                                  _createVNode(_component_ag_div, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ag_row, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_AgIconInfoBar, {
                                            "test-id": "",
                                            icon: "contactTravelerIcon",
                                            title: 
                            'Price Per Passenger (passenger count: ' +
                            priceQuote.passenger_count +
                            ')'
                          ,
                                            style: {"margin-top":"0.5cm"}
                                          }, null, 8 /* PROPS */, ["title"])
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1024 /* DYNAMIC_SLOTS */),
                                      _createVNode(_component_ag_row, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ag_agent_info_box, {
                                            class: "no_border",
                                            style: {"margin-right":"0.3cm","padding-right":"0.3cm"},
                                            "test-id": "",
                                            icon: "currentBalance",
                                            title: "Base Fare",
                                            value: 
                            _ctx.formatNumber(
                              priceQuote.price_per_passenger.base_fare
                            ) +
                            ' ' +
                            priceQuote.price_per_passenger.currency_code
                          
                                          }, null, 8 /* PROPS */, ["value"]),
                                          _createVNode(_component_ag_agent_info_box, {
                                            class: "no_border",
                                            "test-id": "",
                                            style: {"margin-right":"0.3cm","padding-right":"0.3cm"},
                                            icon: "notInvoice",
                                            title: "Total Tax",
                                            value: 
                            _ctx.formatNumber(
                              priceQuote.price_per_passenger.total_tax
                            ) +
                            ' ' +
                            priceQuote.price_per_passenger.currency_code
                          
                                          }, null, 8 /* PROPS */, ["value"]),
                                          _createVNode(_component_ag_agent_info_box, {
                                            class: "no_border",
                                            "test-id": "",
                                            icon: "tempCreditLimit",
                                            style: {"margin-right":"0.3cm","padding-right":"0.3cm"},
                                            title: "Fees",
                                            value: 
                            _ctx.formatNumber(priceQuote.price_per_passenger.fees) +
                            ' ' +
                            priceQuote.price_per_passenger.currency_code
                          
                                          }, null, 8 /* PROPS */, ["value"]),
                                          _createVNode(_component_ag_agent_info_box, {
                                            class: "no_border",
                                            "test-id": "",
                                            icon: "permanentCredit",
                                            title: "Surcharges",
                                            style: {"margin-right":"0.3cm","padding-right":"0.3cm"},
                                            value: 
                            _ctx.formatNumber(
                              priceQuote.price_per_passenger.surcharges
                            ) +
                            ' ' +
                            priceQuote.price_per_passenger.currency_code
                          
                                          }, null, 8 /* PROPS */, ["value"]),
                                          _createVNode(_component_ag_agent_info_box, {
                                            class: "no_border",
                                            "test-id": "",
                                            icon: "userType",
                                            title: "Gross Fare",
                                            style: {"margin-right":"0.3cm","padding-right":"0.3cm"},
                                            value: 
                            _ctx.formatNumber(
                              priceQuote.price_per_passenger.gross_fare
                            ) +
                            ' ' +
                            priceQuote.price_per_passenger.currency_code
                          
                                          }, null, 8 /* PROPS */, ["value"])
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1024 /* DYNAMIC_SLOTS */),
                                      _createVNode(_component_ag_table, {
                                        headers: _ctx.oldPriceQuotesTable,
                                        items: priceQuote.price_per_passenger.tax_list,
                                        "has-pagination": false,
                                        "items-per-page": 
                          priceQuote.price_per_passenger.tax_list.length
                        ,
                                        "has-search": false
                                      }, {
                                        "col-code": _withCtx(({ item }) => [
                                          _createVNode(_component_ag_div, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(item.raw.code), 1 /* TEXT */)
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1024 /* DYNAMIC_SLOTS */)
                                        ]),
                                        "col-amount": _withCtx(({ item }) => [
                                          _createVNode(_component_ag_div, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.formatNumber(item.raw.amount)) + " " + _toDisplayString(item.raw.currency_code), 1 /* TEXT */)
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1024 /* DYNAMIC_SLOTS */)
                                        ]),
                                        "col-taxName": _withCtx(({ item }) => [
                                          _createVNode(_component_ag_div, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(item.raw.tax_name !== null
                                ? item.raw.tax_name
                                : "-"), 1 /* TEXT */)
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1024 /* DYNAMIC_SLOTS */)
                                        ]),
                                        "col-ticketing_tax_code": _withCtx(({ item }) => [
                                          _createVNode(_component_ag_div, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(item.raw.ticketing_tax_code !== null
                                ? item.raw.ticketing_tax_code
                                : "-"), 1 /* TEXT */)
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1024 /* DYNAMIC_SLOTS */)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["headers", "items", "items-per-page"]),
                                      _createVNode(_component_AgIconInfoBar, {
                                        "test-id": "",
                                        icon: "contactTravelerIcon",
                                        title: "Segments",
                                        style: {"margin-top":"0.5cm"}
                                      }),
                                      _createVNode(_component_ag_table, {
                                        style: {"margin-bottom":"0.5cm"},
                                        headers: _ctx.segments,
                                        items: priceQuote.segments,
                                        "has-pagination": false,
                                        "items-per-page": priceQuote.segments.length,
                                        "has-search": false
                                      }, {
                                        "col-Sector": _withCtx(({ item }) => [
                                          _createVNode(_component_ag_div, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(item.raw.arrival_city) + " - " + _toDisplayString(item.raw.departure_city), 1 /* TEXT */)
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1024 /* DYNAMIC_SLOTS */)
                                        ]),
                                        "col-arrival_date_time": _withCtx(({ item }) => [
                                          _createVNode(_component_ag_div, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(
                              item.raw.arrival_date_time,
                              _ctx.FORMAT_YYY_MM_DD_HH_SS_A
                            )), 1 /* TEXT */)
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1024 /* DYNAMIC_SLOTS */)
                                        ]),
                                        "col-departure_date_time": _withCtx(({ item }) => [
                                          _createVNode(_component_ag_div, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(
                              item.raw.departure_date_time,
                              _ctx.FORMAT_YYY_MM_DD_HH_SS_A
                            )), 1 /* TEXT */)
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1024 /* DYNAMIC_SLOTS */)
                                        ]),
                                        "col-flight_number": _withCtx(({ item }) => [
                                          _createVNode(_component_ag_div, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(item.raw.flight_number), 1 /* TEXT */)
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1024 /* DYNAMIC_SLOTS */)
                                        ]),
                                        "col-rbd": _withCtx(({ item }) => [
                                          _createVNode(_component_ag_div, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(item.raw.rbd), 1 /* TEXT */)
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1024 /* DYNAMIC_SLOTS */)
                                        ]),
                                        "col-segment_status": _withCtx(({ item }) => [
                                          _createVNode(_component_ag_div, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(item.raw.segment_status), 1 /* TEXT */)
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1024 /* DYNAMIC_SLOTS */)
                                        ]),
                                        "col-Baggage": _withCtx(({ item }) => [
                                          _createVNode(_component_ag_div, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(item.raw.baggage.allowance + item.raw.baggage.type), 1 /* TEXT */)
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1024 /* DYNAMIC_SLOTS */)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["headers", "items", "items-per-page"])
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1024 /* DYNAMIC_SLOTS */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */)
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 512 /* NEED_PATCH */), [
      [_vShow, _ctx.Response]
    ]),
    _withDirectives(_createVNode(_component_ag_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_AgIconInfoBar, {
          "test-id": "",
          icon: "userType",
          title: 
        'New Price Quotes (' + _ctx.formatNumber(_ctx.totalFare) + ' ' + _ctx.currency + ')'
      
        }, null, 8 /* PROPS */, ["title"]),
        _createVNode(_component_ag_accordion, {
          class: "flight_accordion_wrap margin_bottom_0",
          "panel-value": [0]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ag_accordion_panel, null, {
              tabSection: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Response.new_price_quotes, (priceQuote, index) => {
                  return (_openBlock(), _createBlock(_component_ag_div, { key: index }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ag_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_accordion, {
                            class: "flight_accordion_wrap margin_bottom_0",
                            "panel-value": [0]
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ag_accordion_panel, null, {
                                tabSection: _withCtx(() => [
                                  _createVNode(_component_ag_row, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ag_agent_info_box, {
                                        style: {"margin-right":"0.3cm","padding-right":"0.3cm"},
                                        class: "no_border",
                                        "test-id": "",
                                        icon: "contactTravelerIcon",
                                        title: "Passenger Type",
                                        value: _ctx.formatNumber(priceQuote.passenger_type)
                                      }, null, 8 /* PROPS */, ["value"]),
                                      _createVNode(_component_ag_agent_info_box, {
                                        class: "no_border",
                                        style: {"margin-right":"0.3cm","padding-right":"0.3cm"},
                                        "test-id": "",
                                        icon: "currentBalance",
                                        title: "Base Fare",
                                        value: 
                          _ctx.formatNumber(priceQuote.price.base_fare) +
                          ' ' +
                          priceQuote.price.currency_code
                        
                                      }, null, 8 /* PROPS */, ["value"]),
                                      _createVNode(_component_ag_agent_info_box, {
                                        style: {"margin-right":"0.3cm","padding-right":"0.3cm"},
                                        class: "no_border",
                                        "test-id": "",
                                        icon: "notInvoice",
                                        title: "Total Tax",
                                        value: 
                          _ctx.formatNumber(priceQuote.price.total_tax) +
                          ' ' +
                          priceQuote.price.currency_code
                        
                                      }, null, 8 /* PROPS */, ["value"]),
                                      _createVNode(_component_ag_agent_info_box, {
                                        class: "no_border",
                                        "test-id": "",
                                        icon: "tempCreditLimit",
                                        style: {"margin-right":"0.3cm","padding-right":"0.3cm"},
                                        title: "Fees",
                                        value: 
                          _ctx.formatNumber(priceQuote.price.fees) +
                          ' ' +
                          priceQuote.price.currency_code
                        
                                      }, null, 8 /* PROPS */, ["value"]),
                                      _createVNode(_component_ag_agent_info_box, {
                                        class: "no_border",
                                        "test-id": "",
                                        icon: "permanentCredit",
                                        style: {"margin-right":"0.3cm","padding-right":"0.3cm"},
                                        title: "Surcharges",
                                        value: 
                          _ctx.formatNumber(priceQuote.price.surcharges) +
                          ' ' +
                          priceQuote.price.currency_code
                        
                                      }, null, 8 /* PROPS */, ["value"]),
                                      _createVNode(_component_ag_agent_info_box, {
                                        class: "no_border",
                                        "test-id": "",
                                        icon: "userType",
                                        title: "Gross Fare",
                                        style: {"margin-right":"0.3cm","padding-right":"0.3cm"},
                                        value: 
                          _ctx.formatNumber(priceQuote.price.gross_fare) +
                          ' ' +
                          priceQuote.price.currency_code
                        
                                      }, null, 8 /* PROPS */, ["value"])
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1024 /* DYNAMIC_SLOTS */),
                                  _createVNode(_component_ag_table, {
                                    headers: _ctx.oldPriceQuotesTable,
                                    items: priceQuote.price.tax_list,
                                    "has-pagination": false,
                                    "items-per-page": priceQuote.price.tax_list.length,
                                    "has-search": false
                                  }, {
                                    "col-code": _withCtx(({ item }) => [
                                      _createVNode(_component_ag_div, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(item.raw.code), 1 /* TEXT */)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1024 /* DYNAMIC_SLOTS */)
                                    ]),
                                    "col-amount": _withCtx(({ item }) => [
                                      _createVNode(_component_ag_div, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.formatNumber(item.raw.amount)) + " " + _toDisplayString(item.raw.currency_code), 1 /* TEXT */)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1024 /* DYNAMIC_SLOTS */)
                                    ]),
                                    "col-taxName": _withCtx(({ item }) => [
                                      _createVNode(_component_ag_div, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(item.raw.tax_name !== null ? item.raw.tax_name : "-"), 1 /* TEXT */)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1024 /* DYNAMIC_SLOTS */)
                                    ]),
                                    "col-ticketing_tax_code": _withCtx(({ item }) => [
                                      _createVNode(_component_ag_div, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(item.raw.ticketing_tax_code !== null
                              ? item.raw.ticketing_tax_code
                              : "-"), 1 /* TEXT */)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1024 /* DYNAMIC_SLOTS */)
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["headers", "items", "items-per-page"]),
                                  _createVNode(_component_ag_card, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ag_row, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_AgIconInfoBar, {
                                            "test-id": "",
                                            icon: "contactTravelerIcon",
                                            title: 
                            'Price Per Passenger (passenger count: ' +
                            priceQuote.passenger_count +
                            ')'
                          ,
                                            style: {"margin-top":"0.5cm"}
                                          }, null, 8 /* PROPS */, ["title"])
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1024 /* DYNAMIC_SLOTS */),
                                      _createVNode(_component_ag_row, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ag_agent_info_box, {
                                            class: "no_border",
                                            style: {"margin-right":"0.3cm","padding-right":"0.3cm"},
                                            "test-id": "",
                                            icon: "currentBalance",
                                            title: "Base Fare",
                                            value: 
                            _ctx.formatNumber(
                              priceQuote.price_per_passenger.base_fare
                            ) +
                            ' ' +
                            priceQuote.price.currency_code
                          
                                          }, null, 8 /* PROPS */, ["value"]),
                                          _createVNode(_component_ag_agent_info_box, {
                                            class: "no_border",
                                            "test-id": "",
                                            icon: "notInvoice",
                                            title: "Total Tax",
                                            style: {"margin-right":"0.3cm","padding-right":"0.3cm"},
                                            value: 
                            _ctx.formatNumber(
                              priceQuote.price_per_passenger.total_tax
                            ) +
                            ' ' +
                            priceQuote.price.currency_code
                          
                                          }, null, 8 /* PROPS */, ["value"]),
                                          _createVNode(_component_ag_agent_info_box, {
                                            class: "no_border",
                                            "test-id": "",
                                            icon: "tempCreditLimit",
                                            title: "Fees",
                                            style: {"margin-right":"0.3cm","padding-right":"0.3cm"},
                                            value: 
                            _ctx.formatNumber(priceQuote.price_per_passenger.fees) +
                            ' ' +
                            priceQuote.price.currency_code
                          
                                          }, null, 8 /* PROPS */, ["value"]),
                                          _createVNode(_component_ag_agent_info_box, {
                                            class: "no_border",
                                            "test-id": "",
                                            icon: "permanentCredit",
                                            style: {"margin-right":"0.3cm","padding-right":"0.3cm"},
                                            title: "Surcharges",
                                            value: 
                            _ctx.formatNumber(
                              priceQuote.price_per_passenger.surcharges
                            ) +
                            ' ' +
                            priceQuote.price.currency_code
                          
                                          }, null, 8 /* PROPS */, ["value"]),
                                          _createVNode(_component_ag_agent_info_box, {
                                            class: "no_border",
                                            "test-id": "",
                                            icon: "userType",
                                            style: {"margin-right":"0.3cm","padding-right":"0.3cm"},
                                            title: "Gross Fare",
                                            value: 
                            _ctx.formatNumber(
                              priceQuote.price_per_passenger.gross_fare
                            ) +
                            ' ' +
                            priceQuote.price.currency_code
                          
                                          }, null, 8 /* PROPS */, ["value"])
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1024 /* DYNAMIC_SLOTS */),
                                      _createVNode(_component_ag_table, {
                                        headers: _ctx.oldPriceQuotesTable,
                                        items: priceQuote.price_per_passenger.tax_list,
                                        "has-pagination": false,
                                        "items-per-page": 
                          priceQuote.price_per_passenger.tax_list.length
                        ,
                                        "has-search": false
                                      }, {
                                        "col-code": _withCtx(({ item }) => [
                                          _createVNode(_component_ag_div, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(item.raw.code), 1 /* TEXT */)
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1024 /* DYNAMIC_SLOTS */)
                                        ]),
                                        "col-amount": _withCtx(({ item }) => [
                                          _createVNode(_component_ag_div, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.formatNumber(item.raw.amount)) + " " + _toDisplayString(item.raw.currency_code), 1 /* TEXT */)
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1024 /* DYNAMIC_SLOTS */)
                                        ]),
                                        "col-taxName": _withCtx(({ item }) => [
                                          _createVNode(_component_ag_div, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(item.raw.tax_name !== null
                                ? item.raw.tax_name
                                : "-"), 1 /* TEXT */)
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1024 /* DYNAMIC_SLOTS */)
                                        ]),
                                        "col-ticketing_tax_code": _withCtx(({ item }) => [
                                          _createVNode(_component_ag_div, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(item.raw.ticketing_tax_code !== null
                                ? item.raw.ticketing_tax_code
                                : "-"), 1 /* TEXT */)
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1024 /* DYNAMIC_SLOTS */)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["headers", "items", "items-per-page"]),
                                      _createVNode(_component_AgIconInfoBar, {
                                        "test-id": "",
                                        icon: "contactTravelerIcon",
                                        title: "Price Quote Policies",
                                        style: {"margin-top":"0.5cm"}
                                      }),
                                      _createVNode(_component_ag_table, {
                                        style: {"margin-bottom":"0.5cm"},
                                        headers: _ctx.priceQuotePolicies,
                                        items: priceQuote.price_quote_policies,
                                        "has-pagination": false,
                                        "items-per-page": priceQuote.price_quote_policies.length,
                                        "has-search": false
                                      }, {
                                        "col-type": _withCtx(({ item }) => [
                                          _createVNode(_component_ag_div, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(item.raw.type), 1 /* TEXT */)
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1024 /* DYNAMIC_SLOTS */)
                                        ]),
                                        "col-amount": _withCtx(({ item }) => [
                                          _createVNode(_component_ag_div, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.formatNumber(item.raw.amount)) + " " + _toDisplayString(item.raw.currency), 1 /* TEXT */)
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1024 /* DYNAMIC_SLOTS */)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["headers", "items", "items-per-page"]),
                                      _createVNode(_component_AgIconInfoBar, {
                                        "test-id": "",
                                        icon: "contactTravelerIcon",
                                        title: "Baggage info",
                                        style: {"margin-top":"0.5cm"}
                                      }),
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(priceQuote.baggage_info, (Info, i) => {
                                        return (_openBlock(), _createElementBlock("p", { key: i }, _toDisplayString(Info), 1 /* TEXT */))
                                      }), 128 /* KEYED_FRAGMENT */))
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1024 /* DYNAMIC_SLOTS */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */)
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 512 /* NEED_PATCH */), [
      [_vShow, _ctx.Response]
    ]),
    _createVNode(_component_ag_div, { class: "d-flex justify-content-end" }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_AGButton, {
          variant: "primary",
          onClick: _ctx.ApplyButtonClick,
          isLoading: _ctx.isSaveLoading
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Apply Fare ")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick", "isLoading"]), [
          [_vShow, _ctx.Response]
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _withDirectives(_createVNode(_component_AgNotFound, {
      "test-id": "",
      heading: "Modify PNR & Airline Code",
      description: ""
    }, null, 512 /* NEED_PATCH */), [
      [_vShow, !_ctx.Response]
    ])
  ], 64 /* STABLE_FRAGMENT */))
}