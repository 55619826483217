<template>
  <ag-modal :is-open="isOpen" modal-width="40%" @close="$emit('handleClose')">
    <template #body>
      <AgHeading title="Unlink Financial Profile" variant="h3" test-id="" />
      <AgDiv
        >Are you sure to Unlink <b>{{ financial_profile_name }}</b
        >?</AgDiv
      >
      <AgDiv class="btn_container">
        <AGButton
          class="reject_btn"
          test-id=""
          @click="$emit('handleClose')"
          variant="danger"
          type="button"
          >No
        </AGButton>
        <AGButton
          :disbaled="isLoading"
          :is-loading="isLoading"
          test-id=""
          @click="$emit('handleAccept')"
          variant="primary"
          type="button"
          >Yes</AGButton
        >
      </AgDiv>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "UnlinkFinancialProfileConfirmationDialog",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    financial_profile_name: {
      type: String,
      default: "",
      required: true,
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters.isUnlinkingFinancialProfile;
    },
  },
});
</script>

<style scoped>
.btn_container {
  display: flex;
  float: right;
}
.reject_btn {
  margin-right: 10px;
}
</style>
