<template>
  <ag-loader v-if="$store.getters.isCurrenciesLoading"></ag-loader>
  <template v-else>
    <ag-card>
      <AgDiv class="header-title-container">
        <ag-heading variant="h2" title="Currency Conversion" />
      </AgDiv>
      <hr />
      <ag-row>
        <ag-column xs="12" sm="12" md="6" lg="5">
          <ag-row>
            <p class="converter-label">
              Covert PKR amount into other currencies
            </p>
            <ag-column xs="12" sm="12" md="6" lg="8">
              <div>
                <AgTextFieldNew
                  label="PKR Amount"
                  test-id=""
                  type="number"
                  variant="outlined"
                  v-model="pkrAmount"
                />
                <p v-show="convertPkrToCurrency()" class="converted-amount">
                  {{ convertPkrToCurrency() }}
                </p>
              </div>
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="4">
              <AgSelect
                :items="currencyItemsPKRToCurrencies"
                label="Currency"
                variant="outlined"
                v-model="selectedCurrency1"
                test-id=""
              />
            </ag-column>
          </ag-row>
        </ag-column>
        <ag-column xs="0" sm="0" md="0" lg="2"> </ag-column>
        <ag-column xs="12" sm="12" md="6" lg="5">
          <ag-row>
            <p class="converter-label">
              Covert other currencies amount into PKR
            </p>
            <ag-column xs="12" sm="12" md="6" lg="8">
              <div>
                <AgTextFieldNew
                  label="Currency Amount"
                  test-id=""
                  type="number"
                  variant="outlined"
                  v-model="currencyAmount"
                />
                <p v-show="convertCurrencyToPkr()" class="converted-amount">
                  {{ convertCurrencyToPkr() }}
                </p>
              </div>
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="4">
              <AgSelect
                :items="currencyItems"
                label="Currency"
                variant="outlined"
                v-model="selectedCurrency2"
                test-id=""
              />
            </ag-column>
          </ag-row>
        </ag-column>
      </ag-row>
    </ag-card>
    <ag-card>
      <AgDiv class="header-title-container">
        <ag-heading variant="h2" title="Currencies Rates" />
      </AgDiv>
      <AgTable
        :headers="headers"
        :items="currencies"
        :items-per-page="40"
        :has-pagination="true"
        :has-search="false"
      >
      </AgTable>
    </ag-card>
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IHeading } from "@/ag-portal-common/interfaces/header.interface";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import { CurrencyConversion } from "@/ag-portal-common/types/index";
import { getCurrencyFormatter } from "@/ag-portal-common/utils/helpers";
import analyticsService from "@/analytic.service";
import { CURRENCY_CONVERSION_ANALYTICS_EVENTS } from "@/modules/Organization/constants/analyticsEvents";

export default defineComponent({
  name: "CurrencyConversion",
  data(): {
    headers: IHeading[];
    pkrAmount: number | null;
    currencyAmount: number | null;
    convertedAmount1: number | null;
    convertedAmount2: number | null;
    selectedCurrency1: CurrencyConversion | null;
    selectedCurrency2: CurrencyConversion | null;
  } {
    return {
      headers: [
        {
          title: "Currency Name",
          value: "currency_name",
          key: "currency_name",
          sortable: true,
        },
        {
          title: "Code",
          value: "currency_code",
          key: "currency_code",
          sortable: true,
        },
        {
          title: "Rate",
          value: "conversion_rate",
          key: "conversion_rate",
          sortable: true,
        },
      ],
      pkrAmount: null,
      currencyAmount: null,
      convertedAmount1: null,
      convertedAmount2: null,
      selectedCurrency1: null,
      selectedCurrency2: null,
    };
  },
  methods: {
    formatCurrency(amount: number, currency: string) {
      const formattedEarning = getCurrencyFormatter(currency).format(amount);
      return formattedEarning;
    },
    convertPkrToCurrency() {
      const selectedCurrency = this.selectedCurrency1;
      analyticsService.logActionEvent(
        CURRENCY_CONVERSION_ANALYTICS_EVENTS.CURRENCY_SELECTED,
        {
          BASE: "PKR",
          CONVERTED_INTO: selectedCurrency?.currency_code,
        }
      );
      if (selectedCurrency) {
        const calculatedAmount =
          Number(this.pkrAmount) / Number(selectedCurrency.conversion_rate);
        const formatedAmount = this.formatCurrency(
          calculatedAmount,
          selectedCurrency.currency_code
        );
        const baseFormatedAmount = this.formatCurrency(
          Number(this.pkrAmount),
          "PKR"
        );

        return calculatedAmount
          ? `${baseFormatedAmount} = ${formatedAmount}`
          : "";
      }
    },
    convertCurrencyToPkr() {
      const selectedCurrency = this.selectedCurrency2;
      analyticsService.logActionEvent(
        CURRENCY_CONVERSION_ANALYTICS_EVENTS.CURRENCY_SELECTED,
        {
          BASE: selectedCurrency?.currency_code,
          CONVERTED_INTO: "PKR",
        }
      );
      if (selectedCurrency) {
        const calculatedAmount =
          (Number(this.currencyAmount) ?? 0) *
          Number(selectedCurrency.conversion_rate);
        const formatedAmount = this.formatCurrency(calculatedAmount, "PKR");
        const baseFormatedAmount = this.formatCurrency(
          Number(this.currencyAmount) ?? 0,
          selectedCurrency.currency_code
        );

        return calculatedAmount
          ? `${baseFormatedAmount} = ${formatedAmount}`
          : "";
      }
    },
  },
  computed: {
    currencyItems(): IObject[] {
      const localItems = this.$store.getters.currencies;
      return localItems
        .filter((a: CurrencyConversion) => a.currency_code !== "PKR")
        .map((x: CurrencyConversion) => {
          return {
            label: x.currency_code,
            value: x,
          };
        });
    },
    currencyItemsPKRToCurrencies(): IObject[] {
      const localItems = this.$store.getters.currencies;
      return localItems
        .filter((a: CurrencyConversion) => a.currency_code !== "PKR")
        .map((x: CurrencyConversion) => {
          return {
            label: x.currency_code,
            value: x,
          };
        });
    },
    currencies(): CurrencyConversion[] {
      return this.$store.getters.currencies?.map((x: CurrencyConversion) => {
        return {
          ...x,
          currency_name: x.currency_name.replace("_", " "),
        };
      });
    },
  },
  mounted() {
    this.$store.dispatch("getCurrencies");
  },
});
</script>

<style lang="scss">
.passenger-action-btn-container {
  display: flex;
  justify-content: space-between;
  width: 150px;
}

.header-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.passenger-delete-icon {
  svg {
    stroke: #d21414;
  }
}

.converted-amount {
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  background: #0c4118;
  color: #fff;
  padding: 7px;
}
.converter-label {
  margin-left: 15px;
  color: #000;
  font-weight: bold;
}
</style>
