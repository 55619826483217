<template>
  <ag-card>
    <ag-div class="d-flex justify-content-end">
      <MButton
        type="filled"
        :testId="genTestId(ELEMENT_TYPES.BUTTON, 'payment-list-create-new')"
        @click="routeToCreatePayment"
        >Create New Payment
      </MButton>
    </ag-div>
    <ag-loader v-if="$store.getters.isFetchingPayments" />
    <ag-table
      v-else-if="
        !$store.getters.isFetchingPayments &&
        $store.getters.payments.payments.length
      "
      :items-per-page="$store.getters.payments.pageSize"
      :page="$store.getters.payments.pageNumber"
      :total-page-count="$store.getters.payments.totalPages"
      @onPageChange="onPageChange"
      :headers="headers"
      :items="$store.getters.payments.payments"
      :has-pagination="true"
      :has-search="true"
    >
      <template #col-transactionType="{ item }">
        <ag-div>
          {{ TRANSACTION_TYPES[item.raw.transactionType] }}
        </ag-div>
      </template>
      <template #col-paymentStatus="{ item }">
        <ag-div :class="paymentStatusClass(item.raw.paymentStatus)">
          {{ PAYMENT_STATUSES[item.raw.paymentStatus] }}
        </ag-div>
      </template>
      <template #col-receiptStatus="{ item }">
        <ag-div :class="receiptStatusClass(item.raw.receiptStatus)">
          {{ RECEIPT_STATUSES[item.raw.receiptStatus] }}
        </ag-div>
      </template>
      <template #col-dateTime="{ item }">
        <ag-div>{{ formattedDate(item.raw.dateTime) }}</ag-div>
        <ag-div class="text-color-green"
          >{{ formattedTime(item.raw.dateTime) }}
        </ag-div>
      </template>
      <template #col-amount="{ item }">
        <ag-div>{{ formattedCurrency(item.raw.amount) }}</ag-div>
      </template>
      <template #col-action="{ item }">
        <ag-div class="table_last_col_link">
          <strong>
            <router-link
              :data-test-id="
                genTestId(ELEMENT_TYPES.LINK, `payment-list-${item.raw.id}`)
              "
              :to="
                formatStringToRoutePath(PATH.PAYMENT_DETAIL, {
                  id: item.raw.id,
                })
              "
            >
              <ag-heading
                v-if="
                  item.raw.transactionType === PAYMENT_METHODS_TYPES.ONE_BILL
                "
                title="Update Status"
                variant="p"
              />

              <ag-heading v-else title="View" variant="p" />
            </router-link>
          </strong>
        </ag-div>
      </template>
    </ag-table>
    <ag-not-found v-else :heading="NOTIFICATION_MESSAGES.NO_RESULTS_FOUND" />
  </ag-card>

  <PaymentDetailModal
    :show-modal="showDetailModal"
    v-if="showDetailModal"
    @onCloseModal="onCloseModal"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  formatNumber,
  formatStringToRoutePath,
  genTestId,
  getFormattedDateTime,
  getCurrencyFormatter,
} from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { FetchPaymentsParamsRequestModel } from "@/modules/Payments/models/fetchPaymentsParams.request";
import { TRANSACTION_TYPES } from "@/ag-portal-common/constants/transactionTypes";
import { PAYMENT_STATUSES } from "@/ag-portal-common/constants/paymentStatuses";
import { RECEIPT_STATUSES } from "../../../ag-portal-common/constants/receiptStatuses";
import { PAYMENT_METHODS_TYPES } from "../../../ag-portal-common/enums/PAYMENT_METHODS_TYPES";
import {
  FORMAT_DD_MMM_YYYY,
  FORMAT_HH_SS_A,
} from "@/ag-portal-common/constants/dateTimeFormats";
import { RECEIPT_STATUS } from "@/ag-portal-common/enums/RECEIPT_STATUS";
import { PAYMENT_STATUS } from "@/ag-portal-common/enums/PAYMENT_STATUS";
import { PATH } from "@/ag-portal-common/constants/path";
import PaymentDetailModal from "@/modules/Payments/components/PaymentDetailModal.vue";
import {
  PAYMENT_STATUS_MAPPING,
  RECEIPT_STATUS_MAPPING,
} from "@/modules/Payments/constants";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import { MButton } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "PaymentList",
  components: { PaymentDetailModal },
  computed: {
    PATH() {
      return PATH;
    },
  },
  data(): {
    ELEMENT_TYPES: IObject;
    PAYMENT_METHODS_TYPES: IObject;
    NOTIFICATION_MESSAGES: IObject;
    TRANSACTION_TYPES: IObject;
    PAYMENT_STATUSES: IObject;
    RECEIPT_STATUSES: IObject;
    headers: Array<{
      title: string;
      value: string;
      key: string;
      sortable: boolean;
    }>;
    fetchPaymentsParams: FetchPaymentsParamsRequestModel;
    showDetailModal: boolean;
  } {
    return {
      fetchPaymentsParams: {
        page_no: 1,
      },
      ELEMENT_TYPES,
      PAYMENT_METHODS_TYPES,
      NOTIFICATION_MESSAGES,
      TRANSACTION_TYPES,
      PAYMENT_STATUSES,
      RECEIPT_STATUSES,
      showDetailModal: false,
      headers: [
        {
          title: "Transaction ID",
          value: "transactionId",
          key: "transactionId",
          sortable: false,
        },
        {
          title: "Transaction Type",
          value: "transactionType",
          key: "transactionType",
          sortable: false,
        },
        {
          title: "Amount",
          value: "amount",
          key: "amount",
          sortable: false,
        },
        {
          title: "Financial Profile",
          value: "financialProfile",
          key: "financialProfile",
          sortable: false,
        },
        {
          title: "Date & Time",
          value: "dateTime",
          key: "dateTime",
          sortable: false,
        },
        {
          title: "Payment Status",
          value: "paymentStatus",
          key: "paymentStatus",
          sortable: false,
        },
        {
          title: "Receipt Status",
          value: "receiptStatus",
          key: "receiptStatus",
          sortable: false,
        },
        {
          title: "Agent Email",
          value: "agentEmail",
          key: "agentEmail",
          sortable: false,
        },
        {
          title: "Action",
          value: "action",
          key: "action",
          sortable: false,
        },
      ],
    };
  },
  beforeMount() {
    this.fetchPayments();
  },
  methods: {
    formattedCurrency(amount: number) {
      const currency = localStorage.getItem("currency") || "";
      const formattedAmount = getCurrencyFormatter(currency).format(amount);
      return formattedAmount;
    },
    formatStringToRoutePath,
    formatNumber,
    genTestId,
    onPageChange(pageNum: number) {
      this.fetchPaymentsParams.page_no = pageNum;
      this.fetchPayments();
    },
    receiptStatusClass(status: RECEIPT_STATUS) {
      return `color_chip ${RECEIPT_STATUS_MAPPING[status]}`;
    },
    paymentStatusClass(status: PAYMENT_STATUS) {
      return `color_chip ${PAYMENT_STATUS_MAPPING[status]}`;
    },
    routeToCreatePayment() {
      this.$router.push(PATH.CREATE_PAYMENT);
    },
    formattedDate(date: Date) {
      return getFormattedDateTime(date, FORMAT_DD_MMM_YYYY);
    },
    formattedTime(date: Date) {
      return getFormattedDateTime(date, FORMAT_HH_SS_A);
    },
    fetchPayments() {
      this.$store.dispatch("fetchPayments", this.fetchPaymentsParams);
    },
    openDetailModal(value: string) {
      if (value) {
        this.showDetailModal = true;
      }
    },
    onCloseModal() {
      this.$router.replace(PATH.PAYMENTS);
      this.showDetailModal = false;
    },
  },

  watch: {
    "$route.params.id": {
      handler(value) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.openDetailModal(value);
      },
      immediate: true,
    },
  },
});
</script>
