<template>
  <SnapshotDetailAccordion title="Credit Limit Requests">
    <template #overview>
      <ag-column
        xs="12"
        sm="12"
        md="6"
        lg="3"
        v-for="(item, index) in creditLimitAggregates"
        :key="index"
      >
        <ag-agent-info-box
          :title="renderItemTitle(item)"
          icon="notInvoice"
          :value="renderItemValue(item)"
        />
      </ag-column>
    </template>
    <template #accordion-content>
      <MDataTable
        v-if="showDetails"
        :headers="creditLimitSegmentHeader"
        :data="creditLimitOrganizations"
        :items-per-page="10"
        :has-pagination="false"
        :simpleDataTable="true"
        :hasSearch="true"
      >
        <template #organization_name="{ item }">
          <div
            @click="handleOrganizationRowClick($event, item)"
            class="name-column organization-link"
          >
            {{ item.organization_name }}
          </div>
        </template>
      </MDataTable>
      <AgNotFound v-else test-id="" heading="Organizations not found" />
    </template>
  </SnapshotDetailAccordion>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import _ from "lodash";

import {
  ISectorProductivity,
  CreditLimitRequestsAggregates,
} from "@/ag-portal-common/interfaces/sectorProductivity.interface";
import { MDataTableHeader } from "@/ag-portal-common/types";
import { MDataTable } from "@aeroglobe/ag-core-ui";
import SnapshotDetailAccordion from "@/components/SnapshotDetailAccordion.vue";

export default defineComponent({
  name: "CreditLimit",
  components: {
    MDataTable,
    SnapshotDetailAccordion,
  },
  props: {
    handleOrganizationRowClick: {
      type: Function,
      default: () => ({}),
    },
  },
  data(): {
    startDate: Date;
    endDate: Date;
    selectedSector: string;
    creditLimitSegmentHeader: MDataTableHeader[];
  } {
    return {
      startDate: new Date(),
      endDate: new Date(),
      selectedSector: "",
      creditLimitSegmentHeader: [
        {
          title: "Organization Name",
          value: "organization_name",
          key: "organization_name",
          sortable: true,
          align: "left",
        },
        {
          title: "Credit Limit Requests",
          value: "credit_limit_requested",
          key: "credit_limit_requested",
          sortable: true,
          align: "left",
        },
      ],
    };
  },
  methods: {
    renderItemTitle(value: string) {
      return _.capitalize(value?.replaceAll("_", " "));
    },
    renderItemValue(value: keyof CreditLimitRequestsAggregates) {
      return this.salesProductivity.credit_limit_requests_data?.aggregates[
        value
      ];
    },
  },
  computed: {
    salesProductivity(): ISectorProductivity {
      return this.$store.getters.salesProductivity;
    },
    showDetails(): boolean {
      const isLoading = this.$store.getters.isSalesLoading;
      const organizations =
        this.$store.getters.salesProductivity?.credit_limit_requests_data
          ?.organizations || [];
      return !isLoading && organizations.length > 0;
    },
    creditLimitOrganizations() {
      const salesProductivity = this.$store.getters
        .salesProductivity as ISectorProductivity;
      const organizations =
        salesProductivity?.credit_limit_requests_data?.organizations ?? [];

      return organizations;
    },
    creditLimitAggregates(): Array<keyof CreditLimitRequestsAggregates> {
      const salesProductivity = this.$store.getters.salesProductivity as
        | ISectorProductivity
        | undefined;
      const aggregates =
        salesProductivity?.credit_limit_requests_data?.aggregates;

      if (aggregates) {
        return Object.keys(aggregates) as Array<
          keyof CreditLimitRequestsAggregates
        >;
      }

      return [];
    },
  },
});
</script>

<style lang="scss" scoped>
.card-wrapper {
  margin-block: 30px;
  border: solid 1px var(--green-color);
  border-top: solid 8px var(--green-color);
}

.heading {
  font-size: 30px;
  font-weight: bold;
}

.overview-heading {
  font-size: 19px;
  margin: 10px;
  font-weight: bold;
}

.items-container {
  border-left: solid 4px var(--green-color);
}

.empty-items-message {
  text-align: center;
  margin-block: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #828282;
}
.organization-link {
  color: var(--green-color);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
