<template>
  <ag-div v-if="expiryCount">
    <AgBookingExpiryNotification :bookingCount="expiryCount">
      <template #buttonAction>
        <AGButton
          variant="warning"
          @click="onClickViewBookings"
          class="view_booking_yellow_btn"
          >View Booking</AGButton
        >
      </template></AgBookingExpiryNotification
    >
  </ag-div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PATH } from "@/ag-portal-common/constants/path";

export default defineComponent({
  name: "BookingExpiry",
  data() {
    return {
      expiryCount: null,
    };
  },
  methods: {
    onClickViewBookings() {
      this.$router.push(PATH.MY_BOOKINGS);
    },
    async fetchExpiryCount() {
      try {
        const count = await this.$store.dispatch("getExpirySoon");
        this.expiryCount = count;
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.fetchExpiryCount();
  },
});
</script>
