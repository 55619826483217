<template>
  <ag-modal test-id="" :is-open="isOpen" @close="closeModal" modal-width="40%">
    <template #header>
      <v-card-title>Organization Documents</v-card-title>
      <AGButton
        test-id=""
        type="button"
        class="modal_close_icon"
        variant="link"
        @click="closeModal"
        >Close
      </AGButton>
    </template>
    <template #body>
      <AgDiv>
        <h4>CNIC</h4>
        <hr />
        <AgDiv class="cnic_container">
          <v-img
            v-for="(item, idx) in renderCNIC()"
            :key="idx"
            :src="item?.image_url"
            class="mx-auto"
            height="350"
            lazy-src="https://picsum.photos/id/11/100/60"
            width="450"
            max-width="500"
          >
            <template v-slot:placeholder>
              <div class="lazy-img-loader fill-height">
                <v-progress-circular
                  color="grey-lighten-4"
                  indeterminate
                ></v-progress-circular>
              </div>
            </template>
          </v-img>
        </AgDiv>
      </AgDiv>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DocumentsModal",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    selectedDocuments: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      documents: {},
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    renderCNIC() {
      return this.selectedDocuments?.cnic || [];
    },
  },
  watch: {
    selectedDocuments(data: object) {
      this.documents = data;
    },
  },
});
</script>

<style scoped>
.cnic_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.lazy-img-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
</style>

<style>
.v-img__placeholder {
  display: flex;
  justify-content: center;
}
</style>
