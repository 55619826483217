import RestClientService from "@/ag-portal-common/services/restClient.service";
import {
  ChangePasswordDto,
  ForgotPasswordDto,
  LoginDto,
  SignupDto,
  ResetPasswordDto,
} from "@/modules/Auth/dtos/auth.dto";
import { ChangeNumberDto } from "@/modules/Profile/dtos/auth.dto";
import { API_PATH } from "@/ag-portal-common/constants/apiPath";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { LoginResponseModel } from "@/ag-portal-common/models/loginResponse.model";

class AuthService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public login(body: LoginDto): Promise<IAGResponse<LoginResponseModel>> {
    return this.restClient.post(API_PATH.login, body);
  }

  public signup(body: SignupDto): Promise<IAGResponse<LoginResponseModel>> {
    return this.restClient.post(API_PATH.signup, body);
  }

  public forgotPassword(body: ForgotPasswordDto): Promise<IAGResponse<any>> {
    return this.restClient.post(API_PATH.forgotPassword, body);
  }

  public resetPassword(body: ResetPasswordDto): Promise<IAGResponse<any>> {
    return this.restClient.post(API_PATH.changePassword, body);
  }

  public changePassword(body: ChangePasswordDto): Promise<IAGResponse<any>> {
    return this.restClient.post(API_PATH.resetPassword, body);
  }
  public changeNumber(body: ChangeNumberDto): Promise<IAGResponse<any>> {
    return this.restClient.patch(API_PATH.changePhoneNumber, body);
  }
}

export default AuthService;
