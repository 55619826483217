<template>
  <MCard class="organization-snapshot-header">
    <div class="field-container">
      <OrganizationAutocomplete
        @onChange="handleOrganizationChange"
        :default-value="lastOrganizationId"
      />
    </div>
    <ShimmerCard
      card-height="400px"
      v-if="$store.getters.isOrganizationStatsLoading"
    />
    <template v-else>
      <template v-if="showDetails">
        <div class="name-container">
          <img :src="organizationStats?.organization?.org_logo" />
          <div class="agency-name-container">
            <p class="agency-name">{{ renderOrgName() }}</p>
            <p>
              <a
                class="website-slug"
                :href="`https://${generateWebsiteDomain(renderWebsite())}`"
                target="_blank"
              >
                {{ generateWebsiteDomain(renderWebsite()) }}
              </a>
            </p>
          </div>
        </div>
        <hr />
        <MCard>
          <ag-row>
            <ag-column xs="12" sm="12" md="6" lg="2">
              <ag-agent-info-box
                title="Owners"
                icon="customerStatus"
                :value="organizationStats?.owner_count"
              />
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="2">
              <ag-agent-info-box
                title="Sub Agents"
                icon="customerStatus"
                :value="organizationStats?.sub_agent_count"
              />
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="2">
              <ag-agent-info-box
                title="Employees"
                icon="customerStatus"
                :value="organizationStats?.employee_count"
              />
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <ag-agent-info-box
                title="Website Status"
                icon="provider"
                :value="organizationStats?.organization?.website_status"
              />
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <ag-agent-info-box
                title="Theme Color"
                icon="provider"
                value="Green"
              >
                <template #value>
                  <ag-color-chip
                    class="color-chip"
                    :color-name="renderColorCode()"
                    :color-code="renderColorCode()"
                  />
                </template>
              </ag-agent-info-box>
            </ag-column>
          </ag-row>
        </MCard>

        <MAccordion :initial-open="true">
          <template v-slot:title>
            <div>
              <span class="accordion-title">Organization Users</span>
            </div>
          </template>
          <template v-slot:content>
            <MDataTable
              :headers="headers"
              :data="users() || []"
              :item-per-page="10"
              :has-search="true"
            ></MDataTable>
          </template>
        </MAccordion>
      </template>
      <ResultNotFound v-if="showResultNotFound" />
    </template>
  </MCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { formatDistance } from "date-fns";
import _ from "lodash";

import {
  IOrganizationStats,
  OrganizationUserInfo,
} from "@/ag-portal-common/interfaces/organizationStatistics.interface";
import OrganizationAutocomplete from "@/modules/Organization/components/OrganizationAutocomplete.vue";
import { formatQueryPath } from "@/ag-portal-common/utils/helpers";
import { PATH } from "@/ag-portal-common/constants/path";
import ShimmerCard from "@/components/ShimmerCard.vue";
import ResultNotFound from "../OrganizationDetails/ResultNotFound.vue";
import { MDataTable, MAccordion, MCard } from "@aeroglobe/ag-core-ui";
import { MDataTableHeader } from "@/ag-portal-common/types";

export default defineComponent({
  name: "OrganizationSnapshotAnalyticsTab",
  components: {
    OrganizationAutocomplete,
    ShimmerCard,
    ResultNotFound,
    MDataTable,
    MAccordion,
    MCard,
  },
  data(): {
    headers: MDataTableHeader[];
    lastOrganizationId: string;
    isInitial: boolean;
  } {
    return {
      headers: [
        {
          title: "Email",
          value: "email",
          key: "email",
          sortable: true,
          align: "left",
        },
        {
          title: "User Type",
          value: "user_type",
          key: "user_type",
          sortable: true,
          align: "left",
        },
        {
          title: "Last Login",
          value: "last_login",
          key: "last_login",
          sortable: true,
          align: "left",
        },
      ],
      lastOrganizationId: "",
      isInitial: true,
    };
  },
  methods: {
    generateWebsiteDomain(slug: string) {
      const isDev = process.env.NODE_ENV === "development";
      return `${slug}${isDev ? ".dev" : ""}.web.aeroglobe.pk`;
    },
    formatLastLogin(date: Date | null): string {
      return formatDistance(date ? date : new Date(), new Date(), {
        addSuffix: true,
      });
    },
    handleOrganizationChange(value: string) {
      if (value) {
        const path = `${PATH.ORGANIZATION_SNAPSHOT}${formatQueryPath({
          org_id: value,
        })}`;
        this.$router.push(path);
      }
    },
    fetchOrganization() {
      const { org_id } = this.$route.query;
      this.isInitial = false;
      if (org_id) {
        this.$store.dispatch("getOrganizationStats", org_id);
      }
    },
    renderOrgName() {
      return this.organizationStats?.organization?.org_name || "Aeroglobe";
    },
    renderWebsite() {
      return this.organizationStats?.organization?.website_slug || "Aeroglobe";
    },
    renderColorCode() {
      return this.organizationStats?.organization?.org_default_color;
    },
    users() {
      const Users = this.organizationStats?.users || [];
      const formatedUsers = Users.map((item: OrganizationUserInfo) => {
        const updatedLastLogin = this.formatLastLogin(
          new Date(item?.last_logged_in_at_iso as Date)
        );
        return {
          email: item.email,
          last_login: item.last_logged_in_at_iso ? updatedLastLogin : "-",
          user_type: item.user_type.replace("_", " "),
        };
      });
      return formatedUsers;
    },
  },
  computed: {
    websiteEnabled() {
      return "Enabled";
    },
    isStatsFetching(): boolean {
      return this.$store.getters.isOrganizationStatsLoading;
    },
    organizationStats(): IOrganizationStats {
      return this.$store.getters.organizationStats;
    },
    showDetails(): boolean {
      const isNotEmpty = !_.isEmpty(this.organizationStats);
      return isNotEmpty;
    },
    showResultNotFound(): boolean {
      return !this.isInitial && !this.isStatsFetching && !this.showDetails;
    },
  },
  watch: {
    "$route.query": {
      handler: function (value) {
        const isNotEmpty = !_.isEmpty(value);
        if (isNotEmpty) {
          if (this.lastOrganizationId !== value?.org_id) {
            this.fetchOrganization();
            this.lastOrganizationId = value?.org_id;
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$store.dispatch("fetchOrganizations");
  },
});
</script>

<style lang="scss" scoped>
.organization-snapshot-header {
  .name-container {
    display: flex;
    align-items: center;
    margin-top: 40px;
    img {
      width: 70px;
    }
    .agency-name-container {
      margin-left: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .agency-name {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 0;
        color: #000;
      }
      p {
        margin-bottom: 0;
        color: #6b7280;
      }
    }
  }
}

.heading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
}

.color-chip {
  width: 120px;
}

.website-slug {
  font-weight: bold;
  color: #6b7280;
  &:hover {
    color: #0c47bc;
  }
}
.accordion-title {
  font-size: 20px;
  margin-right: 20px;
  font-weight: 600;
  text-align: start;
  @media (max-width: 767px) {
    text-align: center;
  }
}
.field-container {
  max-width: 640px;
}
</style>
