import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_agheading = _resolveComponent("agheading")!
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_AgTable = _resolveComponent("AgTable")!
  const _component_AgNotFound = _resolveComponent("AgNotFound")!
  const _component_ag_card = _resolveComponent("ag-card")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createBlock(_component_ag_modal, {
    "is-open": _ctx.isOpen,
    onClose: _ctx.onCloseModal,
    "modal-width": "60%"
  }, {
    header: _withCtx(() => [
      _createVNode(_component_agheading, null, {
        default: _withCtx(() => [
          _createTextVNode("Pending Receipt and Invoice of "),
          _createElementVNode("b", null, _toDisplayString(_ctx.financialProfile.financial_profile_name), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    body: _withCtx(() => [
      _createVNode(_component_ag_card, null, {
        default: _withCtx(() => [
          (_ctx.pendingPostingInvoices.length > 0)
            ? (_openBlock(), _createBlock(_component_ag_heading, {
                key: 0,
                variant: "h2",
                title: "Invoices"
              }))
            : _createCommentVNode("v-if", true),
          (_ctx.pendingPostingInvoices && _ctx.pendingPostingInvoices.length > 0)
            ? (_openBlock(), _createBlock(_component_AgTable, {
                key: 1,
                headers: _ctx.tableHeaders_Invoices,
                items: _ctx.pendingPostingInvoices,
                "has-pagination": true,
                "items-per-page": 5,
                "has-search": false
              }, {
                "col-ticket_no": _withCtx(({ item }) => [
                  _createVNode(_component_ag_div, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.raw.ticket_no), 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */)
                ]),
                "col-pnr": _withCtx(({ item }) => [
                  _createVNode(_component_ag_div, { class: "color_chip green" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.raw.pnr), 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */)
                ]),
                "col-posting_status": _withCtx(({ item }) => [
                  _createVNode(_component_ag_div, { class: "color_chip green" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.raw.posting_status), 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["headers", "items"]))
            : _createCommentVNode("v-if", true),
          (_ctx.pendingPostingReceipts.length > 0)
            ? (_openBlock(), _createBlock(_component_ag_heading, {
                key: 2,
                variant: "h2",
                title: "Receipts",
                style: {"margin-bottom":"0.5cm","margin-top":"0.5cm"}
              }))
            : _createCommentVNode("v-if", true),
          (_ctx.pendingPostingReceipts && _ctx.pendingPostingReceipts.length > 0)
            ? (_openBlock(), _createBlock(_component_AgTable, {
                key: 3,
                headers: _ctx.tableHeaders_Receipts,
                items: _ctx.pendingPostingReceipts,
                "has-pagination": true,
                "items-per-page": 5,
                "has-search": false
              }, {
                "col-payment_id": _withCtx(({ item }) => [
                  _createVNode(_component_ag_div, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.raw.payment_id), 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */)
                ]),
                "col-amount": _withCtx(({ item }) => [
                  _createVNode(_component_ag_div, { class: "color_chip green" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.raw.amount), 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */)
                ]),
                "col-posting_status": _withCtx(({ item }) => [
                  _createVNode(_component_ag_div, { class: "color_chip green" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.raw.posting_status), 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["headers", "items"]))
            : _createCommentVNode("v-if", true),
          (
            _ctx.pendingPostingInvoices.length === 0 &&
            _ctx.pendingPostingReceipts.length === 0
          )
            ? (_openBlock(), _createBlock(_component_AgNotFound, {
                key: 4,
                "test-id": "",
                heading: "No Pending Receipts & Invoices Found",
                description: ""
              }))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_AGButton, {
        onClick: _ctx.onCloseModal,
        variant: "primary"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Close")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-open", "onClose"]))
}