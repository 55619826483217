<template>
  <div class="create-org-btn-container">
    <MButton
      @click="onClickCreateNew"
      :testId="genTestId(ELEMENT_TYPES.BUTTON, 'create-new-organization')"
      type="filled"
    >
      Create new organization
    </MButton>
  </div>
  <MDataTable
    :headers="headers"
    :is-loading="$store.getters.isFetchingOrganizations"
    :data="transformTableData"
    :items-per-page="10"
    :has-search="true"
    :simpleDataTable="true"
  >
    <template #public_id="{ item }">
      <router-link :to="`${PATH.ORGANIZATION}/${item.id}`">
        <span class="organization-id">{{ item.public_id }}</span>
      </router-link>
    </template>
    <template #name="{ item }">
      <div class="min-width-200">
        {{ item.name }}
      </div>
    </template>
    <template #sector_name="{ item }">
      <div class="min-width-200">
        {{ item.sector_name }}
      </div>
    </template>
    <template #status="{ item }">
      <div class="status-container">
        <MChip
          :border-less="true"
          class="pricing-chip"
          :variant="organizationStatusClass(item.status)"
          >{{ item.status }}</MChip
        >
      </div>
    </template>

    <template #action="{ item }">
      <MButton
        type="filled"
        @click="onClickUpdate($event, item.id)"
        :testId="
          genTestId(ELEMENT_TYPES.BUTTON, `organization-${item.id}-action`)
        "
        >{{ item.action }}
      </MButton>
    </template>
  </MDataTable>
</template>

<script lang="ts">
import { PATH } from "@/ag-portal-common/constants/path";
import { defineComponent } from "vue";
import { IOrganization } from "@/ag-portal-common/interfaces/organization.interface";
import {
  formatStringToRoutePath,
  genTestId,
} from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { ORGANIZATION_STATUS_MAPPING, ORGANIZATION_STATUS } from "../constants";
import { MButton, MDataTable, MChip } from "@aeroglobe/ag-core-ui";
import { ChipVariant } from "@aeroglobe/ag-core-ui/dist/src/components/material/atoms/atoms.type";

export default defineComponent({
  name: "OrganizationListView",
  components: {
    MDataTable,
    MButton,
    MChip,
  },
  data() {
    return {
      search: "",
      isMobile: false,
      ELEMENT_TYPES,
      ORGANIZATION_STATUS_MAPPING,
      ORGANIZATION_STATUS,
      headers: [
        {
          key: "public_id",
          title: "Public Id",
          align: "left",
          value: "public_id",
        },
        {
          key: "name",
          title: "Name",
          align: "left",
          value: "name",
        },
        {
          title: "Sector Name",
          value: "sector_name",
          key: "sector_name",
          align: "left",
          sortable: true,
        },
        {
          title: "PIA Provider",
          value: "pia_provider",
          key: "pia_provider",
          align: "left",
          sortable: true,
        },
        {
          title: "Phone",
          value: "phone",
          key: "phone",
          align: "left",
          sortable: true,
        },
        {
          title: "Email",
          value: "email",
          key: "email",
          align: "left",
          sortable: true,
        },
        {
          title: "Status",
          value: "status",
          key: "status",
          sortable: true,
        },
        {
          title: "Action",
          value: "action",
          key: "action",
          sortable: false,
        },
      ],
    };
  },
  beforeMount() {
    this.fetchOrganizations();
  },
  methods: {
    genTestId,
    fetchOrganizations() {
      this.$store.dispatch("fetchOrganizations");
    },
    onClickCreateNew() {
      this.$router.push(PATH.CREATE_ORGANIZATION);
    },
    onClickUpdate(e: any, id: string) {
      e.stopImmediatePropagation();
      this.$router.push(
        formatStringToRoutePath(PATH.UPDATE_ORGANIZATION, { id })
      );
    },
    organizationStatusClass(status: ORGANIZATION_STATUS): ChipVariant {
      return status === ORGANIZATION_STATUS.ACTIVE ? "success" : "error";
    },
  },
  computed: {
    PATH() {
      return PATH;
    },
    transformTableData() {
      return this.$store.getters.organizations.map((item: IOrganization) => ({
        id: item.public_id,
        public_id: item.default_financial_profile?.platform_id
          ? `${item.public_id} (${item.default_financial_profile?.platform_id})`
          : `${item.public_id} (-)`,
        name: item.name,
        sector_name: item.sector,
        pia_provider: item.pia_provider,
        phone: item.contact_person_phone ? item.contact_person_phone : "-",
        email: item.contact_person_email ? item.contact_person_email : "-",
        status: item.status,
        action: "Update",
      }));
    },
  },
});
</script>

<style scoped>
.organization-id {
  color: var(--green-color);
}
.min-width-200 {
  min-width: 200px;
}
.status-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.text-align-center {
  text-align: center;
}
.create-org-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
</style>
