<template>
  <ag-modal :is-open="isOpen" @close="handleClose" modal-width="70%">
    <template #body>
      <vue-json-pretty
        :data="jsonData"
        :show-icon="true"
        :show-line-number="true"
      />
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

export default defineComponent({
  name: "ApiResponseJsonViewerModal",
  components: {
    VueJsonPretty,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    jsonData: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
  },
});
</script>
