<template>
  <ag-modal :is-open="isOpen" @close="onCloseModal" modal-width="60%">
    <template #header>
      <agheading
        >Pending Receipt and Invoice of
        <b>{{ financialProfile.financial_profile_name }}</b></agheading
      >
    </template>
    <template #body>
      <ag-card>
        <ag-heading
          variant="h2"
          title="Invoices"
          v-if="pendingPostingInvoices.length > 0"
        ></ag-heading>

        <AgTable
          v-if="pendingPostingInvoices && pendingPostingInvoices.length > 0"
          :headers="tableHeaders_Invoices"
          :items="pendingPostingInvoices"
          :has-pagination="true"
          :items-per-page="5"
          :has-search="false"
        >
          <template #col-ticket_no="{ item }">
            <ag-div>{{ item.raw.ticket_no }}</ag-div>
          </template>
          <template #col-pnr="{ item }">
            <ag-div class="color_chip green">{{ item.raw.pnr }}</ag-div>
          </template>
          <template #col-posting_status="{ item }">
            <ag-div class="color_chip green"
              >{{ item.raw.posting_status }}
            </ag-div>
          </template>
        </AgTable>

        <ag-heading
          variant="h2"
          title="Receipts"
          v-if="pendingPostingReceipts.length > 0"
          style="margin-bottom: 0.5cm; margin-top: 0.5cm"
        ></ag-heading>
        <AgTable
          v-if="pendingPostingReceipts && pendingPostingReceipts.length > 0"
          :headers="tableHeaders_Receipts"
          :items="pendingPostingReceipts"
          :has-pagination="true"
          :items-per-page="5"
          :has-search="false"
        >
          <template #col-payment_id="{ item }">
            <ag-div>{{ item.raw.payment_id }}</ag-div>
          </template>
          <template #col-amount="{ item }">
            <ag-div class="color_chip green">{{ item.raw.amount }}</ag-div>
          </template>
          <template #col-posting_status="{ item }">
            <ag-div class="color_chip green"
              >{{ item.raw.posting_status }}
            </ag-div>
          </template>
        </AgTable>
        <AgNotFound
          v-if="
            pendingPostingInvoices.length === 0 &&
            pendingPostingReceipts.length === 0
          "
          test-id=""
          heading="No Pending Receipts & Invoices Found"
          description=""
        />
      </ag-card>
    </template>
    <template #footer>
      <AGButton @click="onCloseModal" variant="primary">Close</AGButton>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { formatNumber } from "@/ag-portal-common/utils/helpers";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";

export default defineComponent({
  name: "ViewNotInvoicedReceiptsDialog",
  props: {
    financialProfile: {
      type: Object as PropType<IFinancialProfile>,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    selectedId: string;
    isModalOpen: boolean;
    pendingPostingReceipts: [];
    pendingPostingInvoices: [];
    isLoading: boolean;
    tableHeaders_Receipts: {
      title: string;
      value: string;
      key: string;
      sortable: boolean;
    }[];
    tableHeaders_Invoices: {
      title: string;
      value: string;
      key: string;
      sortable: boolean;
    }[];
  } {
    return {
      selectedId: "",
      isLoading: false,
      isModalOpen: false,
      pendingPostingReceipts: [],
      pendingPostingInvoices: [],
      tableHeaders_Receipts: [
        {
          title: "Payment Id",
          value: "payment_id",
          key: "payment_id",
          sortable: false,
        },
        {
          title: "Amount",
          value: "amount",
          key: "amount",
          sortable: false,
        },
        {
          title: "Posting Status",
          value: "posting_status",
          key: "posting_status",
          sortable: false,
        },
      ],
      tableHeaders_Invoices: [
        {
          title: "Ticket Number",
          value: "ticket_no",
          key: "ticket_no",
          sortable: false,
        },
        {
          title: "PNR",
          value: "pnr",
          key: "pnr",
          sortable: false,
        },
        {
          title: "Posting Status",
          value: "posting_status",
          key: "posting_status",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    formatNumber,
    async openModal() {
      this.isLoading = true;
      const responseData = await this.$store.dispatch(
        "fetchPendingReceiptAndInvoice",
        {
          id: this.financialProfile?.public_id,
        }
      );
      this.pendingPostingInvoices = responseData.invoices || [];
      this.pendingPostingReceipts = responseData.receipts || [];
      this.isLoading = false;
    },
    onCloseModal() {
      this.$emit("handleClose");
    },
  },
  watch: {
    isOpen(isDialogOpen) {
      if (isDialogOpen) {
        this.openModal();
      }
    },
  },
});
</script>

<style scoped>
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0px;
}

.financial-profile-name {
  font-weight: bold;
  font-size: 18px;
  color: black;
  margin: 0;
}

.financial-profile-name-sub-items {
  color: black;
  margin-right: 10px;
  font-weight: 600;
}

.financial-profile-status-container {
  color: black;
  margin-right: 10px;
  font-weight: 600;
}

.financial-profile-status-active {
  background: green;
  padding: 5px;
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
}

.financial-profile-status-inactive {
  background: red;
  padding: 5px;
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
}
</style>
