<template>
  <div class="card">
    <div class="search-input-container">
      <MTextfield
        v-model:inputValue="inputPNR"
        label="Enter PNR"
        :hasError="inputError"
        errorMessage="Enter A Valid PNR"
      />
      <div class="action-btn-container">
        <MButton type="filled" :disabled="disableBtn" @click="handleClear">
          Clear
        </MButton>
        <MButton type="filled" :disabled="disableBtn" @click="onGetPNRLogs">
          Get PNR Logs
        </MButton>
      </div>
    </div>
  </div>

  <ShimmerCard card-height="300px" v-if="isLoading" />

  <div class="card" v-if="apiResponse && apiResponse?.length > 0">
    <MTypography type="title"> PNR: {{ pnrValue }}</MTypography>
    <hr />

    <MDataTable
      :has-pagination="false"
      :headers="tableHeaders"
      :data="apiResponse || []"
    >
      <template #created_at="{ item }">
        <div>
          {{
            item.created_at
              ? getFormattedDateTime(
                  item.created_at,
                  FORMAT_DD_MM_YYYY_HH_MM_24_HOURS
                )
              : "NA"
          }}
        </div>
      </template>

      <template #log_type="{ item }">
        <div class="log-type">
          <MChip
            class="log-type-item"
            :borderLess="true"
            :variant="renderLogType(item.log_type)"
          >
            {{ item?.log_type?.replace("_", " ") }}
          </MChip>
        </div>
      </template>

      <template #request_data="{ item }">
        <data class="btn-container">
          <MButton
            variant="primary"
            type="filled"
            @click="handleViewMoreBtn(item.request_data)"
          >
            View More
          </MButton>
        </data>
      </template>

      <template #response_data="{ item }">
        <div class="btn-container">
          <MButton
            variant="primary"
            type="filled"
            @click="handleViewMoreBtn(item.response_data)"
          >
            View More
          </MButton>
        </div>
      </template>
    </MDataTable>
  </div>

  <ResultNotFound
    class="result-not-found"
    v-if="showResultNotFound"
    title="No PNR Details Found"
    description="Please Try Modify Your PNR"
  />

  <ApiResponseJsonViewerModal
    :is-open="isJSONViewerModalOpen"
    :json-data="selectedJsonItem"
    @handleClose="handleCloseJSONModal"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MyBookingsService from "@/modules/MyBookings/services/myBookings.service";
import { getFormattedDateTime } from "@/ag-portal-common/utils/helpers";
import { FORMAT_DD_MM_YYYY_HH_MM_24_HOURS } from "@/ag-portal-common/constants/dateTimeFormats";
import {
  MButton,
  MTextfield,
  MDataTable,
  MTypography,
  MChip,
} from "@aeroglobe/ag-core-ui";
import ApiResponseJsonViewerModal from "../components/ApiResponseJsonViewerModal.vue";
import ShimmerCard from "@/components/ShimmerCard.vue";
import ResultNotFound from "@/components/ResultNotFound.vue";
import {
  ISSUANCE_LOG_TYPE,
  ISSUANCE_LOG_TYPE_SERVERITY,
} from "@/ag-portal-common/enums/ISSUANCE_LOGS_TYPE";
import { ChipVariant } from "@aeroglobe/ag-core-ui/dist/src/components/material/atoms/atoms.type";

export default defineComponent({
  name: "IssuanceLogs",
  components: {
    MButton,
    MTextfield,
    MDataTable,
    ApiResponseJsonViewerModal,
    MTypography,
    ShimmerCard,
    ResultNotFound,
    MChip,
  },

  data() {
    return {
      isInitial: true,
      isLoading: false,
      pnrValue: "",
      apiResponse: [],
      isJSONViewerModalOpen: false,
      selectedJsonItem: {},
      inputPNR: "",
      inputError: false,
      tableHeaders: [
        {
          title: "Created At",
          value: "created_at",
          key: "created_at",
          sortable: false,
          align: "left",
        },
        {
          title: "Log Type",
          value: "log_type",
          key: "log_type",
          sortable: false,
        },
        {
          title: "Request Data",
          value: "request_data",
          key: "request_data",
          sortable: false,
        },
        {
          title: "Response Data",
          value: "response_data",
          key: "response_data",
          sortable: false,
        },
      ],
    };
  },
  async created() {
    const pnrValue = this.$route.params.pnr as string | null;
    this.apiResponse = [];
    if (pnrValue) {
      this.pnrValue = pnrValue;
      if (this.haveProperLength(this.pnrValue)) {
        this.inputPNR = this.pnrValue;
        await this.onViewIssuanceLogs();
      }
    }
  },

  methods: {
    getFormattedDateTime,
    haveProperLength(value: string) {
      return value.length >= 6;
    },
    async onGetPNRLogs() {
      this.inputError = false;
      this.apiResponse = [];
      this.isInitial = false;
      if (this.haveProperLength(this.inputPNR)) {
        this.pnrValue = this.inputPNR;
        await this.onViewIssuanceLogs();
      } else {
        this.inputError = true;
      }
    },
    handleClear() {
      this.isInitial = true;
      this.apiResponse = [];
      this.inputPNR = "";
    },

    async onViewIssuanceLogs() {
      this.isLoading = true;
      try {
        const myBookingService = new MyBookingsService();
        const response = await myBookingService.fetchIssuanceLogs(
          this.pnrValue
        );
        this.apiResponse = response.data;
      } catch (e) {
        this.apiResponse = [];
      } finally {
        this.isLoading = false;
      }
    },
    handleViewMoreBtn(item: any) {
      this.selectedJsonItem = item;
      this.isJSONViewerModalOpen = true;
    },
    handleCloseJSONModal() {
      this.isJSONViewerModalOpen = false;
    },
    renderLogType(type: ISSUANCE_LOG_TYPE): ChipVariant {
      return ISSUANCE_LOG_TYPE_SERVERITY[type] as ChipVariant;
    },
  },

  computed: {
    FORMAT_DD_MM_YYYY_HH_MM_24_HOURS() {
      return FORMAT_DD_MM_YYYY_HH_MM_24_HOURS;
    },
    renderError(): boolean {
      return this.inputPNR.length < 1;
    },
    showResultNotFound(): boolean {
      return (
        this.isInitial === false &&
        this.isLoading === false &&
        this.apiResponse.length < 1
      );
    },
    disableBtn(): boolean {
      return this.isLoading || this.inputPNR.length < 1;
    },
  },
  watch: {
    inputPNR() {
      this.inputError = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.card {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 15px;
}
.search-input-container {
  height: 120px;
  button {
    float: right;
  }
}
.log-type {
  display: flex;
  justify-content: center;
}

.log-type-item {
  font-weight: 600;
}
.result-not-found {
  margin-top: 50px;
}
.btn-container {
  display: flex;
  justify-content: center;
}
.action-btn-container {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
}
</style>
