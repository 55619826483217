import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7414cca8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field-container" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MCombobox = _resolveComponent("MCombobox")!
  const _component_ShimmerCard = _resolveComponent("ShimmerCard")!
  const _component_ag_agent_info_box = _resolveComponent("ag-agent-info-box")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_SnapshotDetailAccordion = _resolveComponent("SnapshotDetailAccordion")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_ag_color_chip = _resolveComponent("ag-color-chip")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_ResultNotFound = _resolveComponent("ResultNotFound")!
  const _component_MCard = _resolveComponent("MCard")!

  return (_openBlock(), _createBlock(_component_MCard, { class: "sector-snapshot-header" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_MCombobox, {
          inputValue: _ctx.sector,
          "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sector) = $event)),
          label: "Select Sector",
          itemValue: "value",
          itemLabel: "label",
          options: _ctx.sectors,
          disabled: _ctx.$store.getters.isFetchingSectors
        }, null, 8 /* PROPS */, ["inputValue", "options", "disabled"])
      ]),
      (_ctx.$store.getters.isSectorStatsLoading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_ShimmerCard, { "card-height": "200px" }),
            _createVNode(_component_ShimmerCard, { "card-height": "200px" }),
            _createVNode(_component_ShimmerCard, { "card-height": "150px" }),
            _createVNode(_component_ShimmerCard, { "card-height": "100px" })
          ], 64 /* STABLE_FRAGMENT */))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.showDetails)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createCommentVNode(" General Stats Card "),
                  _createVNode(_component_SnapshotDetailAccordion, { title: "General Stats" }, {
                    overview: _withCtx(() => [
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_agent_info_box, {
                            title: "Enabled Websites",
                            icon: "provider",
                            value: _ctx.sectorStats?.aggregates?.enabled_website_count
                          }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_agent_info_box, {
                            title: "Disabled Websites",
                            icon: "provider",
                            value: _ctx.sectorStats?.aggregates?.disabled_website_count
                          }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_agent_info_box, {
                            title: "Logo Added",
                            icon: "provider",
                            value: _ctx.sectorStats?.aggregates?.logo_added_count
                          }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_agent_info_box, {
                            title: "Color Added",
                            icon: "provider",
                            value: _ctx.sectorStats?.aggregates?.color_added_count
                          }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_agent_info_box, {
                            title: "Default Color",
                            icon: "provider",
                            value: _ctx.sectorStats?.aggregates?.default_color_count
                          }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_agent_info_box, {
                            title: "Default Logo",
                            icon: "provider",
                            value: _ctx.sectorStats?.aggregates?.default_logo_count
                          }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createCommentVNode(" Users Card "),
                  _createVNode(_component_SnapshotDetailAccordion, { title: "Users" }, {
                    overview: _withCtx(() => [
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_agent_info_box, {
                            title: "Owners",
                            icon: "customerStatus",
                            value: _ctx.sectorStats?.users?.owners
                          }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_agent_info_box, {
                            title: "Employees",
                            icon: "customerStatus",
                            value: _ctx.sectorStats?.users?.employees
                          }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_agent_info_box, {
                            title: "Sub Agents",
                            icon: "customerStatus",
                            value: _ctx.sectorStats?.users?.sub_agents
                          }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createCommentVNode(" Organization Card "),
                  _createVNode(_component_SnapshotDetailAccordion, { title: "Organizations" }, {
                    "accordion-content": _withCtx(() => [
                      (_ctx.showOrganizationTable)
                        ? (_openBlock(), _createBlock(_component_MDataTable, {
                            key: 0,
                            headers: _ctx.headers,
                            data: _ctx.sectorOrganizations,
                            "items-per-page": 10,
                            simpleDataTable: true
                          }, {
                            logo: _withCtx(({ item }) => [
                              _createVNode(_component_AgDiv, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("img", {
                                    width: "60",
                                    src: item.logo
                                  }, null, 8 /* PROPS */, _hoisted_2)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */)
                            ]),
                            org_color: _withCtx(({ item }) => [
                              _createVNode(_component_AgDiv, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ag_color_chip, {
                                    "color-name": item.org_color,
                                    "color-code": item.org_color
                                  }, null, 8 /* PROPS */, ["color-name", "color-code"])
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */)
                            ]),
                            name: _withCtx(({ item }) => [
                              _createVNode(_component_AgDiv, {
                                onClick: ($event: any) => (_ctx.handleRowClick($event, item)),
                                class: "name-column organization-link"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.name), 1 /* TEXT */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                            ]),
                            domain_name: _withCtx(({ item }) => [
                              _createVNode(_component_AgDiv, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.domain_name ? item.domain_name : "N/A"), 1 /* TEXT */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */)
                            ]),
                            display_name: _withCtx(({ item }) => [
                              _createVNode(_component_AgDiv, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.display_name ? item.display_name : "N/A"), 1 /* TEXT */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */)
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["headers", "data"]))
                        : (_openBlock(), _createBlock(_component_ResultNotFound, {
                            key: 1,
                            "test-id": "",
                            title: "Organizations not found"
                          }))
                    ]),
                    _: 1 /* STABLE */
                  })
                ], 64 /* STABLE_FRAGMENT */))
              : _createCommentVNode("v-if", true),
            (_ctx.showResultNotFound)
              ? (_openBlock(), _createBlock(_component_ResultNotFound, {
                  key: 1,
                  "test-id": "",
                  title: "Sector Details Not Found"
                }))
              : _createCommentVNode("v-if", true)
          ], 64 /* STABLE_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }))
}