import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_ag_not_found = _resolveComponent("ag-not-found")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_ag_drawer = _resolveComponent("ag-drawer")!

  return (_openBlock(), _createBlock(_component_ag_drawer, {
    width: "560",
    "is-open": _ctx.isOpen,
    direction: "right"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_AgDiv, { class: "margin_bottom_10 margin_top_10 main_payment_detail_box" }, {
        default: _withCtx(() => [
          _createVNode(_component_ag_div, { class: "payment_pop_head" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Payment Details")
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_a_g_button, {
                testId: 
            _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'payment-detail-modal-close')
          ,
                type: "button",
                class: "modal_close_icon",
                variant: "link",
                onClick: _ctx.closeModal
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Close ")
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["testId", "onClick"])
            ]),
            _: 1 /* STABLE */
          }),
          (_ctx.isFetchingPayment)
            ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
            : (!_ctx.isFetchingPayment && _ctx.payment)
              ? (_openBlock(), _createBlock(_component_ag_div, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ag_div, { class: "d-flex justify-content-end align-items-center margin_right_20" }, {
                      default: _withCtx(() => [
                        (_ctx.payment?.oneBillDetail)
                          ? (_openBlock(), _createBlock(_component_a_g_button, {
                              key: 0,
                              testId: 
              _ctx.genTestId(
                _ctx.ELEMENT_TYPES.BUTTON,
                'payment-detail-modal-one-bill-guide'
              )
            ,
                              onClick: _ctx.onPaymentGuide
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("How To Pay ")
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["testId", "onClick"]))
                          : _createCommentVNode("v-if", true),
                        (_ctx.payment?.creditCardlDetail)
                          ? (_openBlock(), _createBlock(_component_a_g_button, {
                              key: 1,
                              testId: 
              _ctx.genTestId(
                _ctx.ELEMENT_TYPES.BUTTON,
                'payment-detail-modal-proceed-to-pay'
              )
            ,
                              onClick: _ctx.onProceedPayment
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("Proceed To Pay ")
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["testId", "onClick"]))
                          : _createCommentVNode("v-if", true)
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_ag_div, { class: "d-flex margin_top_20 justify-content-between align-items-center pymnt_financial_profile" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ag_div, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ag_div, { class: "p_label" }, {
                              default: _withCtx(() => [
                                _createTextVNode(" Agent Profile")
                              ]),
                              _: 1 /* STABLE */
                            }),
                            _createVNode(_component_ag_div, { class: "p_min_value" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.payment?.agentEmail), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_ag_div, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ag_div, { class: "p_min_value" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.payment?.agentName), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_ag_div, { class: "d-flex margin_top_20 justify-content-between align-items-center pymnt_financial_profile" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ag_div, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ag_div, { class: "p_label" }, {
                              default: _withCtx(() => [
                                _createTextVNode(" Financial Profile")
                              ]),
                              _: 1 /* STABLE */
                            }),
                            _createVNode(_component_ag_div, { class: "p_min_value" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString("#" + _ctx.payment?.financialProfile), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_ag_div, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ag_div, { class: "p_min_amount" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.formattedCurrency(_ctx.payment?.amount)), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_ag_div, { class: "d-flex justify-content-between payment_col_2" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ag_div, { class: "d-flex flex-direction-column justify-content-between" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ag_div, { class: "margin_top_10 margin_bottom_20" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ag_div, { class: "p_label" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" Transaction ID")
                                  ]),
                                  _: 1 /* STABLE */
                                }),
                                _createVNode(_component_ag_div, { class: "p_min_value" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.payment?.transactionId), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ]),
                              _: 1 /* STABLE */
                            }),
                            (_ctx.payment?.oneBillDetail)
                              ? (_openBlock(), _createBlock(_component_ag_div, {
                                  key: 0,
                                  class: "margin_top_20 margin_bottom_20"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ag_div, { class: "p_label" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(" One Bill ID")
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    _createVNode(_component_ag_div, { class: "p_min_value" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.payment?.oneBillDetail.billCode), 1 /* TEXT */)
                                      ]),
                                      _: 1 /* STABLE */
                                    })
                                  ]),
                                  _: 1 /* STABLE */
                                }))
                              : _createCommentVNode("v-if", true),
                            (_ctx.payment?.ibftDetail)
                              ? (_openBlock(), _createBlock(_component_ag_div, {
                                  key: 1,
                                  class: "margin_top_20 margin_bottom_20"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ag_div, { class: "p_label" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(" Payment Code")
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    _createVNode(_component_ag_div, { class: "p_min_value" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.payment?.ibftDetail.paymentCode), 1 /* TEXT */)
                                      ]),
                                      _: 1 /* STABLE */
                                    })
                                  ]),
                                  _: 1 /* STABLE */
                                }))
                              : _createCommentVNode("v-if", true),
                            _createVNode(_component_ag_div, { class: "margin_top_20 margin_bottom_10" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ag_div, { class: "p_label" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" Transaction Type")
                                  ]),
                                  _: 1 /* STABLE */
                                }),
                                _createVNode(_component_ag_div, { class: "p_min_value" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.TRANSACTION_TYPES[_ctx.payment?.transactionType]), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_ag_div, { class: "d-flex flex-direction-column justify-content-between" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ag_div, { class: "margin_bottom_20" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ag_div, { class: "p_label" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Payment Status")
                                  ]),
                                  _: 1 /* STABLE */
                                }),
                                _createVNode(_component_ag_div, {
                                  class: _normalizeClass('margin_top_10 pay_chip_wrap ' + _ctx.paymentStatusClass)
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.PAYMENT_STATUSES[_ctx.payment?.paymentStatus]), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                }, 8 /* PROPS */, ["class"])
                              ]),
                              _: 1 /* STABLE */
                            }),
                            _createVNode(_component_ag_div, { class: "margin_top_20 margin_bottom_10" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ag_div, { class: "p_label" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Receipt Status")
                                  ]),
                                  _: 1 /* STABLE */
                                }),
                                _createVNode(_component_ag_div, {
                                  class: _normalizeClass('margin_top_10 pay_chip_wrap ' + _ctx.receiptStatusClass)
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.RECEIPT_STATUSES[_ctx.payment?.receiptStatus]), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                }, 8 /* PROPS */, ["class"])
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_ag_div, { class: "d-flex justify-content-between align-items-center pymnt_financial_profile" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ag_div, { class: "p_min_head" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Payment Created At")
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_ag_div, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ag_div, { class: "p_date" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.formattedDate) + " ", 1 /* TEXT */),
                                _createVNode(_component_ag_div, { class: "p_time" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.formattedTime), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    }),
                    (_ctx.payment.description)
                      ? (_openBlock(), _createBlock(_component_ag_div, {
                          key: 0,
                          class: "pymnt_financial_profile"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ag_heading, {
                              title: "Description",
                              variant: "h2"
                            }),
                            _createVNode(_component_ag_heading, {
                              title: _ctx.payment?.description,
                              variant: "p"
                            }, null, 8 /* PROPS */, ["title"])
                          ]),
                          _: 1 /* STABLE */
                        }))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _: 1 /* STABLE */
                }))
              : (_openBlock(), _createBlock(_component_ag_not_found, { key: 2 }))
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-open"]))
}