<template>
  <AGAppContainer class="default-page-color" fullWidth>
    <div class="ag-resetpass-wrapper">
      <div class="ag-resetpass-card">
        <AGPanel>
          <AGLogo :url="urlLogo" is-small />
          <h5>Reset Your <span>Password?</span></h5>
          <p>Enter your new password below</p>
          <form @submit.prevent="onSubmit">
            <AGTextField
              v-model="resetPasswordPayload.newPassword"
              :testId="genTestId(ELEMENT_TYPES.TEXT_FIELD, 'reset-password')"
              :value="resetPasswordPayload.newPassword"
              class="reset-pass-text-field"
              name="newPassword"
              label="Password"
              :error="errors['newPassword']"
              :appendIcon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
            />

            <AGTextField
              v-model="resetPasswordPayload.confirmPassword"
              :testId="genTestId(ELEMENT_TYPES.TEXT_FIELD, 'reset-password')"
              :value="resetPasswordPayload.confirmPassword"
              class="reset-pass-text-field"
              name="confirmPassword"
              label="Confirm Password"
              :error="errors['confirmPassword']"
              :appendIcon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
            />
            <div class="forgot_card">
              <router-link
                :to="PATH.ROOT"
                :testId="genTestId(ELEMENT_TYPES.LINK, 'back-to-login')"
              >
                <AGButton
                  variant="link"
                  :testId="genTestId(ELEMENT_TYPES.BUTTON, 'back-to-login')"
                  >Back to Login
                </AGButton>
              </router-link>
            </div>

            <div class="d-grid">
              <AGButton
                type="submit"
                :testId="genTestId(ELEMENT_TYPES.BUTTON, 'login-submit')"
                :is-loading="isLoading"
                variant="primary"
                >Reset Password
              </AGButton>
            </div>
          </form>
        </AGPanel>
      </div>
    </div>
  </AGAppContainer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PATH } from "@/ag-portal-common/constants/path";
import {
  genTestId,
  yupValidationErrorAsSchema,
  parseLocalStorageData,
} from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import { ResetPasswordDto } from "@/modules/Auth/dtos/auth.dto";
import { validateResetPasswordSchema } from "@/modules/Auth/validations/resetPassword.validation";
import queryString from "query-string";
import { ValidationError } from "yup";
import { IOrganizationPreference } from "@/ag-portal-common/interfaces/organization.interface";
import { STORAGE_KEYS } from "@/ag-portal-common/constants/storageKeys";

export default defineComponent({
  name: "ResetPasswordView",
  computed: {
    PATH() {
      return PATH;
    },
    urlLogo() {
      const organizationPreference: IOrganizationPreference | null =
        parseLocalStorageData(STORAGE_KEYS.PREFERENCES);
      return organizationPreference?.logo;
    },
  },
  data(): {
    isLoading: boolean;
    showPassword: boolean;
    ELEMENT_TYPES: typeof ELEMENT_TYPES;
    resetPasswordPayload: IObject;
    errors: IObject;
  } {
    return {
      isLoading: false,
      showPassword: false,
      ELEMENT_TYPES,
      resetPasswordPayload: { newPassword: "", confirmPassword: "" },
      errors: {},
    };
  },

  methods: {
    genTestId,
    async onSubmit() {
      try {
        this.errors = {};
        await validateResetPasswordSchema.validate(this.resetPasswordPayload, {
          abortEarly: false,
        });
        this.isLoading = true;
        let parsedQuery = queryString.parse(window.location.search) as IObject;
        let payload: ResetPasswordDto = {
          new_password: this.resetPasswordPayload.newPassword,
          token: parsedQuery?.token || "",
        };
        this.$store.dispatch("resetPassword", {
          body: payload,
          sucessCallback: this.onPasswordResetSuccess,
          failureCallback: this.disableIsLoading,
        });
      } catch (ex) {
        if (ex instanceof ValidationError) {
          this.errors = yupValidationErrorAsSchema(ex);
        }
      }
    },
    onPasswordResetSuccess() {
      this.disableIsLoading();
      this.$router.push(PATH.ROOT);
    },
    disableIsLoading() {
      this.isLoading = false;
    },

    onPageTitleUpdateHandler() {
      document.title = "Reset Password";
    },
  },
});
</script>

<style lang="scss">
.reset-pass-text-field {
  position: relative;

  .v-input {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .v-input__control {
      flex: 1;
    }

    .v-input__append {
      right: 2px;
      background-color: white;
      padding: 10px;
      margin: 0;
    }

    .error-text {
      width: calc(100% - 10px);
      position: absolute;
      inset: 58px 10px;
    }
  }
}
</style>

<style scoped lang="css">
.ag-resetpass-wrapper {
  min-height: 100vh;

  display: grid;
  place-content: center;

  padding: 12px;
}

.ag-resetpass-wrapper .ag-resetpass-card .login_box {
  min-height: 0;
  width: 400px;
}

@media screen and (max-width: 480px) {
  .ag-resetpass-wrapper .ag-resetpass-card .login_box {
    max-width: none;
    width: 100%;
  }
}
</style>
