import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36639482"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn-container" }
const _hoisted_2 = { class: "table-column-width sales-channel" }
const _hoisted_3 = { class: "table-column-width" }
const _hoisted_4 = { class: "table-column-width pricing-chip-container" }
const _hoisted_5 = { class: "table-column-width" }
const _hoisted_6 = { class: "table-column-width" }
const _hoisted_7 = { class: "table-column-width" }
const _hoisted_8 = { class: "table-column-width" }
const _hoisted_9 = { class: "table-column-width" }
const _hoisted_10 = { class: "table-column-width" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MFinancialProfileCombobox = _resolveComponent("MFinancialProfileCombobox")!
  const _component_AgColumn = _resolveComponent("AgColumn")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_AgRow = _resolveComponent("AgRow")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_MChip = _resolveComponent("MChip")!
  const _component_MDataTable = _resolveComponent("MDataTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MCard, { class: "search-bar-card" }, {
      default: _withCtx(() => [
        _createVNode(_component_AgRow, null, {
          default: _withCtx(() => [
            _createVNode(_component_AgColumn, {
              sm: "12",
              md: "8",
              lg: "8",
              cols: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MFinancialProfileCombobox, {
                  class: "fp-combo",
                  inputValue: _ctx.financialProfileInput,
                  "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.financialProfileInput) = $event)),
                  label: "Financial Profiles",
                  itemValue: "value",
                  itemLabel: "label",
                  disabled: _ctx.isFinancialProfileFetching,
                  options: _ctx.financialProfileOptions,
                  hasError: _ctx.hasError,
                  errorMessage: _ctx.errorMessage
                }, null, 8 /* PROPS */, ["inputValue", "disabled", "options", "hasError", "errorMessage"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_AgColumn, {
              sm: "12",
              md: "4",
              lg: "4",
              cols: "12"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_MButton, {
                    onClick: _ctx.onFetchAgPricing,
                    class: "get-price-detail-btn",
                    disabled: _ctx.getPriceDetailsButtonDisabled
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Get Pricing ")
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick", "disabled"]),
                  _createVNode(_component_MButton, {
                    type: "outlined",
                    onClick: _ctx.handleClear
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Clear ")
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"])
                ])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }),
    (_ctx.showTable)
      ? (_openBlock(), _createBlock(_component_MDataTable, {
          key: 0,
          headers: _ctx.tableHeaders,
          "is-loading": _ctx.isLoading,
          data: _ctx.pricings || [],
          "item-per-page": 100,
          "has-search": true
        }, {
          sales_channel: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_2, _toDisplayString(item.sales_channel), 1 /* TEXT */)
          ]),
          airline: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_3, _toDisplayString(item.airline), 1 /* TEXT */)
          ]),
          pricing: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_MChip, {
                "border-less": true,
                class: "pricing-chip",
                variant: _ctx.renderChipVariant(item.pricing)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.pricing), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["variant"])
            ])
          ]),
          apply_on_gross_fare: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_5, _toDisplayString(item.apply_on_gross_fare ? "✅" : "❌"), 1 /* TEXT */)
          ]),
          sale_on_and_after: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_6, _toDisplayString(item.sale_on_and_after ? item.sale_on_and_after : "-"), 1 /* TEXT */)
          ]),
          sale_on_and_before: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_7, _toDisplayString(item.sale_on_and_before ? item.sale_on_and_before : "-"), 1 /* TEXT */)
          ]),
          travel_on_and_after: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_8, _toDisplayString(item.travel_on_and_after ? item.travel_on_and_after : "-"), 1 /* TEXT */)
          ]),
          travel_on_and_before: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_9, _toDisplayString(item.travel_on_and_before ? item.travel_on_and_before : "-"), 1 /* TEXT */)
          ]),
          remarks: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_10, _toDisplayString(item.remarks ? item.remarks : "-"), 1 /* TEXT */)
          ]),
          rbds: _withCtx(({ item }) => [
            _createElementVNode("div", null, _toDisplayString(_ctx.displayRbds(item.rbds)), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["headers", "is-loading", "data"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}