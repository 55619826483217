<template>
  <ag-card>
    <AgDiv class="header-title-container">
      <ag-heading variant="h2" title="Passenger Management" />
      <MButton style="margin-right: 10px" @click="handleNewPassenger">
        Add New Passenger
      </MButton>
    </AgDiv>
    <ag-loader v-if="isFetchingOrganizationPassengers" />
    <template v-else>
      <AgNotFound
        v-if="showNoReults()"
        test-id=""
        heading="No Passenger Found"
      />
      <AgTable
        v-else
        :headers="headers"
        :items="passengersList"
        :items-per-page="10"
        :has-pagination="false"
        :has-search="false"
        :simpleDataTable="true"
      >
        <template #col-action="{ item }">
          <AgDiv class="passenger-action-btn-container">
            <MButton
              :testId="
                genTestId(
                  ELEMENT_TYPES.BUTTON,
                  `passenger-${item.raw.public_id}-action`
                )
              "
              :disabled="item.raw.agent_type === AGENT_SUB_ROLES.OWNER"
              @click="handleUpdate($event, item.raw)"
            >
              {{ item.raw.action_update }}
            </MButton>
            <AGButton
              class="compare_icon"
              @click="handleDelete($event, item.raw)"
              variant="link"
            >
              <ag-icon class="passenger-delete-icon" name="deleteIcon" />
            </AGButton>
          </AgDiv>
        </template>
      </AgTable>
    </template>
    <DeletePassengerConfirmationModel
      :is-loading="isConfirmationDialogLoading"
      :is-open="confirmationDialogOpen"
      @handleAccept="handleConfirmDeletePassenger"
      @handleReject="handleDeletePassengerDialogClose"
    />
    <AddPassengerModal
      :is-open="updatePassengerDialogOpen"
      :is-edit="isPassengerEdit"
      @closeModal="handleClosePassengerModal"
      @onPassengerSubmit="handlePassengerSubmit"
      :passenger="selectedPassenger"
    />
  </ag-card>
</template>

<script>
import { PATH } from "@/ag-portal-common/constants/path";
import { AGENT_SUB_ROLES } from "@/ag-portal-common/enums/AGENT_SUB_ROLES";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { inject } from "vue";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import DeletePassengerConfirmationModel from "@/modules/Organization/components/DeletePassengerConfirmationModel";
import AddPassengerModal from "@/modules/Organization/components/AddPassengerModal";
import { format } from "date-fns";
import { FORMAT_YYY_MM_DD } from "@/ag-portal-common/constants/dateTimeFormats";
import analyticsService from "@/analytic.service";
import { ORGANIZATION_ANALYTICS_EVENTS } from "@/modules/Organization/constants/analyticsEvents";

export default {
  name: "PassengerManagement",
  components: {
    DeletePassengerConfirmationModel,
    AddPassengerModal,
  },
  mounted() {
    this.$store.dispatch(
      "getOrganizationPassengers",
      this.organizationPreference()?.organization_id
    );
  },
  data() {
    return {
      ELEMENT_TYPES,
      AGENT_SUB_ROLES,
      headers: [
        {
          title: "First Name",
          value: "first_name",
          key: "first_name",
          sortable: true,
        },
        {
          title: "Last Name",
          value: "last_name",
          key: "last_name",
          sortable: true,
        },
        {
          title: "Passport Number",
          value: "passport_number",
          key: "passport_number",
          sortable: false,
        },
        {
          title: "CNIC",
          value: "cnic",
          key: "cnic",
          sortable: false,
        },
        {
          title: "Action",
          value: "action",
          key: "action",
          sortable: false,
        },
      ],
      selectedPassenger: null,
      confirmationDialogOpen: false,
      updatePassengerDialogOpen: false,
      isPassengerEdit: false,
    };
  },
  methods: {
    genTestId,
    handleDelete(e, passenger) {
      e.stopImmediatePropagation();
      this.selectedPassenger = passenger;
      this.confirmationDialogOpen = true;
    },
    handleUpdate(e, passenger) {
      e.stopImmediatePropagation();
      this.selectedPassenger = passenger;
      this.isPassengerEdit = true;
      this.updatePassengerDialogOpen = true;
    },
    handleNewPassenger() {
      this.isPassengerEdit = false;
      this.updatePassengerDialogOpen = true;
    },
    handleConfirmDeletePassenger() {
      analyticsService.logActionEvent(
        ORGANIZATION_ANALYTICS_EVENTS.DELETE_PASSENGER_MANAGEMENT,
        this.selectedPassenger
      );
      this.$store.dispatch("deleteOrganizationPassengerById", {
        passengerId: this.selectedPassenger?.public_id,
        organizationId: this.organizationPreference()?.organization_id,
      });
      this.selectedPassenger = null;
      this.confirmationDialogOpen = false;
    },
    onSuccessHandler(successCallback) {
      return () => {
        this.selectedPassenger = null;
        this.isPassengerEdit = false;
        this.updatePassengerDialogOpen = false;
        successCallback();
      };
    },
    handlePassengerSubmit({ payload, successCallback }) {
      const updatedPayload = {
        ...payload,
        dob: format(new Date(payload.dob), FORMAT_YYY_MM_DD),
        passport_expiry: payload.passport_expiry
          ? format(new Date(payload.passport_expiry), FORMAT_YYY_MM_DD)
          : null,
        passport_number: payload.passport_number
          ? payload.passport_number
          : null,
        cnic: payload.cnic ? payload.cnic : null,
      };
      if (this.isPassengerEdit) {
        this.$store.dispatch("updateOrganizationPassengerById", {
          passengerId: this.selectedPassenger?.public_id,
          organizationId: this.organizationPreference()?.organization_id,
          body: updatedPayload,
          onSuccessHandler: this.onSuccessHandler(successCallback),
        });
      } else {
        this.$store.dispatch("createOrganizationPassenger", {
          organizationId: this.organizationPreference()?.organization_id,
          body: [updatedPayload],
          onSuccessHandler: this.onSuccessHandler(successCallback),
        });
      }
    },
    handleDeletePassengerDialogClose() {
      this.selectedPassenger = null;
      this.isPassengerEdit = false;
      this.confirmationDialogOpen = false;
    },
    handleClosePassengerModal() {
      this.selectedPassenger = null;
      this.isPassengerEdit = false;
      this.updatePassengerDialogOpen = false;
    },
    showNoReults() {
      return (
        !this.isFetchingOrganizationPassengers &&
        this.$store.getters.organizationPassengers.length < 1
      );
    },
  },
  computed: {
    PATH() {
      return PATH;
    },
    passengersList() {
      const passengersList = this.$store.getters.organizationPassengers;
      return passengersList.map((item) => ({
        ...item,
        public_id: item.public_id,
        first_name: item?.first_name,
        last_name: item?.last_name,
        passport_number: item?.passport_number ? item?.passport_number : "-",
        cnic: item?.cnic ? item?.cnic : "-",
        action_delete: "Delete",
        action_update: "Update",
      }));
    },
    isFetchingOrganizationPassengers() {
      return this.$store.getters.isFetchingOrganizationPassengers;
    },
    isConfirmationDialogLoading() {
      return this.$store.getters.setPassengerActionLoading;
    },
  },

  setup() {
    const organizationPreference = inject(
      AUTH_CONTEXT_KEYS.organizationPreference
    );
    return {
      organizationPreference,
    };
  },
};
</script>

<style lang="scss">
.passenger-action-btn-container {
  display: flex;
  justify-content: space-between;
  width: 150px;
}

.header-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.passenger-delete-icon {
  svg {
    stroke: #d21414;
  }
}
</style>
