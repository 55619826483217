import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f37c506"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "create-org-btn-container" }
const _hoisted_2 = { class: "organization-id" }
const _hoisted_3 = { class: "min-width-200" }
const _hoisted_4 = { class: "min-width-200" }
const _hoisted_5 = { class: "status-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MButton = _resolveComponent("MButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MChip = _resolveComponent("MChip")!
  const _component_MDataTable = _resolveComponent("MDataTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_MButton, {
        onClick: _ctx.onClickCreateNew,
        testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'create-new-organization'),
        type: "filled"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Create new organization ")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick", "testId"])
    ]),
    _createVNode(_component_MDataTable, {
      headers: _ctx.headers,
      "is-loading": _ctx.$store.getters.isFetchingOrganizations,
      data: _ctx.transformTableData,
      "items-per-page": 10,
      "has-search": true,
      simpleDataTable: true
    }, {
      public_id: _withCtx(({ item }) => [
        _createVNode(_component_router_link, {
          to: `${_ctx.PATH.ORGANIZATION}/${item.id}`
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(item.public_id), 1 /* TEXT */)
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
      ]),
      name: _withCtx(({ item }) => [
        _createElementVNode("div", _hoisted_3, _toDisplayString(item.name), 1 /* TEXT */)
      ]),
      sector_name: _withCtx(({ item }) => [
        _createElementVNode("div", _hoisted_4, _toDisplayString(item.sector_name), 1 /* TEXT */)
      ]),
      status: _withCtx(({ item }) => [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_MChip, {
            "border-less": true,
            class: "pricing-chip",
            variant: _ctx.organizationStatusClass(item.status)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.status), 1 /* TEXT */)
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["variant"])
        ])
      ]),
      action: _withCtx(({ item }) => [
        _createVNode(_component_MButton, {
          type: "filled",
          onClick: ($event: any) => (_ctx.onClickUpdate($event, item.id)),
          testId: 
          _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, `organization-${item.id}-action`)
        
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.action), 1 /* TEXT */)
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick", "testId"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["headers", "is-loading", "data"])
  ], 64 /* STABLE_FRAGMENT */))
}