import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_AgHeading = _resolveComponent("AgHeading")!

  return (_openBlock(), _createBlock(_component_AgDiv, { class: "not_found_wrap" }, {
    default: _withCtx(() => [
      _createVNode(_component_AgDiv, { class: "icon_404" }),
      _createVNode(_component_AgHeading, {
        title: "Page Not Found",
        variant: "h3"
      }),
      _createVNode(_component_AgHeading, {
        title: "May be this page doesn't exists or you don't have permission to view it",
        variant: "p"
      })
    ]),
    _: 1 /* STABLE */
  }))
}