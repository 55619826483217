<template>
  <AgCard>
    <template v-if="isAGSuperUser()">
      <ag-loader v-if="$store.getters.isFetchingOrganizations" />
      <template v-else>
        <AgDiv class="autocomplete-container">
          <p class="label">Select Organization</p>
          <OrganizationAutocomplete @onChange="handleOrganizationChange" />
        </AgDiv>
      </template>
    </template>
    <template v-if="showWhatsAppManagement">
      <AgDiv class="header-title-container">
        <AgDiv>
          <ag-heading variant="h2" :title="renderOrganizationName" />
          <p class="paragraph">
            You can manage your whatsapp numbers authorised to contact our
            support team here. Authorised numbers can contact our support team
            for all support matters: Ticket Issuance, Refund, Reissue, Billing,
            Payments, Hotels and everything else.
          </p>
          <p>
            For more details contact
            <a
              class="whatsapp_link"
              href="https://wa.me/923111145623"
              target="_blank"
              ><AgIcon
                name="whatsappIcon"
                style="display: inline; margin-left: 5px"
              />
              Support</a
            >
          </p>
        </AgDiv>
        <MButton
          style="margin-right: 10px"
          @click="handleNewWhatsappMember"
          :disabled="isWhatsappMembersLoading"
        >
          Add New Whatsapp Member
        </MButton>
      </AgDiv>
      <ag-loader v-if="isWhatsappMembersLoading" />
      <template v-else>
        <AgNotFound
          v-if="showNoReults()"
          test-id=""
          heading="No Whatsapp Member Found"
        />
        <AgTable
          v-else
          :headers="headers"
          :items="whatsappMembers"
          :items-per-page="10"
          :has-pagination="true"
          :has-search="false"
          :simpleDataTable="true"
        >
          <template #col-is_authorized="{ item }">
            <AgDiv class="is-authorized-icon-container">{{
              item.raw.is_authorized ? "✅" : "❌"
            }}</AgDiv>
          </template>
          <template #col-action="{ item }">
            <AgDiv class="whatsapp-action-btn-container">
              <MButton
                @click="handleGenerateOTP($event, item.raw)"
                type="filled"
                :is-loading="isGenerateOTPLoading(item)"
              >
                {{ item.raw.is_authorized ? "Un Authorize" : "Authorize" }}
              </MButton>
              <AGButton
                class="compare_icon"
                @click="handleDelete($event, item.raw)"
                style="margin-right: 10px"
                variant="link"
              >
                <ag-icon class="whatsapp-delete-icon" name="deleteIcon" />
              </AGButton>
            </AgDiv>
          </template>
        </AgTable>
      </template>
    </template>
    <AgNotFound v-else test-id="" heading="Please Select Organization" />
  </AgCard>

  <DeleteWhatsappMemberConfirmationModel
    :is-loading="isConfirmationDialogLoading"
    :is-open="confirmationDialogOpen"
    @handleAccept="handleConfirmDeleteWhatsappMember"
    @handleReject="handleDialogClose"
  />
  <OTPInputModal
    :is-open="isOTPModalOpen"
    :isSubmitLoading="$store.getters.isWhatsappActionLoading"
    @submitOtp="handleSubmitOTP"
    @handleCloseDialog="handleCloseOTPDialog"
  />
  <AddWhatsappMemberModal
    :is-open="isAddWhatsappMemberModalOpen"
    @closeModal="handleCloseAddWhatsappMemberModal"
  />
</template>

<script lang="ts">
import { PATH } from "@/ag-portal-common/constants/path";
import { AGENT_SUB_ROLES } from "@/ag-portal-common/enums/AGENT_SUB_ROLES";
import { formatQueryPath, genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { defineComponent, inject } from "vue";
import _ from "lodash";

import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { IWhatsappMember } from "@/ag-portal-common/interfaces/whatsappMember.interface";
import {
  IOrganization,
  IOrganizationFromLoginResponse,
} from "@/ag-portal-common/interfaces/organization.interface";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import { IHeading } from "@/ag-portal-common/interfaces/header.interface";
import AddWhatsappMemberModal from "@/modules/Organization/components/AddWhatsappMemberModal.vue";
import DeleteWhatsappMemberConfirmationModel from "@/modules/Organization/components/DeleteWhatsappMember.vue";
import OTPInputModal from "@/ag-portal-common/components/OTPInputModal.vue";
import OrganizationAutocomplete from "@/modules/Organization/components/OrganizationAutocomplete.vue";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";

export default defineComponent({
  name: "WhatsappManagement",
  components: {
    AddWhatsappMemberModal,
    DeleteWhatsappMemberConfirmationModel,
    OTPInputModal,
    OrganizationAutocomplete,
  },
  mounted() {
    if (!this.isAGSuperUser()) {
      this.fetchMembersWithClearState();
    }
  },
  methods: {
    genTestId,
    handleDelete(e: Event, whatsappMember: IWhatsappMember) {
      e.stopImmediatePropagation();
      this.selectedMember = whatsappMember;
      this.confirmationDialogOpen = true;
    },
    handleNewWhatsappMember() {
      this.isAddWhatsappMemberModalOpen = true;
    },
    handleDialogClose() {
      this.selectedMember = null;
      this.confirmationDialogOpen = false;
    },
    fetchMembersWithClearState() {
      this.selectedMember = null;
      this.confirmationDialogOpen = false;
      this.fetchWhatsappMembers();
    },
    handleConfirmDeleteWhatsappMember() {
      const orgId = this.getOrganizationId();

      this.$store.dispatch("deleteWhatsappMemberById", {
        whatsappMemberId: this.selectedMember?.public_id,
        organizationId: orgId,
        successHandler: this.fetchMembersWithClearState,
      });
    },
    showNoReults() {
      return (
        !this.$store.getters.isWhatsappMembersLoading &&
        this.whatsappMembers.length < 1
      );
    },
    handleCloseAddWhatsappMemberModal() {
      this.isAddWhatsappMemberModalOpen = false;
    },
    fetchWhatsappMembers() {
      const orgId = this.getOrganizationId();
      this.$store.dispatch("getWhatsappMembers", orgId);
    },
    handleSuccessHanlderGenerateOTP(data: any) {
      this.generatedOTPData = data;
      this.isOTPModalOpen = true;
    },
    handleGenerateOTP(e: Event, whatsappMember: IWhatsappMember) {
      this.currentLoadingGenerateOTPButton = whatsappMember;
      const orgId = this.getOrganizationId();
      this.$store.dispatch("generateOTPForWhatsappMember", {
        whatsappMemberId: whatsappMember?.public_id,
        organizationId: orgId,
        successHandler: this.handleSuccessHanlderGenerateOTP,
      });
    },
    handleCloseOTPDialog() {
      this.currentLoadingGenerateOTPButton = null;
      this.generatedOTPData = {};
      this.isOTPModalOpen = false;
    },
    handleSuccessHanlderSubmitOTP() {
      this.isOTPModalOpen = false;
      this.generatedOTPData = {};
      this.fetchWhatsappMembers();
    },
    handleSubmitOTP(otp: string) {
      const payload = {
        authorization_id: this.generatedOTPData?.authorization_id,
        is_authorized: this.currentLoadingGenerateOTPButton?.is_authorized
          ? false
          : true,
        otp_code: otp,
      };
      const orgId = this.getOrganizationId();
      this.$store.dispatch("authorizeOTPForWhatsappMember", {
        whatsappMemberId: this.currentLoadingGenerateOTPButton?.public_id,
        organizationId: orgId,
        payload: payload,
        successHandler: this.handleSuccessHanlderSubmitOTP,
      });
    },
    isGenerateOTPLoading(item: any) {
      const currentItemLoading =
        this.currentLoadingGenerateOTPButton?.public_id === item.raw?.public_id;
      return this.$store.getters.isGenerateOTPLoading && currentItemLoading;
    },
    handleOrganizationChange(value: string) {
      if (value) {
        const path = `${PATH.WHATSAPP_MANAGEMENT}${formatQueryPath({
          org_id: value,
        })}`;
        this.$router.push(path);
      }
    },
    isAGSuperUser() {
      const user = this.user();
      return user?.role_unique_id === USER_ROLES.AG_SUPER_USER;
    },
    getOrganizationId() {
      if (this.isAGSuperUser()) {
        const { org_id } = this.$route.query;
        return org_id;
      } else {
        return this.organization()?.organization_id;
      }
    },
  },
  computed: {
    PATH() {
      return PATH;
    },
    whatsappMembers() {
      const whatsappMembers = this.$store.getters.whatsappMembers || [];
      return whatsappMembers.map((item: IWhatsappMember) => ({
        ...item,
        action_delete: "Delete",
        generate_otp: "Generate OTP",
      }));
    },
    isWhatsappMembersLoading() {
      return this.$store.getters.isWhatsappMembersLoading;
    },
    isConfirmationDialogLoading() {
      return this.$store.getters.isWhatsappActionLoading;
    },
    showWhatsAppManagement(): boolean {
      const { org_id } = this.$route.query;
      if (this.isAGSuperUser()) {
        return Boolean(org_id);
      }
      return true;
    },
    renderOrganizationName(): string {
      const { org_id } = this.$route.query;
      if (this.isAGSuperUser() && org_id) {
        const organizations: IOrganization[] =
          this.$store.getters.organizations;
        const name =
          organizations.find((x: IOrganization) => x.public_id == org_id)
            ?.name || "";
        return `Whatsapp Management - (${name})`;
      }
      return "Whatsapp Management";
    },
  },
  data(): {
    ELEMENT_TYPES: IObject;
    AGENT_SUB_ROLES: IObject;
    headers: IHeading[];
    selectedMember: IWhatsappMember | null;
    confirmationDialogOpen: boolean;
    isAddWhatsappMemberModalOpen: boolean;
    isOTPModalOpen: boolean;
    generatedOTPData: any;
    currentLoadingGenerateOTPButton: IWhatsappMember | null;
    lastOrganizationId: string | null;
  } {
    return {
      isAddWhatsappMemberModalOpen: false,
      lastOrganizationId: null,
      ELEMENT_TYPES,
      AGENT_SUB_ROLES,
      headers: [
        {
          title: "Name",
          value: "name",
          key: "name",
          sortable: true,
        },
        {
          title: "Contact Number",
          value: "contact_number",
          key: "contact_number",
          sortable: true,
        },
        {
          title: "Authorized",
          value: "is_authorized",
          key: "is_authorized",
          sortable: false,
        },
        {
          title: "Action",
          value: "action",
          key: "action",
          sortable: false,
        },
      ],
      selectedMember: null,
      confirmationDialogOpen: false,
      isOTPModalOpen: false,
      currentLoadingGenerateOTPButton: null,
      generatedOTPData: {},
    };
  },
  created() {
    if (this.isAGSuperUser()) {
      this.$store.dispatch("fetchOrganizations");
    }
  },
  setup() {
    const organization = inject(
      AUTH_CONTEXT_KEYS.organization
    ) as () => IOrganizationFromLoginResponse;
    const user = inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2;
    return { organization, user };
  },
  watch: {
    "$route.query": {
      handler: function (value) {
        const isNotEmpty = !_.isEmpty(value);
        if (isNotEmpty) {
          if (this.lastOrganizationId !== value?.org_id) {
            this.fetchMembersWithClearState();
            this.lastOrganizationId = value?.org_id;
          }
        }
      },
      immediate: true,
    },
  },
});
</script>

<style lang="scss">
.whatsapp-action-btn-container {
  display: flex;
  justify-content: space-between;
  width: 200px;
}
.is-authorized-icon-container {
  margin-left: 30px;
}

.header-title-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start !important;
}

.whatsapp-delete-icon {
  svg {
    stroke: #d21414;
  }
}

.autocomplete-container {
  max-width: 400px;
  .label {
    font-weight: bold;
  }
}

.whatsapp_link {
  color: svg path {
    fill: green;
  }
  &:hover {
    color: blue;
    text-decoration: underline;
  }
}

.paragraph {
  max-width: 600px;
  text-align: justify;
  line-height: 1.3;
}

@media screen and (max-width: 600px) {
  .header-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
  }
}
</style>
