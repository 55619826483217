<template>
  <MAccordion :initial-open="false" class="booking-filter-accordion">
    <template #title>
      <div class="accordion-title-container">
        <span class="accordion-title">Filters</span>
      </div></template
    >
    <template #content>
      <div class="booking-filter-accordion-content">
        <ag-row class="d-flex align-items-center">
          <ag-column sm="6" md="4" lg="3" cols="12">
            <MTextfield v-model:inputValue="bookingId" label="Booking Id" />
          </ag-column>

          <ag-column sm="6" md="4" lg="3" cols="12">
            <MTextfield type="email" v-model:inputValue="email" label="Email" />
          </ag-column>
          <ag-column sm="6" md="4" lg="2" cols="12">
            <MCombobox
              v-model:inputValue="statusField"
              label="Status"
              itemValue="value"
              itemLabel="label"
              :options="BOOKING_STATUSES_OPTIONS"
            />
          </ag-column>
          <ag-column sm="6" md="4" lg="3" cols="12">
            <ag-calendar
              label="Booking Date (From)"
              :testId="
                genTestId(
                  ELEMENT_TYPES.CALENDAR_FIELD,
                  'myBookings-bookingFromField'
                )
              "
              variant="outlined"
              v-model="bookingFromField"
              :date="bookingFromField"
              :max-date="new Date()"
              @onChangeDate="changeBookingFromField"
              @update:bookingFromField="changeBookingFromField"
            />
          </ag-column>
          <ag-column sm="6" md="4" lg="3" cols="12">
            <ag-calendar
              label="Booking Date (To)"
              :testId="
                genTestId(
                  ELEMENT_TYPES.CALENDAR_FIELD,
                  'myBookings-bookingToField'
                )
              "
              variant="outlined"
              v-model="bookingToField"
              :date="bookingToField"
              :min-date="bookingFromField"
              :max-date="new Date()"
              @onChangeDate="changeBookingToField"
              @update:bookingToField="changeBookingToField"
            />
          </ag-column>
          <div class="btn-container">
            <MButton
              :testId="genTestId(ELEMENT_TYPES.BUTTON, 'myBookings-search')"
              variant="primary"
              type="filled"
              @click="fetchBookings"
              :disabled="isLoading"
            >
              Search
            </MButton>

            <MButton
              :testId="
                genTestId(ELEMENT_TYPES.BUTTON, 'myBookings-refresh-search')
              "
              variant="primary"
              type="filled"
              @click="fetchBookings"
              :disabled="isLoading"
            >
              Refresh
            </MButton>

            <MButton
              :testId="genTestId(ELEMENT_TYPES.BUTTON, 'myBookings-clearAll')"
              variant="primary"
              type="filled"
              @click="clearSearchFilters"
              :disabled="isLoading"
            >
              Clear All
            </MButton>
          </div>
        </ag-row>
      </div>
    </template>
  </MAccordion>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import {
  genTestId,
  getFormattedDateTime,
} from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { BOOKING_STATUS } from "@/ag-portal-common/enums/BOOKING_STATUS";
import { FetchBookingsParamsRequestModel } from "@/modules/MyBookings/models/fetchBookingsParams.request";
import { FORMAT_YYY_MM_DD } from "@/ag-portal-common/constants/dateTimeFormats";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import {
  MAccordion,
  MButton,
  MTextfield,
  MCombobox,
} from "@aeroglobe/ag-core-ui";
import { BOOKING_STATUSES_OPTIONS } from "../constants";
import analyticsService from "@/analytic.service";
import { MY_BOOKING_ANALYTICS_EVENTS } from "@/modules/MyBookings/constants/anaylticsEvents";

export default defineComponent({
  name: "MyBookingsHotelFiltersView",
  components: {
    MButton,
    MTextfield,
    MCombobox,
    MAccordion,
  },
  data(): {
    ELEMENT_TYPES: IObject;
    bookingStatuses: Array<BOOKING_STATUS>;
    bookingId: string;
    statusField: string;
    bookingFromField: Date | null;
    bookingToField: Date | null;
    email: string;
    BOOKING_STATUSES_OPTIONS: IObject[];
  } {
    return {
      ELEMENT_TYPES,
      BOOKING_STATUSES_OPTIONS,
      bookingStatuses: Object.values(BOOKING_STATUS),
      statusField: "",
      bookingFromField: null,
      bookingToField: null,
      bookingId: "",
      email: "",
    };
  },
  methods: {
    genTestId,
    changeBookingFromField(date: Date) {
      this.bookingFromField = date;
    },
    changeBookingToField(date: Date) {
      this.bookingToField = date;
    },
    clearSearchFilters() {
      this.statusField = "";
      this.bookingToField = null;
      this.bookingFromField = null;
      this.bookingId = "";
      this.email = "";

      const payload = {
        "clear-all": true,
      };

      let eventName = MY_BOOKING_ANALYTICS_EVENTS.HOTELS_CLEARALL;

      analyticsService.logActionEvent(eventName, payload);

      this.fetchBookings();
    },
    fetchBookings() {
      let payload: FetchBookingsParamsRequestModel = {
        ...(this.bookingId && { booking_id: this.bookingId }),
        ...(this.email && { creator_email: this.email }),
        ...(this.statusField && { status: this.statusField?.toUpperCase() }),
        ...(this.bookingFromField && {
          start: getFormattedDateTime(this.bookingFromField, FORMAT_YYY_MM_DD),
        }),
        ...(this.bookingToField && {
          end: getFormattedDateTime(this.bookingToField, FORMAT_YYY_MM_DD),
        }),
      };

      this.$emit("fetchBookings", payload);
    },
  },
  computed: {
    isLoading(): boolean {
      return this.$store.getters.isFetchingHotelBookings;
    },
  },
});
</script>

<style lang="scss" scoped>
.booking-filter-accordion {
  margin: 24px;
  overflow: visible;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.accordion-title-container {
  display: flex;
  align-items: center;
}
.accordion-chip-container {
  display: flex;
  gap: 10px;
}
.accordion-title {
  font-size: 20px;
  margin-right: 20px;
  font-weight: 600;
  text-align: start;
}
.booking-filter-accordion-content {
  margin-top: 40px;
}
.btn-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .btn-container {
    margin-bottom: 20px;
  }
}
</style>
