<template>
  <MCard class="search-bar-card">
    <AgRow>
      <AgColumn sm="12" md="8" lg="8" cols="12">
        <MFinancialProfileCombobox
          class="fp-combo"
          v-model:inputValue="financialProfileInput"
          label="Financial Profiles"
          itemValue="value"
          itemLabel="label"
          :disabled="isFinancialProfileFetching"
          :options="financialProfileOptions"
          :hasError="hasError"
          :errorMessage="errorMessage"
        />
      </AgColumn>

      <AgColumn sm="12" md="4" lg="4" cols="12">
        <div class="btn-container">
          <MButton
            @click="onFetchAgPricing"
            class="get-price-detail-btn"
            :disabled="getPriceDetailsButtonDisabled"
          >
            Get Pricing
          </MButton>
          <MButton type="outlined" @click="handleClear"> Clear </MButton>
        </div>
      </AgColumn>
    </AgRow>
  </MCard>

  <MDataTable
    :headers="tableHeaders"
    :is-loading="isLoading"
    :data="pricings || []"
    :item-per-page="100"
    :has-search="true"
    v-if="showTable"
  >
    <template #sales_channel="{ item }">
      <div class="table-column-width sales-channel">
        {{ item.sales_channel }}
      </div>
    </template>
    <template #airline="{ item }">
      <div class="table-column-width">{{ item.airline }}</div>
    </template>
    <template #pricing="{ item }">
      <div class="table-column-width pricing-chip-container">
        <MChip
          :border-less="true"
          class="pricing-chip"
          :variant="renderChipVariant(item.pricing)"
          >{{ item.pricing }}</MChip
        >
      </div>
    </template>
    <template #apply_on_gross_fare="{ item }">
      <div class="table-column-width">
        {{ item.apply_on_gross_fare ? "✅" : "❌" }}
      </div>
    </template>
    <template #sale_on_and_after="{ item }">
      <div class="table-column-width">
        {{ item.sale_on_and_after ? item.sale_on_and_after : "-" }}
      </div>
    </template>
    <template #sale_on_and_before="{ item }">
      <div class="table-column-width">
        {{ item.sale_on_and_before ? item.sale_on_and_before : "-" }}
      </div>
    </template>
    <template #travel_on_and_after="{ item }">
      <div class="table-column-width">
        {{ item.travel_on_and_after ? item.travel_on_and_after : "-" }}
      </div>
    </template>
    <template #travel_on_and_before="{ item }">
      <div class="table-column-width">
        {{ item.travel_on_and_before ? item.travel_on_and_before : "-" }}
      </div>
    </template>
    <template #remarks="{ item }">
      <div class="table-column-width">
        {{ item.remarks ? item.remarks : "-" }}
      </div>
    </template>
    <template #rbds="{ item }">
      <div>{{ displayRbds(item.rbds) }}</div>
    </template>
  </MDataTable>
</template>

<script lang="ts">
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { defineComponent, inject } from "vue";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";

import analyticsService from "@/analytic.service";
import AgPricingService from "@/modules/AgPricing/services/agPricing.service";
import notificationService from "@/ag-portal-common/services/notification.service";

import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { AG_PRICING_ANALYTICS_EVENTS } from "@/modules/AgPricing/constants/anaylticsEvents";

import {
  MFinancialProfileCombobox,
  MButton,
  MDataTable,
  MChip,
  MCard,
} from "@aeroglobe/ag-core-ui";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import { ChipVariant } from "@aeroglobe/ag-core-ui/dist/src/components/material/atoms/atoms.type";
import { IOrganizationFromLoginResponse } from "@/ag-portal-common/interfaces/organization.interface";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import { FPComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";

export default defineComponent({
  name: "AgentPricing",
  components: {
    MFinancialProfileCombobox,
    MButton,
    MChip,
    MDataTable,
    MCard,
  },
  data() {
    return {
      ELEMENT_TYPES,
      financialProfileInput: "",
      disableButtons: false,
      isLoading: false,
      pricings: [],
      hasError: false,
      errorMessage: "",
      validateFinancialProfileErrors: false,
      tableHeaders: [
        {
          title: "Sales Channel",
          value: "sales_channel",
          key: "sales_channel",
          sortable: true,
          align: "left",
        },
        {
          title: "Airline",
          value: "airline",
          key: "airline",
          sortable: true,
        },
        {
          title: "Pricing",
          value: "pricing",
          key: "pricing",
          sortable: true,
        },
        {
          title: "Region",
          value: "region",
          key: "region",
          sortable: true,
        },
        {
          title: "Apply on gross fare",
          value: "apply_on_gross_fare",
          key: "apply_on_gross_fare",
          sortable: true,
        },
        {
          title: "Rbds",
          value: "rbds",
          key: "rbds",
          sortable: true,
        },
        {
          title: "Sale on and after",
          value: "sale_on_and_after",
          key: "sale_on_and_after",
          sortable: true,
        },
        {
          title: "Sale on and before",
          value: "sale_on_and_before",
          key: "sale_on_and_before",
          sortable: true,
        },
        {
          title: "Travel on and after",
          value: "travel_on_and_after",
          key: "travel_on_and_after",
          sortable: true,
        },
        {
          title: "Travel on and before",
          value: "travel_on_and_before",
          key: "travel_on_and_before",
          sortable: true,
        },
        {
          title: "Remarks",
          value: "remarks",
          key: "remarks",
          sortable: true,
        },
      ],
    };
  },
  async created() {
    await this.$store.dispatch("fetchFinancialProfiles");
  },
  async mounted() {
    let organization =
      (this.organization() as IOrganizationFromLoginResponse) || null;

    if (!this.isSuperUserOrOperationUser) {
      let defaultFinancialProfile = organization.financial_profiles.find(
        (item) => item.is_default
      );

      if (defaultFinancialProfile) {
        this.financialProfileInput = defaultFinancialProfile.public_id;
      }
    }
  },
  computed: {
    isFinancialProfileFetching(): boolean {
      return this.$store.getters.isFetchingFinancialProfiles;
    },
    financialProfileOptions(): FPComboboxOptions[] {
      let financialProfiles;
      if (this.isSuperUserOrOperationUser) {
        financialProfiles = this.$store.getters.financialProfiles;
      } else {
        const organization =
          this.organization() as IOrganizationFromLoginResponse;
        financialProfiles = organization?.financial_profiles;
      }

      return financialProfiles?.map((fp: IFinancialProfile) => {
        const planType = fp?.plan_name?.split(" ")[1]?.toLowerCase();
        const sector = fp?.sector?.replace(/^Aeroglobe\s*-\s*/, "");
        return {
          id: fp?.platform_id,
          label: fp?.financial_profile_name,
          value: fp?.public_id,
          isActive: fp?.status === ORGANIZATION_STATUSES.ACTIVE,
          status: fp?.status,
          sector: sector,
          type: planType,
        };
      });
    },
    getPriceDetailsButtonDisabled(): boolean {
      return (
        this.isFinancialProfileFetching || this.financialProfileInput === ""
      );
    },
    resultNotFound(): boolean {
      return this.isLoading === false && this.pricings.length < 1;
    },
    showTable(): boolean {
      return this.isLoading || this.pricings.length > 0;
    },
    isSuperUserOrOperationUser(): boolean {
      return [USER_ROLES.AG_SUPER_USER, USER_ROLES.OPERATIONS].includes(
        this.userRole()
      );
    },
  },
  methods: {
    genTestId,
    displayRbds(rbds: []): string {
      if (rbds.length === 0) {
        return "-";
      } else {
        return rbds.join(" ").toUpperCase();
      }
    },
    async onFetchAgPricing(): Promise<void> {
      this.pricings = [];
      this.isLoading = true;

      this.trackAnalytics();

      try {
        const service = new AgPricingService();
        const response = await service.get(this.financialProfileInput);
        this.pricings = response.data;
        this.isLoading = false;
      } catch {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = "Error while fetching ag-pricing";
        notificationService.triggerNotification();
      }
    },

    canFetch(): boolean {
      return this.financialProfileInput !== "";
    },
    trackAnalytics(): void {
      const payload = {
        "user-role": this.user().role_unique_id,
        "financial-profile-id": this.financialProfileInput,
      };
      analyticsService.logActionEvent(
        AG_PRICING_ANALYTICS_EVENTS.FETCH_AG_PRICING,
        payload
      );
    },
    handleClear() {
      this.financialProfileInput = "";
      this.pricings = [];
      this.isLoading = false;
    },
    renderChipVariant(pricing: string): ChipVariant {
      return pricing.startsWith("-") ? "success" : "warning";
    },
    userRole(): number {
      let user = this.user() as IUserV2 | null;
      return user?.role_unique_id as number;
    },
    handleFinancialProfileErrors() {
      if (this.validateFinancialProfileErrors) {
        if (this.financialProfileInput === "") {
          this.hasError = true;
          this.errorMessage = "Please select financial profile";
        } else {
          this.hasError = false;
          this.errorMessage = "";
        }
      }
    },
  },
  watch: {
    financialProfileInput(value: string) {
      localStorage.setItem("fp", this.financialProfileInput);
    },
  },
  setup() {
    const user = inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2;
    const organization = inject(
      AUTH_CONTEXT_KEYS.organization
    ) as () => IOrganizationFromLoginResponse;
    return { user, organization };
  },
});
</script>

<style lang="scss" scoped>
.table-column-width {
  min-width: 150px;
  text-align: center;
}
.search-bar-card {
  margin-bottom: 15px;
}
.pricing-chip {
  text-align: center;
  display: flex;
  justify-content: center;
  font-weight: 600;
}
.btn-container {
  display: flex;
  gap: 10px;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
}
.fp-combo {
  margin-bottom: 0px !important;
}
.pricing-chip-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sales-channel {
  text-align: left;
}
</style>
