import { PAYMENT_STATUS } from "@/ag-portal-common/enums/PAYMENT_STATUS";
import { RECEIPT_STATUS } from "@/ag-portal-common/enums/RECEIPT_STATUS";

export const PAYMENTS_API_PATH = {
  GET: "v1/payments/",
  GET_BY_ID: "v1/payments/{0}",
  POST: "v1/payments/",
  GET_PAYMENTS_BY_DATE_RANGE: "v1/payments/payments-by-sector",
  GET_PAYMENT_LIST: "v1/payments/methods/?organization_id=",
};
export const payGuideUrl =
  "https://firebasestorage.googleapis.com/v0/b/aeroglobe-network.appspot.com/o/Guide%20for%20INTERNET%20%26%20MOBILE%20BANKING.%20OTC%2C%20Wallets%20%26%20ATM's.pdf?alt=media&token=6ad19cce-670d-4ee0-9b2c-cb1a6a69b795";

export const PAYMENT_STATUS_MAPPING = {
  [PAYMENT_STATUS.PENDING]: "yellow",
  [PAYMENT_STATUS.INITIATED]: "yellow",
  [PAYMENT_STATUS.SUCCESS]: "green",
  [PAYMENT_STATUS.CANCELED]: "red",
  [PAYMENT_STATUS.ERROR]: "red",
  [PAYMENT_STATUS.DECLINED]: "red",
  [PAYMENT_STATUS.EXPIRED]: "red",
};

export const RECEIPT_STATUS_MAPPING = {
  [RECEIPT_STATUS.PAYMENT_PENDING]: "gray",
  [RECEIPT_STATUS.PENDING]: "gray",
  [RECEIPT_STATUS.FAILED]: "red",
  [RECEIPT_STATUS.POSTED]: "blue",
};
