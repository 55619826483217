<template>
  <AgTabs test-id="test">
    <template #TabHeading>
      <v-tab value="one" @click="trackAnalytics('flights')">
        <ag-icon test-id="flightIcon" name="flightIcon"></ag-icon>
        <span>Flights</span>
      </v-tab>
      <v-tab value="two" @click="trackAnalytics('hotels')">
        <ag-icon test-id="hotelIcon" name="hotelIcon"></ag-icon>
        <span>Hotels</span></v-tab
      >
      <v-tab value="three" @click="trackAnalytics('tours')">
        <ag-icon test-id="umrah" name="umrah"></ag-icon>
        <span>Tours</span></v-tab
      >
    </template>
    <template #TabDetail>
      <v-window-item
        value="one"
        transition="fade"
        reverse-transition="fade"
        :disabled="true"
      >
        <flight-bookings :is-dashboard-view="isDashboardView" />
      </v-window-item>
      <v-window-item
        value="two"
        transition="fade"
        reverse-transition="fade"
        :disabled="true"
      >
        <hotel-bookings />
      </v-window-item>
      <v-window-item
        value="three"
        transition="fade"
        reverse-transition="fade"
        :disabled="true"
      >
        <tour-bookings />
      </v-window-item>
    </template>
  </AgTabs>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FlightBookings from "@/modules/MyBookings/components/FlightBookings.vue";
import HotelBookings from "@/modules/MyBookings/components/HotelBookings.vue";
import TourBookings from "@/modules/MyBookings/components/GroupTourBookings.vue";

import analyticsService from "@/analytic.service";
import { MY_BOOKING_ANALYTICS_EVENTS } from "@/modules/MyBookings/constants/anaylticsEvents";

export default defineComponent({
  name: "MyBookingsTable",
  props: {
    isDashboardView: {
      type: Boolean,
      default: false,
    },
  },
  components: { HotelBookings, FlightBookings, TourBookings },
  methods: {
    trackAnalytics(tabName: string): void {
      const payload = {
        "tab-name": tabName,
      };

      let eventName = "";

      switch (tabName) {
        case "flights":
          eventName = MY_BOOKING_ANALYTICS_EVENTS.FLIGHTS_TAB_CLICKED;
          break;

        case "hotels":
          eventName = MY_BOOKING_ANALYTICS_EVENTS.HOTELS_TAB_CLICKED;
          break;

        case "tours":
          eventName = MY_BOOKING_ANALYTICS_EVENTS.TOURS_TAB_CLICKED;
          break;

        default:
          break;
      }

      analyticsService.logActionEvent(eventName, payload);
    },
  },
});
</script>
