<template>
  <ag-loader v-if="isLoading" />
  <template v-else>
    <AgCard class="insights_header">
      <p class="heading">New Organizations Created</p>
      <div class="insights_header_action_btn">
        <ag-calendar
          label="Start Date"
          @update:startDate="(value) => (start_date = value)"
          calendar-name="startDate"
          :max-date="new Date()"
          :date="start_date"
        />
        <ag-calendar
          label="End Date"
          @update:endDate="(value) => (end_date = value)"
          calendar-name="endDate"
          :max-date="new Date()"
          :date="end_date"
          :min-date="start_date"
        />
        <AGButton
          variant="primary"
          :is-loading="isLoading"
          class="fire_btn_wrap"
          id="fetch_btn"
          icon="refreshIcon"
          @click="fetchData"
          >Fetch</AGButton
        >
      </div>
    </AgCard>

    <AgCard class="chart_card">
      <highcharts
        class="hc"
        :options="NewOrganizationsCreatedChart"
        ref="chart"
      />
    </AgCard>
  </template>
</template>

<script>
import Highcharts from "highcharts";
import { sub, format } from "date-fns";
import exportingInit from "highcharts/modules/exporting";

import { FORMAT_YYY_MM_DD } from "@/ag-portal-common/constants/dateTimeFormats";

exportingInit(Highcharts);

export default {
  data() {
    return {
      start_date: sub(new Date(), { days: 15 }),
      end_date: new Date(),
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters.isFetchingOrganizations;
    },
    organizations() {
      return this.$store.getters.organizations || [];
    },
    NewOrganizationsCreatedChart() {
      return {
        chart: {
          type: "column",
        },
        title: {
          text: "Organizations Count by Sector",
        },
        xAxis: {
          categories: ["Sector Breakdown"],
          crosshair: true,
          accessibility: {
            description: "Countries",
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Number of Organizations",
          },
        },
        tooltip: {
          useHTML: true,
          formatter: function () {
            const { series, point } = this;
            return (
              `<tr><td style="color:${series.color};padding:0">${series.name}: </td>` +
              `<td style="padding:0"><b>${point.y}</b></td></tr>` +
              `</table>`
            );
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        series: this.generateOrganizationsSeriesData(),
      };
    },
    minDate() {
      const minDate = sub(this.date, { days: 7 });
      return minDate;
    },
  },
  methods: {
    generateOrganizationsSeriesData() {
      if (this.organizations.length === 0) {
        // If organizations are empty, create a default series with zero counts for each sector
        const defaultSeries = [
          {
            name: "Aergolobe",
            data: ["No organizations Found"],
          },
        ];
        return defaultSeries;
      }

      const sectorCounts = this.organizations.reduce((counts, org) => {
        const sector = org.sector;
        counts[sector] = (counts[sector] || 0) + 1;
        return counts;
      }, {});

      // Convert the map to Highcharts series format
      const seriesData = Object.keys(sectorCounts).map((sector) => ({
        name: sector,
        data: [sectorCounts[sector]],
      }));

      return seriesData;
    },
    fetchData() {
      const payload = {
        start_date: format(this.start_date, FORMAT_YYY_MM_DD),
        end_date: format(this.end_date, FORMAT_YYY_MM_DD),
      };
      this.$store.dispatch("fetchOrganizationsByCreationDate", payload);
    },
  },
  beforeMount() {
    this.fetchData();
  },
  unmounted() {
    this.$store.dispatch("saveOrganizations", []);
  },
};
</script>

<style lang="scss" scoped>
.heading {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  font-weight: 500;
}
.insights_header {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
    text-align: center;
  }
}

.insights_header_action_btn {
  display: flex;
  align-items: center;

  > :first-child {
    margin-right: 10px;
  }
  > :nth-child(2) {
    margin-right: 10px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    text-align: center;
  }
}
.chart_card {
  margin-top: 20px;
}
</style>
