import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d9169004"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "icon-m-check"
}
const _hoisted_2 = {
  key: 1,
  class: "icon-m-cross"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!

  return (_ctx.isCheck)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_MIcon, {
          name: "m-check",
          class: "icon"
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_MIcon, {
          name: "m-cross",
          class: "icon"
        })
      ]))
}