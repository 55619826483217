import { MutationTree } from "vuex";
import { IPaymentState } from "@/interfaces/paymentState.interface";
import {
  IPayment,
  IPayments,
  IPaymentMethodsByAeroglobeSector,
} from "@/interfaces/payments.interface";

const mutations: MutationTree<IPaymentState> = {
  enableIsFetchingPayments(state) {
    state.isFetchingPayments = true;
  },
  disbaleIsFetchingPayments(state) {
    state.isFetchingPayments = false;
  },
  enableIsFetchingPayment(state) {
    state.isFetchingPayment = true;
    state.payment = null;
  },
  enableIsCreatingPayment(state) {
    state.isCreatingPayment = true;
  },
  disableIsCreatingPayment(state) {
    state.isCreatingPayment = false;
  },
  savePayments(state, payload: IPayments) {
    state.payments = payload;
    state.isFetchingPayments = false;
  },
  savePayment(state, payload: IPayment) {
    state.payment = payload;
    state.isFetchingPayment = false;
  },
  setPaymentsByMethods(state, payload: IPaymentMethodsByAeroglobeSector) {
    state.paymentsByMethods = payload;
  },
};

export default mutations;
