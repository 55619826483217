<template>
  <FlightIssuanceChart />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FlightIssuanceChart from "@/modules/Dashboard/components/FlightIssuanceChart.vue";

export default defineComponent({
  name: "DailyIssuancePage",
  components: {
    FlightIssuanceChart,
  },
});
</script>
