<template>
  <ag-modal :is-open="isOpen" @close="onCloseModal" modal-width="40%">
    <template #body>
      <AgHeading title="Delete Agent" variant="h3" test-id="" />
      <AgDiv
        >Are you sure you want to delete <b>{{ agent?.user.email }}</b
        >?</AgDiv
      >
      <AgDiv class="btn_container">
        <AGButton
          class="reject_btn"
          test-id=""
          @click="onCloseModal"
          variant="danger"
          type="button"
          >No
        </AGButton>
        <AGButton
          :disbaled="$store.getters.isDeletingAgentFromOrganization"
          :is-loading="$store.getters.isDeletingAgentFromOrganization"
          test-id=""
          @click="onDelete"
          variant="primary"
          type="button"
          >Yes</AGButton
        >
      </AgDiv>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { IAgentV2 } from "@/ag-portal-common/interfaces/agent.interface";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";

export default defineComponent({
  name: "AgentDeleteConfirmationModal",
  events: ["onCloseModal", "onDeleteAgent"],
  props: {
    agent: { type: Object as PropType<IAgentV2>, required: true },
    isOpen: { type: Boolean, required: true },
  },
  data(): {
    ELEMENT_TYPES: IObject;
  } {
    return {
      ELEMENT_TYPES,
    };
  },
  methods: {
    genTestId,
    onCloseModal() {
      this.$emit("onCloseModal");
    },
    onDelete() {
      this.$emit("onDeleteAgent");
    },
  },
});
</script>

<style scoped>
.btn_container {
  display: flex;
  float: right;
}
.reject_btn {
  margin-right: 10px;
}
</style>
