import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgHeading = _resolveComponent("AgHeading")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createBlock(_component_ag_modal, {
    "is-open": _ctx.isOpen,
    "modal-width": "40%",
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('handleClose')))
  }, {
    body: _withCtx(() => [
      _createVNode(_component_AgHeading, {
        title: "Unlink Financial Profile",
        variant: "h3",
        "test-id": ""
      }),
      _createVNode(_component_AgDiv, null, {
        default: _withCtx(() => [
          _createTextVNode("Are you sure to Unlink "),
          _createElementVNode("b", null, _toDisplayString(_ctx.financial_profile_name), 1 /* TEXT */),
          _createTextVNode("?")
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_AgDiv, { class: "btn_container" }, {
        default: _withCtx(() => [
          _createVNode(_component_AGButton, {
            class: "reject_btn",
            "test-id": "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('handleClose'))),
            variant: "danger",
            type: "button"
          }, {
            default: _withCtx(() => [
              _createTextVNode("No ")
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_AGButton, {
            disbaled: _ctx.isLoading,
            "is-loading": _ctx.isLoading,
            "test-id": "",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('handleAccept'))),
            variant: "primary",
            type: "button"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Yes")
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disbaled", "is-loading"])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-open"]))
}