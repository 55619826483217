import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ad93bc7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tile" }
const _hoisted_2 = { class: "icon-container" }
const _hoisted_3 = { class: "value-container" }
const _hoisted_4 = { class: "value" }
const _hoisted_5 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.value), 1 /* TEXT */),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.title), 1 /* TEXT */)
    ]),
    _renderSlot(_ctx.$slots, "button", {}, undefined, true)
  ]))
}