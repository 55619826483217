<template>
  <DashboardGreetingsCard user="Sales User" />
  <SalesMyTeam />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DashboardGreetingsCard from "../DashboardGreetingsCard.vue";
import SalesMyTeam from "@/modules/Sales/components/SalesMyTeam.vue";

export default defineComponent({
  name: "SalesUserDashboardView",
  components: { DashboardGreetingsCard, SalesMyTeam },
});
</script>
